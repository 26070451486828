import * as React from "react";
import { Component } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { nl } from 'date-fns/esm/locale';

import IAdminMessageFormProps from "./interfaces/IAdminMessageFormProps";
import IAdminMessageFormState from "./interfaces/IAdminMessageFormState";

import LanguageProvider from "../../../providers/languageProvider";
import IAdministratorMessage from "../../../interfaces/IAdministratorMessage";
import AdminMessagePriority from "../../../enums/adminMessagePriority";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import AdminMessageService from "../../../services/adminMessageService";

export default class AdminMessageForm extends Component<IAdminMessageFormProps, IAdminMessageFormState> {
    private readonly customerSeperator: string = ';';

    private readonly adminMessageService: AdminMessageService;

    public constructor(props: IAdminMessageFormProps) {
        super(props);

        this.adminMessageService = new AdminMessageService();

        const state: IAdminMessageFormState = {
            adminMessage: this.props.adminMessage,
            loading: false,
            prioritySelectOptions: [],
            isFormValid: false
        };

        this.state = state;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCustomerSelectChange = this.onCustomerSelectChange.bind(this);
        this.renderCustomerSelect = this.renderCustomerSelect.bind(this);
        this.getPriorityOptions = this.getPriorityOptions.bind(this);
        this.renderPrioritySelect = this.renderPrioritySelect.bind(this);
        this.onPrioritySelectChange = this.onPrioritySelectChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    public componentWillMount(): void {
        this.getPriorityOptions();
    }

    public componentDidMount(): void {
        this.validateForm();

        registerLocale('nl', nl);
        setDefaultLocale('nl');
    }

    public async getPriorityOptions(): Promise<void> {
        const priorityOptions: IReactSelectValue[] = [];
        const priorityValues = Object.keys(AdminMessagePriority).filter(p => typeof AdminMessagePriority[p as any] === "number");

        priorityValues.forEach(p => {
            const selectOption: IReactSelectValue = {
                label: LanguageProvider.getTranslation(`pages.adminmessagecenter.messagepriority.${p.toLowerCase()}`),
                value: priorityValues.indexOf(p).toString(),
            };
            priorityOptions.push(selectOption);
        });

        this.setState({
            prioritySelectOptions: priorityOptions,
        });
    }

    public async onCustomerSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): Promise<void> {
        let customerIds: string;
        const newMessage = this.state.adminMessage;

        if (optionSelected === null || optionSelected === undefined) {
            customerIds = '';
            newMessage.customerIds = customerIds;
            this.setState({ adminMessage: newMessage }, () => this.validateForm());
            return;
        }

        const optionValues: string[] = [];

        (optionSelected as IReactSelectValue[]).map(option => optionValues.push(option.value));
        customerIds = optionValues.join(this.customerSeperator);
        newMessage.customerIds = customerIds;
        this.setState({
            adminMessage: newMessage
        }, () => this.validateForm());
    }

    public async onPrioritySelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): Promise<void> {
        if (optionSelected === null || optionSelected === undefined) {
            return;
        }

        const priority = parseInt((optionSelected as IReactSelectValue).value, 10);

        if (isNaN(priority)) {
            return;
        }
        const newMessage = this.state.adminMessage;

        newMessage.priority = priority;
        this.setState({
            adminMessage: newMessage
        });
    }

    public renderCustomerSelect(): JSX.Element {
        if (this.props.customerSelectOptions.length === 0) {
            return <div />;
        }
        const preSelectedCustomers: IReactSelectValue[] = [];

        if (this.props.adminMessage.customerIds) {
            const customerIds = this.props.adminMessage.customerIds.split(this.customerSeperator);

            this.props.customerSelectOptions.forEach(option => {
                customerIds.forEach(id => {
                    if (id === option.value) {
                        preSelectedCustomers.push(option);
                    }
                });
            });
        }

        return <Select
            defaultValue={preSelectedCustomers}
            options={this.props.customerSelectOptions}
            onChange={this.onCustomerSelectChange}
            isClearable={true}
            isMulti={true}
        />;
    }

    public renderPrioritySelect(): JSX.Element {
        let currentOption: IReactSelectValue | undefined;

        if (this.props.adminMessage.priority !== undefined) {
            currentOption = this.state.prioritySelectOptions.find(option => {
                return parseInt(option.value, 10) === this.props.adminMessage.priority;
            });
        }

        return (
            <Select
                defaultValue={currentOption}
                options={this.state.prioritySelectOptions}
                onChange={this.onPrioritySelectChange}
                isClearable={true}
            />
        );
    }

    public async handleInputChange(event: any): Promise<void> {
        const newMessage = this.state.adminMessage;
        const target = event.target;
        const inputType = target.type;
        const input = inputType === "checkbox" ? target.checked : target.value;
        const name = target.name;
        if (name) {
            newMessage[name] = input;
            this.setState({ adminMessage: newMessage }, () => this.validateForm());
        }
    }

    public async handleSubmit(): Promise<void> {
        this.setState({ loading: true });

        if (this.props.isNewMessage) {
            try {
                await this.adminMessageService.createNewAdminMessage(this.state.adminMessage);
            } catch (error) {
                this.setState({ loading: false });
                return NotificationManager.error(LanguageProvider.getTranslation('pages.adminmessagecenter.errormessagecreate'));
            }
        } else {
            try {
                await this.adminMessageService.putAdminMessage(this.state.adminMessage);
            } catch (error) {
                this.setState({ loading: false });
                return NotificationManager.error(LanguageProvider.getTranslation('pages.adminmessagecenter.errormessageupdate'));
            }
        }

        const emptyFormInput: IAdministratorMessage = {
            customerIds: '',
            isVisibleForAllCustomers: false,
            body: '',
            priority: AdminMessagePriority.Low,
            title: '',
            startDate: moment().format(),
            endDate: moment().add(1, 'days').format(),
            rowKey: '',
        };

        this.setState({
            adminMessage: emptyFormInput,
            loading: false
        });

        NotificationManager.info(LanguageProvider.getTranslation(`pages.adminmessagecenter.${this.props.isNewMessage ? 'messagecreated' : 'succesfullyupdated'}`));
        await this.props.refreshMessages();
    }

    private async handleStartDateChange(date: Date | null): Promise<void> {
        if (date === null) {
            return;
        }
        const momentDate = moment(date);

        const newMessage = this.state.adminMessage;
        newMessage.startDate = momentDate.format();
        this.setState({
            adminMessage: newMessage
        });
    }

    private async handleEndDateChange(date: Date | null): Promise<void> {
        if (date === null) {
            return;
        }
        const momentDate = moment(date);

        const newMessage = this.state.adminMessage;
        newMessage.endDate = momentDate.format();
        this.setState({
            adminMessage: newMessage
        });
    }

    private validateForm(): void {
        const isFormValid = (this.state.adminMessage.isVisibleForAllCustomers ||
            this.state.adminMessage.customerIds.length !== 0) &&
            (this.state.adminMessage.title.length >= 1 ||
                this.state.adminMessage.body.length >= 1);

        const currentlyValid = this.state.isFormValid;
        if (currentlyValid === isFormValid) {
            return;
        }

        this.setState({
            isFormValid: isFormValid
        });
    }

    public render(): JSX.Element {
        return (
            <div className="new-message-container ">
                {!this.state.loading && <h3>{LanguageProvider.getTranslation(`pages.adminmessagecenter.${this.props.isNewMessage ? 'newmessageheader' : 'editmessageheader'}`)}</h3>}

                <div className="row">
                    {!this.state.loading && <div className="admin-message-input-form col-md-6">
                        <label>
                            {LanguageProvider.getTranslation('pages.adminmessagecenter.title')}
                            <input
                                type="text"
                                value={this.state.adminMessage.title}
                                onChange={this.handleInputChange}
                                name="title"
                                autoComplete="off"
                                maxLength={100}
                            />
                        </label>

                        <label>
                            {LanguageProvider.getTranslation('pages.adminmessagecenter.message')}
                            <textarea
                                value={this.state.adminMessage.body}
                                onChange={this.handleInputChange}
                                name="body"
                                maxLength={250}
                            />
                        </label>

                        <label className="input-label">
                            {LanguageProvider.getTranslation('pages.adminmessagecenter.startdate')}
                            <DatePicker
                                selected={this.state.adminMessage.startDate ? moment(this.state.adminMessage.startDate).toDate() : moment().toDate()}
                                maxDate={moment(this.state.adminMessage.endDate).toDate()}
                                onChange={this.handleStartDateChange}
                                placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
                                className="filter-datepicker"
                                todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                dateFormat="dd-MM-yyyy, HH:mm"
                                timeCaption={LanguageProvider.getTranslation("datepicker.time")}
                            />
                        </label>

                        <label className="input-label">
                            {LanguageProvider.getTranslation('pages.adminmessagecenter.enddate')}

                            <DatePicker
                                selected={this.state.adminMessage.endDate ? moment(this.state.adminMessage.endDate).toDate() : moment().toDate()}
                                minDate={moment(this.state.adminMessage.startDate).toDate()}
                                onChange={this.handleEndDateChange}
                                placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
                                className="filter-datepicker"
                                todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                dateFormat="dd-MM-yyyy, HH:mm"
                                timeCaption={LanguageProvider.getTranslation("datepicker.time")}
                            />
                        </label>

                        <label className="input-label">
                            {LanguageProvider.getTranslation('pages.adminmessagecenter.visibleforallcustomers')}
                            <input
                                name="isVisibleForAllCustomers"
                                type="checkbox"
                                checked={this.state.adminMessage.isVisibleForAllCustomers}
                                onChange={this.handleInputChange} />
                        </label>

                        <label>
                            {LanguageProvider.getTranslation('pages.adminmessagecenter.customers')}
                            {this.props.customerSelectOptions.length > 0 && this.renderCustomerSelect()}
                        </label>

                        <label>
                            {LanguageProvider.getTranslation('pages.adminmessagecenter.priority')}
                            {this.state.prioritySelectOptions && this.renderPrioritySelect()}
                        </label>


                        <button
                            className={`besense-button yellow-button ${!this.state.isFormValid ? 'disabled' : 'clickable'} `}
                            onClick={!this.state.isFormValid ? (): void => window.alert(LanguageProvider.getTranslation("pages.adminmessagecenter.validationerror")) : this.handleSubmit}>
                            {LanguageProvider.getTranslation('buttons.submit')}
                        </button>
                        <button className="besense-button blue-button clickable" onClick={this.props.handleCancel}>{LanguageProvider.getTranslation('buttons.cancel')}</button>
                    </div>
                    }
                </div>
            </div>
        );
    }
}