import { AccountInfo } from "@azure/msal-common";
import { PublicClientApplication } from "@azure/msal-browser";
import {ApplicationConfig} from "../config";
import MaintenanceService from './maintenanceService';
import MaintenanceUserAuthenticator from '../authenticators/maintenanceUserAuthenticator';

export default class AccessService {

    private maintenanceService: MaintenanceService;
    private readonly publicClientApplication : PublicClientApplication;
    private readonly maintenanceUserAuthenticator : MaintenanceUserAuthenticator;

    public constructor() {
        this.publicClientApplication = new PublicClientApplication({
            auth: {
                clientId: ApplicationConfig.ad.clientId,
                authority: `https://login.microsoftonline.com/${ApplicationConfig.ad.clientId}`,
                redirectUri: ApplicationConfig.ad.redirectUri,
            },
            cache: {
                cacheLocation: ApplicationConfig.ad.cacheLocation
            }
        });
        this.maintenanceService = new MaintenanceService();
        this.maintenanceUserAuthenticator = new MaintenanceUserAuthenticator();


        this.ensureDocumentsAccess = this.ensureDocumentsAccess.bind(this);
        this.ensureDeviceManagementAccess = this.ensureDeviceManagementAccess.bind(this);
        this.ensureAdminMessagesAccess = this.ensureAdminMessagesAccess.bind(this);
        this.ensureUserManagementAccess = this.ensureUserManagementAccess.bind(this);
        this.performAccessRequest = this.performAccessRequest.bind(this);
    }

    public signIn(success: () => void, failure: () => void): void {

        this.maintenanceUserAuthenticator.login()
        .then(()=> this.ensureAccess())
        .then(allowed => allowed ? success() : failure());
    }

    public getUser(): AccountInfo | null {
        return this.publicClientApplication.getActiveAccount();
    }

    public signOut(): void {
        this.publicClientApplication.logoutRedirect();
        sessionStorage.clear();
        window.location.href = "https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=" + encodeURIComponent(process.env.REACT_APP_LogoutReturnUrl || window.location.protocol + "//" + window.location.host);
    }

    public getName(): string {
        const user = this.getUser();
        return user ? user.name ?? "Unknown" : "Unknown";
    }

    public getUserName(): string {
        const user = this.getUser();
        return user ? user.username : "Unknown";
    }

    public async ensureDocumentsAccess(): Promise<boolean> {
        return this.performAccessRequest("access/documents");
    }

    public async ensureDeviceManagementAccess(): Promise<boolean> {
        return this.performAccessRequest("access/devicemanagement");
    }

    public async ensureAdminMessagesAccess(): Promise<boolean> {
        return this.performAccessRequest("access/adminmessages");
    }

    public async ensureUserManagementAccess(): Promise<boolean> {
        return this.performAccessRequest("access/usermanagement");
    }

    private async ensureAccess(): Promise<boolean> {
        return this.performAccessRequest("access");
    }

    private async performAccessRequest(url: string): Promise<boolean> {
        return this.maintenanceService.get<boolean>(url).catch(e => false);
    }
}