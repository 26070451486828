import AppEventHub, { AppEvents } from "../utils/appEventHub";
import { ApplicationConfig } from "../config";
import CoreSpace from "../models/coreSpace";
import ICoreSpace from "../interfaces/ICoreSpace";


export default class VenueProvider {
    private static venueStorageKey: string = `bs.${ApplicationConfig.environment}.active.venue`;

    public static saveActiveVenue(venue: CoreSpace): void {
        if (!venue) {
            return;
        }

        sessionStorage.setItem(VenueProvider.venueStorageKey, JSON.stringify(venue));
        AppEventHub.emit(AppEvents.BuildingSelected);
    }

    public static getActiveVenue(): CoreSpace | undefined {
        const json = sessionStorage.getItem(VenueProvider.venueStorageKey);
        if (!json || json.length <= 0) {
            return undefined;
        }

        const venue = JSON.parse(json) as ICoreSpace;
        return new CoreSpace(venue);
    }

    public static clear(): void {
        sessionStorage.removeItem(VenueProvider.venueStorageKey);
    }
}