import IAdministratorMessage from "../interfaces/IAdministratorMessage";
import MaintenanceService from "./maintenanceService";

export default class AdminMessageService {
    private readonly maintenanceService: MaintenanceService;

    public constructor() {
        this.maintenanceService = new MaintenanceService();
    }

    public async createNewAdminMessage(newMessage: IAdministratorMessage): Promise<Response> {
        return await this.maintenanceService.post(`AdministratorMessage`, newMessage);
    }

    public async getAdminMessages(): Promise<IAdministratorMessage[]> {
        return await this.maintenanceService.get<IAdministratorMessage[]>(`AdministratorMessage`, true);
    }

    public async putAdminMessage(message: IAdministratorMessage): Promise<Response> {
        return await this.maintenanceService.put(`AdministratorMessage`, message);
    }

    public async deleteAdminMessage(rowKey: string): Promise<Response> {
        return await this.maintenanceService.delete(`AdministratorMessage/deletemessage/${rowKey}`);
    }
}