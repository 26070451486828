import * as React from "react";
import { NotificationManager } from 'react-notifications';
import { withLocalize, Translate } from 'react-localize-redux';

import IAddDeviceModalProps from "./interfaces/IAddDeviceModalProps";
import IAddDeviceModalState from "./interfaces/IAddDeviceModalState";
import ClickHandler from "../../../components/utils/clickHandler";

import Yellow_Arrow_Dropdown from "../../../images/Yellow_Arrow_Dropdown.svg";
import './addDeviceModal.scss';
import Yellow_Cross_Cancel from "../../../images/Yellow_Cross_Cancel.svg";
import Arrow from "../../../images/Yellow_Arrow_Right.svg";

import LanguageProvider from "../../../providers/languageProvider";
import VenueProvider from '../../../providers/venueProvider';
import DeviceService from '../../../services/deviceService';
import DeviceAssetChange from "../../../models/deviceAssetChange";
import { ApplicationConfig } from "../../../config";
import Device from "../../../models/device";
import TargetType from "../../../enums/targetType";
import MaintenanceService from "../../../services/maintenanceService";
import CoreSpaceService from "../../../services/coreSpaceService";
import AssetManagementService from "../../../services/assetManagementService";
import WarningSign from "../../../images/WarningSign.svg";

class AddDeviceModal extends React.Component<IAddDeviceModalProps, IAddDeviceModalState> {

    private defaultDeviceId: string = "0059AC00001A";
    private beSenseStockName: string = "Voorraad BeSense";

    private deviceService: DeviceService;
    private spaceService: CoreSpaceService;
    private assetManagementService: AssetManagementService;

    private currentDeviceRefresh: NodeJS.Timeout;

    private maintenanceService: MaintenanceService;
    private deviceInputRef: React.RefObject<HTMLInputElement>;

    public constructor(props: IAddDeviceModalProps) {
        super(props);

        const venue = VenueProvider.getActiveVenue();

        const state: IAddDeviceModalState = {
            inputDeviceHardwareId: "",
            loading: false,
            showNotification: false,
            notificationText: "",
            visible: false,
            connectedDevices: [],
            venueId: venue?.id ?? "",
            isInEditNameMode: false
        };

        this.state = state;

        this.deviceService = new DeviceService();
        this.spaceService = new CoreSpaceService();
        this.assetManagementService = new AssetManagementService();

        this.maintenanceService = new MaintenanceService();

        this.deviceInputRef = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.setInputDeviceId = this.setInputDeviceId.bind(this);
        this.sendAssetChangeAsync = this.sendAssetChangeAsync.bind(this);
        this.onAssetChange = this.onAssetChange.bind(this);
        this.onMoveToStock = this.onMoveToStock.bind(this);
        this.onMoveToMalfunctioning = this.onMoveToMalfunctioning.bind(this);
        this.onMoveToLost = this.onMoveToLost.bind(this);
        this.renderAssetInsightPage = this.renderAssetInsightPage.bind(this);
        this.enableEditNameMode = this.enableEditNameMode.bind(this);
        this.saveAssetNameAsync = this.saveAssetNameAsync.bind(this);
    }

    private async handleSubmit(event: any): Promise<void> {
        event.preventDefault();

        this.setState({
            showNotification: false,
        });

        if (!this.validateInput()) {
            this.setState({
                showNotification: true,
                notificationText: LanguageProvider.getTranslation("pages.map.adddevice.invalidid")
            });

            return;
        }

        const device = await this.deviceService.getDeviceByDeviceId(this.state.inputDeviceHardwareId, true);
        if (!device) {
            this.setState({
                showNotification: true,
                notificationText: LanguageProvider.getTranslation("pages.map.adddevice.devicenotfound")
            });

            return;
        }

        if (device.spaceName !== this.beSenseStockName) {
            const allCustomers = await this.spaceService.getCustomers();
            const matchingCustomer = allCustomers.find(c => c.id === device.customerId);
            const noSupplyText = LanguageProvider.getTranslation("pages.map.adddevice.notinsupply");
            const deviceSpaceText = LanguageProvider.getTranslation("pages.map.adddevice.devicefoundinspace");
            const customerText = LanguageProvider.getTranslation("pages.map.adddevice.andcustomer");
            const notificationText = `${noSupplyText}. ${deviceSpaceText} '${device.spaceName}' ${customerText} '${matchingCustomer?.name}'`;

            const shouldMove = window.confirm(`${notificationText}\n\n${LanguageProvider.getTranslation("pages.map.adddevice.assetoccupiedconfirmation")}`);
            if (!shouldMove) {
                return;
            }
        }

        await this.onAssetChange(device);
    }

    private async onAssetChange(device: Device): Promise<void> {
        const deviceAssetChange = new DeviceAssetChange(device.id, TargetType.Space, this.props.spaceId);
        await this.sendAssetChangeAsync(deviceAssetChange);
    }

    private enableEditNameMode(): void {
        this.setState({
            isInEditNameMode: true,
            showNotification: false
        });
    }

    private async saveAssetNameAsync(): Promise<void> {
        if (!this.state.newSpaceNameValue) {
            return;
        }

        const success = await this.assetManagementService.setSpaceNameAsync(this.props.spaceId, this.state.newSpaceNameValue);

        if (success) {
            NotificationManager.success(LanguageProvider.getTranslation("pages.map.adddevice.namechangedsuccess"));
            this.props.onSpaceNameChange && this.props.onSpaceNameChange(this.props.spaceId, this.state.newSpaceNameValue);
            this.resetForm();
        }
        else {
            NotificationManager.success(LanguageProvider.getTranslation("pages.map.adddevice.namechangederror"));
        }
    }

    private async onMoveToStock(hardwareId: string | undefined): Promise<void> {
        if (hardwareId === undefined) {
            return;
        }

        const shouldMove = window.confirm(`${hardwareId}\n\n${LanguageProvider.getTranslation("pages.map.adddevice.movetostock")}`);
        if (!shouldMove) {
            return;
        }

        const deviceAssetChange = new DeviceAssetChange(hardwareId, TargetType.Stock);
        await this.sendAssetChangeAsync(deviceAssetChange);
    }

    private async onMoveToMalfunctioning(hardwareId: string | undefined): Promise<void> {
        if (hardwareId === undefined) {
            return;
        }

        const shouldMove = window.confirm(`${hardwareId}\n\n${LanguageProvider.getTranslation("pages.map.adddevice.movetomalfunction")}`);
        if (!shouldMove) {
            return;
        }

        const deviceAssetChange = new DeviceAssetChange(hardwareId, TargetType.Malfunction);
        await this.sendAssetChangeAsync(deviceAssetChange);
    }

    private async onMoveToLost(hardwareId: string | undefined): Promise<void> {
        if (hardwareId === undefined) {
            return;
        }
        const shouldMove = window.confirm(`${hardwareId}\n\n${LanguageProvider.getTranslation("pages.map.adddevice.movetolost")}`);
        if (!shouldMove) {
            return;
        }

        const deviceAssetChange = new DeviceAssetChange(hardwareId, TargetType.Lost);
        await this.sendAssetChangeAsync(deviceAssetChange);
    }

    private async sendAssetChangeAsync(deviceAssetChange: DeviceAssetChange): Promise<void> {
        const response = await this.maintenanceService.sendDeviceAssetChange(deviceAssetChange);

        if (response.ok) {
            NotificationManager.success(LanguageProvider.getTranslation("pages.map.adddevice.deviceconnected"));
        }
        else {
            if (response.status === 403) {
                NotificationManager.error(LanguageProvider.getTranslation("pages.map.adddevice.missingrights"));
            }
            else {
                NotificationManager.error(LanguageProvider.getTranslation("pages.map.adddevice.genericerror"));
            }
        }

        this.resetForm();
    }

    private resetForm(): void {
        this.setState({
            notificationText: "",
            inputDeviceHardwareId: this.defaultDeviceId,
            newSpaceNameValue: undefined,
            showNotification: false,
            visible: false,
            isInEditNameMode: false
        });

        const node = this.deviceInputRef.current;
        if (node) {
            node.value = this.defaultDeviceId;
        }

        this.props.onClose();
    }

    private setInputDeviceId(event: any): void {
        this.setState({
            inputDeviceHardwareId: event.target.value
        });
    }

    private validateInput(): boolean {
        return true;
    }

    private async refreshDevices(): Promise<void> {
        this.currentDeviceRefresh = setTimeout(async () => {
            await this.refreshDevices();
        }, 3000);

        const devices = await this.deviceService.getDevicesBySpaceId(this.props.spaceId, true);
        this.setState({
            connectedDevices: devices
        });
    }

    public async componentDidUpdate(prevProps: IAddDeviceModalProps, prevState: IAddDeviceModalState): Promise<void> {
        if (prevProps.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible
            });

            if (this.props.visible) {
                await this.refreshDevices();
                const node = this.deviceInputRef.current;
                if (node) {
                    node.focus();
                    node.select();
                }
            }
            else if (this.currentDeviceRefresh) {
                clearTimeout(this.currentDeviceRefresh);
            }
        }
    }

    public renderAssetInsightPage(): void {
        window.location.href = `/map/insights/assets=${this.props.spaceId}`;
    }

    public render(): JSX.Element {
        return (
            <div className={"add-device-modal modal " + (this.state.visible ? "d-block" : "d-none")} id="add-device-modal" role="dialog" aria-labelledby="addDeviceModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form onSubmit={this.handleSubmit}>
                            <div className="modal-header add-device-header">
                                <h5 className="modal-title" id="addDeviceModalLabel">
                                    <Translate id="pages.map.adddevice.title" />
                                </h5>
                                <button type="button" className="close" onClick={this.resetForm} aria-label="Close">
                                    <img src={Yellow_Cross_Cancel} alt="Cancel" />
                                </button>
                            </div>
                            <span>
                                <div className="modal-body">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {this.props.spaceId === this.props.spaceName && <img src={WarningSign} className="warning-sign" />}
                                                    <b>{`${LanguageProvider.getTranslation("pages.map.adddevice.name")}`}</b>
                                                </td>
                                                {!this.state.isInEditNameMode &&
                                                    <>
                                                        <td><a target="_blank" rel="noopener noreferrer" href={`${ApplicationConfig.endpoints.dynamics}main.aspx?etn=bs_asset&pagetype=entityrecord&id=%7B${this.props.crmId}%7D`}>{`${this.props.spaceName}`}</a></td>
                                                        <td><ClickHandler
                                                            onClick={this.enableEditNameMode}
                                                            name={`${LanguageProvider.getTranslation("pages.map.adddevice.edit")}`} /></td>
                                                    </>}
                                                {this.state.isInEditNameMode &&
                                                    <>
                                                        <td><input
                                                            value={this.state.newSpaceNameValue}
                                                            onChange={(e): void => this.setState({
                                                                newSpaceNameValue: e.target.value
                                                            })}
                                                            placeholder={this.props.spaceName}
                                                            size={36}
                                                            type="text" /></td>
                                                    </>}
                                            </tr>
                                            <tr><td className="pt-3" /></tr>
                                            <tr>
                                                <td><b>{`${LanguageProvider.getTranslation("pages.map.adddevice.connected")}`}</b></td>
                                            </tr>
                                            {this.state.connectedDevices && this.state.connectedDevices.length > 0 ? this.state.connectedDevices.map((device) => (
                                                <tr key={device.id}>
                                                    <td className="hide-overflow">{device.type}</td>
                                                    <td>{device.id}</td>
                                                    {!this.state.isInEditNameMode && <>
                                                        <td><ClickHandler onClick={this.onMoveToStock} name={`${LanguageProvider.getTranslation("pages.map.adddevice.stock")}`} params={device.id} /></td>
                                                        <td><ClickHandler onClick={this.onMoveToMalfunctioning} name={`${LanguageProvider.getTranslation("pages.map.adddevice.malfunctioning")}`} params={device.id} /></td>
                                                        <td><ClickHandler onClick={this.onMoveToLost} name={`${LanguageProvider.getTranslation("pages.map.adddevice.lost")}`} params={device.id} /></td>
                                                    </>}
                                                </tr>
                                            )) : <tr><td /><td>---</td></tr>}
                                            <tr><td className="pt-3" /></tr>
                                            <tr>
                                                <td><b>{`${LanguageProvider.getTranslation("pages.map.adddevice.newid")}`}</b></td>
                                                {!this.state.isInEditNameMode && <td><input type="text" defaultValue={this.defaultDeviceId} size={36} maxLength={36} id="device-id-input" onBlur={this.setInputDeviceId} ref={this.deviceInputRef} /></td>}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    {this.state.showNotification && <b>{this.state.notificationText}</b>}
                                    {!this.state.isInEditNameMode &&
                                        <>
                                            <button type="submit" className="btn btn-primary send-button" value="Submit">
                                                <Translate id="pages.map.adddevice.add" />
                                                <img className="pl-1" src={Yellow_Arrow_Dropdown} alt="Add" />
                                            </button>

                                            <div id="footer">
                                                <button type="button" className="btn blue-button" onClick={this.renderAssetInsightPage}>{LanguageProvider.getTranslation("mappopup.buttons.insights")} <img src={Arrow} alt="Insights" className="logo" /></button>
                                            </div>
                                        </>
                                    }
                                    {this.state.isInEditNameMode &&
                                        <>
                                            <button type="button" className="btn btn-primary" onClick={this.saveAssetNameAsync}>
                                                <Translate id="buttons.save" />
                                                <img className="pl-1" src={Yellow_Arrow_Dropdown} alt="Add" />
                                            </button>
                                        </>
                                    }
                                </div>
                            </span>
                        </form>

                    </div>
                </div>
            </div >
        );
    }
}

export default withLocalize(AddDeviceModal);