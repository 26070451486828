import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import AssetManagementService from '../../../../services/assetManagementService';
import { useEffect } from 'react';
import { Alert } from '@mui/material';

export interface IAssetFeedbackProps {
    messageType: string;
    floorId: string;
    refreshKey: number;
    onFeedbackStatusCompleted: () => void;
}

interface IAssetFeedbackState {
    open: boolean,
    message: JSX.Element | undefined
}

export const AssetFeedback: React.FC<IAssetFeedbackProps> = (props: IAssetFeedbackProps) => {
    const [state, setState] = React.useState<IAssetFeedbackState>({
        open: false,
        message: undefined
    });

    const { open, message } = state;
    const { messageType, floorId, refreshKey, onFeedbackStatusCompleted } = props;

    const handleClose = (): void => {
        setState({ ...state, open: false });
    };

    useEffect(() => {

        if (refreshKey === 0) {
            return;
        }

        const assetManagementService: AssetManagementService = new AssetManagementService();

        const getFormattedMessage = (message: string): JSX.Element => {
            const splittedMessages = message.split("|");
            return (
                <ul>
                    {splittedMessages.map((message, index) => <li key={index}>{message}</li>)}
                </ul>
            );
        };

        setState({
            open: true,
            message: getFormattedMessage("Getting feedback, please wait ...")
        });

        const intervalId = setInterval(async () => {
            const feedback = await assetManagementService.getFeedback(messageType, floorId);

            if (feedback.message) {
                setState({
                    open: true,
                    message: getFormattedMessage(feedback.message)
                });
            }

            if (feedback.isCompleted) {
                clearInterval(intervalId);
                onFeedbackStatusCompleted();
            }
        }, 3000);

        return (): void => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [messageType, floorId, refreshKey]);

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                    <div style={{ maxHeight: '500px', maxWidth: "1000px" }}>
                        {message}
                    </div>
                </Alert>
            </Snackbar>
        </div >
    );
};