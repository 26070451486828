import * as React from "react";
import "./autodeskUploadPage.scss";
import { PulseLoader } from "react-spinners";
import { useState } from "react";
import { UploadMap } from "./components/uploadMap";
import { PreviewMap } from "./components/previewMap";
import { BasicTooltip } from "../../components/material/infoTooltip";
import Info from "../../images/info_icon.svg";
import LanguageProvider from "../../providers/languageProvider";
import Mapper from "../../translations/mapper";

export const AutodeskUploadPage: React.FC = () => {
    const [showPreview, setShowPreview] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const [venueId, setVenueId] = useState<string>();

    const uploadInfo: JSX.Element = (
        <div dangerouslySetInnerHTML={{ __html: LanguageProvider.getTranslation(Mapper.pages.autodesk.uploadinfo) }} />
    );

    const previewInfo: JSX.Element = (
        <div dangerouslySetInnerHTML={{ __html: LanguageProvider.getTranslation(Mapper.pages.autodesk.previewinfo) }} />
    );

    return (
        <div className="autodesk-upload-page">
            <h3>Autodesk<BasicTooltip icon={Info} content={showPreview ? previewInfo : uploadInfo} /></h3>

            {loading && <div className="disabledPage" />}
            <div style={{ display: showPreview ? "none" : "block" }}>
                <UploadMap setLoading={setLoading} setVenueId={setVenueId} showPreview={setShowPreview} />
            </div>
            {
                showPreview && <PreviewMap
                    showPreview={setShowPreview}
                    venueId={venueId} />
            }
            {loading && (
                <div id="pulse-loader" className="loader">
                    <PulseLoader
                        color="#37a0e6"
                        size={8}
                        margin="15px"
                        loading={loading}
                    />
                </div>
            )}
        </div>
    );
};

export default AutodeskUploadPage;