import * as React from 'react';
import ReactTable, { Column, RowInfo, FinalState } from 'react-table';
import { PulseLoader } from 'react-spinners';
import queryString from "query-string";
import { NotificationManager } from 'react-notifications';
import { withLocalize, Translate } from 'react-localize-redux';

import "./deviceConfigPage.scss";
import 'react-table/react-table.css';

import IDeviceConfigPageProps from './interfaces/IDeviceConfigPageProps';
import IDeviceConfigPageState from './interfaces/IDeviceConfigPageState';
import DropDownMenu from './components/dropDownMenu';
import DeviceConfigSettingsPane from './components/deviceConfigSettingsPane';

import ChangedDeviceConfig from '../../models/changedDeviceConfig';
import DownLinkProperty from '../../models/downLinkProperty';
import DeviceService from '../../services/deviceService';
import CoreSpaceService from '../../services/coreSpaceService';
import Dictionary from '../../utils/dictionary';
import { ReactTableUtils } from '../../utils/reactTableUtils';
import { IKeyedCollection } from "../../utils/dictionary";
import SettingsDescriptionOverview from './components/settingsDescriptionOverview';
import Device from '../../models/device';
import MaintenanceService from '../../services/maintenanceService';
import DeviceConfigurationExtractor from './utils/deviceConfigurationExtractor';

class DeviceConfigPage extends React.Component<IDeviceConfigPageProps, IDeviceConfigPageState> {

    private deviceService: DeviceService;
    private coreSpaceService: CoreSpaceService;
    private maintenanceService: MaintenanceService;

    private unknownDeviceType: string = 'Unknown';

    public constructor(props: IDeviceConfigPageProps) {
        super(props);
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
        this.applyDeviceConfigHandler = this.applyDeviceConfigHandler.bind(this);
        this.deviceService = new DeviceService();
        this.coreSpaceService = new CoreSpaceService();
        this.maintenanceService = new MaintenanceService();

        const q = queryString.parse(this.props.location.search);
        let selectedCustomerId = '';
        if (q.customerId !== undefined && q.customerId !== null) {
            selectedCustomerId = (q.customerId as string);
        }

        const state: IDeviceConfigPageState = {
            tableLoading: false,
            loading: q.customerId === undefined,
            customers: [],
            selectedCustomerId: selectedCustomerId,
            deviceConfigurations: [],
            selectedDeviceConfigurations: new Dictionary<Device>(),
            availableDownLinkProperties: [],
            tableColumns: [],
            configEditTableColumns: [],
            selectedRowIndex: null,
            customerIdFromQueryString: q.customerId !== undefined && q.customerId !== null,
            availableDeviceTypes: [],
        };

        this.state = state;
    }

    public async componentDidMount(): Promise<void> {
        if (this.state.customerIdFromQueryString) {
            await this.loadDeviceConfiguration(this.state.selectedCustomerId);
        }
        else {
            const customers = await this.coreSpaceService.getCustomers();
            this.setState({
                customers,
                loading: false
            });
        }
    }

    private async onCustomerSelect(spaceId: string): Promise<void> {
        await this.loadDeviceConfiguration(spaceId);
    }

    private async loadDeviceConfiguration(spaceId: string): Promise<void> {
        this.setState({
            tableLoading: true,
            selectedDeviceConfigurations: new Dictionary<Device>()
        });

        const deviceTypes = await this.deviceService.getDeviceTypes();
        const devices = await this.deviceService.getDevicesForCustomerId(spaceId, true);
        const downLinkProperties = DeviceConfigurationExtractor.extractConfiguration(devices);
        const filteredDeviceTypes = deviceTypes.filter((e) => e.displayValue !== this.unknownDeviceType);

        this.setState({
            selectedCustomerId: spaceId,
            deviceConfigurations: devices,
            tableLoading: false,
            availableDownLinkProperties: downLinkProperties,
            tableColumns: this.initializeColumns(downLinkProperties, true),
            availableDeviceTypes: filteredDeviceTypes
        });
    }

    private initializeColumns(downLinkProperties: DownLinkProperty[], allowFiltering: boolean): Column[] {
        const columns = [{
            accessor: "id",
            show: false,
        },
        {
            Header: 'Device ID',
            accessor: 'id',
            filterable: allowFiltering
        }, {
            id: 'assetName',
            Header: 'Asset',
            filterable: allowFiltering,
            accessor: (v): string => v.spaceName,
            Cell: (row): JSX.Element => <span>{row.value}</span>
        }, {
            id: 'type',
            Header: 'Type',
            accessor: "type",
            filterable: allowFiltering
        }];

        for (const column of downLinkProperties) {
            columns.push({
                Header: column.name,
                id: column.id,
                filterable: false,
                accessor: v => v.type,
                Cell: (row) => <span>{row.original.getDownLinkProperties().find(p => p.name === column.name) === undefined ? "" : row.original.getDownLinkProperties().find(p => p.name === column.name).value}</span>
            });
        }

        return columns;
    }

    private tableRowProperties: (tableState: FinalState, rowInfo?: RowInfo) => any = (tableState: FinalState, rowInfo?: RowInfo): any => {
        if (rowInfo === undefined) {
            return {};
        }

        return {
            onClick: (e: MouseEvent): void => {

                const initializeEditableGridColumns = (selectedDevices: IKeyedCollection<Device>): void => {
                    const devices = selectedDevices.getValues();
                    const downlinkProperties = DeviceConfigurationExtractor.extractConfiguration(devices);
                    const columns = this.initializeColumns(downlinkProperties, false);

                    this.setState({
                        configEditTableColumns: columns
                    });
                };

                if (this.state.selectedDeviceConfigurations.containsKey(rowInfo.row.id)) {
                    // remove row from selection
                    const currentlySelectedConfigurations = this.state.selectedDeviceConfigurations;
                    currentlySelectedConfigurations.remove(rowInfo.row.id);

                    initializeEditableGridColumns(currentlySelectedConfigurations);

                    this.setState({
                        selectedDeviceConfigurations: currentlySelectedConfigurations,
                        selectedRowIndex: null
                    });
                }
                else {
                    // add row to selection
                    const currentlySelectedConfigurations = this.state.selectedDeviceConfigurations;
                    currentlySelectedConfigurations.add(rowInfo.row.id, rowInfo.original);

                    initializeEditableGridColumns(currentlySelectedConfigurations);

                    this.setState({
                        selectedDeviceConfigurations: currentlySelectedConfigurations,
                        selectedRowIndex: rowInfo.index
                    });
                }

                if (e.shiftKey && this.state.selectedRowIndex != null) {
                    const inactiveRows = ReactTableUtils.getInactiveRows();

                    // Selecting range of configurations
                    const startIndex = Math.min(rowInfo.index, this.state.selectedRowIndex);
                    const endIndex = Math.max(rowInfo.index, this.state.selectedRowIndex);
                    const currentlySelectedConfigurations = this.state.selectedDeviceConfigurations;

                    // Determine which rows are actually selected.
                    // Note that each row has a specific index, but the array starts at index 0, meaning we have to add the 'start' index to each index
                    const activeRows = this.state.deviceConfigurations
                        .slice(startIndex, endIndex)
                        .filter((_: Device, arrayIndex: number) => inactiveRows.indexOf((arrayIndex + startIndex).toString()) === -1);

                    activeRows.forEach(r => currentlySelectedConfigurations.add(r.id, r));
                    this.setState({ selectedDeviceConfigurations: currentlySelectedConfigurations });
                }
            },
            style: {
                background: rowInfo != null && this.state.selectedDeviceConfigurations.containsKey(rowInfo.row.id) ? '#cccccc' : null
            }
        };
    };

    private async applyDeviceConfigHandler(newValues: Dictionary<number>, type: string | null): Promise<void> {
        const updates: ChangedDeviceConfig[] = [];
        for (const deviceConfig of this.state.selectedDeviceConfigurations.getValues()) {
            updates.push(new ChangedDeviceConfig(deviceConfig.id, newValues, type));
        }

        try {
            const response = await this.maintenanceService.updateDeviceConfig(updates);
            if (response.ok) {
                NotificationManager.success('De configuratie update is successvol verstuurd', 'Device configuratie');
            }
            else {
                if (response.status === 403) {
                    NotificationManager.error('Geen rechten om device config te versturen gevonden!', 'Device configuratie');
                }
                else {
                    NotificationManager.error('Fout bij het versturen van de update', 'Device configuratie');
                }
            }
            this.setState({
                selectedDeviceConfigurations: new Dictionary<Device>()
            });

            await new Promise(resolve => {
                setTimeout(() => resolve(this.loadDeviceConfiguration(this.state.selectedCustomerId)), 10000);
            });

        }
        catch (e) {
            console.error(`Error occured while sending new command values to API. Details: ${e}`);
            NotificationManager.error(`Er is een fout opgetreden tijdens het versturen van de nieuwe configuratie. Neem contact op met uw beheerder als het probleem zoch voor blijft doen. Details:${e}`, 'Device configuratie', 10000);
        }
    }

    public render(): JSX.Element {
        return (
            <div className="page">
                {!this.state.loading && !this.state.customerIdFromQueryString &&
                    <div className="row">
                        <div className="col-sm-12 col-md-12 pt-5 pl-5 pr-5">
                            <Translate>
                                {({ translate }): JSX.Element => <DropDownMenu
                                    isRequired={true}
                                    sort={true}
                                    initialDisplayName={translate("buttons.dropdowns.company") as string}
                                    items={this.state.customers}
                                    onClickHandler={this.onCustomerSelect}
                                    displayPropertyName="name"
                                    valuePropertyName="id" />}
                            </Translate>
                        </div>
                    </div>}

                <div className="row">
                    <div className="col-sm-10 col-md-11 pt-5 pl-5 pr-5">
                        <div className="header">
                            <h3><Translate id="pages.deviceconfiguration.title" /></h3>
                        </div>

                        <div className="row justify-content-center">
                            <PulseLoader
                                color={'#37a0e6'}
                                size={10}
                                margin={"15px"}
                                loading={this.state.loading} />
                        </div>

                        {!this.state.loading &&
                            <ReactTable
                                loading={this.state.tableLoading}
                                defaultPageSize={10}
                                className="-striped -highlight deviceConfigSelection"
                                data={this.state.deviceConfigurations}
                                columns={this.state.tableColumns}
                                getTrProps={this.tableRowProperties}
                                defaultFilterMethod={ReactTableUtils.useContainsFilter}
                            />}
                    </div>
                </div>
                {!this.state.loading && this.state.selectedDeviceConfigurations.count > 0 &&
                    <div className="row">
                        <div className="col-sm-12 col-md-12 pt-5 pl-5 pr-5">

                            <div id="configEditContainer">
                                <div id="deviceConfigEdit">
                                    <div className="header">
                                        <h3>Wijzig configuratie</h3>
                                    </div>

                                    <ReactTable
                                        data={this.state.selectedDeviceConfigurations.getValues()}
                                        columns={this.state.configEditTableColumns}
                                        className="-striped"
                                    />
                                </div>
                            </div>
                            <div id="settingPane">
                                <div className="header">
                                    <h3>Instellingen</h3>
                                </div>
                                <DeviceConfigSettingsPane
                                    DeviceConfigurations={this.state.selectedDeviceConfigurations}
                                    availableDeviceTypes={this.state.availableDeviceTypes}
                                    onClickHandler={this.applyDeviceConfigHandler} />
                            </div>
                            <SettingsDescriptionOverview />
                        </div>
                    </div>}
            </div>
        );
    }
}

export default withLocalize(DeviceConfigPage);