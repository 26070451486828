import React from "react";
import { withLocalize } from "react-localize-redux";
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';

import IDomainAccessCreateModalProps from "./interfaces/IDomainAccessCreateModalProps";
import IDomainAccessCreateModalState from "./interfaces/IDomainAccessCreateModalState";

import Yellow_Cross_Cancel from "../../../images/Yellow_Cross_Cancel.svg";
import LanguageProvider from "../../../providers/languageProvider";
import mapper from "../../../translations/mapper";
import CoreSpaceService from "../../../services/coreSpaceService";
import CenteredPageLoader from "../../../components/loaders/centeredPageLoader";
import Sorter from "../../../utils/sorter";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import RightsService from "../../../services/rightsService";

class DomainAccessCreateModal extends React.Component<IDomainAccessCreateModalProps, IDomainAccessCreateModalState>{
    private readonly spaceService: CoreSpaceService;
    private readonly rightsService: RightsService;

    private closeButtonRef: React.RefObject<HTMLButtonElement>;

    public constructor(props: IDomainAccessCreateModalProps) {
        super(props);

        this.state = {
            customers: [],
            loading: true,
            selectedCustomers: [],
            customerSelectionOptions: [],
            submitting: false,
            domain: ""
        };

        this.spaceService = new CoreSpaceService();
        this.rightsService = new RightsService();
        this.resetForm = this.resetForm.bind(this);
        this.onCustomerSelectChange = this.onCustomerSelectChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDomainChange = this.onDomainChange.bind(this);

        this.closeButtonRef = React.createRef();
    }

    public async componentDidMount(): Promise<void> {
        let customers = await this.spaceService.getCustomers();
        customers = Sorter.sortByProperty(customers, "name");
        const customerSelectionOptions = customers.map(v => ({
            value: v.id,
            label: v.name
        }));

        this.setState({
            customers: customers,
            loading: false,
            customerSelectionOptions: customerSelectionOptions
        });
    }

    private resetForm(): void {
        this.setState({
            selectedCustomers: [],
            submitting: false,
            domain: ""
        });
    }

    private onCustomerSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): void {
        if (optionSelected === undefined) {
            return;
        }

        const selectedOptions = optionSelected as IReactSelectValue[] ?? [];
        this.setState({
            selectedCustomers: selectedOptions
        });
    }

    private onDomainChange(event: any): void {
        this.setState({
            domain: event.target.value
        });
    }

    private async onSave(): Promise<void> {
        if (!this.validateInputForm()) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.invalidform));
            return;
        }

        this.setState({
            submitting: true
        });

        const customers = this.state.selectedCustomers.map(option => { return { customerId: option.value, customerName: option.label }; });

        const requestBody = {
            domain: this.state.domain,
            customers: customers
        };

        const response = await this.rightsService.UpsertDomainCustomerAccessAssignment(requestBody);

        if (response.status !== 204) {
            if (this.state.selectedCustomers.length > 1) {
                NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.multiplecustomerserror));
            }
            else {
                NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.createerror));
            }

            this.setState({
                submitting: false
            });
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation(mapper.pages.rights.createsuccess));

        if (this.closeButtonRef.current) {
            this.closeButtonRef.current.click();
        }

        await this.props.callbackAfterSuccessfullCreate();
        this.resetForm();
    }

    private validateInputForm(): boolean {
        const customerSelectionValid = this.state.selectedCustomers.length > 0;
        const domainNameValid = this.state.domain.length > 0;

        return customerSelectionValid && domainNameValid;
    }

    public render(): JSX.Element {
        return (
            <div className="modal fade"
                id="domain-customeraccess-create-modal"
                role="dialog"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="rights-create-modal domain-create-modal modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {LanguageProvider.getTranslation(mapper.pages.rights.domaincreate)}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetForm}>
                                <img src={Yellow_Cross_Cancel} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                            {!this.state.loading &&
                                <form>
                                    <div className="form-group">
                                        <span className="access-input-title">{LanguageProvider.getTranslation(mapper.pages.rights.customers)}</span>
                                        <div className="customers-select">
                                            <Select
                                                classNamePrefix={this.state.selectedCustomers.length > 0 ? "access-customers-valid" : "access-customers-invalid"}
                                                defaultValue={[]}
                                                options={this.state.customerSelectionOptions}
                                                onChange={this.onCustomerSelectChange}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.selectedCustomers}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.domain)}</span>
                                        <input
                                            className={`form-control ${this.state.domain.length > 0 ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.domain}
                                            onChange={this.onDomainChange}
                                            name="domain"
                                            autoComplete="off"
                                            maxLength={80}
                                        />
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            {!this.state.submitting && <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.onSave}
                            >
                                {LanguageProvider.getTranslation(mapper.pages.rights.save)}
                            </button>}
                            {this.state.submitting && <CenteredPageLoader loading={this.state.submitting} />}
                        </div>
                        <button type="button"
                            className="d-none"
                            data-dismiss="modal"
                            data-target="#domain-customeraccess-create-modal"
                            ref={this.closeButtonRef}></button>
                    </div>
                </div>

            </div >
        );
    }
}

export default withLocalize(DomainAccessCreateModal);