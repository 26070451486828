import * as React from "react";
import { PulseLoader } from 'react-spinners';
import ReactTable, { Column } from 'react-table';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import moment from 'moment';
import {nl} from 'date-fns/esm/locale';

import "./rawDataOverview.scss";

import IRawDataProps from "./interfaces/IRawDataProps";
import IRawDataState from "./interfaces/IRawDataState";
import PageHeader from "../../../components/header/pageHeader";
import ReportFilter from "../models/reportFilter";
import CustomPagination from '../../../components/pagination/customPagination';

import VenueProvider from '../../../providers/venueProvider';
import LanguageProvider from "../../../providers/languageProvider";
import CoreSpaceService from "../../../services/coreSpaceService";
import AppEventHub, { AppEvents } from "../../../utils/appEventHub";
import IDeviceData from "../../../interfaces/IDeviceData";
import MaintenanceService from "../../../services/maintenanceService";

export default class Reports extends React.Component<IRawDataProps, IRawDataState>{

    private maintenanceService: MaintenanceService;
    private coreSpaceService: CoreSpaceService;

    public constructor(props: IRawDataProps) {
        super(props);
        this.maintenanceService = new MaintenanceService();
        this.coreSpaceService = new CoreSpaceService();
        const venue = VenueProvider.getActiveVenue();

        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() - 1);
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        const reportFilter = ReportFilter.parse(props.match.params.filter);
        this.state = {
            loading: true,
            startDate: startDate,
            endDate: endDate,
            data: [],
            columns: [],
            reportFilter: reportFilter,
            spaceName: "",
            assetSelected: false,
            venueId: venue?.id
        } as IRawDataState;

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.setColumnsAndData = this.setColumnsAndData.bind(this);

        this.updateColumnNames = this.updateColumnNames.bind(this);
        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumnNames);
    }

    public async componentDidMount(): Promise<void> {
        if (this.state.reportFilter.assets.length !== 1 || this.state.reportFilter.assets[0] === "") {
            // currently we don't support reporting without filtering on 1 asset
            this.setState({
                loading: false
            });
            return;
        }

        this.setColumnsAndData(this.state.startDate, this.state.endDate);

        registerLocale('nl', nl);
        setDefaultLocale('nl');
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumnNames);
    }

    private async setColumnsAndData(startDate: Date, endDate: Date): Promise<void> {
        this.setState({
            loading: true
        });

        const space = await this.coreSpaceService.getSpaceById(this.state.venueId, this.state.reportFilter.assets[0]);
        const data = await this.maintenanceService.getDeviceData(this.state.reportFilter.assets[0], startDate.toUTCString(), endDate.toUTCString());

        const columns = this.initializeColumns(data);
        this.setState({
            loading: false,
            data: data,
            columns: columns,
            startDate: startDate,
            endDate: endDate,
            spaceName: space.name,
            assetSelected: true
        });
    }

    private updateColumnNames(): void {
        const columns = this.initializeColumns(this.state.data);

        this.setState({
            columns: columns
        });
    }

    private initializeColumns(data: IDeviceData[]): Column[] {
        let hasOccupied = false;
        let hasPeopleCount = false;
        let hasTemperature = false;
        let hasHumidity = false;
        let hasCarbonDioxide = false;

        for (const deviceData of data) {
            if (deviceData.occupied != null) {
                hasOccupied = true;
            }

            if (deviceData.peopleCount != null) {
                hasPeopleCount = true;
            }

            if (deviceData.temperature != null) {
                hasTemperature = true;
            }

            if (deviceData.humidity != null) {
                hasHumidity = true;
            }

            if (deviceData.carbonDioxide != null) {
                hasCarbonDioxide = true;
            }
        }

        const columns = [
            {
                Header: LanguageProvider.getTranslation('pages.map.assetinsights.datetime'),
                id: 'eventTimestamp',
                accessor: (v: any): string => moment((v as IDeviceData).eventTimestamp).format("YYYY-MM-DD HH:mm")
            }, {
                Header: LanguageProvider.getTranslation('pages.map.assetinsights.occupancy'),
                id: 'occupied',
                accessor: (v: any): string => (v as IDeviceData).occupied === true ?
                    LanguageProvider.getTranslation('pages.map.assetinsights.values.occupied')
                    : v.occupied === false ? LanguageProvider.getTranslation('pages.map.assetinsights.values.unoccupied') : "",
                show: hasOccupied
            }, {
                Header: LanguageProvider.getTranslation('pages.map.assetinsights.peoplecount'),
                accessor: 'peopleCount',
                show: hasPeopleCount
            }, {
                Header: LanguageProvider.getTranslation('pages.map.assetinsights.temperature'),
                accessor: 'temperature',
                show: hasTemperature
            }, {
                Header: LanguageProvider.getTranslation('pages.map.assetinsights.humidity'),
                accessor: 'humidity',
                show: hasHumidity
            }, {
                Header: LanguageProvider.getTranslation('pages.map.assetinsights.carbondioxide'),
                accessor: 'carbonDioxide',
                show: hasCarbonDioxide
            }];

        return columns;
    }

    private async handleStartDateChange(state: Date | null): Promise<void> {
        if (state === null) {
            return;
        }
        this.setState(
            {
                startDate: state
            });
        await this.setColumnsAndData(state, this.state.endDate);
    }

    private async handleEndDateChange(state: Date | null): Promise<void> {
        if (state === null) {
            return;
        }
        this.setState(
            {
                endDate: state
            });
        await this.setColumnsAndData(this.state.startDate, state);
    }

    public render(): JSX.Element {
        return (
            <div className='raw-data-overview'>
                <PageHeader pageName="map" />
                <div className="pt-5 pr-5 pl-5 pb-5 rawdataoverview" id="rawdata">

                    {!this.state.loading &&
                        <div className="row" >
                            <h2>{this.state.spaceName !== "" ? this.state.spaceName : LanguageProvider.getTranslation("pages.map.noassetsselected")}</h2>
                        </div>
                    }
                    {this.state.loading &&
                        <div className="row justify-content-center">
                            <PulseLoader
                                color={'#009FE3'}
                                size={10}
                                margin={"15px"}
                                loading={this.state.loading} />
                        </div>
                    }
                    {!this.state.loading && this.state.assetSelected && <div className="row">
                        <DatePicker
                            selected={moment(this.state.startDate).toDate()}
                            onChange={this.handleStartDateChange}
                            maxDate={moment(this.state.endDate).toDate()}
                            placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
                            className="asset-datepicker"
                            dateFormat="dd-MM-yyyy, HH:mm"
                            locale="nl"
                            todayButton={LanguageProvider.getTranslation("datepicker.today")}
                        />
                        <DatePicker
                            selected={moment(this.state.endDate).toDate()}
                            onChange={this.handleEndDateChange}
                            minDate={moment(this.state.startDate).toDate()}
                            maxDate={moment().toDate()}
                            placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
                            className="asset-datepicker"
                            dateFormat="dd-MM-yyyy, HH:mm"
                            todayButton={LanguageProvider.getTranslation("datepicker.today")}
                        />
                    </div>}
                    {!this.state.loading && ((this.state.data.length > 0 &&
                        <span>
                            <div className="row">
                                <PulseLoader
                                    color={'#009FE3'}
                                    size={10}
                                    margin={"15px"}
                                    loading={this.state.loading} />
                                <ReactTable
                                    className="rawdataoverview-table"
                                    loading={this.state.loading}
                                    showPaginationBottom={false}
                                    showPaginationTop={true}
                                    defaultPageSize={25}
                                    PaginationComponent={CustomPagination}
                                    data={this.state.data}
                                    columns={this.state.columns} />
                            </div>
                        </span>) || <div className="row justify-content-center"> {LanguageProvider.getTranslation("pages.map.norawdatafound")} </div>)
                    }
                </div>
            </div>);
    }
}