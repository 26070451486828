import * as React from 'react';
import "./pageHeader.css";
import { withLocalize, Translate } from 'react-localize-redux';

import IPageHeaderProps from './interfaces/IPageHeaderProps';
import IPageHeaderState from './interfaces/IPageHeaderState';
import VenueSelectionDropDown from './venueSelectionDropDown';
import { Link } from 'react-router-dom';
import IPageHeaderItem from '../../interfaces/IPageHeaderItem';
import PageHeaderManager from '../../models/pageHeaderManager';

class PageHeader extends React.Component<IPageHeaderProps, IPageHeaderState> {

    public constructor(props: IPageHeaderProps) {
        super(props);

        const pageHeaderInfo = PageHeaderManager.getHeadersForPage(this.props.pageName);
        let activeTab: IPageHeaderItem;

        const state: IPageHeaderState = {
            tabs: pageHeaderInfo ? pageHeaderInfo.tabs : [],
            activeTab: undefined
        };

        if (pageHeaderInfo) {
            // to determine the current active tab we need to filter/clone the array and then match on url. The item with the longest relative URL is the closest match
            activeTab = pageHeaderInfo.tabs.filter((i) => window.location.pathname.startsWith(i.relativeUrl)).sort((a, b) => b.relativeUrl.length - a.relativeUrl.length)[0];
            if (!activeTab) {
                console.error(`No tab found for path '${window.location.pathname}'. Please check pageHeader configuration in calling component.`);
            }
            state.activeTab = activeTab;
        }
        else {
            console.error(`No page header items found for page '${this.props.pageName}'`);
        }

        this.state = state;

        this.toggleTab = this.toggleTab.bind(this);
    }


    public render(): JSX.Element {
        return <span>
            <div className="navbar navbar-expand-lg navbar-light page-header pr-5">
                {this.state.tabs.length > 0 &&
                    <ul className="nav mr-auto">
                        {this.state.tabs.map((item) => <Item key={item.id} data={item} onClick={this.toggleTab} isActive={this.state.activeTab != null && this.state.activeTab.id === item.id} />)}
                    </ul>
                }

                {this.state.activeTab != null && this.state.activeTab.showVenueButton
                    && <div className="pl-4 venue-selection-dropdown">
                        <VenueSelectionDropDown />
                    </div>
                }

                {this.props.extraHeaderSection}
            </div>
        </span>;
    }

    private toggleTab(activeTab: IPageHeaderItem): void {
        if (this.props.onTabChange) {
            this.props.onTabChange(activeTab.id);
        }

        this.setState({ activeTab });
    }
}

export default withLocalize(PageHeader);


interface IItem {
    onClick: (tab: IPageHeaderItem) => void;
    data: IPageHeaderItem;
    isActive: boolean;

}

// tslint:disable-next-line:max-classes-per-file
class Item extends React.Component<IItem, {}>{
    public constructor(props: any) {
        super(props);
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    public render(): JSX.Element {
        return (
            <li className="nav-item" >
                <Link className={this.props.isActive ? "nav-link active pageheader-item" : "nav-link pageheader-item"} to={this.props.data.relativeUrl} id={this.props.data.id} onClick={this.onClickHandler}>
                    <Translate id={this.props.data.translationLabel} />
                </Link>
            </li>
        );
    }

    private onClickHandler(): void {
        this.props.onClick(this.props.data);
    }
}