export default class ReportFilter {
    private assetsBackingField: string[];

    public constructor() {
        this.assetsBackingField = [];
    }

    public get assets(): string[] {
        return this.assetsBackingField;
    }

    public set assets(value: string[]) {
        this.assetsBackingField = value;
    }

    public static parse(filter: string): ReportFilter {
        const reportFilter = new ReportFilter();
        if (filter != null) {
            for (const filterPart of filter.split("&")) {
                const keyvalue = filterPart.split("=");
                if (keyvalue.length > 1) {
                    reportFilter[keyvalue[0]] = keyvalue[1].split(",");
                }
            }
        }

        return reportFilter;
    }
}