import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { NotificationManager } from 'react-notifications';
import AutodeskService from "../../../services/autodeskService";
import CoreSpaceService from "../../../services/coreSpaceService";
import CoreSpaceIncludes from "../../../enums/coreSpaceIncludes";
import { IFloorChangeEvent, ISpaceOnMap, Point } from "@beyondeyes/shared";
import LanguageProvider from "../../../providers/languageProvider";
import Mapper, { translations } from "../../../translations/mapper";
import { MaintenanceMap } from "./maintenanceMap";
import { PreviewAssets } from "./assets/previewAssets";
import { IMapFloor } from "./assets/interfaces/IMapFloor";
import AssetManagementService from "../../../services/assetManagementService";
import { AddedAssetsRequest, UpsertAssetsRequest } from "../../../models/upsertAssetsRequest";
import { IAsset } from "./assets/interfaces/IAsset";
import { PulseLoader } from "react-spinners";
import "./previewMap.scss";
import { AssetFeedback } from "./assets/assetFeedback";

interface IVenueFloor {
    id: string;
    venueId: string;
    currentUrn: string;
    previewUrn: string;
    customerId: string;
}

interface IPreviewMapProps {
    showPreview: (value: boolean) => void;
    venueId: string | undefined;
}

export const PreviewMap: React.FC<IPreviewMapProps> = (props: IPreviewMapProps) => {

    const autodeskService: AutodeskService = new AutodeskService();
    const assetManagementService: AssetManagementService = new AssetManagementService();
    const venueFloors = useRef<IVenueFloor[]>();
    const upsertAssetsRequest = useRef<UpsertAssetsRequest>();
    const selectedAssets = useRef<Record<string, string[]>>({});

    const [mapFloor, setMapFloor] = useState<IMapFloor | undefined>(undefined);
    const [selectedMapSpaces, setSelectedMapSpaces] = useState<ISpaceOnMap[]>([]);
    const [publishEnabled, setPublishEnabled] = useState<boolean>(false);
    const [publishAndUpsertAssetsEnabled, setPublishAndUpsertAssetsEnabled] = useState<boolean>(false);
    const [upsertAssetsEnabled, setUpsertAssetsEnabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAssetPreview, setShowAssetPreview] = useState<boolean>(true);
    const [showNoPreviewAvailableMsg, setShowNoPreviewAvailableMsg] = useState<boolean>(false);
    const [refreshAssetFeedbackKey, setRefreshAssetFeedbackKey] = useState<number>(0);
    const [refreshAssetKey, setRefreshAssetKey] = useState<number>(0);

    const { venueId } = props;

    useEffect(() => {
        if (!venueId) {
            return;
        }

        setLoading(true);

        const loadFloors = async (): Promise<void> => {
            const coreSpaceService = new CoreSpaceService();
            const floors = await coreSpaceService.getFloorsForVenue(venueId!, [CoreSpaceIncludes.Properties], true);
            venueFloors.current = floors.map(floor => (
                {
                    id: floor.id,
                    venueId: floor.venueId,
                    previewUrn: floor.previewUrn,
                    currentUrn: floor.currentUrn,
                    customerId: floor.customerId
                }));
        };

        loadFloors();

    }, [venueId]);

    const setUpsertAssetsRequest = useMemo(() => (addedAssets: IAsset[], removedAssets: IAsset[]): void => {
        const addedAssetsRequest = addedAssets.map(asset => new AddedAssetsRequest(asset.id, asset.type));
        const removedAssetIds = removedAssets.map(asset => asset.id);
        const currentVenueFloor = getFloor(mapFloor!.id);
        upsertAssetsRequest.current = new UpsertAssetsRequest(currentVenueFloor.customerId, currentVenueFloor.venueId, currentVenueFloor.id, addedAssetsRequest, removedAssetIds);
        setPublishAndUpsertAssetsEnabled(upsertAssetsRequest.current.hasAssetsToUpsert || currentVenueFloor.currentUrn !== currentVenueFloor.previewUrn);
        setUpsertAssetsEnabled(upsertAssetsRequest.current.hasAssetsToUpsert);
    }, [mapFloor]);

    const publishAsync = async (): Promise<void> => {
        const isConfirmed = window.confirm(LanguageProvider.getTranslation(Mapper.pages.autodesk.publishconfirm));
        if (!isConfirmed) {
            return;
        }

        if (!venueFloors.current || !mapFloor) {
            return;
        }

        setLoading(true);
        await updateMapAsync();
        setLoading(false);
    };

    const publishAndUpsertAssetsAsync = async (): Promise<void> => {
        const isConfirmed = window.confirm(LanguageProvider.getTranslation(Mapper.pages.autodesk.publishandupsertassetsconfirm));
        if (!isConfirmed) {
            return;
        }

        if (!venueFloors.current || !mapFloor || !upsertAssetsRequest.current) {
            return;
        }

        setLoading(true);
        await updateMapAsync();
        await upsertAssetsInternalAsync();
        setLoading(false);
    };

    const upsertAssetsAsync = async (): Promise<void> => {
        const isConfirmed = window.confirm(LanguageProvider.getTranslation(Mapper.pages.autodesk.upsertassetsconfirm));
        if (!isConfirmed) {
            return;
        }

        setLoading(true);
        await upsertAssetsInternalAsync();
        setRefreshAssetFeedbackKey(refreshAssetFeedbackKey + 1);
        setLoading(false);
    };

    const updateMapAsync = async (): Promise<void> => {
        if (!mapFloor) {
            return;
        }

        const currentVenueFloor = getFloor(mapFloor.id);
        if (currentVenueFloor.currentUrn === currentVenueFloor.previewUrn) {
            return;
        }

        const setCurrentUrnResponse = await autodeskService.setCurrentAsync(currentVenueFloor.venueId, currentVenueFloor.id, currentVenueFloor.previewUrn);

        if (!setCurrentUrnResponse.ok) {
            NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.autodesk.maploadfailed));
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation(Mapper.pages.autodesk.mapupdatesucceeded));
        currentVenueFloor.currentUrn = currentVenueFloor.previewUrn;
        setPublishEnabled(false);
    };

    const upsertAssetsInternalAsync = async (): Promise<void> => {
        if (!upsertAssetsRequest.current || !upsertAssetsRequest.current.hasAssetsToUpsert) {
            return;
        }

        const upsertAssetsResponse = await assetManagementService.upsertAssets(upsertAssetsRequest.current);

        setRefreshAssetFeedbackKey(refreshAssetFeedbackKey + 1);

        if (!upsertAssetsResponse.ok) {
            NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.autodesk.assetupdatefailed));
        }
    };

    const onFloorChange = (event: IFloorChangeEvent): void => {
        selectedAssets.current = {};
        setRefreshAssetFeedbackKey(0);
        setMapFloor({ id: event.floor.id, venueId: event.floor.venueId, spaces: event.renderedSpaces });
        const currentVenueFloor = getFloor(event.floor.id);
        resetSelectedSpaces(event.floor.id);
        setPublishEnabled(currentVenueFloor.currentUrn !== currentVenueFloor.previewUrn);
        setLoading(false);
    };

    const resetSelectedSpaces = (floorId: string): void => {
        const fakeSpace: ISpaceOnMap = { dbId: 1, id: floorId, position: new Point(0, 0) };
        setSelectedMapSpaces([fakeSpace]);
    };

    const getFloor = (floorId: string): IVenueFloor => {
        return venueFloors.current!.find(floor => floor.id === floorId)!;
    };

    const setSelectedSpaces = (selectedAssetIds: string[], color: string): void => {
        if (!mapFloor) {
            return;
        }

        selectedAssets.current[color] = selectedAssetIds;

        let selectedSpaces: ISpaceOnMap[] = [];
        Object.keys(selectedAssets.current).forEach(key => {
            const spaces = mapFloor.spaces
                .filter(space => selectedAssets.current[key].some(assetId => space.id === assetId))
                .map(space => {
                    space.style = {
                        placeStyle: {
                            fillColor: key
                        }
                    };
                    return space;
                });
            selectedSpaces = selectedSpaces.concat(spaces);
        });

        setSelectedMapSpaces(selectedSpaces);
    };

    const onNoPreviewAvailable = useMemo(() => (): void => {
        setLoading(false);
        setShowAssetPreview(false);
        setShowNoPreviewAvailableMsg(true);
    }, []);

    const refreshAssets = (): void => {
        setRefreshAssetKey(refreshAssetKey + 1);
    };

    return (
        <>
            <div className="autodesk-page-form">
                <div className="row">
                    <div className="col-md-9">
                        {loading && (
                            <div className="loader">
                                <PulseLoader
                                    color="#37a0e6"
                                    size={8}
                                    margin="5px"
                                    loading={loading}
                                />
                            </div>
                        )}
                        <MaintenanceMap
                            venueId={props.venueId}
                            onFloorChange={onFloorChange}
                            spaces={selectedMapSpaces}
                            onNoPreviewAvailable={onNoPreviewAvailable}
                        />
                        <button disabled={!publishEnabled}
                            className="btn btn-primary"
                            type="button"
                            onClick={publishAsync}
                        >{LanguageProvider.getTranslation(Mapper.pages.autodesk.publish)}</button>
                        <button disabled={!publishAndUpsertAssetsEnabled}
                            className="btn btn-primary"
                            type="button"
                            onClick={publishAndUpsertAssetsAsync}
                        >{LanguageProvider.getTranslation(Mapper.pages.autodesk.publishandupsertassets)}</button>
                        <button className="btn btn-primary"
                            type="button"
                            onClick={(): void => props.showPreview(false)}
                        >{LanguageProvider.getTranslation(Mapper.pages.autodesk.back)}</button>
                    </div>
                    <div className="col-md-3">
                        {showAssetPreview && mapFloor &&
                            <div>
                                <div className="col-md-12">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={refreshAssets}
                                    >{LanguageProvider.getTranslation(translations.pages.autodesk.refreshassets)}</button>
                                    <button
                                        disabled={!upsertAssetsEnabled}
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={upsertAssetsAsync}
                                    >{LanguageProvider.getTranslation(translations.pages.autodesk.upsertassets)}</button>
                                </div>
                                <PreviewAssets
                                    refreshKey={refreshAssetKey}
                                    mapFloor={mapFloor}
                                    setSelectedSpaces={setSelectedSpaces}
                                    setUpsertAssetsRequest={setUpsertAssetsRequest}>
                                </PreviewAssets>
                            </div>
                        }

                    </div>
                </div>
                {showNoPreviewAvailableMsg &&
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="alert alert-warning">
                                {LanguageProvider.getTranslation(translations.pages.autodesk.nopreviewavailable)}
                            </div>
                        </div>
                    </div>
                }
            </div>
            {mapFloor && <AssetFeedback onFeedbackStatusCompleted={refreshAssets} messageType="UpsertAssetsMessage" floorId={mapFloor.id} refreshKey={refreshAssetFeedbackKey}></AssetFeedback>}
        </>
    );
};