import React from "react";
import { withLocalize } from "react-localize-redux";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';

import IDomainRolesEditModalProps from "./interfaces/IDomainRolesEditModalProps";
import IDomainRolesEditModalState from "./interfaces/IDomainRolesEditModalState";

import Yellow_Cross_Cancel from "../../../images/Yellow_Cross_Cancel.svg";
import LanguageProvider from "../../../providers/languageProvider";
import mapper from "../../../translations/mapper";
import CenteredPageLoader from "../../../components/loaders/centeredPageLoader";
import RightsService from "../../../services/rightsService";
import roles from "../../../enums/roles";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import IDomainRolesAssignmentModification from "../../../interfaces/IDomainRolesAssignmentModification";

class DomainRolesEditModal extends React.Component<IDomainRolesEditModalProps, IDomainRolesEditModalState>{
    private readonly rightsService: RightsService;

    private closeButtonRef: React.RefObject<HTMLButtonElement>;

    public constructor(props: IDomainRolesEditModalProps) {
        super(props);

        const rolesSelectionOptions: IReactSelectValue[] = [
            {
                value: roles[roles.FacilityManager],
                label: roles[roles.FacilityManager] + " : " + LanguageProvider.getTranslation(mapper.pages.rights.roledescriptions.facilitymanager),
            },
            {
                value: roles[roles.WorkspacesAdmin.toString()],
                label: roles[roles.WorkspacesAdmin.toString()] + " : " + LanguageProvider.getTranslation(mapper.pages.rights.roledescriptions.workspacesadmin)
            }
        ];

        this.state = {
            loading: false,
            submitting: false,
            rolesSelectionOptions: rolesSelectionOptions,
            selectedRoles: []
        };

        this.rightsService = new RightsService();
        this.resetForm = this.resetForm.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onRolesSelectChange = this.onRolesSelectChange.bind(this);

        this.closeButtonRef = React.createRef();
    }

    public async componentDidMount(): Promise<void> {
    }

    public async componentDidUpdate(prevProps: IDomainRolesEditModalProps): Promise<void> {
        if (prevProps.existingRoles !== this.props.existingRoles) {
            const existingRolesSelection: IReactSelectValue[] = [];
            for (const rolesSelectionOption of this.state.rolesSelectionOptions) {
                if (this.props.existingRoles.find(r => r.toString() === rolesSelectionOption.value)) {
                    existingRolesSelection.push({value: rolesSelectionOption.value, label: rolesSelectionOption.value});
                }
            }

            this.setState({
                selectedRoles: existingRolesSelection
            });
        }
    }

    private resetForm(): void {
        this.setState({
            submitting: false
        });
    }

    private async onSave(): Promise<void> {
        this.setState({
            submitting: true
        });

        const allRoles = this.state.rolesSelectionOptions.map<roles>(r => roles[r.value]);
        const selectedRoles = this.state.selectedRoles.map<roles>(r => roles[r.value]);

        const modification: IDomainRolesAssignmentModification = {
            description: this.props.domain,
            domain: this.props.domain,
            rolesToAdd: selectedRoles,
            rolesToRemove: allRoles.filter(r => !selectedRoles.includes(r))
        };

        const response = await this.rightsService.ModifyDomainRolesAssignment(modification);

        if (response.status !== 204) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.updateerror));

            this.setState({
                submitting: false
            });
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation(mapper.pages.rights.updatesuccess));

        if (this.closeButtonRef.current) {
            this.closeButtonRef.current.click();
        }

        await this.props.callbackAfterSuccessfullEdit();
        this.resetForm();
    }

    private onRolesSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): void {
        if (optionSelected === null || (optionSelected as IReactSelectValue[]).length === 0) {
            this.setState({
                selectedRoles: []
            });
            return;
        }

        const selectedRoles = (optionSelected as IReactSelectValue[]).map(o => ({ label: o.value, value: o.value }));
        this.setState({
            selectedRoles: selectedRoles
        });
    }

    public render(): JSX.Element {
        return (
            <div className="modal fade"
                id="domain-roles-edit-modal"
                role="dialog"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="rights-create-modal domain-create-modal modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {LanguageProvider.getTranslation(mapper.pages.rights.domainedit)}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetForm}>
                                <img src={Yellow_Cross_Cancel} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                            {!this.state.loading &&
                                <form>
                                    <div className="form-domain">
                                        <span className="access-input-title">{LanguageProvider.getTranslation(mapper.pages.rights.roles)}</span>
                                        <div className="roles-select">
                                            <Select
                                                classNamePrefix={this.state.selectedRoles.length > 0 ? "access-customers-valid" : "access-customers-invalid"}
                                                defaultValue={this.state.selectedRoles}
                                                options={this.state.rolesSelectionOptions}
                                                onChange={this.onRolesSelectChange}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.selectedRoles}
                                            />
                                        </div>
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            {!this.state.submitting && <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.onSave}
                            >
                                {LanguageProvider.getTranslation(mapper.pages.rights.save)}
                            </button>}
                            {this.state.submitting && <CenteredPageLoader loading={this.state.submitting} />}
                        </div>
                        <button type="button"
                            className="d-none"
                            data-dismiss="modal"
                            data-target="#domain-roles-edit-modal"
                            ref={this.closeButtonRef}></button>
                    </div>
                </div>

            </div >
        );
    }
}

export default withLocalize(DomainRolesEditModal);