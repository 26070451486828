import * as React from 'react';
import { NotificationManager } from 'react-notifications';

import { withLocalize } from 'react-localize-redux';
import RightsService from '../../services/rightsService';
import PageHeader from "../../components/header/pageHeader";
import FullPageLoader from "../../components/loaders/fullPageLoader";
import ReactTable, { Column } from "react-table";
import CustomPagination from "../../components/pagination/customPagination";
import translations from '../../translations/mapper';
import UserAccessCreateModal from "./components/userAccessCreateModal";
import UserRolesCreateModal from "./components/userRolesCreateModal";
import UserRolesEditModal from './components/userRolesEditModal';
import UserPartnerAccessCreateModal from './components/userPartnerAccessCreateModal';
import TimeZoneUtils from "../../utils/timeZoneUtils";

import AddGreen from "../../images/Add_Green.svg";
import DeleteBlue from "../../images/Delete_Blue.svg";
import DeleteGrey from "../../images/Delete_Grey.svg";
import EditBlue from "../../images/Edit_Blue.svg";
import EditGrey from "../../images/Edit_Grey.svg";

import IUserRightsPageProps from "./interfaces/IUserRightsPageProps";
import IUserRightsPageState from "./interfaces/IUserRightsPageState";

import "./rightsPage.scss";
import LanguageProvider from '../../providers/languageProvider';
import IUserCustomerAccessAssignment from '../../interfaces/IUserCustomerAccessAssignment';
import { ReactTableUtils } from '../../utils/reactTableUtils';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import CoreSpaceService from '../../services/coreSpaceService';
import { CustomerNameElementStyler } from './utils/customerNameElementStyler';
import roles from '../../enums/roles';
import IUserRolesAssignment from '../../interfaces/IUserRolesAssignment';
import IUserRolesAssignmentModification from '../../interfaces/IUserRolesAssignmentModification';
import IUserPartnerAccess from '../../interfaces/IUserPartnerAccess';

class UserRightsPage extends React.Component<IUserRightsPageProps, IUserRightsPageState>{
    private readonly rightsService: RightsService;
    private readonly spaceService: CoreSpaceService;

    public constructor(props: IUserRightsPageProps) {
        super(props);

        this.rightsService = new RightsService();
        this.spaceService = new CoreSpaceService();

        this.updateColumns = this.updateColumns.bind(this);
        this.updateUserAccess = this.updateUserAccess.bind(this);
        this.updateUserRoles = this.updateUserRoles.bind(this);
        this.updateUserPartnerAccess = this.updateUserPartnerAccess.bind(this);
        this.setActiveRow = this.setActiveRow.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.setCategoryPartnersActive = this.setCategoryPartnersActive.bind(this);
        this.setCategoryRolesActive = this.setCategoryRolesActive.bind(this);
        this.setCategoryCustomerAccessActive = this.setCategoryCustomerAccessActive.bind(this);

        this.state = {
            userCustomerAccessAssignments: [],
            userRolesAssignments: [],
            loading: true,
            columns: this.getColumns(true),
            customers: [],
            partners: [],
            userPartnerAccess: [],
            selectedRows: [],
            activeCategory: 'customerAccess'
        };

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
    }

    public async componentDidMount(): Promise<void> {
        const existingUserAccessTask = this.rightsService.GetUserCustomerAccessAssignments();
        const existingRolesTask = this.rightsService.GetUserRolesAssignments();
        const userPartnerTask = this.rightsService.GetUserPartnerAccess();
        const partnerTask = this.rightsService.GetPartners();
        const customersTask = this.spaceService.getCustomers();

        const [existingUserAccess, existingRoles, customers, userPartnerAccess, partners] =
            await Promise.all([existingUserAccessTask, existingRolesTask, customersTask, userPartnerTask, partnerTask]);

        this.setState({
            userCustomerAccessAssignments: existingUserAccess,
            customers: customers,
            partners: partners,
            userPartnerAccess: userPartnerAccess,
            loading: false,
            userRolesAssignments: existingRoles,
        });
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
    }

    private updateColumns(): void {
        const columns = this.getColumns();
        this.setState({
            columns: columns
        });
    }

    private getColumns(overrideCategoryAsCustomerAccess: Boolean = false): Column[] {
        if (overrideCategoryAsCustomerAccess || this.state.activeCategory === 'customerAccess') {
            const columns = [
                {
                    id: 'userName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.username),
                    accessor: "description",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'userObjectId',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.userobjectid),
                    accessor: "userObjectId",
                    filterable: true
                },
                {
                    id: 'customerName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.customername),
                    accessor: (row: any): string => CustomerNameElementStyler.getCustomerNameForAccessAssignment(row, this.state.customers),
                    Cell: (row: any): JSX.Element => CustomerNameElementStyler.getCustomerNameStyling(row, this.state.customers),
                    filterable: true
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (message: IUserCustomerAccessAssignment): string =>
                        message.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(message.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }];

            return columns;
        }
        else if (this.state.activeCategory === 'roles') {
            const columns = [
                {
                    id: 'userName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.username),
                    accessor: "description",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'userObjectId',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.userobjectid),
                    accessor: "userObjectId",
                    filterable: true
                },
                {
                    id: 'roles',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.roles),
                    accessor: (message: IUserRolesAssignment): string => message.roles?.join(' '),
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            {
                                row.original.roles !== undefined ? (row.original.roles as roles[]).map((r, i) =>
                                    <div className={`role-row ${i > 0 ? 'later-role' : ''}`} key={i}>
                                        {LanguageProvider.getTranslation(translations.pages.rights.rolenames[r.toString().toLowerCase()])}
                                    </div>) :
                                    <div />}
                        </div>
                    )
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (message: IUserRolesAssignment): string =>
                        message.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(message.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }];

            return columns;
        } else {
            const columns = [
                {
                    id: 'userName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.username),
                    accessor: "description",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'userObjectId',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.userobjectid),
                    accessor: "userObjectId",
                    filterable: true
                },
                {
                    id: 'partner',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.partner),
                    accessor: 'partnerDescription',
                    filterable: true,
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (userPartnerAccess: IUserPartnerAccess): string =>
                        userPartnerAccess.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(userPartnerAccess.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }
            ];

            return columns;
        }
    }

    private async setActiveRow(e: any): Promise<void> {
        e.stopPropagation();
        e.preventDefault();
        const rowIndexNumber = parseInt(e.target.dataset.rowIndex);
        if (this.state.selectedRows.includes(rowIndexNumber)) {
            await this.setState({
                selectedRows: this.state.selectedRows.filter(r => r !== rowIndexNumber),
            });
        }
        else {
            const newRows = this.state.selectedRows;
            newRows.push(rowIndexNumber);
            await this.setState({
                selectedRows: newRows,
            });
        }
    }

    private async updateUserAccess(): Promise<void> {
        const existingUserAccess = await this.rightsService.GetUserCustomerAccessAssignments();
        this.setState({
            userCustomerAccessAssignments: existingUserAccess,
            loading: false
        });
    }

    private async updateUserRoles(): Promise<void> {
        const existingUserRoles = await this.rightsService.GetUserRolesAssignments();
        this.setState({
            userRolesAssignments: existingUserRoles,
            selectedRows: [],
            loading: false
        });
    }

    private async updateUserPartnerAccess(): Promise<void> {
        const userPartnerAccess = await this.rightsService.GetUserPartnerAccess();
        this.setState({
            userPartnerAccess: userPartnerAccess,
            selectedRows: [],
            loading: false
        });
    }

    private async handleDeleteClick(): Promise<void> {
        if (this.state.selectedRows.length === 0) {
            return;
        }

        if (!window.confirm(LanguageProvider.getTranslation(translations.pages.rights.deleteconfirm))) {
            return;
        }

        const responses: Response[] = [];
        for (const rowIndex of this.state.selectedRows) {
            const selectedRecord = this.state.activeCategory === 'customerAccess' ?
                this.state.userCustomerAccessAssignments[rowIndex] :
                this.state.activeCategory === 'roles' ? this.state.userRolesAssignments[rowIndex] : this.state.userPartnerAccess[rowIndex];

            if (!selectedRecord) {
                console.error(`Could not find customer record at index '${rowIndex}', this should never happen!`);

                continue;
            }

            let response: Response | undefined = undefined;
            if (this.state.activeCategory === 'customerAccess') {
                var customerAccessSelectedRecord = selectedRecord as IUserCustomerAccessAssignment;
                if (customerAccessSelectedRecord.allCustomers) {
                    response = await this.rightsService.DeleteUserAllCustomersAccess(customerAccessSelectedRecord.userObjectId);
                }
                else {
                    if (!customerAccessSelectedRecord.customerId) {
                        console.error(`Customer record at '${rowIndex}' has no customer id and is not all customers, this should never happen!`);

                        continue;
                    }
                    response = await this.rightsService.DeleteUserSpecificCustomerAccess(customerAccessSelectedRecord.userObjectId, customerAccessSelectedRecord.customerId);
                }
            }
            else if (this.state.activeCategory === 'roles') {
                // 'Deleting' is done by doing a modification that removes the FM and cleaning admin roles (since the user might have other roles, we can't just do a hard delete).
                const modification: IUserRolesAssignmentModification = {
                    description: selectedRecord.description,
                    userObjectId: selectedRecord.userObjectId,
                    rolesToAdd: [],
                    rolesToRemove: [roles.FacilityManager, roles.CleaningAdmin]
                };

                response = await this.rightsService.ModifyUserRolesAssignment(modification);
            } else {
                const userPartnerSelectedRecord = selectedRecord as IUserPartnerAccess;
                response = await this.rightsService.DeleteUserPartnerAccess(userPartnerSelectedRecord.userObjectId, userPartnerSelectedRecord.partnerId);
            }

            responses.push(response);
        }

        if (responses.find(r => r.status !== 204)) {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.rights.deleteerror));
        }
        else {
            NotificationManager.success(LanguageProvider.getTranslation(translations.pages.rights.deletesuccess));
        }

        this.setState({
            selectedRows: []
        }, () => this.state.activeCategory === 'customerAccess' ? this.updateUserAccess() :
            this.state.activeCategory === 'roles' ? this.updateUserRoles() : this.updateUserPartnerAccess());
    }

    private setCategoryRolesActive(): void {
        this.setState({
            activeCategory: 'roles',
            selectedRows: []
        }, this.updateColumns);
    }

    private setCategoryCustomerAccessActive(): void {
        this.setState({
            activeCategory: 'customerAccess',
            selectedRows: []
        }, this.updateColumns);
    }

    private setCategoryPartnersActive(): void {
        this.setState({
            activeCategory: 'partners',
            selectedRows: []
        }, this.updateColumns);
    }

    private getData(): any[] {
        const category = this.state.activeCategory;
        if (category === 'customerAccess') {
            return this.state.userCustomerAccessAssignments;
        }
        if (category === 'roles') {
            return this.state.userRolesAssignments;
        }
        return this.state.userPartnerAccess;
    }

    private renderExtraHeaderSection(): JSX.Element {
        const activeCategory = this.state.activeCategory.toLowerCase();

        return (
            <div>
                {
                    !this.state.loading &&
                    <>
                        <img src={AddGreen} id="add-icon" alt="Add" className="clickable ml-2" data-toggle="modal" data-target={`#user-${activeCategory}-create-modal`} />
                        {
                            this.state.activeCategory === 'roles' && <>
                                <img src={EditBlue} id="edit-icon-active" alt="Edit" className={`clickable ml-2 ${this.state.selectedRows.length === 1 ? "" : "d-none"}`} data-toggle="modal" data-target={`#user-${activeCategory}-edit-modal`} />
                                <img src={EditGrey} id="edit-icon-inactive" alt="Edit" className={`ml-2 ${this.state.selectedRows.length !== 1 ? "" : "d-none"}`} />
                            </>
                        }
                        <img src={DeleteBlue} id="delete-icon-active" alt="Delete" className={`clickable ml-2 ${this.state.selectedRows.length > 0 ? "" : "d-none"}`} onClick={this.handleDeleteClick} />
                        <img src={DeleteGrey} id="delete-icon-inactive" alt="Delete" className={`ml-2 ${this.state.selectedRows.length === 0 ? "" : "d-none"}`} />

                        <div className="category-selection-container">
                            <div className={`category ${this.state.activeCategory === 'customerAccess' ? 'active' : 'inactive'}`} onClick={this.setCategoryCustomerAccessActive}>
                                {LanguageProvider.getTranslation(translations.pages.rights.customeraccess)}
                            </div>
                            <div className={`category ${this.state.activeCategory === 'roles' ? 'active' : 'inactive'}`} onClick={this.setCategoryRolesActive}>
                                {LanguageProvider.getTranslation(translations.pages.rights.roles)}
                            </div>
                            <div className={`category ${this.state.activeCategory === 'partners' ? 'active' : 'inactive'}`} onClick={this.setCategoryPartnersActive}>
                                {LanguageProvider.getTranslation(translations.pages.rights.partners)}
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }

    public render(): JSX.Element {
        const selectedRolesAssignment = this.state.selectedRows.length === 1 ? this.state.userRolesAssignments[this.state.selectedRows[0]] : undefined;

        return (
            <div className="rights">
                <PageHeader pageName="rights" extraHeaderSection={this.renderExtraHeaderSection()} />
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}
                {!this.state.loading &&
                    <div className="assignment-table">
                        <ReactTable
                            className="rights-table"
                            columns={this.state.columns}
                            loading={this.state.loading}
                            showPaginationBottom={true}
                            showPaginationTop={false}
                            defaultPageSize={20}
                            data={this.getData()}
                            PaginationComponent={CustomPagination}
                            minRows={0}
                            defaultSorted={[{
                                id: 'userName',
                                desc: false
                            }]}
                            defaultFilterMethod={ReactTableUtils.useContainsFilter}
                        />
                        <UserAccessCreateModal callbackAfterSuccessfullCreate={this.updateUserAccess} />
                        <UserRolesCreateModal callbackAfterSuccessfullCreate={this.updateUserRoles} />
                        <UserRolesEditModal callbackAfterSuccessfullEdit={this.updateUserRoles}
                            userObjectId={selectedRolesAssignment?.userObjectId ?? ""}
                            userName={selectedRolesAssignment?.description ?? ""}
                            existingRoles={selectedRolesAssignment?.roles ?? []} />
                        <UserPartnerAccessCreateModal callbackAfterSuccessfullCreate={this.updateUserPartnerAccess}
                            existingPartners={this.state.partners} />
                    </div>}
            </div>
        );
    }
}

export default withLocalize(UserRightsPage);