export default class Comparer {
    public static compareProperties(obj1: any, obj2: any): boolean {
        const getKeys = (obj: any): string[] => {
            const keys: string[] = [];

            const rec = (o: any, name?: string): void => {
                Object.keys(o).forEach(key => {
                    const prop = `${name ? name : ""}${key}`;
                    keys.push(prop);

                    if (typeof o[key] === 'object') {
                        rec(o[key], `${prop}.`);
                    }
                });
            };

            rec(obj);
            return keys;
        };

        const keys1 = getKeys(obj1);
        const keys2 = getKeys(obj2);
        const valid = this.areKeysValid(keys1, keys2);

        return valid;
    }

    private static areKeysValid(keysSet1: string[], keysSet2: string[]): boolean {
        if (keysSet1.length !== keysSet2.length) {
            console.log(`Differences in translations: Number of translations do not match. set 1: ${keysSet1.length}, set 2: ${keysSet2.length}`);
            return false;
        }

        const compareKeys = keysSet1.every(key => {
            const keyExists = keysSet2.indexOf(key) === -1;

            if (keyExists) {
                console.log(`Differences in translations: Found difference in item: "${key}"`);
            }

            return !keyExists;
        });

        return compareKeys;
    }
}