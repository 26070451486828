import * as React from 'react';

import './userSettingsMenu.scss';

import DropdownButton from "../../images/DropdownButtonUser.svg";
import ProfileIcon from "../../images/Profile_Icon.svg";
import { withLocalize, Translate } from 'react-localize-redux';
import IUserSettingsMenuProps from './interfaces/IUserSettingsMenuProps';
import IUSerSettingsMenuState from './interfaces/IUserSettingsMenuState';
import AccessService from '../../services/accessService';

class UserSettingsMenu extends React.Component<IUserSettingsMenuProps, IUSerSettingsMenuState> {

    private accessService: AccessService;

    public constructor(props: IUserSettingsMenuProps) {
        super(props);
        this.accessService = new AccessService();
        this.logoutUser = this.logoutUser.bind(this);
    }

    private logoutUser(): void {
        this.accessService.signOut();
    }

    public render(): JSX.Element {

        return (
            <span>
                <div className="dropdown" id="userDropDown">
                    <button className="clickable" type="button" id="dropdownMenuButtonUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={ProfileIcon} id="profile-icon" alt="profile"/>
                        <span className="username-container">{this.accessService.getName()}</span>
                        <img src={DropdownButton} alt="Dropdown" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right userDropDownMenu" aria-labelledby="dropdownMenuButton">
                        <li className="nav-item userDropDownList">
                            <a href="#">{this.accessService.getUserName()}</a>
                        </li>
                        <div className="dropdown-divider"/>
                        <li className="nav-item userDropDownList" onClick={this.logoutUser}>
                            <a href="#"><Translate id="userSettingsMenu.logout" /></a>
                        </li>
                    </div>
                </div>
            </span>
        );
    }
}

export default withLocalize(UserSettingsMenu);