import * as React from "react";
import { Component } from "react";
import { NotificationManager } from 'react-notifications';
import ReactTable from "react-table";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";

import './applicationsPage.scss';

import ApplicationState from "./interfaces/ApplicationsPageState";
import AllApplicationsPageProps from "./interfaces/ApplicationsPageProps";
import CustomPagination from "../../components/pagination/customPagination";
import EditGrey from "../../images/Edit_Grey.svg";
import EditBlue from "../../images/Edit_Blue.svg";
import AddGrey from "../../images/Add_Grey.svg";
import AddGreen from "../../images/Add_Green.svg";
import DeleteBlue from "../../images/Delete_Blue.svg";
import DeleteGrey from "../../images/Delete_Grey.svg";

import IApplicationMessage from "../../interfaces/IApplicationMessage";
import LanguageProvider from "../../providers/languageProvider";
import CoreSpaceService from "../../services/coreSpaceService";
import IReactSelectValue from "../../interfaces/IReactSelectValue";
import ApplicationForm from "./components/applicationForm";
import CoreSpace from "../../models/coreSpace";
import { ReactTableUtils } from "../../utils/reactTableUtils";

export default class ApplicationsPage extends Component<AllApplicationsPageProps, ApplicationState> {
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: AllApplicationsPageProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();

        const emptyFormInput: IApplicationMessage = {
            url: '',
            building: '',
            customerIds: '',
            rowKey: '',
        };

        const state: ApplicationState = {
            buildings: [],
            columns: this.getColumns(),
            loading: true,
            newApplicationMessage: emptyFormInput,
            customers: [],
            customerSelectOptions: [],
            selectedRow: -1,
            showEditMessageForm: false,
            showNewMessageForm: false,
        };
        this.state = state;

        this.getVenues = this.getVenues.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.setActiveRow = this.setActiveRow.bind(this);
        this.renderEditMessageModal = this.renderEditMessageModal.bind(this);
        this.renderNewMessageForm = this.renderNewMessageForm.bind(this);
        this.handleFormCancel = this.handleFormCancel.bind(this);
        this.deleteLink = this.deleteLink.bind(this);
        this.onAddClick = this.onAddClick.bind(this);

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
    }

    public async componentDidMount(): Promise<void> {
        await this.getVenues();
    }


    public getColumns(): any {
        return (
            [
                {
                    Header: '',
                    width: 25,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={row.index === this.state.selectedRow} readOnly />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                }, {
                    Header: LanguageProvider.getTranslation('pages.applications.Building'),
                    id: 'building',
                    accessor: (b: CoreSpace): string => b.name,
                    filterable: true,
                }, {
                    Header: LanguageProvider.getTranslation('pages.applications.Link'),
                    id: 'link',
                    accessor: (b: CoreSpace): string => b.bEEquippedExternalApplicationUrl,
                    filterable: true,
                }
            ]
        );
    }

    private updateColumns(): void {
        const columns = this.getColumns();

        this.setState({ columns: columns });
    }

    private async setActiveRow(e: any): Promise<void> {
        e.stopPropagation();
        e.preventDefault();
        const rowIndexNumber = parseInt(e.target.dataset.rowIndex, 0);
        if (this.state.selectedRow === rowIndexNumber) {
            await this.setState({
                selectedRow: -1,
            });
        }
        else {
            await this.setState({
                selectedRow: rowIndexNumber,
            });
        }
    }

    public async getVenues(): Promise<void> {
        await this.setState({
            loading: true,
            buildings: [],
        });

        this.setState({ loading: true });
        var spaces = await this.coreSpaceService.getVenuesWithProperties(true);

        this.setState({ buildings: spaces });

        const customers = await this.coreSpaceService.getCustomers();
        const customerSelectOptions: IReactSelectValue[] = spaces.map(c => ({
            label: c.name,
            value: c.venueId
        }));

        await this.setState({ customers, customerSelectOptions, });

        this.setState({
            loading: false,
            showEditMessageForm: false,
            showNewMessageForm: false
        });
    }

    public renderEditMessageModal(): void {
        this.setState({
            showEditMessageForm: true,
            showNewMessageForm: false
        });
    }

    public renderNewMessageForm(): void {
        this.setState({
            showNewMessageForm: true,
            showEditMessageForm: false
        });
    }

    public async handleFormCancel(): Promise<void> {
        this.setState({
            showEditMessageForm: false,
            showNewMessageForm: false,
        });
    }

    public async deleteLink(): Promise<void> {
        const consent = window.confirm(LanguageProvider.getTranslation("pages.applications.deleteconsentmessage"));
        if (!consent) {
            return;
        }

        const message = this.state.buildings[this.state.selectedRow];
        if (message !== undefined) {
            await this.coreSpaceService.RemoveBEEquippedExternalApplicationUrlAsync(message.venueId);
        } else {
            NotificationManager.error(LanguageProvider.getTranslation("pages.applications.deletemessageerror"));
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation("pages.applications.deletemessagesucces"));
        this.getVenues();
    }

    private onAddClick(): void {
        if (this.state.selectedRow !== -1) {
            NotificationManager.info(LanguageProvider.getTranslation('pages.applications.selectabuilding'));
        }
        else {
            this.renderNewMessageForm();
        }
    }

    public render(): JSX.Element {
        var url = this?.state?.buildings[this.state.selectedRow]?.bEEquippedExternalApplicationUrl ?? "";

        return (
            <div className="applications-center">
                {!this.state.loading && this.state.showNewMessageForm &&
                    <ApplicationForm
                        buildings={this.state.buildings}
                        customerIds={this.state.selectedRow?.toString()}
                        coreSpace={this.state.buildings[this.state.selectedRow]}
                        customerSelectOptions={this.state.customerSelectOptions}
                        refreshMessages={this.getVenues}
                        isNewMessage={true}
                        handleCancel={this.handleFormCancel}
                        url={url}
                    />}
                {this.state.showEditMessageForm &&
                    <ApplicationForm
                        buildings={this.state.buildings}
                        customerIds={this.state.selectedRow?.toString()}
                        coreSpace={this.state.buildings[this.state.selectedRow]}
                        customerSelectOptions={this.state.customerSelectOptions}
                        refreshMessages={this.getVenues}
                        isNewMessage={false}
                        handleCancel={this.handleFormCancel}
                        url={url}
                    />}

                <div hidden={this.state.showEditMessageForm || this.state.showNewMessageForm ? true : false}>
                    <div className="row mt-5 mb-4" id="message-table-header-section">
                        <div className="col-md-11">
                            {this.state.buildings && <h3>{LanguageProvider.getTranslation('pages.applications.applicationsheader')}</h3>}
                        </div>
                        <div id="message-table-buttons col-md-1">
                            {this.state.buildings.length > 0 && <img src={this.state.selectedRow === -1 ? EditGrey : EditBlue} alt="Edit" id="edit-icon" onClick={this.state.selectedRow === -1 ? (): string => NotificationManager.info(LanguageProvider.getTranslation('pages.applications.selectabuilding')) : this.renderEditMessageModal} />}
                            {this.state.buildings.length > 0 && <img src={this.state.selectedRow === -1 ? DeleteGrey : DeleteBlue} alt="Delete" id="delete-icon" onClick={this.state.selectedRow === -1 ? (): string => NotificationManager.info(LanguageProvider.getTranslation('pages.applications.selectabuilding')) : this.deleteLink} />}
                            <img src={this.state.selectedRow === -1 ? AddGreen : AddGrey} alt="Add" id="add-icon" onClick={this.onAddClick} />
                        </div>
                    </div>

                    {this.state.buildings && this.state.buildings.length > 0 &&
                        <ReactTable
                            className="applications-table-overview"
                            columns={this.state.columns}
                            loading={this.state.loading}
                            showPaginationBottom={true}
                            showPaginationTop={true}
                            defaultPageSize={50}
                            data={this.state.buildings}
                            PaginationComponent={CustomPagination}
                            minRows={0}
                            defaultSorted={[{
                                id: 'building',
                                desc: false
                            }]}
                            defaultFilterMethod={ReactTableUtils.useContainsFilter}
                        />
                    }
                </div>
            </div>
        );
    }
}