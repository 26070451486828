import { IFloor, ISelectorEvent, ISpaceOnMap, ISpaceStyle } from "@beyondeyes/shared";
import React, { useEffect, useRef, useState } from "react";

import PageHeader from "../../components/header/pageHeader";
import CoreSpaceDatatypes from "../../enums/coreSpaceDatatypes";
import CoreSpaceIncludes from "../../enums/coreSpaceIncludes";
import CoreSpaceTypes from "../../enums/coreSpaceTypes";
import CoreSpaceValues from "../../enums/CoreSpaceValues";
import CoreSpace from "../../models/coreSpace";
import Device from "../../models/device";
import LanguageProvider from "../../providers/languageProvider";
import VenueProvider from "../../providers/venueProvider";
import CoreSpaceService from "../../services/coreSpaceService";
import DeviceService from "../../services/deviceService";
import mapper from "../../translations/mapper";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import { AutodeskMapWrapper } from "../autodesk/autodeskMapWrapper";
import AddDeviceModal from "./components/addDeviceModal";
import WarningSign from "../../images/WarningSign.svg";

import './map.scss';

export const Map: React.FC = () => {

    const [venueId, setVenueId] = useState<string>();
    const [venueSpaces, setVenueSpaces] = useState<CoreSpace[]>([]);
    const [venueDevices, setVenueDevices] = useState<{ [deviceId: string]: string }>();
    const [spacesOnMap, setSpacesOnMap] = useState<ISpaceOnMap[]>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [clickedModalSpace, setClickedModalSpace] = useState<CoreSpace>();
    const [assetSearchValue, setAssetSearchValue] = useState<string>('');
    const [floorLevel, setFloorLevel] = useState<number>();
    const [showNotFound, setShowNotFound] = useState<boolean>();
    const inputRef = useRef('');
    const searchResultSpace = useRef<CoreSpace>();

    const findAssetAsync = async (): Promise<void> => {
        if (!venueId || !assetSearchValue) {
            return;
        }

        inputRef.current = assetSearchValue;

        let spaces = venueSpaces;
        let spaceParentOfDeviceLookup = venueDevices;
        if (spaces.length === 0) {
            const coreSpaceService = new CoreSpaceService();
            const coreDeviceService = new DeviceService();
            let devices: Device[] = [];
            [spaces, devices] = await Promise.all([
                coreSpaceService.getSpacesForVenue(venueId, undefined, [CoreSpaceIncludes.Properties]),
                coreDeviceService.getDevicesBySpaceId(venueId, false, true)
            ]);

            spaceParentOfDeviceLookup = Object.assign({}, ...devices.map((device) => ({ [device.id.toLowerCase()]: device.spaceId })));
            setVenueSpaces(spaces);
            setVenueDevices(spaceParentOfDeviceLookup);
        }

        const space = spaces.find(s => {
            const searchValue = inputRef.current.toLowerCase().trim();

            return s.id.toLowerCase() === searchValue
                || s.name.toLowerCase() === searchValue
                || (spaceParentOfDeviceLookup !== undefined && spaceParentOfDeviceLookup[searchValue] === s.id);
        });

        if (!space) {
            setShowNotFound(true);
            searchResultSpace.current = undefined;
            setColoringForSpaces(spacesOnMap);
            return;
        }
        else {
            searchResultSpace.current = space;

            if (spacesOnMap?.find(s => s.id === space.id)) {
                setColoringForSpaces(spacesOnMap);
            }
            else {
                const parentLookup = Object.assign({}, ...spaces.map((space) => ({ [space.id]: space })));
                const maxDepth = 8;
                let current = space;
                for (let i = 0; current.type !== CoreSpaceTypes.Floor && i < maxDepth; i++) {
                    current = parentLookup[current.parentId];
                }

                setFloorLevel(parseInt(current.floorLevel));
                setColoringForSpaces(spacesOnMap);
            }
        }
    };

    const setColoringForSpaces = (spaces?: ISpaceOnMap[]): void => {
        if (!spaces) {
            return;
        }

        const warningMarker = {
            image: WarningSign,
            imageSize: 32
        };

        const styledSpaces = spaces.filter(s => s.beSpace).map(spaceOnMap => {
            const space = spaceOnMap.beSpace as unknown as CoreSpace;
            const style: ISpaceStyle = { fillOpacity: 0.95 };

            if (searchResultSpace.current) {
                if (space.id === searchResultSpace.current.id) {
                    style.fillColor = "#DEDC00";
                }
            }
            else {
                /*
                    No motion                                   = #009FE3 (Light blue)
                    Motion Cooling Down                         = #EDA31E (Orange)
                    Motion Occupied                             = #C42D2D (Red)
                    Motion Unoccupied (<24h)                    = #7CFC00 (Green)
                    Motion Unoccupied (>24h, probably no data)  = #696969 (Grey)
                */
                const containsMotionSensors = space.dataTypes && space.dataTypes.some(d => d === CoreSpaceDatatypes.Motion);
                if (!containsMotionSensors) {
                    style.fillColor = "#009FE3";
                }
                else {
                    const occupiedColor = space.isCoolingDown ? "#EDA31E"
                        : space.isOccupied ? "#C42D2D" :
                            space.hasValue(CoreSpaceValues.OccupancyStatus) && space.hasOccupancyValueWithinLast24Hours ? "#7CFC00" : "#696969";
                    style.fillColor = occupiedColor;
                }
            }

            const copy = { ...spaceOnMap };
            copy.style = {
                placeStyle: style
            };

            if (space.name === space.id) {
                copy.style.placeMarker = warningMarker;
            }

            return copy;
        });

        setSpacesOnMap(styledSpaces);
    };

    const onFloorChange = (floor: IFloor, spaces: ISpaceOnMap[]): void => {
        setColoringForSpaces(spaces);
    };

    const onPlaceClickAsync = async (eventContext: ISelectorEvent): Promise<void> => {
        const mapSpaceRef = eventContext.space;

        if (!mapSpaceRef) {
            return;
        }

        const space = mapSpaceRef.beSpace as unknown as CoreSpace;

        if (!space) {
            return;
        }

        setClickedModalSpace(space);
        setShowModal(true);
    };

    useEffect(() => {
        const setActiveVenueId = (): void => {
            const venueId = VenueProvider.getActiveVenue()?.id ?? "";
            setVenueId(venueId);
            setVenueSpaces([]);
        };

        setActiveVenueId();
        AppEventHub.on(AppEvents.BuildingSelected, setActiveVenueId);

        return (): void => {
            AppEventHub.off(AppEvents.BuildingSelected, setActiveVenueId);
        };
    }, []);

    const onActiveSpaceNameChange = (id: string, name: string): void => {

        const spaces = spacesOnMap?.map(s => {
            if (s.id === id && s.beSpace) {
                s.beSpace.name = name;

                if (name !== id && s.style) {
                    s.style.placeMarker = undefined;
                }
            }
            return s;
        });

        setSpacesOnMap(spaces);
    };

    return (<div className="map-page">
        <PageHeader pageName="map" />
        {
            <div id="map">
                <div className="w-100 search-bar-area">
                    <div className="d-flex justify-content-center">
                        <div className="input-group w-50 mt-5">
                            <input
                                value={assetSearchValue}
                                onChange={(e): void => setAssetSearchValue(e.target.value)}
                                onKeyDown={(e): void => {
                                    if (e.key === "Enter") {
                                        findAssetAsync();
                                    }
                                }}
                                autoComplete="off"
                                type="text"
                                className="form-control mr-3 z-index-front"
                                id="inlineFormInputGroup"
                                placeholder={LanguageProvider.getTranslation(mapper.pages.map.search.text)} />
                            <button
                                className="btn btn-primary clickable mr-3 z-index-front"
                                onClick={async (e): Promise<void> => await findAssetAsync()}>
                                Search
                            </button>
                            <button
                                className="btn btn-primary clickable z-index-front"
                                onClick={(e): void => {
                                    inputRef.current = '';
                                    setAssetSearchValue('');
                                    searchResultSpace.current = undefined;
                                    setShowNotFound(false);
                                    setColoringForSpaces(spacesOnMap);
                                }}>
                                Reset
                            </button>
                        </div>
                    </div>
                    {showNotFound && <div className="d-flex justify-content-center pt-1">
                        <div className="alert alert-warning w-50" role="alert">
                            {LanguageProvider.getTranslation(mapper.pages.map.search.notfound)}
                        </div>
                    </div>}
                </div>

                <AutodeskMapWrapper
                    onPlaceClickAsync={onPlaceClickAsync}
                    onFloorChange={onFloorChange}
                    spaces={spacesOnMap}
                    floorLevel={floorLevel}
                />

                <AddDeviceModal
                    visible={showModal}
                    spaceName={clickedModalSpace?.name ?? ""}
                    spaceId={clickedModalSpace?.id ?? ""}
                    crmId={clickedModalSpace?.crmId ?? ""}
                    onSpaceNameChange={onActiveSpaceNameChange}
                    onClose={(): void => setShowModal(false)} />
            </div>
        }
    </div>);
};

export default Map;