import React from "react";
import { withLocalize } from "react-localize-redux";

import UserAccountService from '../../services/userAccountService';
import IAllAccountsPageProps from "./interfaces/IAllAccountsPageProps";
import IAllAccountsPageState from "./interfaces/IAllAccountsPageState";
import PageHeader from "../../components/header/pageHeader";

import "./allAccountsPage.scss";
import ReactTable, { Column } from "react-table";
import translations from '../../translations/mapper';
import LanguageProvider from "../../providers/languageProvider";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import UserAccount from "../../models/userAccount";
import { ReactTableUtils } from "../../utils/reactTableUtils";
import CustomPagination from "../../components/pagination/customPagination";

import DeleteRed from "../../images/Delete_Red.svg";
import Refresh from "../../images/Refresh.svg";
import Yellow_Cross_Cancel from "../../images/Yellow_Cross_Cancel.svg";
import Arrow from "../../images/Yellow_Arrow_Right.svg";
import { PulseLoader } from "react-spinners";

class AllAccountsPage extends React.Component<IAllAccountsPageProps, IAllAccountsPageState>{

    private readonly accountService: UserAccountService;

    public constructor(props: IAllAccountsPageProps) {
        super(props);

        this.state = {
            loading: false,
            columns: this.getColumns(),
            userAccounts: [],
            showAreYouSureResetPopup: false,
            showAreYouSureDeletePopup: false,
            showCannotResetPopup: false,
            showCannotDeletePopup: false,
            showNewPasswordPopup: false,
            userToDelete: undefined,
            userToReset: undefined,
            cantDeleteModalText: "",
            cantResetModalText: "",
            newPassword: "",
            newPasswordUsername: "",
            modalSubmitting: false
        };

        this.accountService = new UserAccountService();

        this.getAccounts = this.getAccounts.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.resetUser = this.resetUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.passwordResetClicked = this.passwordResetClicked.bind(this);
        this.deleteClicked = this.deleteClicked.bind(this);
        this.getPasswordWarning = this.getPasswordWarning.bind(this);
        this.getPopupResetQuestion = this.getPopupResetQuestion.bind(this);
        this.getPopupDeleteQuestion = this.getPopupDeleteQuestion.bind(this);
        this.closeAreYouSurePopUp = this.closeAreYouSurePopUp.bind(this);
        this.closeCantResetPopUp = this.closeCantResetPopUp.bind(this);
        this.closeCantDeletePopUp = this.closeCantDeletePopUp.bind(this);
        this.closeNewPasswordPopUp = this.closeNewPasswordPopUp.bind(this);

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
    }

    public async componentDidMount(): Promise<void> {
        await this.getAccounts();
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
    }

    private async getAccounts(): Promise<void> {
        this.setState({ loading: true });
        var accounts = await this.accountService.getUserAccounts();
        this.setState({ userAccounts: accounts, loading: false });
    }

    private updateColumns(): void {
        const columns = this.getColumns();
        this.setState({
            columns: columns
        });
    }

    private async resetUser(): Promise<void> {
        if (this.state.userToReset) {
            this.setState({
                modalSubmitting: true
            });
            var [response, newPassword] = await this.accountService.resetPasswordForUserAccount(this.state.userToReset);
            this.closeAreYouSurePopUp();
            if (response.status !== 200) {
                const responseText = await response.text();
                var text = LanguageProvider.getTranslation(translations.pages.accounts.cantreset.text);
                if (responseText.indexOf("could not be reset because of (one of) its roles") >= 0) {
                    text = LanguageProvider.getTranslation(translations.pages.accounts.cantreset.rolestext);
                }
                this.setState({ cantResetModalText: text, showCannotResetPopup: true });
            }
            else {
                this.setState({
                    showNewPasswordPopup: true,
                    newPassword: newPassword,
                    newPasswordUsername: this.state.userToReset.upn
                });
            }
        }
    }

    private async deleteUser(): Promise<void> {
        if (this.state.userToDelete) {
            this.setState({
                loading: true,
                modalSubmitting: true
            });
            var response = await this.accountService.deleteUserAccount(this.state.userToDelete);
            this.closeAreYouSurePopUp();
            if (response.status === 400) {
                const responseText = await response.text();
                var text = LanguageProvider.getTranslation(translations.pages.accounts.cantdelete.text);
                if (responseText.indexOf("could not be deleted because of (one of) its roles") >= 0) {
                    text = LanguageProvider.getTranslation(translations.pages.accounts.cantdelete.rolestext);
                }
                this.setState({ cantDeleteModalText: text, showCannotDeletePopup: true });
            }
            this.getAccounts();
        }
    }

    private getColumns(): Column[] {
        const columns = [
            {
                id: 'fullName',
                Header: LanguageProvider.getTranslation(translations.pages.accounts.fullname),
                accessor: (u: UserAccount): string => u.fullName,
                filterable: true
            },
            {
                id: 'upn',
                Header: LanguageProvider.getTranslation(translations.pages.accounts.upn),
                accessor: (u: UserAccount): string => u.upn,
                filterable: true,
            },
            {
                id: 'companyName',
                Header: LanguageProvider.getTranslation(translations.pages.accounts.companyname),
                accessor: (u: UserAccount): string => u.companyName || `${LanguageProvider.getTranslation(translations.pages.rights.values.allcustomers)} / ${LanguageProvider.getTranslation(translations.pages.rights.values.unknowncustomer)}`,
                filterable: true
            },
            {
                id: 'reset',
                Header: LanguageProvider.getTranslation(translations.pages.accounts.reset),
                filterable: false,
                sortable: false,
                width: 150,
                Cell: (row: any): JSX.Element => (
                    <div className="password-reset-cell">
                        <label className="password-reset-image">
                            <img className="clickable" src={Refresh} onClick={(): void => this.passwordResetClicked(row.index)}></img>
                        </label>
                    </div>
                )
            },
            {
                id: 'delete',
                Header: LanguageProvider.getTranslation(translations.pages.accounts.delete),
                filterable: false,
                sortable: false,
                width: 150,
                Cell: (row: any): JSX.Element => (
                    <div className="delete-cell">
                        <label className="delete-image">
                            <img className="clickable" src={DeleteRed} onClick={(): void => this.deleteClicked(row.index)}></img>
                        </label>
                    </div>
                )
            }];

        return columns;
    }

    private passwordResetClicked(index: string): void {
        const rowIndexNumber = parseInt(index, 0);
        var user = this.state.userAccounts[rowIndexNumber];
        this.setState({ userToReset: user, showAreYouSureResetPopup: true });
    }

    private deleteClicked(index: string): void {
        const rowIndexNumber = parseInt(index, 0);
        var user = this.state.userAccounts[rowIndexNumber];
        this.setState({ userToDelete: user, showAreYouSureDeletePopup: true });
    }

    private getPopupResetQuestion(): string {
        var question = LanguageProvider.getTranslation(translations.pages.accounts.resetpopup.resetquestion);
        return question.replace('{0}', this.state.userToReset?.upn ?? '');
    }

    private getPopupDeleteQuestion(): string {
        var question = LanguageProvider.getTranslation(translations.pages.accounts.deletepopup.deletequestion);
        return question.replace('{0}', this.state.userToDelete?.upn ?? '');
    }

    private closeAreYouSurePopUp(): void {
        this.setState({
            showAreYouSureDeletePopup: false,
            showAreYouSureResetPopup: false,
            modalSubmitting: false
        });
    }

    private closeCantResetPopUp(): void {
        this.setState({
            showCannotResetPopup: false
        });
    }

    private closeCantDeletePopUp(): void {
        this.setState({
            showCannotDeletePopup: false
        });
    }

    private closeNewPasswordPopUp(): void {
        this.setState({
            showNewPasswordPopup: false,
            newPassword: "",
            newPasswordUsername: ""
        });
    }

    public getPasswordWarning(): string {
        const accountName = this.state.newPasswordUsername;
        const warning = LanguageProvider.getTranslation(translations.pages.accounts.passwordwarningpart1) + accountName
            + LanguageProvider.getTranslation(translations.pages.accounts.passwordwarningpart2);

        return warning;
    }

    public render(): JSX.Element {
        return (
            <div className='all-accounts'>
                <PageHeader pageName="accounts" />
                <div className="accounts-table">
                    <ReactTable
                        columns={this.state.columns}
                        loading={this.state.loading}
                        showPaginationBottom={true}
                        showPaginationTop={false}
                        defaultPageSize={20}
                        data={this.state.userAccounts}
                        PaginationComponent={CustomPagination}
                        minRows={0}
                        defaultSorted={[{
                            id: 'fullName',
                            desc: false
                        }]}
                        defaultFilterMethod={ReactTableUtils.useContainsFilter}
                    />
                </div>

                <div className={"reset-account-modal modal " + (this.state.showAreYouSureResetPopup ? "d-block" : "d-none")} id="reset-account-modal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header add-device-header">
                                <h5 className="modal-title" id="addDeviceModalLabel">
                                    {LanguageProvider.getTranslation(translations.pages.accounts.resetpopup.title)}
                                </h5>
                                <button type="button" className="close" onClick={this.closeAreYouSurePopUp} aria-label="Close">
                                    <img src={Yellow_Cross_Cancel} alt="Cancel" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.getPopupResetQuestion()}
                            </div>
                            <div className="modal-footer">
                                {
                                    !this.state.modalSubmitting &&
                                    <>
                                        <button type="submit" className="btn cancel-button" onClick={this.closeAreYouSurePopUp}>{LanguageProvider.getTranslation(translations.pages.accounts.resetpopup.cancel)}<img src={Yellow_Cross_Cancel} /></button>
                                        <button type="button" className="btn blue-button" onClick={this.resetUser}>{LanguageProvider.getTranslation(translations.pages.accounts.resetpopup.title)} <img src={Arrow} /></button>
                                    </>
                                }
                                {
                                    this.state.modalSubmitting && <PulseLoader color={'#37a0e6'} size={10} margin={"15px"} loading={this.state.modalSubmitting} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"remove-account-modal modal " + (this.state.showAreYouSureDeletePopup ? "d-block" : "d-none")} id="remove-account-modal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header add-device-header">
                                <h5 className="modal-title" id="addDeviceModalLabel">
                                    {LanguageProvider.getTranslation(translations.pages.accounts.deletepopup.title)}
                                </h5>
                                <button type="button" className="close" onClick={this.closeAreYouSurePopUp} aria-label="Close">
                                    <img src={Yellow_Cross_Cancel} alt="Cancel" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.getPopupDeleteQuestion()}
                            </div>
                            <div className="modal-footer">
                                {
                                    !this.state.modalSubmitting &&
                                    <>
                                        <button type="submit" className="btn cancel-button" onClick={this.closeAreYouSurePopUp}>{LanguageProvider.getTranslation(translations.pages.accounts.deletepopup.cancel)}<img src={Yellow_Cross_Cancel} /></button>
                                        <button type="button" className="btn blue-button" onClick={this.deleteUser}>{LanguageProvider.getTranslation(translations.pages.accounts.deletepopup.title)} <img src={Arrow} /></button>
                                    </>
                                }
                                {
                                    this.state.modalSubmitting && <PulseLoader color={'#37a0e6'} size={10} margin={"15px"} loading={this.state.modalSubmitting} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"cant-reset-modal modal " + (this.state.showCannotResetPopup ? "d-block" : "d-none")} id="cant-reset-modal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header add-device-header">
                                <h5 className="modal-title" id="resetPasswordModalLabel">
                                    {LanguageProvider.getTranslation(translations.pages.accounts.cantreset.title)}
                                </h5>
                                <button type="button" className="close" onClick={this.closeCantResetPopUp} aria-label="Close">
                                    <img src={Yellow_Cross_Cancel} alt="Cancel" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.cantResetModalText}
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn cancel-button" onClick={this.closeCantResetPopUp}>{LanguageProvider.getTranslation(translations.pages.accounts.cantreset.close)}<img src={Yellow_Cross_Cancel} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"cant-delete-modal modal " + (this.state.showCannotDeletePopup ? "d-block" : "d-none")} id="cant-delete-modal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header add-device-header">
                                <h5 className="modal-title" id="deleteUserModalLabel">
                                    {LanguageProvider.getTranslation(translations.pages.accounts.cantdelete.title)}
                                </h5>
                                <button type="button" className="close" onClick={this.closeCantDeletePopUp} aria-label="Close">
                                    <img src={Yellow_Cross_Cancel} alt="Cancel" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.cantDeleteModalText}
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn cancel-button" onClick={this.closeCantDeletePopUp}>{LanguageProvider.getTranslation(translations.pages.accounts.cantdelete.close)}<img src={Yellow_Cross_Cancel} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"new-password-modal modal " + (this.state.showNewPasswordPopup ? "d-block" : "d-none")} id="new-password-modal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header add-device-header">
                                <h5 className="modal-title" id="newPasswordModalLabel">
                                    {LanguageProvider.getTranslation(translations.pages.accounts.newpassword.title)}
                                </h5>
                                <button type="button" className="close" onClick={this.closeNewPasswordPopUp} aria-label="Close">
                                    <img src={Yellow_Cross_Cancel} alt="Cancel" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                                <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                                <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.newPassword || ""}
                                        contentEditable={false}
                                        readOnly={true}
                                        placeholder={LanguageProvider.getTranslation(translations.pages.accounts.password)} />
                                    <div className="input-group-append">
                                        <span
                                            onClick={(): void => { navigator.clipboard.writeText(this.state.newPassword); }}
                                            className="input-group-text clickable" id="basic-addon2">Copy</span>
                                    </div>
                                </div>
                                <div className="alert alert-warning">
                                    {this.getPasswordWarning()}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn cancel-button" onClick={this.closeNewPasswordPopUp}>{LanguageProvider.getTranslation(translations.pages.accounts.newpassword.close)}<img src={Yellow_Cross_Cancel} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(AllAccountsPage);