import React, { Component } from "react";
import { PulseLoader } from 'react-spinners';

import IFullPageLoaderProps from './interfaces/IFullPageLoaderProps';

export default class FullPageLoader extends Component<IFullPageLoaderProps> {

    public constructor(props: IFullPageLoaderProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div style={{ height: `calc(100vh - ${this.props.viewHeightPxReduction ?? 80}px)` }} className="d-flex justify-content-center align-items-center">
                <PulseLoader color={'#00172e'} size={8} margin="15px" loading={this.props.loading} />
            </div>
        );
    }
}