import MaintenanceService from "./maintenanceService";

export default class AutodeskService {
    private readonly maintenanceService: MaintenanceService;

    public constructor() {
        this.maintenanceService = new MaintenanceService();
    }

    public uploadAsync(venueId: string, floorId: string, formData: FormData): Promise<Response> {
        return this.maintenanceService.postFormData(`autodesk/venue/${venueId}/floor/${floorId}/upload`, formData);
    }

    public setPreviewAsync(venueId: string, floorId: string, urn: string): Promise<Response> {
        return this.maintenanceService.post(`autodesk/venue/${venueId}/floor/${floorId}/urn/${urn}/preview`, null);
    }

    public setCurrentAsync(venueId: string, floorId: string, urn: string): Promise<Response> {
        return this.maintenanceService.post(`autodesk/venue/${venueId}/floor/${floorId}/urn/${urn}/current`, null);
    }
}