import * as React from 'react';
import { NotificationManager } from 'react-notifications';


import { withLocalize } from 'react-localize-redux';
import CoreSpaceService from '../../services/coreSpaceService';
import PageHeader from "../../components/header/pageHeader";

import IGroupRightsPageProps from "./interfaces/IGroupRightsPageProps";
import IGroupRightsPageState from "./interfaces/IGroupRightsPageState";
import IGroupCustomerAccessAssignment from '../../interfaces/IGroupCustomerAccessAssignment';

import AddGreen from "../../images/Add_Green.svg";
import DeleteBlue from "../../images/Delete_Blue.svg";
import DeleteGrey from "../../images/Delete_Grey.svg";
import EditBlue from "../../images/Edit_Blue.svg";
import EditGrey from "../../images/Edit_Grey.svg";

import "./rightsPage.scss";
import LanguageProvider from '../../providers/languageProvider';
import translations from '../../translations/mapper';
import RightsService from '../../services/rightsService';
import ReactTable, { Column } from 'react-table';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import FullPageLoader from '../../components/loaders/fullPageLoader';
import CustomPagination from '../../components/pagination/customPagination';
import { ReactTableUtils } from '../../utils/reactTableUtils';
import { CustomerNameElementStyler } from './utils/customerNameElementStyler';
import GroupAccessCreateModal from "./components/groupAccessCreateModal";
import GroupRolesCreateModal from "./components/groupRolesCreateModal";
import GroupRolesEditModal from "./components/groupRolesEditModal";
import TimeZoneUtils from '../../utils/timeZoneUtils';
import IGroupRolesAssignment from '../../interfaces/IGroupRolesAssignment';
import roles from '../../enums/roles';
import IGroupRolesAssignmentModification from '../../interfaces/IGroupRolesAssignmentModification';

class GroupRightsPage extends React.Component<IGroupRightsPageProps, IGroupRightsPageState>{
    private readonly rightsService: RightsService;
    private readonly spaceService: CoreSpaceService;

    public constructor(props: IGroupRightsPageProps) {
        super(props);

        this.rightsService = new RightsService();
        this.spaceService = new CoreSpaceService();

        this.updateColumns = this.updateColumns.bind(this);
        this.updateGroupAccess = this.updateGroupAccess.bind(this);
        this.updateGroupRoles = this.updateGroupRoles.bind(this);
        this.renderExtraHeaderSection = this.renderExtraHeaderSection.bind(this);
        this.setActiveRow = this.setActiveRow.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.swapActiveCategory = this.swapActiveCategory.bind(this);

        this.state = {
            groupCustomerAccessAssignments: [],
            loading: true,
            columns: this.getColumns(true),
            customers: [],
            selectedRows: [],
            activeCategory: 'customerAccess',
            groupRolesAssignments: []
        };

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
    }

    public async componentDidMount(): Promise<void> {
        const existingGroupAccessTask = this.rightsService.GetGroupCustomerAccessAssignments();
        const existingRolesTask = this.rightsService.GetGroupRolesAssignments();
        const customersTask = this.spaceService.getCustomers();

        const [existingGroupAccess, existingRoles, customers] = await Promise.all([existingGroupAccessTask, existingRolesTask, customersTask]);

        this.setState({
            groupCustomerAccessAssignments: existingGroupAccess,
            groupRolesAssignments: existingRoles,
            customers: customers,
            loading: false
        });
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
    }

    private updateColumns(): void {
        const columns = this.getColumns();
        this.setState({
            columns: columns
        });
    }

    private getColumns(overrideCategoryAsCustomerAccess: Boolean = false): Column[] {
        if (overrideCategoryAsCustomerAccess || this.state.activeCategory === 'customerAccess') {
            const columns = [
                {
                    id: 'domain',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.domain),
                    accessor: "domain",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'groupName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.groupname),
                    accessor: "description",
                    filterable: true
                },
                {
                    id: 'groupobjectid',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.groupobjectid),
                    accessor: "groupObjectId",
                    filterable: true
                },
                {
                    id: 'customerName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.customername),
                    accessor: (row: any): string => CustomerNameElementStyler.getCustomerNameForAccessAssignment(row, this.state.customers),
                    Cell: (row: any): JSX.Element => CustomerNameElementStyler.getCustomerNameStyling(row, this.state.customers),
                    filterable: true
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (message: IGroupCustomerAccessAssignment): string =>
                        message.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(message.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }];

            return columns;
        }
        else {
            const columns = [
                {
                    id: 'domain',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.domain),
                    accessor: "domain",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'groupName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.groupname),
                    accessor: "description",
                    filterable: true
                },
                {
                    id: 'groupobjectid',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.groupobjectid),
                    accessor: "groupObjectId",
                    filterable: true
                },
                {
                    id: 'roles',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.roles),
                    accessor: (message: IGroupRolesAssignment): string => message.roles?.join(' '),
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            {
                                row.original.roles !== undefined ? (row.original.roles as roles[]).map((r, i) =>
                                    <div className={`role-row ${i > 0 ? 'later-role' : ''}`} key={i}>
                                        {LanguageProvider.getTranslation(translations.pages.rights.rolenames[r.toString().toLowerCase()])}
                                    </div>) :
                                    <div />}
                        </div>
                    )
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (message: IGroupRolesAssignment): string =>
                        message.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(message.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }];
            return columns;
        }
    }

    private async setActiveRow(e: any): Promise<void> {
        e.stopPropagation();
        e.preventDefault();
        const rowIndexNumber = parseInt(e.target.dataset.rowIndex);
        if (this.state.selectedRows.includes(rowIndexNumber)) {
            await this.setState({
                selectedRows: this.state.selectedRows.filter(r => r !== rowIndexNumber),
            });
        }
        else {
            const newRows = this.state.selectedRows;
            newRows.push(rowIndexNumber);
            await this.setState({
                selectedRows: newRows,
            });
        }
    }

    private async updateGroupAccess(): Promise<void> {
        const existingGroupAccess = await this.rightsService.GetGroupCustomerAccessAssignments();
        this.setState({
            groupCustomerAccessAssignments: existingGroupAccess,
            loading: false
        });
    }

    private async updateGroupRoles(): Promise<void> {
        const existingRoles = await this.rightsService.GetGroupRolesAssignments();
        this.setState({
            groupRolesAssignments: existingRoles,
            selectedRows: [],
            loading: false
        });
    }

    private async handleDeleteClick(): Promise<void> {
        if (this.state.selectedRows.length === 0) {
            return;
        }

        if (!window.confirm(LanguageProvider.getTranslation(translations.pages.rights.deleteconfirm))) {
            return;
        }

        const responses: Response[] = [];
        for (const rowIndex of this.state.selectedRows) {
            const selectedRecord = this.state.activeCategory === 'customerAccess' ? this.state.groupCustomerAccessAssignments[rowIndex] : this.state.groupRolesAssignments[rowIndex];

            if (!selectedRecord) {
                console.error(`Could not find customer record at index '${rowIndex}', this should never happen!`);

                continue;
            }
            let response: Response | undefined = undefined;
            if (this.state.activeCategory === 'customerAccess') {
                var customerAccessSelectedRecord = selectedRecord as IGroupCustomerAccessAssignment;
                if (customerAccessSelectedRecord.allCustomers) {
                    response = await this.rightsService.DeleteGroupAllCustomersAccess(customerAccessSelectedRecord.domain, customerAccessSelectedRecord.groupObjectId);
                }
                else {
                    if (!customerAccessSelectedRecord.customerId) {
                        console.error(`Customer record at '${rowIndex}' has no customer id and is not all customers, this should never happen!`);

                        continue;
                    }
                    response = await this.rightsService.DeleteGroupSpecificCustomerAccess(customerAccessSelectedRecord.domain, customerAccessSelectedRecord.groupObjectId, customerAccessSelectedRecord.customerId);
                }
            }
            else {
                // 'Deleting' is done by doing a modification that removes the FM and cleaning admin roles (since the user might have other roles, we can't just do a hard delete).
                const modification: IGroupRolesAssignmentModification = {
                    description: selectedRecord.description,
                    domain: selectedRecord.domain,
                    groupObjectId: selectedRecord.groupObjectId,
                    rolesToAdd: [],
                    rolesToRemove: [roles.FacilityManager, roles.CleaningAdmin]
                };

                response = await this.rightsService.ModifyGroupRolesAssignment(modification);
            }

            responses.push(response);
        }

        if (responses.find(r => r.status !== 204)) {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.rights.deleteerror));
        }
        else {
            NotificationManager.success(LanguageProvider.getTranslation(translations.pages.rights.deletesuccess));
        }

        this.setState({
            selectedRows: []
        }, () => this.state.activeCategory === 'customerAccess' ? this.updateGroupAccess() : this.updateGroupRoles());
    }

    private swapActiveCategory(): void {
        if (this.state.activeCategory === 'customerAccess') {
            this.setState({
                activeCategory: 'roles',
                selectedRows: []
            }, this.updateColumns);
        }
        else {
            this.setState({
                activeCategory: 'customerAccess',
                selectedRows: []
            }, this.updateColumns);
        }
    }

    private renderExtraHeaderSection(): JSX.Element {
        const activeCategory = this.state.activeCategory.toLowerCase();

        return (
            <div>
                {
                    !this.state.loading &&
                    <>
                        <img src={AddGreen} id="add-icon" alt="Add" className="clickable ml-2" data-toggle="modal" data-target={`#group-${activeCategory}-create-modal`} />
                        {
                            this.state.activeCategory === 'roles' && <>
                                <img src={EditBlue} id="edit-icon-active" alt="Edit" className={`clickable ml-2 ${this.state.selectedRows.length === 1 ? "" : "d-none"}`} data-toggle="modal" data-target={`#group-${activeCategory}-edit-modal`} />
                                <img src={EditGrey} id="edit-icon-inactive" alt="Edit" className={`ml-2 ${this.state.selectedRows.length !== 1 ? "" : "d-none"}`} />
                            </>
                        }
                        <img src={DeleteBlue} id="delete-icon-active" alt="Delete" className={`clickable ml-2 ${this.state.selectedRows.length > 0 ? "" : "d-none"}`} onClick={this.handleDeleteClick} />
                        <img src={DeleteGrey} id="delete-icon-inactive" alt="Delete" className={`ml-2 ${this.state.selectedRows.length === 0 ? "" : "d-none"}`} />

                        <div className="category-selection-container">
                            <div className={`category ${this.state.activeCategory === 'customerAccess' ? 'active' : 'inactive'}`} onClick={this.state.activeCategory !== 'customerAccess' ? this.swapActiveCategory : undefined}>
                                {LanguageProvider.getTranslation(translations.pages.rights.customeraccess)}
                            </div>
                            <div className={`category ${this.state.activeCategory === 'roles' ? 'active' : 'inactive'}`} onClick={this.state.activeCategory !== 'roles' ? this.swapActiveCategory : undefined}>
                                {LanguageProvider.getTranslation(translations.pages.rights.roles)}
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }

    public render(): JSX.Element {
        const selectedRolesAssignment = this.state.selectedRows.length === 1 ? this.state.groupRolesAssignments[this.state.selectedRows[0]] : undefined;

        return (
            <div className="rights">
                <PageHeader pageName="rights" extraHeaderSection={this.renderExtraHeaderSection()} />
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}
                {!this.state.loading &&
                    <div className="assignment-table">
                        <ReactTable
                            className="rights-table"
                            columns={this.state.columns}
                            loading={this.state.loading}
                            showPaginationBottom={true}
                            showPaginationTop={false}
                            defaultPageSize={20}
                            data={this.state.activeCategory === 'customerAccess' ? this.state.groupCustomerAccessAssignments as any[] : this.state.groupRolesAssignments as any[]}
                            PaginationComponent={CustomPagination}
                            minRows={0}
                            defaultSorted={[{
                                id: 'domain',
                                desc: false
                            }]}
                            defaultFilterMethod={ReactTableUtils.useContainsFilter}
                        />
                        <GroupAccessCreateModal callbackAfterSuccessfullCreate={this.updateGroupAccess} />
                        <GroupRolesCreateModal callbackAfterSuccessfullCreate={this.updateGroupRoles} />
                        <GroupRolesEditModal callbackAfterSuccessfullEdit={this.updateGroupRoles}
                            groupObjectId={selectedRolesAssignment?.groupObjectId ?? ""}
                            domain={selectedRolesAssignment?.domain ?? ""}
                            groupName={selectedRolesAssignment?.description ?? ""}
                            existingRoles={selectedRolesAssignment?.roles ?? []} />
                    </div>}
            </div>
        );
    }
}

export default withLocalize(GroupRightsPage);