import CoreEntity from "./coreEntity";
import ISpaceTag from "./../interfaces/ISpaceTag";
import ISpaceProperty from "./../interfaces/ISpaceProperty";
import ISpaceValue from "./../interfaces/ISpaceValue";
import ISerializedDevice from "../interfaces/ISerializedDevice";
import ICoreSpace from "../interfaces/ICoreSpace";
import CoreSpaceValues from "../enums/CoreSpaceValues";
import moment from "moment";

export default class CoreSpace extends CoreEntity implements ICoreSpace {
    public id: string;
    public venueId: string;
    public parentId: string;
    public name: string;
    public type: string;
    public dataTypes?: string[];
    public properties: ISpaceProperty[];
    public values: ISpaceValue[];
    public tags?: ISpaceTag[];
    public devices: ISerializedDevice[];

    public constructor(space: ICoreSpace) {
        super(space);
        this.id = space.id;
        this.venueId = space.venueId;
        this.parentId = space.parentId;
        this.name = space.name;
        this.type = space.type;
        this.dataTypes = space.dataTypes;
        this.properties = space.properties ?? [];
        this.values = space.values ?? [];
        this.tags = space.tags;
    }

    public get customerId(): string {
        return this.getCustomPropertyValue("CustomerId");
    }

    public get crmId(): string {
        return this.getCustomPropertyValue("CrmId");
    }

    public get mapwizeId(): string {
        return this.getCustomPropertyValue("MapwizeId");
    }

    public get currentUrn(): string {
        return this.getCustomPropertyValue("CurrentDerivativeModelId");
    }

    public get previewUrn(): string {
        return this.getCustomPropertyValue("PreviewDerivativeModelId");
    }

    public get floorLevel(): string {
        return this.getCustomPropertyValue("FloorLevel");
    }

    public get bEEquippedExternalApplicationUrl(): string {
        return this.getCustomPropertyValue("BEEquippedExternalApplicationUrl");
    }

    public hasValue(type: CoreSpaceValues): boolean {
        return this.getValue(type) ? true : false;
    }

    public get isOccupied(): boolean {
        const type = CoreSpaceValues.OccupancyStatus;
        return this.hasValue(type) && this.getValue(type).value.toLowerCase() === "occupied";
    }

    public get hasOccupancyValueWithinLast24Hours(): boolean {
        const type = CoreSpaceValues.OccupancyStatus;

        if(!this.hasValue(type)){
            return false;
        }

        const timestampMoment = moment(this.getValue(type).timestamp);
        return timestampMoment.add(24, "hours") > moment(new Date());
    }

    public get isCoolingDown(): boolean {
        if (!this.hasValue(CoreSpaceValues.ActualFree)) {
            return false;
        }

        const value = this.getValue(CoreSpaceValues.ActualFree);
        const actualFreeLocal = new Date(value.value);
        return actualFreeLocal > new Date();
    }
}