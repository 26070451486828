import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import { NotificationContainer } from 'react-notifications';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import 'react-notifications/lib/notifications.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from './pages/app';
import registerServiceWorker from './serviceworkers/registerServiceWorker';

import './index.scss';
import './main.scss';

import AppEventHub from './utils/appEventHub';
import LanguageProvider from './providers/languageProvider';
import AccessService from './services/accessService';
import { EndpointContextBuilder } from '@beyondeyes/shared';
import { ApplicationConfig } from './config';

AppEventHub.initialize();
LanguageProvider.validateTranslations();
EndpointContextBuilder.initiate(ApplicationConfig);

const appInsights = document.createElement("script");
appInsights.type = "text/javascript";
appInsights.innerHTML = `
    var appInsights=window.appInsights||function(a){
    function b(a){c[a]=function(){var b=arguments;c.queue.push(function(){c[a].apply(c,b)})}}var c={config:a},d=document,e=window;setTimeout(function(){var b=d.createElement("script");b.src=a.url||"https://az416426.vo.msecnd.net/scripts/a/ai.0.js",d.getElementsByTagName("script")[0].parentNode.appendChild(b)});try{c.cookie=d.cookie}catch(a){}c.queue=[];for(var f=["Event","Exception","Metric","PageView","Trace","Dependency"];f.length;)b("track"+f.pop());if(b("setAuthenticatedUserContext"),b("clearAuthenticatedUserContext"),b("startTrackEvent"),b("stopTrackEvent"),b("startTrackPage"),b("stopTrackPage"),b("flush"),!a.disableExceptionTracking){f="onerror",b("_"+f);var g=e[f];e[f]=function(a,b,d,e,h){var i=g&&g(a,b,d,e,h);return!0!==i&&c["_"+f](a,b,d,e,h),i}}return c
    }({
        instrumentationKey:"${process.env.REACT_APP_endpoints_ApplicationInsights}"
    });
    
    window.appInsights=appInsights,appInsights.queue&&0===appInsights.queue.length&&appInsights.trackPageView();`;
document.getElementsByTagName('head')[0].appendChild(appInsights);

const accessService = new AccessService();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

accessService.signIn(() => {
    root.render(
        <Router>
            <LocalizeProvider>
                <NotificationContainer />
                <App location={window.location} />
            </LocalizeProvider>
        </Router>
    );
    registerServiceWorker();
},
    () => {
        root.render(<h1>No access.</h1>);
    });