import * as React from "react";
import { PulseLoader } from "react-spinners";
import { withLocalize, Translate } from 'react-localize-redux';
import Select from 'react-select';

import "./currentOccupanceOverview.scss";

import ICurrentOccupancyOverviewProps from "./interfaces/ICurrentOccupancyOverviewProps";
import ICurrentOccupancyOverviewState from "./interfaces/ICurrentOccupancyOverviewState";
import VenueOccupancyStatusLine from "./venueOccupancyStatusLine";

import CoreSpaceService from '../../../services/coreSpaceService';
import SelectBoxUtils from "../../../utils/selectBoxUtils";
import LanguageProvider from "../../../providers/languageProvider";

class CurrentOccupanceOverview extends React.Component<ICurrentOccupancyOverviewProps, ICurrentOccupancyOverviewState> {
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: ICurrentOccupancyOverviewProps) {
        super(props);

        const state: ICurrentOccupancyOverviewState = {
            loading: true,
            venues: [],
            filteredVenues: [],
            customerOptions: []
        };
        this.state = state;

        this.filterVenues = this.filterVenues.bind(this);

        this.coreSpaceService = new CoreSpaceService();
    }

    private filterVenues(event: any): void {
        if (event === null || event.length === 0) {
            this.setState({
                filteredVenues: this.state.venues
            });
        }
        else {
            const customerIds = event.map(c => c.value);
            const filteredVenues = this.state.venues.filter(v => customerIds.indexOf(v.customerId) !== -1);
            this.setState({
                filteredVenues: filteredVenues
            });
        }
    }

    public async componentDidMount(): Promise<void> {
        const venues = await this.coreSpaceService.getVenuesWithAllRelatedData();
        const customers = await this.coreSpaceService.getCustomers();

        const customerOptions = customers.map(c => {
            return {
                label: c.name,
                value: c.id
            };
        });
        this.setState({
            loading: false,
            venues: venues,
            filteredVenues: venues,
            customerOptions: customerOptions
        });
    }

    public render(): JSX.Element {
        return (
            <div id="currentOccupancy">
                <div className="header">
                    <h3><Translate id="pages.home.actual.title" /></h3>
                </div>
                {this.state.loading &&
                    <div id="pulse-loader">
                        <PulseLoader color="#37a0e6" size={8} margin="15px" loading={this.state.loading} />
                    </div>
                }
                {!this.state.loading &&
                    <span>
                        <Select
                            id="occupance-filter-input"
                            onChange={this.filterVenues}
                            options={this.state.customerOptions}
                            placeholder={LanguageProvider.getTranslation("pages.home.selectcustomer")}
                            styles={SelectBoxUtils.getDefaultSelectStyles(40)}
                            isMulti={true}
                            isClearable={true}
                        />
                        <table className="table table-hover table-borderless" id="actual-occupance">
                            <thead>
                                <tr>
                                    <th><Translate id="pages.home.actual.building" /></th>
                                    <th><Translate id="pages.home.actual.status" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.filteredVenues.sort((a, b) => a.name.localeCompare(b.name)).map(venue => <VenueOccupancyStatusLine key={venue.id} venue={venue} />)}
                            </tbody>
                        </table>
                    </span>
                }
            </div>
        );
    }
}

export default withLocalize(CurrentOccupanceOverview);