import React, { useCallback, useEffect, useState } from "react";
import { AutodeskMap, IAutodeskFloorChangeEvent, IFloor, ISelectorEvent, ISpaceOnMap, ModelUtils } from "@beyondeyes/shared";
import MaintenanceUserAuthenticator from "../../authenticators/maintenanceUserAuthenticator";
import { ApplicationConfig } from "../../config";
import CoreSpaceService from "../../services/coreSpaceService";
import CoreSpaceIncludes from "../../enums/coreSpaceIncludes";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import VenueProvider from "../../providers/venueProvider";

export interface IAutodeskMapWrapperProps {
    spaces?: ISpaceOnMap[];
    onPlaceClickAsync?: (context: ISelectorEvent) => Promise<void>;
    onFloorChange?: (floor: IFloor, spaces: ISpaceOnMap[]) => void;
    setLoading?: (value: boolean) => void;
    skipClientSideCache?: boolean;
    floorLevel?: number;
}

export const AutodeskMapWrapper: React.FC<IAutodeskMapWrapperProps> = (props) => {

    const [accessToken, setAccessToken] = useState<string>();
    const [mapFloors, setMapFloors] = useState<IFloor[]>();
    const [venueIdToLoad, setVenueIdToLoad] = useState<string>();
    const [showMap, setShowMap] = useState<boolean>();

    const proxyEndpoint = ApplicationConfig.endpoints.winvisionAutodeskProxy;
    const { onPlaceClickAsync, onFloorChange, skipClientSideCache } = props;

    const onFloorChangedWrapperAsync = useCallback(
        async (event: IAutodeskFloorChangeEvent): Promise<void> => {
            if (!onFloorChange) {
                return;
            }

            const coreSpaceService = new CoreSpaceService();
            const floor = event.floor;

            const floorSpaces = await coreSpaceService.getSpacesForFloor(floor.venueId,
                floor.id,
                undefined,
                [CoreSpaceIncludes.DataTypes, CoreSpaceIncludes.Values, CoreSpaceIncludes.Properties],
                undefined,
                true
            );

            const floorSpacesDictionary = Object.assign({}, ...floorSpaces.map((space) => ({ [space.id]: space })));
            const availableSpacesOnFloor = await ModelUtils.getAllSpacesFromViewModelAsync(event.model, floorSpacesDictionary);
            onFloorChange(event.floor, availableSpacesOnFloor);
        },

        [onFloorChange]
    );

    useEffect(() => {
        const setVenueFloorsAsync = async (venueId: string): Promise<void> => {

            const coreSpaceService = new CoreSpaceService();
            const floors = await coreSpaceService.getFloorsForVenue(venueId, [CoreSpaceIncludes.Properties], skipClientSideCache);
            const allFloors = floors.filter(floor => floor.currentUrn)
                .map(floor => ({
                    id: floor.id,
                    venueId: floor.venueId,
                    urn: floor.currentUrn,
                    level: parseInt(floor.floorLevel)
                }));

            if (!allFloors) {
                setShowMap(false);
                return;
            }

            const authenticator = new MaintenanceUserAuthenticator();
            const accessToken = await authenticator.getTokenAsync();
            setShowMap(allFloors.length > 0);
            setAccessToken(`Bearer ${accessToken}`);
            setVenueIdToLoad(venueId);
            setMapFloors(allFloors);
        };

        const onVenueChange = async (): Promise<void> => {
            const venueId = VenueProvider.getActiveVenue()?.id ?? "";
            await setVenueFloorsAsync(venueId);
        };

        AppEventHub.on(AppEvents.BuildingSelected, onVenueChange);
        onVenueChange();

        return (): void => {
            AppEventHub.off(AppEvents.BuildingSelected, onVenueChange);
        };

    }, [skipClientSideCache]);

    const getMinFloorLevel = (): number => {
        if (!mapFloors || mapFloors.length === 0) {
            return 0;
        }

        return Math.min(...mapFloors.map(m => m.level));
    };

    return (
        <>
            {showMap && <AutodeskMap
                id="autodesk-map"
                proxyEndpoint={proxyEndpoint}
                accessToken={accessToken}
                venueId={venueIdToLoad}
                floors={mapFloors}
                spaces={props.spaces}
                toolbar={{
                    showCameraActionsButton: true,
                    showFullScreenToggleButton: false,
                    showHomeButton: false,
                    showPanButton: false,
                }}
                onPlaceClickAsync={onPlaceClickAsync}
                onFloorChangedAsync={onFloorChangedWrapperAsync}
                currentFloorLevel={props.floorLevel}
                startFloorLevel={getMinFloorLevel()}
                maxNumberOfFloorButtons={7}
            />}
        </>
    );
};