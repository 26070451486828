import React, { Component } from "react";
import { PulseLoader } from 'react-spinners';

import ICenteredPageLoaderProps from './interfaces/ICenteredPageLoaderProps';

export default class CenteredPageLoader extends Component<ICenteredPageLoaderProps> {

    public constructor(props: ICenteredPageLoaderProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <PulseLoader color={'#00172e'} size={8} margin="15px" loading={this.props.loading} />
            </div>
        );
    }
}