import * as React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import './sideBar.scss';

import ISideBarProps from './interfaces/ISideBarProps';
import ISideBarState from './interfaces/ISideBarState';

import BurgerIcon from "../../images/Burger_Icon.svg";
import SideBar_Cross from "../../images/SideBar_Cross.svg";
import MessageIconSelected from "../../images/Admin_messages_blue.svg";
import MessageIcon from "../../images/Admin_messages_grey.svg";
import MapSelectedIcon from "../../images/Map_blue.svg";
import MapIcon from "../../images/Map_grey.svg";
import UploadSelectedIcon from "../../images/Meldingen blauw.svg";
import UploadIcon from "../../images/Meldingen grijs.svg";
import OverviewSelectedIcon from "../../images/Overview_blue.svg";
import OverviewIcon from "../../images/Overview_grey.svg";
import ApplicationIconSelected from "../../images/Applications_blue.svg";
import ApplicationIcon from "../../images/Applications_grey.svg";
import AccountIconSelected from "../../images/Account_Icon_blue.svg";
import AccountIcon from "../../images/Account_Icon_grey.svg";
import RightsIconSelected from "../../images/Contract_unselected.svg";
import RightsIcon from "../../images/Contract_selected.svg";
import DevicesIconSelected from "../../images/Devices_blue.svg";
import DevicesICon from "../../images/Devices_grey.svg";
import AccessService from '../../services/accessService';

class SideBar extends React.Component<ISideBarProps, ISideBarState> {
    private readonly accessService: AccessService;

    public constructor(props: ISideBarProps) {
        super(props);

        this.accessService = new AccessService();

        this.state = {
            hasDocumentsAccess: false,
            hasAdminMessagesAccess: false,
            hasDeviceManagementAccess: false,
            hasUserManagementAccess: false
        };
    }

    public async componentDidMount(): Promise<void> {
        const accessCalls = [
            this.accessService.ensureDocumentsAccess(),
            this.accessService.ensureAdminMessagesAccess(),
            this.accessService.ensureDeviceManagementAccess(),
            this.accessService.ensureUserManagementAccess()
        ];

        const access = await Promise.all(accessCalls);

        this.setState({
            hasDocumentsAccess: access[0],
            hasAdminMessagesAccess: access[1],
            hasDeviceManagementAccess: access[2],
            hasUserManagementAccess: access[3]
        });

        const dropdownButton = document.getElementById("dropdownMenuButtonNavigation");
        if (dropdownButton) {
            dropdownButton.addEventListener('click', () => {
                const dropdownMenu = document.getElementById("sidebar-dropdown");
                if (dropdownMenu != null) {
                    const height = document.body.scrollHeight - 80;
                    dropdownMenu.style.height = height + "px";
                }
            });
        }
    }

    public render(): JSX.Element {
        const path = window.location.pathname.trim();

        const paths = ["/map", "/documentmanagement", "/adminmessagecenter", "/accounts", "/rights", "/devices", "/applications", "/autodesk"];

        return (
            <div className="dropdown">
                <button className="navbar-toggler" type="button" id="dropdownMenuButtonNavigation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                </button>
                <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton" id="sidebar-dropdown">
                    <li className="nav-item active navigation-dropdown-list">
                        <Link to="/">
                            <img src={paths.filter(p => path.indexOf(p) !== -1).length === 0 ? OverviewSelectedIcon : OverviewIcon} alt="Home" /><br />
                            <Translate id="navigation.home" />
                        </Link>
                    </li>
                    {this.state.hasUserManagementAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/autodesk">
                            <img src={path.indexOf("/autodesk") !== -1 ? ApplicationIconSelected : ApplicationIcon} alt="Autodesk" /><br />
                            <Translate id="navigation.autodesk" />
                        </Link>
                    </li>}
                    {this.state.hasDeviceManagementAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/map">
                            <img src={path.indexOf("/map") !== -1 ? MapSelectedIcon : MapIcon} alt="Map" /><br />
                            <Translate id="navigation.map" />
                        </Link>
                    </li>}
                    {this.state.hasDeviceManagementAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/devices/metadata">
                            <img src={path.indexOf("/devices") !== -1 ? DevicesIconSelected : DevicesICon} alt="Devices" /><br />
                            <Translate id="navigation.devices" />
                        </Link>
                    </li>}
                    {this.state.hasDocumentsAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/documentmanagement">
                            <img src={path.indexOf("/documentmanagement") !== -1 ? MessageIconSelected : MessageIcon} alt="Document Management" /><br />
                            <Translate id="navigation.upload" />
                        </Link>
                    </li>}

                    {this.state.hasAdminMessagesAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/adminmessagecenter">
                            <img src={path.indexOf("/adminmessagecenter") !== -1 ? UploadSelectedIcon : UploadIcon} alt="MessageCenter" /><br />
                            <Translate id="navigation.adminmessagecenter" />
                        </Link>
                    </li>}
                    {this.state.hasUserManagementAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/accounts">
                            <img src={path.indexOf("/accounts") !== -1 ? AccountIconSelected : AccountIcon} alt="Accounts" /><br />
                            <Translate id="navigation.accounts" />
                        </Link>
                    </li>}
                    {this.state.hasUserManagementAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/rights/user">
                            <img src={path.indexOf("/rights") !== -1 ? RightsIconSelected : RightsIcon} alt="Rights" /><br />
                            <Translate id="navigation.rights" />
                        </Link>
                    </li>}
                    {this.state.hasUserManagementAccess && <li className="nav-item navigation-dropdown-list">
                        <Link to="/applications">
                            <img src={path.indexOf("/applications") !== -1 ? ApplicationIconSelected : ApplicationIcon} alt="Applications" /><br />
                            <Translate id="navigation.applications" />
                        </Link>
                    </li>}
                </div>
            </div>
        );
    }
}

export default withLocalize(SideBar);