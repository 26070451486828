import ISerializedDevice from "../interfaces/ISerializedDevice";
import ISerializedDeviceType from "../interfaces/ISerializedDeviceType";
import DeviceType from "../models/deviceType";
import Device from "../models/device";
import MaintenanceService from "./maintenanceService";
import HttpResponseCodeError from "../errors/httpResponseCodeError";
import IMetadata from "../interfaces/IMetadata";

export default class DeviceService {
    private readonly maintenanceService: MaintenanceService;

    public constructor() {
        this.maintenanceService = new MaintenanceService();
    }

    public async getDeviceByDeviceId(id: string, skipCacheCheck: boolean = false): Promise<Device | undefined> {
        const requestUrl = `devices/${id}`;
        try {
            return await this.maintenanceService.get<ISerializedDevice>(requestUrl, skipCacheCheck).then(s => new Device(s));
        }
        catch (error) {
            if (error instanceof HttpResponseCodeError && (error as HttpResponseCodeError).StatusCode === 404) {
                return undefined;
            }

            throw error;
        }
    }

    public async getDevicesBySpaceId(id: string, skipCacheCheck: boolean = false, traverseDown: boolean = false): Promise<Device[]> {
        const requestUrl = `devices/space/${id}?traverseDown=${traverseDown}`;
        const result = await this.maintenanceService.get<ISerializedDevice[]>(requestUrl, skipCacheCheck);
        return result.map(d => new Device(d));
    }

    public async getDevicesForCustomerId(id: string, skipCacheCheck: boolean = false): Promise<Device[]> {
        const requestUrl = `devices/customer/${id}`;
        const result = await this.maintenanceService.get<ISerializedDevice[]>(requestUrl, skipCacheCheck);
        return result.map(d => new Device(d));
    }

    public async getDeviceTypes(): Promise<DeviceType[]> {
        const requestUrl = `devices/devicetypes`;
        const result = await this.maintenanceService.get<ISerializedDeviceType[]>(requestUrl);
        return result.map(dt => new DeviceType(dt));
    }

    public async getMetadataForAllDevices(skipCacheCheck: boolean = false): Promise<IMetadata[]> {
        const requestUrl = `devices/metadata`;
        return await this.maintenanceService.get<IMetadata[]>(requestUrl, skipCacheCheck);
    }
}