import React from "react";
import { withLocalize } from "react-localize-redux";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';

import IUserRolesCreateModalProps from "./interfaces/IUserRolesCreateModalProps";
import IUserRolesCreateModalState from "./interfaces/IUserRolesCreateModalState";
import RightsService from "../../../services/rightsService";
import LanguageProvider from "../../../providers/languageProvider";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import roles from "../../../enums/roles";
import mapper from "../../../translations/mapper";
import IUserRolesAssignmentModification from "../../../interfaces/IUserRolesAssignmentModification";
import Yellow_Cross_Cancel from "../../../images/Yellow_Cross_Cancel.svg";
import CenteredPageLoader from "../../../components/loaders/centeredPageLoader";

class UserRolesCreateModal extends React.Component<IUserRolesCreateModalProps, IUserRolesCreateModalState>{
    private readonly rightsService: RightsService;

    private closeButtonRef: React.RefObject<HTMLButtonElement>;

    public constructor(props: IUserRolesCreateModalProps) {
        super(props);

        const rolesSelectionOptions: IReactSelectValue[] = [
            {
                value: roles[roles.FacilityManager],
                label: roles[roles.FacilityManager] + " : " + LanguageProvider.getTranslation(mapper.pages.rights.roledescriptions.facilitymanager),
            },
            {
                value: roles[roles.WorkspacesAdmin.toString()],
                label: roles[roles.WorkspacesAdmin.toString()] + " : " + LanguageProvider.getTranslation(mapper.pages.rights.roledescriptions.workspacesadmin)
            }
        ];

        this.state = {
            loading: false,
            rolesSelectionOptions: rolesSelectionOptions,
            selectedRoles: [],
            userObjectId: "",
            validObjectId: false,
            submitting: false,
            userName: ""
        };

        this.rightsService = new RightsService();

        this.onSave = this.onSave.bind(this);
        this.onRolesSelectChange = this.onRolesSelectChange.bind(this);
        this.onUserObjectIdChange = this.onUserObjectIdChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onUserNameChange = this.onUserNameChange.bind(this);

        this.closeButtonRef = React.createRef();
    }

    public async componentDidMount(): Promise<void> {
    }

    private onRolesSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): void {
        if (optionSelected === null || (optionSelected as IReactSelectValue[]).length === 0) {
            this.setState({
                selectedRoles: []
            });
            return;
        }

        const selectedRoles = (optionSelected as IReactSelectValue[]).map(o => ({ label: o.value, value: o.value }));
        this.setState({
            selectedRoles: selectedRoles
        });
    }

    private onUserObjectIdChange(event: any): void {
        const invalidCharsRegex = /[^0-9a-zA-Z-]/g;
        let userId: string = event.target.value;
        userId = userId.replace(invalidCharsRegex, '');
        userId = userId.toLowerCase();

        this.setState({
            userObjectId: userId,
            validObjectId: this.validateGuid(userId)
        });
    }

    private validateGuid(potentialGuid: string): boolean {
        const validGuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return validGuidRegex.test(potentialGuid);
    }

    private resetForm(): void {
        this.setState({
            selectedRoles: [],
            userObjectId: "",
            userName: "",
            submitting: false,
            validObjectId: false
        });
    }

    private onUserNameChange(event: any): void {
        const invalidUpnCharactersRegex = /[^A-Za-z0-9,.\-_!#^~'@]/g;
        const upnInput: string = event.target.value;
        this.setState({
            userName: upnInput.replace(invalidUpnCharactersRegex, '')
        });
    }

    private async onSave(): Promise<void> {
        if (!this.validateInputForm()) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.invalidform));
            return;
        }

        const allRoles = this.state.rolesSelectionOptions.map<roles>(r => roles[r.value]);
        const selectedRoles = this.state.selectedRoles.map<roles>(r => roles[r.value]);

        const modification: IUserRolesAssignmentModification = {
            description: this.state.userName,
            userObjectId: this.state.userObjectId,
            rolesToAdd: selectedRoles,
            rolesToRemove: allRoles.filter(r => !selectedRoles.includes(r))
        };

        const response = await this.rightsService.ModifyUserRolesAssignment(modification);

        if (response.status !== 204) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.roleassignmenterror));

            this.setState({
                submitting: false
            });
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation(mapper.pages.rights.roleassignmensuccess));

        if (this.closeButtonRef.current) {
            this.closeButtonRef.current.click();
        }

        await this.props.callbackAfterSuccessfullCreate();
        this.resetForm();
    }

    private validateInputForm(): boolean {
        const rolesValid = this.state.selectedRoles.length > 0;
        const userObjectIdValid = this.state.validObjectId;
        const userNameValid = this.state.userName.length > 0;

        return rolesValid && userObjectIdValid && userNameValid;
    }

    public render(): JSX.Element {
        return (

            <div className="modal fade"
                id="user-roles-create-modal"
                role="dialog"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="rights-create-modal user-create-modal modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {LanguageProvider.getTranslation(mapper.pages.rights.usercreate)}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetForm}>
                                <img src={Yellow_Cross_Cancel} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                            {!this.state.loading &&
                                <form>
                                    <div className="form-group">
                                        <span className="access-input-title">{LanguageProvider.getTranslation(mapper.pages.rights.roles)}</span>
                                        <div className="roles-select">
                                            <Select
                                                classNamePrefix={this.state.selectedRoles.length > 0 ? "access-customers-valid" : "access-customers-invalid"}
                                                defaultValue={[]}
                                                options={this.state.rolesSelectionOptions}
                                                onChange={this.onRolesSelectChange}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.selectedRoles}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.userobjectid)}</span>
                                        <input
                                            className={`form-control ${this.state.validObjectId ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.userObjectId}
                                            onChange={this.onUserObjectIdChange}
                                            name="userobjectid"
                                            autoComplete="off"
                                            maxLength={36}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.username)}</span>
                                        <input
                                            className={`form-control ${this.state.userName.length > 0 ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.userName}
                                            onChange={this.onUserNameChange}
                                            name="username"
                                            autoComplete="off"
                                            maxLength={113}
                                        />
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            {!this.state.submitting && <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.onSave}
                            >
                                {LanguageProvider.getTranslation(mapper.pages.rights.save)}
                            </button>}
                            {this.state.submitting && <CenteredPageLoader loading={this.state.submitting} />}
                        </div>
                        <button type="button"
                            className="d-none"
                            data-dismiss="modal"
                            data-target="#user-roles-create-modal"
                            ref={this.closeButtonRef}></button>
                    </div>
                </div>
            </div >
        );
    }
}

export default withLocalize(UserRolesCreateModal);