import React from "react";
import { withLocalize } from "react-localize-redux";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';

import IGroupRolesCreateModalProps from "./interfaces/IGroupRolesCreateModalProps";
import IGroupRolesCreateModalState from "./interfaces/IGroupRolesCreateModalState";
import RightsService from "../../../services/rightsService";
import LanguageProvider from "../../../providers/languageProvider";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import roles from "../../../enums/roles";
import mapper from "../../../translations/mapper";
import IGroupRolesAssignmentModification from "../../../interfaces/IGroupRolesAssignmentModification";
import Yellow_Cross_Cancel from "../../../images/Yellow_Cross_Cancel.svg";
import CenteredPageLoader from "../../../components/loaders/centeredPageLoader";


class GroupRolesCreateModal extends React.Component<IGroupRolesCreateModalProps, IGroupRolesCreateModalState>{
    private readonly rightsService: RightsService;

    private closeButtonRef: React.RefObject<HTMLButtonElement>;

    public constructor(props: IGroupRolesCreateModalProps) {
        super(props);

        const rolesSelectionOptions: IReactSelectValue[] = [
            {
                value: roles[roles.FacilityManager],
                label: roles[roles.FacilityManager] + " : " + LanguageProvider.getTranslation(mapper.pages.rights.roledescriptions.facilitymanager),
            },
            {
                value: roles[roles.WorkspacesAdmin.toString()],
                label: roles[roles.WorkspacesAdmin.toString()] + " : " + LanguageProvider.getTranslation(mapper.pages.rights.roledescriptions.workspacesadmin)
            }
        ];

        this.state = {
            loading: false,
            rolesSelectionOptions: rolesSelectionOptions,
            selectedRoles: [],
            groupObjectId: "",
            validObjectId: false,
            submitting: false,
            domain: "",
            groupName: ""
        };

        this.rightsService = new RightsService();

        this.onSave = this.onSave.bind(this);
        this.onRolesSelectChange = this.onRolesSelectChange.bind(this);
        this.onGroupObjectIdChange = this.onGroupObjectIdChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onGroupNameChange = this.onGroupNameChange.bind(this);
        this.onDomainChange = this.onDomainChange.bind(this);

        this.closeButtonRef = React.createRef();
    }

    public async componentDidMount(): Promise<void> {
    }

    private onRolesSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): void {
        if (optionSelected === null || (optionSelected as IReactSelectValue[]).length === 0) {
            this.setState({
                selectedRoles: []
            });
            return;
        }

        const selectedRoles = (optionSelected as IReactSelectValue[]).map(o => ({ label: o.value, value: o.value }));
        this.setState({
            selectedRoles: selectedRoles
        });
    }

    private onGroupObjectIdChange(event: any): void {
        const invalidCharsRegex = /[^0-9a-zA-Z-]/g;
        let groupId: string = event.target.value;
        groupId = groupId.replace(invalidCharsRegex, '');
        groupId = groupId.toLowerCase();

        this.setState({
            groupObjectId: groupId,
            validObjectId: this.validateGuid(groupId)
        });
    }

    private validateGuid(potentialGuid: string): boolean {
        const validGuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return validGuidRegex.test(potentialGuid);
    }

    private resetForm(): void {
        this.setState({
            selectedRoles: [],
            groupObjectId: "",
            domain: "",
            groupName: "",
            submitting: false,
            validObjectId: false
        });
    }

    private onGroupNameChange(event: any): void {
        const invalidUpnCharactersRegex = /[^A-Za-z0-9,.\-_!#^~'@]/g;
        const upnInput: string = event.target.value;
        this.setState({
            groupName: upnInput.replace(invalidUpnCharactersRegex, '')
        });
    }

    private onDomainChange(event: any): void {
        this.setState({
            domain: event.target.value
        });
    }

    private async onSave(): Promise<void> {
        if (!this.validateInputForm()) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.invalidform));
            return;
        }

        const allRoles = this.state.rolesSelectionOptions.map<roles>(r => roles[r.value]);
        const selectedRoles = this.state.selectedRoles.map<roles>(r => roles[r.value]);

        const modification: IGroupRolesAssignmentModification = {
            description: this.state.groupName,
            groupObjectId: this.state.groupObjectId,
            domain: this.state.domain,
            rolesToAdd: selectedRoles,
            rolesToRemove: allRoles.filter(r => !selectedRoles.includes(r))
        };

        const response = await this.rightsService.ModifyGroupRolesAssignment(modification);

        if (response.status !== 204) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.roleassignmenterror));

            this.setState({
                submitting: false
            });
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation(mapper.pages.rights.roleassignmensuccess));

        if (this.closeButtonRef.current) {
            this.closeButtonRef.current.click();
        }

        await this.props.callbackAfterSuccessfullCreate();
        this.resetForm();
    }

    private validateInputForm(): boolean {
        const rolesValid = this.state.selectedRoles.length > 0;
        const groupObjectIdValid = this.state.validObjectId;
        const groupNameValid = this.state.groupName.length > 0;
        const domainValid = this.state.domain.length > 0;

        return rolesValid && groupObjectIdValid && groupNameValid && domainValid;
    }

    public render(): JSX.Element {
        return (

            <div className="modal fade"
                id="group-roles-create-modal"
                role="dialog"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="rights-create-modal group-create-modal modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {LanguageProvider.getTranslation(mapper.pages.rights.groupcreate)}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetForm}>
                                <img src={Yellow_Cross_Cancel} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                            {!this.state.loading &&
                                <form>
                                    <div className="form-group">
                                        <span className="access-input-title">{LanguageProvider.getTranslation(mapper.pages.rights.roles)}</span>
                                        <div className="roles-select">
                                            <Select
                                                classNamePrefix={this.state.selectedRoles.length > 0 ? "access-customers-valid" : "access-customers-invalid"}
                                                defaultValue={[]}
                                                options={this.state.rolesSelectionOptions}
                                                onChange={this.onRolesSelectChange}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.selectedRoles}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.domain)}</span>
                                        <input
                                            className={`form-control ${this.state.domain.length > 0 ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.domain}
                                            onChange={this.onDomainChange}
                                            name="domain"
                                            autoComplete="off"
                                            maxLength={80}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.groupobjectid)}</span>
                                        <input
                                            className={`form-control ${this.state.validObjectId ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.groupObjectId}
                                            onChange={this.onGroupObjectIdChange}
                                            name="groupobjectid"
                                            autoComplete="off"
                                            maxLength={36}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.groupname)}</span>
                                        <input
                                            className={`form-control ${this.state.groupName.length > 0 ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.groupName}
                                            onChange={this.onGroupNameChange}
                                            name="groupname"
                                            autoComplete="off"
                                            maxLength={113}
                                        />
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            {!this.state.submitting && <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.onSave}
                            >
                                {LanguageProvider.getTranslation(mapper.pages.rights.save)}
                            </button>}
                            {this.state.submitting && <CenteredPageLoader loading={this.state.submitting} />}
                        </div>
                        <button type="button"
                            className="d-none"
                            data-dismiss="modal"
                            data-target="#group-roles-create-modal"
                            ref={this.closeButtonRef}></button>
                    </div>
                </div>
            </div >
        );
    }
}

export default withLocalize(GroupRolesCreateModal);