export class AddedAssetsRequest {

    public constructor(
        public spaceId: string,
        public assetType: string) {
    }
}

export class UpsertAssetsRequest {

    public constructor(
        public customerId: string,
        public venueId: string,
        public floorId: string,
        public addedAssets: AddedAssetsRequest[],
        public deactivatedAssetIds: string[]) {
    }

    public get hasAssetsToUpsert(): boolean {
        return this.addedAssets.length > 0 || this.deactivatedAssetIds.length > 0;
    }
}