import * as React from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import queryString from "query-string";
import { withLocalize } from "react-localize-redux";

import DocumentManagement from './upload/documentManagement';

import IAppProps from './IAppProps';
import IAppState from './IAppState';
import SideBar from '../components/sidebar/sideBar';
import UserSettingsMenu from '../components/header/userSettingsMenu';

import Logo from "../images/logo_be_dark_text.svg";
import './app.scss';

import LanguageProvider from '../providers/languageProvider';
import PageHeaderManager from '../models/pageHeaderManager';
import CoreSpaceService from '../services/coreSpaceService';
import VenueProvider from '../providers/venueProvider';
import DeviceConfigPage from './deviceconfig/deviceConfigPage';
import HomePage from './home/homePage';
import AdminMessageCenter from './adminMessageCenter/adminMessageCenter';
import AddAccountPage from './accounts/addAccountPage';
import AllAccountsPage from './accounts/allAccountsPage';
import UserRightsPage from './rights/userRightsPage';
import GroupRightsPage from './rights/groupRightsPage';
import DomainRightsPage from './rights/domainRightsPage';
import DevicesMetadataPage from './devices/devicesMetadataPage';
import Reports from './map/tabs/rawDataOverview';
import AuthorizedPage from './authorizedPage';
import AccessService from '../services/accessService';
import applicationsPage from './applications/applicationsPage';
import AutodeskUploadPage from './autodeskupload/autodeskUploadPage';
import Map from './map/map';

class App extends React.Component<IAppProps, IAppState> {

    public constructor(props: IAppProps) {
        super(props);
        this.initializeAppPageHeaders();
        LanguageProvider.initialize(props);

        const q = queryString.parse(this.props.location.search);
        const state: IAppState = {
            isIframeModus: q.modus != null && q.modus === "iframe"
        };

        this.state = state;
    }

    public async componentDidMount(): Promise<void> {
        if (!VenueProvider.getActiveVenue()) {
            const coreSpaceService = new CoreSpaceService();
            const venues = await coreSpaceService.getVenuesWithAllRelatedData();
            const venue = venues[0];

            if (venue) {
                VenueProvider.saveActiveVenue(venue);
            }
        }
    }

    public render(): JSX.Element {
        if (this.state.isIframeModus) {
            return this.renderIframeModus();
        }

        return this.renderFullApp();
    }

    private initializeAppPageHeaders(): void {
        PageHeaderManager.add({
            pageName: "map",
            tabs: [
                {
                    id: "map",
                    relativeUrl: "/map",
                    translationLabel: "navigation.map",
                    showQuestionButton: false,
                    showVenueButton: true
                }, {
                    id: "insights",
                    relativeUrl: "/map/insights",
                    translationLabel: "navigation.insightasset",
                    showQuestionButton: true,
                    showVenueButton: false
                }
            ]
        });

        PageHeaderManager.add({
            pageName: "rights",
            tabs: [
                {
                    id: "user",
                    relativeUrl: "/rights/user",
                    translationLabel: "navigation.userrights",
                    showQuestionButton: false,
                    showVenueButton: false
                }, {
                    id: "group",
                    relativeUrl: "/rights/group",
                    translationLabel: "navigation.grouprights",
                    showQuestionButton: false,
                    showVenueButton: false
                }, {
                    id: "domain",
                    relativeUrl: "/rights/domain",
                    translationLabel: "navigation.domainrights",
                    showQuestionButton: false,
                    showVenueButton: false
                }
            ]
        });

        PageHeaderManager.add({
            pageName: "accounts",
            tabs: [
                {
                    id: "accounts",
                    relativeUrl: "/accounts",
                    translationLabel: "navigation.accounts",
                    showQuestionButton: false,
                    showVenueButton: false
                }, {
                    id: "add",
                    relativeUrl: "/accounts/add",
                    translationLabel: "navigation.addaccount",
                    showQuestionButton: false,
                    showVenueButton: false
                }
            ]
        });
    }

    private renderIframeModus(): JSX.Element {
        return (
            <div className="container-fluid">
                <div className="row">
                    <main className="col-sm-10 col-md-11 pt-5 pl-5 pr-5">
                        {routes()}
                    </main>
                </div>
            </div>
        );
    }

    private renderFullApp(): JSX.Element {
        return (
            <span>
                <Route component={SideBar} />
                <nav className="navbar navbar-expand-lg navbar-light" id="user-menu-navbar">
                    <Link to="/" ><img src={Logo} className="logo" alt="Logo" /></Link>
                    <div className="navbar-nav ml-auto">
                        <div className="nav-item nav-link">
                            <UserSettingsMenu />
                        </div>
                    </div>
                </nav>

                <main className="container-fluid">
                    {routes()}
                </main>
            </span>
        );
    }
}

const accessService = new AccessService();
const routes = (): JSX.Element => (
    <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/map" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={Map} authorizeRequest={accessService.ensureDeviceManagementAccess} />
        )} />
        <Route exact path="/map/insights/" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={Reports} authorizeRequest={accessService.ensureDeviceManagementAccess} />
        )} />
        <Route exact path="/map/insights/:filter" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={Reports} authorizeRequest={accessService.ensureDeviceManagementAccess} />
        )} />
        <Route exact path="/devices/metadata" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={DevicesMetadataPage} authorizeRequest={accessService.ensureDeviceManagementAccess} />
        )} />
        <Route exact path="/documentmanagement" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={DocumentManagement} authorizeRequest={accessService.ensureDocumentsAccess} />
        )} />
        <Route exact path="/deviceconfig" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={DeviceConfigPage} authorizeRequest={accessService.ensureDeviceManagementAccess} />
        )} />
        <Route exact path="/adminmessagecenter" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={AdminMessageCenter} authorizeRequest={accessService.ensureAdminMessagesAccess} />
        )} />
        <Route exact path="/accounts" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={AllAccountsPage} authorizeRequest={accessService.ensureUserManagementAccess} />
        )} />
        <Route exact path="/accounts/add" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={AddAccountPage} authorizeRequest={accessService.ensureUserManagementAccess} />
        )} />
        <Route exact path="/rights/user" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={UserRightsPage} authorizeRequest={accessService.ensureUserManagementAccess} />
        )} />
        <Route exact path="/rights/group" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={GroupRightsPage} authorizeRequest={accessService.ensureUserManagementAccess} />
        )} />
        <Route exact path="/rights/domain" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={DomainRightsPage} authorizeRequest={accessService.ensureUserManagementAccess} />
        )} />
        <Route exact path="/applications" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={applicationsPage} authorizeRequest={accessService.ensureUserManagementAccess} />
        )} />
        <Route exact path="/autodesk" render={(props): JSX.Element => (
            <AuthorizedPage {...props} component={AutodeskUploadPage} authorizeRequest={accessService.ensureUserManagementAccess} />
        )} />
        <Redirect exact from="/null" to="/" />
        <Route component={NoMatch} />
    </Switch>
);

const NoMatch = ({ location }): JSX.Element => (
    <div>
        <h3>
            No match for <code>{location.pathname}</code>
        </h3>
    </div>
);

export default withLocalize(App);