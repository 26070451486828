import React from "react";
import { withLocalize } from "react-localize-redux";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';

import Yellow_Cross_Cancel from "../../../images/Yellow_Cross_Cancel.svg";
import LanguageProvider from "../../../providers/languageProvider";
import mapper from "../../../translations/mapper";
import RightsService from "../../../services/rightsService";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import CenteredPageLoader from "../../../components/loaders/centeredPageLoader";
import IPartner from "../../../interfaces/IPartner";
import IDomainPartnersAssignmentModification from "../../../interfaces/IDomainPartnersAssignmentModification";
import IDomainPartnerAccessCreateModalProps from "./interfaces/IDomainPartnerAccessCreateModalProps";
import IDomainPartnerAccessCreateModalState from "./interfaces/IDomainPartnerAccessCreateModalState";

class DomainPartnerAccessCreateModal extends React.Component<IDomainPartnerAccessCreateModalProps, IDomainPartnerAccessCreateModalState>{
    private readonly rightsService: RightsService;

    private closeButtonRef: React.RefObject<HTMLButtonElement>;

    public constructor(props: IDomainPartnerAccessCreateModalProps) {
        super(props);

        this.state = {
            loading: false,
            partnerSelectionOptions: [],
            selectedPartners: [],
            submitting: false,
            domain: ""
        };

        this.rightsService = new RightsService();

        this.setPartnerSelectValues = this.setPartnerSelectValues.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onPartnerSelectChange = this.onPartnerSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onDomainChange = this.onDomainChange.bind(this);

        this.closeButtonRef = React.createRef();
    }

    public async componentDidMount(): Promise<void> {
        this.setPartnerSelectValues();
    }

    private setPartnerSelectValues(): void {
        var selectValues: IReactSelectValue[] = this.props.existingPartners.map(p => ({
            value: p.id,
            label: p.name
        }));
        this.setState({ partnerSelectionOptions: selectValues });
    }

    private onPartnerSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): void {
        if (optionSelected === null || (optionSelected as IReactSelectValue[]).length === 0) {
            this.setState({
                selectedPartners: []
            });
            return;
        }

        const selectedPartners = (optionSelected as IReactSelectValue[]).map(o => ({ label: o.label, value: o.value }));
        this.setState({
            selectedPartners: selectedPartners
        });
    }

    private resetForm(): void {
        this.setState({
            selectedPartners: [],
            domain: "",
            submitting: false,
        });
    }

    private onDomainChange(event: any): void {
        const invalidUpnCharactersRegex = /[^A-Za-z0-9,.\-_!#^~'@]/g;
        const upnInput: string = event.target.value;
        this.setState({
            domain: upnInput.replace(invalidUpnCharactersRegex, '')
        });
    }

    private async onSave(): Promise<void> {
        if (!this.validateInputForm()) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.invalidformpartners));
            return;
        }

        const selectedPartners = this.state.selectedPartners.map<IPartner>(p => ({ id: p.value, name: p.label }));

        const modification: IDomainPartnersAssignmentModification = {
            domain: this.state.domain,
            description: this.state.domain,
            partnersToAdd: selectedPartners,
        };

        const response = await this.rightsService.UpsertDomainPartnersAssignment(modification);

        if (response.status !== 204) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.roleassignmenterror));

            this.setState({
                submitting: false
            });
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation(mapper.pages.rights.roleassignmensuccess));

        if (this.closeButtonRef.current) {
            this.closeButtonRef.current.click();
        }

        await this.props.callbackAfterSuccessfullCreate();
        this.resetForm();
    }

    private validateInputForm(): boolean {
        const partnersValid = this.state.selectedPartners.length > 0;
        const userNameValid = this.state.domain.length > 0;

        return partnersValid && userNameValid;
    }

    public render(): JSX.Element {
        return (

            <div className="modal fade"
                id="domain-partners-create-modal"
                role="dialog"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="partner-create-modal user-create-modal modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {LanguageProvider.getTranslation(mapper.pages.rights.domaincreate)}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetForm}>
                                <img src={Yellow_Cross_Cancel} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                            {!this.state.loading &&
                                <form>
                                    <div className="form-group">
                                        <span className="access-input-title">{LanguageProvider.getTranslation(mapper.pages.rights.partners)}</span>
                                        <div className="roles-select">
                                            <Select
                                                classNamePrefix={this.state.selectedPartners.length > 0 ? "access-customers-valid" : "access-customers-invalid"}
                                                defaultValue={[]}
                                                options={this.state.partnerSelectionOptions}
                                                onChange={this.onPartnerSelectChange}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.selectedPartners}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.domain)}</span>
                                        <input
                                            className={`form-control ${this.state.domain.length > 0 ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.domain}
                                            onChange={this.onDomainChange}
                                            name="username"
                                            autoComplete="off"
                                            maxLength={113}
                                        />
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            {!this.state.submitting && <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.onSave}
                            >
                                {LanguageProvider.getTranslation(mapper.pages.rights.save)}
                            </button>}
                            {this.state.submitting && <CenteredPageLoader loading={this.state.submitting} />}
                        </div>
                        <button type="button"
                            className="d-none"
                            data-dismiss="modal"
                            data-target="#user-roles-create-modal"
                            ref={this.closeButtonRef}></button>
                    </div>
                </div>
            </div >
        );
    }
}

export default withLocalize(DomainPartnerAccessCreateModal);