import { LocalizeContextProps } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";

import translationMapperCoreManagement from "../translations/mapper";
import dutchCoreManagementTranslations from "../translations/nl";

import AppEventHub, { AppEvents } from "../utils/appEventHub";
import Comparer from "../utils/comparer";
import { ApplicationConfig } from "../config";

import * as moment from 'moment';
import 'moment/locale/nl';
import TranslationMapperGenerator from "../utils/translationMapperGenerator";

export enum LanguageCode {
    EN,
    NL
}

export default class LanguageProvider {
    private static activeLanguageFile: any;
    private static availableLanguages: any[];
    private static activeLanguageCodeBackingField: LanguageCode;

    private static isTestOrDevEnvironment : boolean = ApplicationConfig.environment === "test" || ApplicationConfig.environment === "dev";

    public static get activeLanguage(): LanguageCode {
        const languagePreference = localStorage.getItem('BeSense_LanguagePreference');
        if (languagePreference) {
            this.activeLanguageCodeBackingField = LanguageCode[languagePreference];
        } else if (window.navigator.language === "nl-NL") {
            localStorage.setItem('BeSense_LanguagePreference', LanguageCode[1]);
            this.activeLanguageCodeBackingField = 1;
        } else {
            localStorage.setItem('BeSense_LanguagePreference', LanguageCode[this.activeLanguageCodeBackingField]);
        }
        moment.locale(this.activeLanguageCodeBackingField === 1 ? 'nl' : 'en');
        return this.activeLanguageCodeBackingField;
    }

    public static set activeLanguage(value: LanguageCode) {
        localStorage.setItem('BeSense_LanguagePreference', LanguageCode[value]);
        LanguageProvider.activeLanguageCodeBackingField = value;
        LanguageProvider.activeLanguageFile = null;
        AppEventHub.emit(AppEvents.LanguageChanged, value);
    }

    public static initialize(properties: LocalizeContextProps): void {
        LanguageProvider.availableLanguages = LanguageProvider.getAvailableLanguages();

        LanguageProvider.activeLanguageCodeBackingField = LanguageCode.NL;
        properties.initialize({
            languages: LanguageProvider.availableLanguages,
            options: {
                renderToStaticMarkup,
                defaultLanguage: LanguageCode[LanguageCode.NL]
            }
        });

        for (const language of LanguageProvider.availableLanguages) {
            properties.addTranslationForLanguage(language.translations, language.code);
        }
    }

    public static getTranslation(label: string): string {
        label = label.toLocaleLowerCase();
        let languageDetails;
        if (!LanguageProvider.activeLanguageFile) {
            languageDetails = this.availableLanguages.find(i => i.code === LanguageCode[this.activeLanguageCodeBackingField]);
        }

        if (!LanguageProvider.activeLanguageFile) {
            if (!languageDetails) {
                return `Unable to find translation file for code ${LanguageCode[this.activeLanguageCodeBackingField]}`;
            }

            LanguageProvider.activeLanguageFile = languageDetails.translations;
        }

        const translation = label
            .split(".")
            .reduce((p, c) => p && p[c] !== null ? p[c] : `Translation (${LanguageCode[this.activeLanguageCodeBackingField]}) not found for '${label}'`, this.activeLanguageFile);

        return translation;
    }

    public static getTranslations(labels: string[]): string[] {
        const translations = labels.map((l) => {
            return LanguageProvider.getTranslation(l);
        });
        return translations;
    }

    public static validateTranslations(): void {
        const languages = LanguageProvider.getAvailableLanguages();

        const validMapper = Comparer.compareProperties(languages[0], languages[1]);

        if (!validMapper) {
            this.createMapper();
        }
    }

    public static createMapper(): void {
        if (!LanguageProvider.isTestOrDevEnvironment) {
            return;
        }

        const languages = LanguageProvider.getAvailableLanguages();

        console.warn("Mapper is invalid, a new mapper will be generated because you're in dev/ test mode");
        const mapper = TranslationMapperGenerator.GenerateMapper(languages[0].translations);

        const sessionStorageItemName = `bs.${ApplicationConfig.environment}.translationMapper`;
        sessionStorage.setItem(sessionStorageItemName, JSON.stringify(mapper));

        console.log(`Because you are running test/ dev, a new translationMapper has been generated for you in sessionstorage: ${sessionStorageItemName}`);
    }

    private static getAvailableLanguages(): any[] {
        return [
            { name: "Nederlands", code: LanguageCode[LanguageCode.NL], translations: dutchCoreManagementTranslations },
            { name: "Mapper", code: 'mapper', translations: translationMapperCoreManagement }
        ];
    }
}
