import SpatialIntelligenceEntity from "./spatialIntelligenceEntity";
import ISerializedDevice from "../interfaces/ISerializedDevice";
import DownLinkProperty from "./downLinkProperty";

export default class Device extends SpatialIntelligenceEntity {
    public id: string;
    public type: string;
    public spaceId: string;
    public spaceName: string;
    public customerId: string;

    private downLinkProperties: DownLinkProperty[];

    public constructor(json?: ISerializedDevice) {
        super(json);
        this.fromJSON(json);
    }

    public getDownLinkProperties(): DownLinkProperty[] {
        if (this.properties == null) {
            return [];
        }

        if (this.downLinkProperties != null) {
            return this.downLinkProperties;
        }

        this.downLinkProperties = [];

        for (const property of this.properties.filter(p => p.name.startsWith("DownlinkProperty"))) {
            this.downLinkProperties.push(new DownLinkProperty(property.name, property.value));
        }

        return this.downLinkProperties;
    }
}