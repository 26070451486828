import * as React from 'react';
import { PulseLoader } from 'react-spinners';
import { withRouter } from 'react-router-dom';

import Occupied from "../../../images/Occupied.svg";
import Unoccupied from "../../../images/Unoccupied.svg";
import Soon_Unoccupied from "../../../images/Soon_Unoccupied.svg";

import IVenueOccupancyStatusLineState from "./interfaces/IVenueOccupancyStatusLineState";
import IVenueOccupancyStatusLineProps from "./interfaces/IVenueOccupancyStatusLineProps";

import CoreSpaceService from '../../../services/coreSpaceService';
import CoreSpaceValues from '../../../enums/CoreSpaceValues';
import VenueProvider from '../../../providers/venueProvider';
import CoreSpaceIncludes from '../../../enums/coreSpaceIncludes';
import CoreSpaceTypes from '../../../enums/coreSpaceTypes';

class VenueOccupancyStatusLine extends React.Component<IVenueOccupancyStatusLineProps, IVenueOccupancyStatusLineState> {
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: IVenueOccupancyStatusLineProps) {
        super(props);

        this.navigateToVenue = this.navigateToVenue.bind(this);

        this.coreSpaceService = new CoreSpaceService();

        const state: IVenueOccupancyStatusLineState = {
            loading: true,
            coolingDownSpaces: 0,
            freeSpaces: 0,
            occupiedSpaces: 0,
            spaces: []
        };

        this.state = state;
    }

    public async componentDidMount(): Promise<void> {
        const spaces = await this.coreSpaceService.getSpacesForVenue(this.props.venue.id, [CoreSpaceTypes.Workspace, CoreSpaceTypes.Room], [CoreSpaceIncludes.Properties, CoreSpaceIncludes.DataTypes, CoreSpaceIncludes.Values]);
        let occupied = 0;
        let coolingDown = 0;
        let free = 0;

        for (const space of spaces) {
            if(space.hasValue(CoreSpaceValues.OccupancyStatus)){
                if(space.isOccupied){
                    occupied++;
                }
                else if(space.isCoolingDown){
                    coolingDown++;
                }
                else {
                    free++;
                }
            }
            else {
                free++;
            }
        }

        this.setState({
            spaces: spaces,
            freeSpaces: free,
            occupiedSpaces: occupied,
            coolingDownSpaces: coolingDown,
            loading: false
        });
    }

    private navigateToVenue(): void {
        VenueProvider.saveActiveVenue(this.props.venue);
        this.props.history.push('/map');
    }

    public render(): JSX.Element {
        return (
            <tr onClick={this.navigateToVenue} className="clickable">
                <td>
                    <p> {this.props.venue.name}</p>
                </td>
                <td>
                    <PulseLoader color="#37a0e6" size={8} margin="5px" loading={this.state.loading} />
                    {!this.state.loading && <div>
                        <div className="occupancy-status">
                            <img src={Unoccupied} alt="Unoccupied" />
                            <span>{this.state.freeSpaces}</span>
                        </div>
                        <div className="occupancy-status">
                            <img src={Soon_Unoccupied} alt="Soon_Unoccupied" />
                            <span>{this.state.coolingDownSpaces}</span>
                        </div>
                        <div className="occupancy-status">
                            <img src={Occupied} alt="Occupied" />
                            <span>{this.state.occupiedSpaces}</span>
                        </div>
                    </div>}
                </td>
            </tr>
        );
    }
}

export default withRouter(VenueOccupancyStatusLine);