import { UpsertAssetsRequest } from "../models/upsertAssetsRequest";
import { IFeedbackResponse } from "../pages/autodeskupload/components/assets/interfaces/IFeedbackResponse";
import MaintenanceService from "./maintenanceService";

export default class AssetManagementService {
    private readonly maintenanceService: MaintenanceService;

    public constructor() {
        this.maintenanceService = new MaintenanceService();
    }

    public async setSpaceNameAsync(spaceId: string, name: string): Promise<boolean> {
        const requestUrl = `assetmanagement/space/${spaceId}`;

        return this.maintenanceService.patch(requestUrl, { name: name })
            .then(r => r.ok);
    }

    public async upsertAssets(request: UpsertAssetsRequest): Promise<Response> {
        const requestUrl = `assetmanagement/upsert`;

        return this.maintenanceService.post(requestUrl, request);
    }

    public async getFeedback(messageType: string, floorId: string): Promise<IFeedbackResponse> {
        const requestUrl = `assetmanagement/feedback/${messageType}/${floorId}`;

        return this.maintenanceService.get<IFeedbackResponse>(requestUrl, true);
    }
}