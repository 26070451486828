import IAdministratorMessage from "../interfaces/IAdministratorMessage";
import Customer from "./customer";
import AdminMessagePriority from "../enums/adminMessagePriority";

export default class AdministratorMessage implements IAdministratorMessage {
    private customers: Customer[];
    private customerNamesBackingField?: string;

    public constructor(message: IAdministratorMessage, customers: Customer[]) {
        this.startDate = message.startDate;
        this.endDate = message.endDate;
        this.title = message.title;
        this.body = message.body;
        this.isVisibleForAllCustomers = message.isVisibleForAllCustomers;
        this.priority = message.priority;
        this.customerIds = message.customerIds;
        this.rowKey = message.rowKey;
        this.customers = customers;
    }

    public startDate: string;
    public endDate: string;
    public title: string;
    public body: string;
    public isVisibleForAllCustomers: boolean;
    public priority: AdminMessagePriority;
    public customerIds: string;
    public rowKey: string;

    public get customerNames(): string {
        if (this.customerNamesBackingField !== undefined) {
            return this.customerNamesBackingField;
        }

        if (this.customerIds) {
            const customerIds = this.customerIds.split(';');
            const customerNames = customerIds.map(id => {
                const result = this.customers.find(customer => customer.id === id);
                if (result) {
                    return result.name;
                } else {
                    return '';
                }
            }).join(',');
            this.customerNamesBackingField = customerNames;
        } else {
            this.customerNamesBackingField = '';
        }

        return this.customerNamesBackingField;
    }
}