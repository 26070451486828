import * as React from 'react';
import { withLocalize } from 'react-localize-redux';
import Select from 'react-select';

import IVenueSelectionDropDownProps from "./interfaces/IVenueSelectionDropDownProps";
import IVenueSelectionDropDownState from "./interfaces/IVenueSelectionDropDownState";

import CoreSpaceService from '../../services/coreSpaceService';
import VenueProvider from '../../providers/venueProvider';
import CoreSpace from '../../models/coreSpace';
import Sorter from '../../utils/sorter';
import SelectBoxUtils from '../../utils/selectBoxUtils';
import IReactSelectValue from '../../interfaces/IReactSelectValue';
import Dictionary from '../../utils/dictionary';
import LanguageProvider from '../../providers/languageProvider';
import CustomerProvider from '../../providers/customerProvider';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';

class VenueSelectionDropDown extends React.Component<IVenueSelectionDropDownProps, IVenueSelectionDropDownState> {
    private coreSpaceService: CoreSpaceService;

    private venues: Dictionary<CoreSpace>;

    public constructor(props: IVenueSelectionDropDownProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        this.setActiveVenue = this.setActiveVenue.bind(this);

        this.venues = new Dictionary<CoreSpace>();

        const venue = VenueProvider.getActiveVenue();
        const state: IVenueSelectionDropDownState = {
            selectedVenue: venue ? { label: venue.name, value: venue.id } : null,
            venueSelectionOptions: []
        };

        this.state = state;

        this.setVenueSelectionOptionsAsync = this.setVenueSelectionOptionsAsync.bind(this);
        AppEventHub.on(AppEvents.CustomerSpaceSelected, this.setVenueSelectionOptionsAsync);
    }

    public async componentDidMount(): Promise<void> {
        await this.setVenueSelectionOptionsAsync();
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.CustomerSpaceSelected, this.setVenueSelectionOptionsAsync);
    }

    public async setVenueSelectionOptionsAsync(): Promise<void> {
        let venues = await this.coreSpaceService.getVenuesWithAllRelatedData();

        venues = Sorter.sortByProperty(venues, "name");
        venues.forEach(v => this.venues.add(v.id, v));
        const customer = CustomerProvider.getActiveCustomer();

        const venueSelectionOptions = venues
            .filter(v => !this.props.filterByActiveCustomer || !customer || v.customerId === customer.id)
            .map(v => ({
                value: v.id,
                label: v.name
            }));

        if (!this.props.disableSessionStorage) {
            this.setState({
                venueSelectionOptions: venueSelectionOptions,
            });
        }
        else {
            this.setState({
                venueSelectionOptions: venueSelectionOptions,
                selectedVenue: null
            }, () => this.props.onChange && this.props.onChange(null));
        }
    }

    private setActiveVenue(selectedItem: IReactSelectValue | readonly IReactSelectValue[] | null | undefined): void {
        this.props.onChange && this.props.onChange(selectedItem);

        this.setState({
            selectedVenue: (selectedItem as IReactSelectValue | null)
        });

        if (selectedItem === null || selectedItem === undefined) {
            return;
        }

        const venue = this.venues.item((selectedItem as IReactSelectValue).value);
        if (!this.props.disableSessionStorage) {
            VenueProvider.saveActiveVenue(venue);
        }
    }

    public render(): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation("buttons.dropdowns.nooptions");

        return (
            <Select
                value={this.state.selectedVenue}
                onChange={this.setActiveVenue}
                options={this.state.venueSelectionOptions}
                isClearable={this.props.isClearable}
                placeholder={`${LanguageProvider.getTranslation("buttons.dropdowns.venue")}...`}
                noOptionsMessage={noOptionsMessage}
                styles={SelectBoxUtils.getVenueSelectStyles(40)}
            />
        );
    }
}

export default withLocalize(VenueSelectionDropDown);