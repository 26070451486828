import MaintenanceService from "./maintenanceService";
import IUserCustomerAccessAssignment from '../interfaces/IUserCustomerAccessAssignment';
import IGroupCustomerAccessAssignment from '../interfaces/IGroupCustomerAccessAssignment';
import IDomainCustomerAccessAssignment from '../interfaces/IDomainCustomerAccessAssignment';
import IUserCustomerAccessUpsert from "../interfaces/IUserCustomerAccessUpsert";
import IGroupCustomerAccessUpsert from "../interfaces/IGroupCustomerAccessUpsert";
import IDomainCustomerAccessUpsert from "../interfaces/IDomainCustomerAccessUpsert";
import IUserRolesAssignment from "../interfaces/IUserRolesAssignment";
import IGroupRolesAssignment from "../interfaces/IGroupRolesAssignment";
import IDomainRolesAssignment from "../interfaces/IDomainRolesAssignment";
import IUserRolesAssignmentModification from "../interfaces/IUserRolesAssignmentModification";
import IGroupRolesAssignmentModification from "../interfaces/IGroupRolesAssignmentModification";
import IDomainRolesAssignmentModification from "../interfaces/IDomainRolesAssignmentModification";
import IUserPartnerAccess from "../interfaces/IUserPartnerAccess";
import IDomainPartnerAccess from "../interfaces/IDomainPartnerAccess";
import IPartner from "../interfaces/IPartner";
import IUserPartnersAssignmentModification from "../interfaces/IUserPartnersAssignmentModification";
import IDomainPartnersAssignmentModification from "../interfaces/IDomainPartnersAssignmentModification";

export default class RightsService {
    private maintenanceService: MaintenanceService;

    public constructor() {
        this.maintenanceService = new MaintenanceService();
    }

    public async GetUserCustomerAccessAssignments(): Promise<IUserCustomerAccessAssignment[]> {
        const requestUrl = 'rights/users';
        var result = await this.maintenanceService.get<IUserCustomerAccessAssignment[]>(requestUrl, true);

        return result;
    }

    public async GetGroupCustomerAccessAssignments(): Promise<IGroupCustomerAccessAssignment[]> {
        const requestUrl = 'rights/groups';
        var result = await this.maintenanceService.get<IGroupCustomerAccessAssignment[]>(requestUrl, true);

        return result;
    }

    public async GetDomainCustomerAccessAssignments(): Promise<IDomainCustomerAccessAssignment[]> {
        const requestUrl = 'rights/domains';
        var result = await this.maintenanceService.get<IDomainCustomerAccessAssignment[]>(requestUrl, true);

        return result;
    }

    public async UpsertUserCustomerAccessAssignment(assignment: IUserCustomerAccessUpsert): Promise<Response> {
        const requestUrl = 'rights/users';
        var result = await this.maintenanceService.post(requestUrl, assignment);

        return result;
    }

    public async UpsertGroupCustomerAccessAssignment(assignment: IGroupCustomerAccessUpsert): Promise<Response> {
        const requestUrl = 'rights/groups';
        var result = await this.maintenanceService.post(requestUrl, assignment);

        return result;
    }

    public async UpsertDomainCustomerAccessAssignment(assignment: IDomainCustomerAccessUpsert): Promise<Response> {
        const requestUrl = 'rights/domains';
        var result = await this.maintenanceService.post(requestUrl, assignment);

        return result;
    }

    public async DeleteUserAllCustomersAccess(userObjectId: string): Promise<Response> {
        const requestUrl = `rights/users/${userObjectId}/all`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async DeleteUserSpecificCustomerAccess(userObjectId: string, customerId: string): Promise<Response> {
        const requestUrl = `rights/users/${userObjectId}/${customerId}`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async DeleteGroupAllCustomersAccess(domain: string, groupObjectId: string): Promise<Response> {
        const requestUrl = `rights/groups/${domain}/${groupObjectId}/all`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async DeleteGroupSpecificCustomerAccess(domain: string, groupObjectId: string, customerId: string): Promise<Response> {
        const requestUrl = `rights/groups/${domain}/${groupObjectId}/${customerId}`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async DeleteDomainAllCustomersAccess(domain: string): Promise<Response> {
        const requestUrl = `rights/domains/${domain}/all`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async DeleteDomainSpecificCustomerAccess(domain: string, customerId: string): Promise<Response> {
        const requestUrl = `rights/domains/${domain}/${customerId}`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async GetUserRolesAssignments(): Promise<IUserRolesAssignment[]> {
        const requestUrl = 'rights/users/roles';
        var result = await this.maintenanceService.get<IUserRolesAssignment[]>(requestUrl, true);

        return result;
    }

    public async GetGroupRolesAssignments(): Promise<IGroupRolesAssignment[]> {
        const requestUrl = 'rights/groups/roles';
        var result = await this.maintenanceService.get<IGroupRolesAssignment[]>(requestUrl, true);

        return result;
    }

    public async GetDomainRolesAssignments(): Promise<IDomainRolesAssignment[]> {
        const requestUrl = 'rights/domains/roles';
        var result = await this.maintenanceService.get<IDomainRolesAssignment[]>(requestUrl, true);

        return result;
    }

    public async GetPartners(): Promise<IPartner[]> {
        const requestUrl = 'rights/partners';
        var result = await this.maintenanceService.get<IPartner[]>(requestUrl, true);

        return result;
    }

    public async GetUserPartnerAccess(): Promise<IUserPartnerAccess[]> {
        const requestUrl = 'rights/users/partners';
        var result = await this.maintenanceService.get<IUserPartnerAccess[]>(requestUrl, true);

        return result;
    }

    public async GetDomainPartnerAccess(): Promise<IDomainPartnerAccess[]> {
        const requestUrl = 'rights/domains/partners';
        var result = await this.maintenanceService.get<IDomainPartnerAccess[]>(requestUrl, true);

        return result;
    }

    public async DeleteUserPartnerAccess(userObjectId: string, partnerId: string): Promise<Response> {
        const requestUrl = `rights/users/${userObjectId}/partner/${partnerId}`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async DeleteDomainPartnerAccess(domain: string, partnerId: string): Promise<Response> {
        const requestUrl = `rights/domains/${domain}/partner/${partnerId}`;
        var result = await this.maintenanceService.delete(requestUrl);

        return result;
    }

    public async ModifyUserRolesAssignment(modification: IUserRolesAssignmentModification): Promise<Response> {
        const requestUrl = 'rights/users/roles';
        var result = await this.maintenanceService.patch(requestUrl, modification);

        return result;
    }

    public async ModifyGroupRolesAssignment(modification: IGroupRolesAssignmentModification): Promise<Response> {
        const requestUrl = 'rights/groups/roles';
        var result = await this.maintenanceService.patch(requestUrl, modification);

        return result;
    }

    public async ModifyDomainRolesAssignment(modification: IDomainRolesAssignmentModification): Promise<Response> {
        const requestUrl = 'rights/domains/roles';
        var result = await this.maintenanceService.patch(requestUrl, modification);

        return result;
    }

    public async UpsertUserPartnersAssignment(modification: IUserPartnersAssignmentModification): Promise<Response> {
        const requestUrl = 'rights/users/partners';
        var result = await this.maintenanceService.put(requestUrl, modification);

        return result;
    }

    public async UpsertDomainPartnersAssignment(modification: IDomainPartnersAssignmentModification): Promise<Response> {
        const requestUrl = 'rights/domains/partners';
        var result = await this.maintenanceService.put(requestUrl, modification);

        return result;
    }
}