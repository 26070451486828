import * as React from 'react';
import { NotificationManager } from 'react-notifications';

import { withLocalize } from 'react-localize-redux';
import CoreSpaceService from '../../services/coreSpaceService';
import PageHeader from "../../components/header/pageHeader";

import IDomainRightsPageProps from "./interfaces/IDomainRightsPageProps";
import IDomainRightsPageState from "./interfaces/IDomainRightsPageState";

import AddGreen from "../../images/Add_Green.svg";
import DeleteBlue from "../../images/Delete_Blue.svg";
import DeleteGrey from "../../images/Delete_Grey.svg";
import EditBlue from "../../images/Edit_Blue.svg";
import EditGrey from "../../images/Edit_Grey.svg";

import "./rightsPage.scss";
import RightsService from '../../services/rightsService';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import ReactTable, { Column } from 'react-table';
import LanguageProvider from '../../providers/languageProvider';
import translations from '../../translations/mapper';
import IDomainCustomerAccessAssignment from '../../interfaces/IDomainCustomerAccessAssignment';
import FullPageLoader from '../../components/loaders/fullPageLoader';
import CustomPagination from '../../components/pagination/customPagination';
import { ReactTableUtils } from '../../utils/reactTableUtils';
import { CustomerNameElementStyler } from './utils/customerNameElementStyler';
import DomainAccessCreateModal from "./components/domainAccessCreateModal";
import DomainRolesCreateModal from "./components/domainRolesCreateModal";
import DomainRolesEditModal from "./components/domainRolesEditModal";
import DomainPartnerAccessCreateModal from "./components/domainPartnerAccessCreateModal";
import TimeZoneUtils from '../../utils/timeZoneUtils';
import IDomainRolesAssignment from '../../interfaces/IDomainRolesAssignment';
import roles from '../../enums/roles';
import IDomainRolesAssignmentModification from '../../interfaces/IDomainRolesAssignmentModification';
import IDomainPartnerAccess from '../../interfaces/IDomainPartnerAccess';

class DomainRightsPage extends React.Component<IDomainRightsPageProps, IDomainRightsPageState>{
    private readonly rightsService: RightsService;
    private readonly spaceService: CoreSpaceService;

    public constructor(props: IDomainRightsPageProps) {
        super(props);

        this.rightsService = new RightsService();
        this.spaceService = new CoreSpaceService();

        this.updateColumns = this.updateColumns.bind(this);
        this.updateDomainAccess = this.updateDomainAccess.bind(this);
        this.updateDomainRoles = this.updateDomainRoles.bind(this);
        this.updateDomainPartnerAccess = this.updateDomainPartnerAccess.bind(this);
        this.renderExtraHeaderSection = this.renderExtraHeaderSection.bind(this);
        this.setActiveRow = this.setActiveRow.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.setCategoryPartnersActive = this.setCategoryPartnersActive.bind(this);
        this.setCategoryRolesActive = this.setCategoryRolesActive.bind(this);
        this.setCategoryCustomerAccessActive = this.setCategoryCustomerAccessActive.bind(this);

        this.state = {
            domainCustomerAccessAssignments: [],
            loading: true,
            columns: this.getColumns(true),
            customers: [],
            partners: [],
            selectedRows: [],
            activeCategory: 'customerAccess',
            domainRolesAssignments: [],
            domainPartnerAccess: []
        };

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
    }

    public async componentDidMount(): Promise<void> {
        const existingDomainAccessTask = this.rightsService.GetDomainCustomerAccessAssignments();
        const existingDomainRolesTask = this.rightsService.GetDomainRolesAssignments();
        const customersTask = this.spaceService.getCustomers();
        const existingDomainPartnerTask = this.rightsService.GetDomainPartnerAccess();
        const partnerTask = this.rightsService.GetPartners();

        const [existingDomainAccess, existingDomainRoles, customers, domainPartnerAccess, partners] = await Promise.all([existingDomainAccessTask, existingDomainRolesTask, customersTask, existingDomainPartnerTask, partnerTask]);

        this.setState({
            domainCustomerAccessAssignments: existingDomainAccess,
            domainRolesAssignments: existingDomainRoles,
            customers: customers,
            partners: partners,
            domainPartnerAccess: domainPartnerAccess,
            loading: false
        });
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
    }

    private updateColumns(): void {
        const columns = this.getColumns();
        this.setState({
            columns: columns
        });
    }

    private getColumns(overrideCategoryAsCustomerAccess: Boolean = false): Column[] {
        if (overrideCategoryAsCustomerAccess || this.state.activeCategory === 'customerAccess') {
            const columns = [
                {
                    id: 'domain',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.domain),
                    accessor: "domain",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'customerName',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.customername),
                    accessor: (row: any): string => CustomerNameElementStyler.getCustomerNameForAccessAssignment(row, this.state.customers),
                    Cell: (row: any): JSX.Element => CustomerNameElementStyler.getCustomerNameStyling(row, this.state.customers),
                    filterable: true
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (message: IDomainCustomerAccessAssignment): string =>
                        message.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(message.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }];

            return columns;
        }
        else if (this.state.activeCategory === 'roles') {
            const columns = [
                {
                    id: 'domain',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.domain),
                    accessor: "domain",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'roles',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.roles),
                    accessor: (message: IDomainRolesAssignment): string => message.roles?.join(' '),
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            {
                                row.original.roles !== undefined ? (row.original.roles as roles[]).map((r, i) =>
                                    <div className={`role-row ${i > 0 ? 'later-role' : ''}`} key={i}>
                                        {LanguageProvider.getTranslation(translations.pages.rights.rolenames[r.toString().toLowerCase()])}
                                    </div>) :
                                    <div />}
                        </div>
                    )
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (message: IDomainCustomerAccessAssignment): string =>
                        message.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(message.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }];

            return columns;
        } else {
            const columns = [
                {
                    id: 'domain',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.domain),
                    accessor: "domain",
                    filterable: true,
                    Cell: (row: any): JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={this.state.selectedRows.includes(row.index)} data-row-index={`${row.index}`} onChange={(): void => { }} />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                },
                {
                    id: 'partner',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.partner),
                    accessor: 'partnerDescription',
                    filterable: true,
                },
                {
                    id: 'lastModified',
                    Header: LanguageProvider.getTranslation(translations.pages.rights.columns.lastmodified),
                    accessor: (domainPartnerAccess: IDomainPartnerAccess): string =>
                        domainPartnerAccess.timestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(domainPartnerAccess.timestamp).format('YYYY-MM-DD, HH:mm') : "",
                    filterable: true
                }
            ];

            return columns;
        }
    }

    private async setActiveRow(e: any): Promise<void> {
        e.stopPropagation();
        e.preventDefault();
        const rowIndexNumber = parseInt(e.target.dataset.rowIndex);
        if (this.state.selectedRows.includes(rowIndexNumber)) {
            await this.setState({
                selectedRows: this.state.selectedRows.filter(r => r !== rowIndexNumber),
            });
        }
        else {
            const newRows = this.state.selectedRows;
            newRows.push(rowIndexNumber);
            await this.setState({
                selectedRows: newRows,
            });
        }
    }

    private async updateDomainAccess(): Promise<void> {
        const existingDomainAccess = await this.rightsService.GetDomainCustomerAccessAssignments();
        this.setState({
            domainCustomerAccessAssignments: existingDomainAccess,
            loading: false
        });
    }

    private async updateDomainRoles(): Promise<void> {
        const existingDomainRoles = await this.rightsService.GetDomainRolesAssignments();
        this.setState({
            domainRolesAssignments: existingDomainRoles,
            selectedRows: [],
            loading: false
        });
    }

    private async updateDomainPartnerAccess(): Promise<void> {
        const userPartnerAccess = await this.rightsService.GetDomainPartnerAccess();
        this.setState({
            domainPartnerAccess: userPartnerAccess,
            selectedRows: [],
            loading: false
        });
    }

    private async handleDeleteClick(): Promise<void> {
        if (this.state.selectedRows.length === 0) {
            return;
        }

        if (!window.confirm(LanguageProvider.getTranslation(translations.pages.rights.deleteconfirm))) {
            return;
        }

        const responses: Response[] = [];
        for (const rowIndex of this.state.selectedRows) {
            const selectedRecord = this.state.activeCategory === 'customerAccess' ?
                this.state.domainCustomerAccessAssignments[rowIndex] :
                this.state.activeCategory === 'roles' ? this.state.domainRolesAssignments[rowIndex] : this.state.domainPartnerAccess[rowIndex];

            if (!selectedRecord) {
                console.error(`Could not find customer record at index '${rowIndex}', this should never happen!`);

                continue;
            }

            let response: Response | undefined = undefined;
            if (this.state.activeCategory === 'customerAccess') {
                var customerAccessSelectedRecord = selectedRecord as IDomainCustomerAccessAssignment;
                if (customerAccessSelectedRecord.allCustomers) {
                    response = await this.rightsService.DeleteDomainAllCustomersAccess(customerAccessSelectedRecord.domain);
                }
                else {
                    if (!customerAccessSelectedRecord.customerId) {
                        console.error(`Customer record at '${rowIndex}' has no customer id and is not all customers, this should never happen!`);

                        continue;
                    }
                    response = await this.rightsService.DeleteDomainSpecificCustomerAccess(customerAccessSelectedRecord.domain, customerAccessSelectedRecord.customerId);
                }

                responses.push(response);
            }
            else if (this.state.activeCategory === 'roles') {
                const modification: IDomainRolesAssignmentModification = {
                    description: selectedRecord.description,
                    domain: selectedRecord.domain,
                    rolesToAdd: [],
                    rolesToRemove: [roles.FacilityManager, roles.CleaningAdmin]
                };

                response = await this.rightsService.ModifyDomainRolesAssignment(modification);
            } else {
                const domainPartnerSelectedRecord = selectedRecord as IDomainPartnerAccess;
                response = await this.rightsService.DeleteDomainPartnerAccess(domainPartnerSelectedRecord.domain, domainPartnerSelectedRecord.partnerId);
            }
        }

        if (responses.find(r => r.status !== 204)) {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.rights.deleteerror));
        }
        else {
            NotificationManager.success(LanguageProvider.getTranslation(translations.pages.rights.deletesuccess));
        }

        this.setState({
            selectedRows: []
        }, () => this.state.activeCategory === 'customerAccess' ? this.updateDomainAccess() :
            this.state.activeCategory === 'roles' ? this.updateDomainRoles() : this.updateDomainPartnerAccess());
    }

    private setCategoryRolesActive(): void {
        this.setState({
            activeCategory: 'roles',
            selectedRows: []
        }, this.updateColumns);
    }

    private setCategoryCustomerAccessActive(): void {
        this.setState({
            activeCategory: 'customerAccess',
            selectedRows: []
        }, this.updateColumns);
    }

    private setCategoryPartnersActive(): void {
        this.setState({
            activeCategory: 'partners',
            selectedRows: []
        }, this.updateColumns);
    }

    private getData(): any[] {
        const category = this.state.activeCategory;
        if (category === 'customerAccess') {
            return this.state.domainCustomerAccessAssignments;
        }
        if (category === 'roles') {
            return this.state.domainRolesAssignments;
        }
        return this.state.domainPartnerAccess;
    }

    private renderExtraHeaderSection(): JSX.Element {
        const activeCategory = this.state.activeCategory.toLowerCase();
        const editAvailable = this.state.selectedRows.length === 1 && this.state.activeCategory === 'roles';

        return (
            <div>
                {
                    !this.state.loading &&
                    <>
                        <img src={AddGreen} id="add-icon" alt="Add" className="clickable ml-2" data-toggle="modal" data-target={`#domain-${activeCategory}-create-modal`} />
                        {
                            this.state.activeCategory === 'roles' && <>
                                <img src={EditBlue} id="edit-icon-active" alt="Edit" className={`clickable ml-2 ${editAvailable ? "" : "d-none"}`} data-toggle="modal" data-target={`#domain-${activeCategory}-edit-modal`} />
                                <img src={EditGrey} id="edit-icon-inactive" alt="Edit" className={`ml-2 ${!editAvailable ? "" : "d-none"}`} />
                            </>
                        }
                        <img src={DeleteBlue} id="delete-icon-active" alt="Delete" className={`clickable ml-2 ${this.state.selectedRows.length > 0 ? "" : "d-none"}`} onClick={this.handleDeleteClick} />
                        <img src={DeleteGrey} id="delete-icon-inactive" alt="Delete" className={`ml-2 ${this.state.selectedRows.length === 0 ? "" : "d-none"}`} />

                        <div className="category-selection-container">
                            <div className={`category ${this.state.activeCategory === 'customerAccess' ? 'active' : 'inactive'}`} onClick={this.state.activeCategory !== 'customerAccess' ? this.setCategoryRolesActive : undefined}>
                                {LanguageProvider.getTranslation(translations.pages.rights.customeraccess)}
                            </div>
                            <div className={`category ${this.state.activeCategory === 'roles' ? 'active' : 'inactive'}`} onClick={this.state.activeCategory !== 'roles' ? this.setCategoryRolesActive : undefined}>
                                {LanguageProvider.getTranslation(translations.pages.rights.roles)}
                            </div>
                            <div className={`category ${this.state.activeCategory === 'partners' ? 'active' : 'inactive'}`} onClick={this.state.activeCategory !== 'partners' ? this.setCategoryPartnersActive : undefined}>
                                {LanguageProvider.getTranslation(translations.pages.rights.partners)}
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }

    public render(): JSX.Element {
        const selectedRolesAssignment = this.state.selectedRows.length === 1 ? this.state.domainRolesAssignments[this.state.selectedRows[0]] : undefined;

        return (
            <div className="rights">
                <PageHeader pageName="rights" extraHeaderSection={this.renderExtraHeaderSection()} />
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}
                {!this.state.loading &&
                    <div className="assignment-table">
                        <ReactTable
                            className="rights-table"
                            columns={this.state.columns}
                            loading={this.state.loading}
                            showPaginationBottom={true}
                            showPaginationTop={false}
                            defaultPageSize={20}
                            data={this.getData()}
                            PaginationComponent={CustomPagination}
                            minRows={0}
                            defaultSorted={[{
                                id: 'domain',
                                desc: false
                            }]}
                            defaultFilterMethod={ReactTableUtils.useContainsFilter}
                        />
                        <DomainAccessCreateModal callbackAfterSuccessfullCreate={this.updateDomainAccess} />
                        <DomainRolesCreateModal callbackAfterSuccessfullCreate={this.updateDomainRoles} />
                        <DomainRolesEditModal callbackAfterSuccessfullEdit={this.updateDomainRoles}
                            domain={selectedRolesAssignment?.domain ?? ""}
                            existingRoles={selectedRolesAssignment?.roles ?? []} />
                        <DomainPartnerAccessCreateModal existingPartners={this.state.partners}
                            callbackAfterSuccessfullCreate={this.updateDomainPartnerAccess}/>
                    </div>}
            </div>
        );
    }
}

export default withLocalize(DomainRightsPage);