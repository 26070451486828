import * as React from 'react';
import IDeviceConfigSettingsPaneProps from './interfaces/IDeviceConfigSettingsPaneProps';
import IDeviceConfigSettingsPaneState from './interfaces/IDeviceConfigSettingsPaneState';
import DropDownMenu from './dropDownMenu';

import Dictionary from "../../../utils/dictionary";
import DeviceConfigurationExtractor from '../utils/deviceConfigurationExtractor';

export default class DeviceConfigSettingsPane extends React.Component<IDeviceConfigSettingsPaneProps, IDeviceConfigSettingsPaneState> {

    public constructor(props: IDeviceConfigSettingsPaneProps) {
        super(props);
        this.onChangeDownlinkProperty = this.onChangeDownlinkProperty.bind(this);
        this.applyConfig = this.applyConfig.bind(this);
        this.onDeviceTypeClick = this.onDeviceTypeClick.bind(this);

        const state: IDeviceConfigSettingsPaneState = {
            newValues: new Dictionary<number>(),
            selectedDeviceType: null
        };

        this.state = state;
    }

    public render(): JSX.Element {
        const devices = this.props.DeviceConfigurations.getValues();
        const configuration = DeviceConfigurationExtractor.extractConfiguration(devices);

        return (
            <div>
                {configuration.map(k =>
                    <span key={k.name}>
                        <label>{k.name}</label><br />
                        <input type="number" id={k.name} value={this.state.newValues.item(k.name)} onChange={this.onChangeDownlinkProperty} />
                        <br />
                    </span>)}

                {configuration.length > 0 &&
                    <span>
                        <br />
                        <DropDownMenu items={this.props.availableDeviceTypes} onClickHandler={this.onDeviceTypeClick} initialDisplayName={"Update device type"} displayPropertyName={"displayValue"} valuePropertyName={"displayValue"} isRequired={false} />
                        <br />
                        <br />
                        <br />
                        <button type="button" className="btn btn-success" onClick={this.applyConfig}>Toepassen</button>
                    </span>
                }
            </div>
        );
    }

    private onChangeDownlinkProperty(e: React.ChangeEvent<HTMLInputElement>): void {
        const newValues = this.state.newValues;
        newValues.add(e.target.id, parseInt(e.target.value, 10));
        this.setState({ newValues });
    }

    private onDeviceTypeClick(deviceType: string): void {
        this.setState({ selectedDeviceType: deviceType });
    }

    private applyConfig(): void {
        if (window.confirm("Weet u zeker dat u de nieuwe configuratie wilt doorvoeren op de geselecteerde devices?\n\nLet op: Het kan tot enkele uren duren voordat het device de nieuwe configuratie heeft verwerkt.")) {
            this.props.onClickHandler(this.state.newValues, this.state.selectedDeviceType);
        }
    }
}