import IProperty from "../interfaces/IProperty";
import Serializable from "./serializeable";
import CoreSpaceValues from "../enums/CoreSpaceValues";
import IValue from "../interfaces/IValue";

export default class SpatialIntelligenceEntity extends Serializable {
    protected properties: IProperty[];
    protected values: IValue[];

    private propertyMapping: { [name: string]: string };
    private valueMapping: { [type: string]: IValue };

    public constructor(json?: any) {
        super();
        this.fromJSON(json);

        this.propertyMapping = {};
        this.valueMapping = {};

        if (this.properties) {
            for (const property of this.properties) {
                this.propertyMapping[property.name] = property.value;
            }
        }

        if (this.values) {
            for (const value of this.values) {
                this.valueMapping[value.type] = value;
            }
        }
    }

    protected getCustomPropertyValue(name: string): string {
        return this.propertyMapping[name];
    }

    protected getValue(type: CoreSpaceValues): IValue {
        return this.valueMapping[CoreSpaceValues[type]];
    }
}