export default class DownLinkProperty {
    private internalNameBackingField: string;
    private valueBackingField: string;

    public get id(): string {
        return this.internalName;
    }

    public get name(): string {
        return this.internalNameBackingField.replace("DownlinkProperty", "");
    }

    public get internalName(): string {
        return this.internalNameBackingField;
    }

    public get value(): string {
        return this.valueBackingField;
    }

    public constructor(name: string, value: string) {
        this.internalNameBackingField = name;
        this.valueBackingField = value;
    }
}