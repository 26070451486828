enum UserRoles {
    FacilityManager = "FacilityManager",
    TeamMember = "TeamMember",
    FunctionalAdmin = "FunctionalAdmin",
    Analyst = "Analyst",
    CustomerManager = "CustomerManager",
    CleaningAdmin = "CleaningAdmin",
    Cleaner = "Cleaner",
    CleaningCustomerEmployee = "CleaningCustomerEmployee",
    WorkspacesAdmin = "WorkspacesAdmin"
}

export default UserRoles;