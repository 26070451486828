import AppEventHub, { AppEvents } from "../utils/appEventHub";
import { ApplicationConfig } from "../config";
import Customer from "../models/customer";

export default class CustomerProvider {
    private static customerSpaceStorageKey: string = `bs.${ApplicationConfig.environment}.active.customer.space`;

    public static saveActiveCustomer(customer: Customer): void {
        if (!customer) {
            return;
        }

        sessionStorage.setItem(CustomerProvider.customerSpaceStorageKey, JSON.stringify(customer));
        AppEventHub.emit(AppEvents.CustomerSpaceSelected);
    }

    public static getActiveCustomer(): Customer | null {
        return CustomerProvider.getItem<Customer>(CustomerProvider.customerSpaceStorageKey);
    }

    private static getItem<T>(key: string): T | null {
        const json = sessionStorage.getItem(key);
        if (!json || json.length <= 0) {
            return null;
        }

        const customer = JSON.parse(json) as T;
        return customer;
    }

    public static clear(): void {
        sessionStorage.removeItem(CustomerProvider.customerSpaceStorageKey);
    }
}