export const translations = {
    "buttons": {
        "logout": "buttons.logout",
        "dropdowns": {
            "venue": "buttons.dropdowns.venue",
            "company": "buttons.dropdowns.company",
            "floor": "buttons.dropdowns.floor",
            "asset": "buttons.dropdowns.asset",
            "customer": "buttons.dropdowns.customer",
            "documenttype": "buttons.dropdowns.documenttype",
            "nooptions": "buttons.dropdowns.nooptions"
        },
        "cancel": "buttons.cancel",
        "submit": "buttons.submit",
        "save": "buttons.save",
    },
    "pagination": {
        "showing": "pagination.showing",
        "to": "pagination.to",
        "of": "pagination.of",
        "items": "pagination.items"
    },
    "datepicker": {
        "startdate": "datepicker.startdate",
        "enddate": "datepicker.enddate",
        "today": "datepicker.today",
        "time": "datepicker.time"
    },
    "dates": {
        "day": "dates.day",
        "week": "dates.week",
        "month": "dates.month",
        "year": "dates.year",
        "january": "dates.january",
        "february": "dates.february",
        "march": "dates.march",
        "april": "dates.april",
        "may": "dates.may",
        "june": "dates.june",
        "july": "dates.july",
        "august": "dates.august",
        "september": "dates.september",
        "october": "dates.october",
        "november": "dates.november",
        "december": "dates.december",
        "shorthand": {
            "monday": "dates.shorthand.monday",
            "tuesday": "dates.shorthand.tuesday",
            "wednesday": "dates.shorthand.wednesday",
            "thursday": "dates.shorthand.thursday",
            "friday": "dates.shorthand.friday",
            "saturday": "dates.shorthand.saturday",
            "sunday": "dates.shorthand.sunday",
            "january": "dates.shorthand.january",
            "february": "dates.shorthand.february",
            "march": "dates.shorthand.march",
            "april": "dates.shorthand.april",
            "may": "dates.shorthand.may",
            "june": "dates.shorthand.june",
            "july": "dates.shorthand.july",
            "august": "dates.shorthand.august",
            "september": "dates.shorthand.september",
            "october": "dates.shorthand.october",
            "november": "dates.shorthand.november",
            "december": "dates.shorthand.december"
        }
    },
    "navigation": {
        "home": "navigation.home",
        "map": "navigation.map",
        "insightasset": "navigation.insightasset",
        "upload": "navigation.upload",
        "adminmessagecenter": "navigation.adminmessagecenter",
        "devices": "navigation.devices",
        "accounts": "navigation.accounts",
        "addaccount": "navigation.addaccount",
        "rights": "navigation.rights",
        "userrights": "navigation.userrights",
        "grouprights": "navigation.grouprights",
        "domainrights": "navigation.domainrights",
        "autodesk": "navigation.autodesk",
        "applications": "navigation.applications",
    },
    "comfort": {
        "temperature": "comfort.temperature",
        "carbondioxide": "comfort.carbondioxide",
        "humidity": "comfort.humidity",
        "sound": "comfort.sound",
        "light": "comfort.light",
        "empty": "comfort.empty"
    },
    "pages": {
        "home": {
            "title": "pages.home.title",
            "all": "pages.home.all",
            "total": {
                "title": "pages.home.total.title"
            },
            "actual": {
                "building": "pages.home.actual.building",
                "status": "pages.home.actual.status",
                "title": "pages.home.actual.title"
            },
            "latest": {
                "title": "pages.home.latest.title",
                "datetime": "pages.home.latest.datetime",
                "category": "pages.home.latest.category",
                "building": "pages.home.latest.building",
                "asset": "pages.home.latest.asset",
                "description": "pages.home.latest.description",
                "status": "pages.home.latest.status"
            },
            "graph": {
                "total": "pages.home.graph.total",
                "currentweek": "pages.home.graph.currentweek"
            },
            "selectcustomer": "pages.home.selectcustomer"
        },
        "map": {
            "title": "pages.map.title",
            "noapikey": "pages.map.noapikey",
            "norawdatafound": "pages.map.norawdatafound",
            "noassetsselected": "pages.map.noassetsselected",
            "adddevice": {
                "title": "pages.map.adddevice.title",
                "close": "pages.map.adddevice.close",
                "name": "pages.map.adddevice.name",
                "add": "pages.map.adddevice.add",
                "connected": "pages.map.adddevice.connected",
                "newid": "pages.map.adddevice.newid",
                "devicenotfound": "pages.map.adddevice.devicenotfound",
                "invalidid": "pages.map.adddevice.invalidid",
                "notinsupply": "pages.map.adddevice.notinsupply",
                "devicefoundinspace": "pages.map.adddevice.devicefoundinspace",
                "andcustomer": "pages.map.adddevice.andcustomer",
                "deviceconnected": "pages.map.adddevice.deviceconnected",
                "assetoccupiedconfirmation": "pages.map.adddevice.assetoccupiedconfirmation",
                "missingrights": "pages.map.adddevice.missingrights",
                "genericerror": "pages.map.adddevice.genericerror",
                "stock": "pages.map.adddevice.stock",
                "malfunctioning": "pages.map.adddevice.malfunctioning",
                "lost": "pages.map.adddevice.lost",
                "movetostock": "pages.map.adddevice.movetostock",
                "movetomalfunction": "pages.map.adddevice.movetomalfunction",
                "movetolost": "pages.map.adddevice.movetolost",
                "edit": "pages.map.adddevice.edit",
                "namechangedsuccess": "pages.map.adddevice.namechangedsuccess",
                "namechangederror": "pages.map.adddevice.namechangederror"
            },
            "assetinsights": {
                "datetime": "pages.map.assetinsights.datetime",
                "occupancy": "pages.map.assetinsights.occupancy",
                "peoplecount": "pages.map.assetinsights.peoplecount",
                "temperature": "pages.map.assetinsights.temperature",
                "humidity": "pages.map.assetinsights.humidity",
                "carbondioxide": "pages.map.assetinsights.carbondioxide",
                "values": {
                    "occupied": "pages.map.assetinsights.values.occupied",
                    "unoccupied": "pages.map.assetinsights.values.unoccupied"
                }
            },
            "search": {
                "text": "pages.map.search.text",
                "notfound": "pages.map.search.notfound"
            }
        },
        "devices": {
            "metadata": {
                "title": "pages.devices.metadata.title",
                "export": "pages.devices.metadata.export",
                "columns": {
                    "customername": "pages.devices.metadata.columns.customername",
                    "venuename": "pages.devices.metadata.columns.venuename",
                    "floorname": "pages.devices.metadata.columns.floorname",
                    "spacename": "pages.devices.metadata.columns.spacename",
                    "floorlevel": "pages.devices.metadata.columns.floorlevel",
                    "deviceid": "pages.devices.metadata.columns.deviceid",
                    "batterylevel": "pages.devices.metadata.columns.batterylevel",
                    "devicefirmwareversion": "pages.devices.metadata.columns.devicefirmwareversion",
                    "adtdevicetype": "pages.devices.metadata.columns.adtdevicetype",
                    "lastkeepalive": "pages.devices.metadata.columns.lastkeepalive",
                    "lastdatamessage": "pages.devices.metadata.columns.lastdatamessage",
                    "expiredkeepalive": "pages.devices.metadata.columns.expiredkeepalive",
                    "devicestatus": "pages.devices.metadata.columns.devicestatus",
                    "laststatusupdate": "pages.devices.metadata.columns.laststatusupdate"
                },
                "devicestatus": {
                    "unknown": "pages.devices.metadata.devicestatus.unknown",
                    "notyetregistered": "pages.devices.metadata.devicestatus.notyetregistered",
                    "registered": "pages.devices.metadata.devicestatus.registered",
                    "downlinkfailed": "pages.devices.metadata.devicestatus.downlinkfailed",
                    "downlinksend": "pages.devices.metadata.devicestatus.downlinksend",
                    "wrongconfiguration": "pages.devices.metadata.devicestatus.wrongconfiguration",
                    "deleted": "pages.devices.metadata.devicestatus.deleted",
                    "downlinkexpired": "pages.devices.metadata.devicestatus.downlinkexpired"
                },
                "hasexpired": "pages.devices.metadata.hasexpired",
                "hasnotexpired": "pages.devices.metadata.hasnotexpired"
            }
        },
        "upload": {
            "title": "pages.upload.title",
            "forbiddendocument": "pages.upload.forbiddendocument",
            "upload": "pages.upload.upload",
            "customerreport": "pages.upload.customerreport",
            "venuereport": "pages.upload.venuereport",
            "nofileerror": "pages.upload.nofileerror",
            "fileuploaded": "pages.upload.fileuploaded",
            "erroronupload": "pages.upload.erroronupload",
            "error400onupload": "pages.upload.error400onupload",
            "nodocumenttype": "pages.upload.nodocumenttype",
            "columns": {
                "documenttype": "pages.upload.columns.documenttype",
                "customer": "pages.upload.columns.customer",
                "venue": "pages.upload.columns.venue",
                "filename": "pages.upload.columns.filename",
                "uploadedon": "pages.upload.columns.uploadedon",
                "downloadedon": "pages.upload.columns.downloadedon"
            },
            "requiredfield": "pages.upload.requiredfield",
            "deleteareyousure": "pages.upload.deleteareyousure"
        },
        "deviceconfiguration": {
            "title": "pages.deviceconfiguration.title"
        },
        "applications": {
            "applicationsheader": "pages.applications.applicationsheader",
            "newmessageheader": "pages.applications.newmessageheader",
            "editmessageheader": "pages.applications.editmessageheader",
            "messagecreated": "pages.applications.messagecreated",
            "url": "pages.applications.url",
            "customers": "pages.applications.customers",
            "errormessage": "pages.applications.errormessage",
            "errormessagecreate": "pages.applications.errormessagecreate",
            "errormessageupdate": "pages.applications.errormessageupdate",
            "deleteconsentmessage": "pages.applications.deleteconsentmessage",
            "deletemessageerror": "pages.applications.deletemessageerror",
            "deletemessagesucces": "pages.applications.deletemessagesucces",
            "selectabuilding": "pages.applications.selectabuilding",
            "validationerror": "pages.applications.validationerror",
            "succesfullyupdated": "pages.applications.succesfullyupdated",
            "true": "pages.applications.true",
            "false": "pages.applications.false",
            "building": "pages.applications.building",
            "link": "pages.applications.link"
        },
        "adminmessagecenter": {
            "adminmessagecenterheader": "pages.adminmessagecenter.adminmessagecenterheader",
            "newmessageheader": "pages.adminmessagecenter.newmessageheader",
            "editmessageheader": "pages.adminmessagecenter.editmessageheader",
            "startdate": "pages.adminmessagecenter.startdate",
            "enddate": "pages.adminmessagecenter.enddate",
            "message": "pages.adminmessagecenter.message",
            "title": "pages.adminmessagecenter.title",
            "visibleforallcustomers": "pages.adminmessagecenter.visibleforallcustomers",
            "customers": "pages.adminmessagecenter.customers",
            "priority": "pages.adminmessagecenter.priority",
            "messagecreated": "pages.adminmessagecenter.messagecreated",
            "errormessage": "pages.adminmessagecenter.errormessage",
            "errormessagecreate": "pages.adminmessagecenter.errormessagecreate",
            "errormessageupdate": "pages.adminmessagecenter.errormessageupdate",
            "succesfullyupdated": "pages.adminmessagecenter.succesfullyupdated",
            "deleteconsentmessage": "pages.adminmessagecenter.deleteconsentmessage",
            "deletemessageerror": "pages.adminmessagecenter.deletemessageerror",
            "deletemessagesucces": "pages.adminmessagecenter.deletemessagesucces",
            "validationerror": "pages.adminmessagecenter.validationerror",
            "selectamessage": "pages.adminmessagecenter.validationerror.selectamessage",
            "messagepriority": {
                "high": "pages.adminmessagecenter.messagepriority.high",
                "medium": "pages.adminmessagecenter.messagepriority.medium",
                "low": "pages.adminmessagecenter.messagepriority.low"
            },
            "true": "pages.adminmessagecenter.true",
            "false": "pages.adminmessagecenter.false"
        },
        "autodesk": {
            "title": "pages.autodesk.title",
            "mapupdatesucceeded": "pages.autodesk.mapupdatesucceeded",
            "mapupdatefailed": "pages.autodesk.mapupdatefailed",
            "assetupdatesucceeded": "pages.autodesk.assetupdatesucceeded",
            "assetupdatefailed": "pages.autodesk.assetupdatefailed",
            "translationinprogress": "pages.autodesk.translationinprogress",
            "maploadfailed": "pages.autodesk.maploadfailed",
            "publish": "pages.autodesk.publish",
            "publishandupsertassets": "pages.autodesk.publishandupsertassets",
            "preview": "pages.autodesk.preview",
            "upload": "pages.autodesk.upload",
            "uploadinfo": "pages.autodesk.uploadinfo",
            "previewinfo": "pages.autodesk.previewinfo",
            "back": "pages.autodesk.back",
            "invalidfile": "pages.autodesk.invalidfile",
            "nodwgpresent": "pages.autodesk.nodwgpresent",
            "publishconfirm": "pages.autodesk.publishconfirm",
            "publishandupsertassetsconfirm": "pages.autodesk.publishandupsertassetsconfirm",
            "upsertassetsconfirm": "pages.autodesk.upsertassetsconfirm",
            "addedassets": "pages.autodesk.addedassets",
            "deactivatedassets": "pages.autodesk.deactivatedassets",
            "existingdassets": "pages.autodesk.existingdassets",
            "invalidguidassets": "pages.autodesk.invalidguidassets",
            "invalidpositionassets": "pages.autodesk.invalidpositionassets",
            "refreshassets": "pages.autodesk.refreshassets",
            "upsertassets": "pages.autodesk.upsertassets",
            "nopreviewavailable": "pages.autodesk.nopreviewavailable",
            "loadingfailed": "pages.autodesk.loadingfailed",
            "selectall": "pages.autodesk.selectall",
        },
        "accounts": {
            "title": "pages.accounts.title",
            "accountname": "pages.accounts.accountname",
            "companyname": "pages.accounts.companyname",
            "firstname": "pages.accounts.firstname",
            "lastname": "pages.accounts.lastname",
            "fullname": "pages.accounts.fullname",
            "reset": "pages.accounts.reset",
            "delete": "pages.accounts.delete",
            "customers": "pages.accounts.customers",
            "rolessection": "pages.accounts.rolessection",
            "selectcustomers": "pages.accounts.selectcustomers",
            "allcustomers": "pages.accounts.allcustomers",
            "selectroles": "pages.accounts.selectroles",
            "accountnameexplanation": "pages.accounts.accountnameexplanation",
            "roles": {
                "facilitymanager": {
                    "title": "pages.accounts.roles.facilitymanager.title",
                    "description": "pages.accounts.roles.facilitymanager.description"
                },
                "besensemember": {
                    "title": "pages.accounts.roles.besensemember.title",
                    "description": "pages.accounts.roles.besensemember.description"
                },
                "functionaladmin": {
                    "title": "pages.accounts.roles.functionaladmin.title",
                    "description": "pages.accounts.roles.functionaladmin.description"
                },
                "besenseanalist": {
                    "title": "pages.accounts.roles.besenseanalist.title",
                    "description": "pages.accounts.roles.besenseanalist.description"
                },
                "customermanager": {
                    "title": "pages.accounts.roles.customermanager.title",
                    "description": "pages.accounts.roles.customermanager.description"
                },
                "cleaningadmin": {
                    "title": "pages.accounts.roles.cleaningadmin.title",
                    "description": "pages.accounts.roles.cleaningadmin.description"
                },
                "cleaner": {
                    "title": "pages.accounts.roles.cleaner.title",
                    "description": "pages.accounts.roles.cleaner.description"
                },
                "cleaningcustomeremployee": {
                    "title": "pages.accounts.roles.cleaningcustomeremployee.title",
                    "description": "pages.accounts.roles.cleaningcustomeremployee.description"
                },
                "workspacesadmin": {
                    "title": "pages.accounts.roles.workspacesadmin.title",
                    "description": "pages.accounts.roles.workspacesadmin.description"
                }
            },
            "norolesselected": "pages.accounts.norolesselected",
            "upn": "pages.accounts.upn",
            "password": "pages.accounts.password",
            "passwordwarningpart1": "pages.accounts.passwordwarningpart1",
            "passwordwarningpart2": "pages.accounts.passwordwarningpart2",
            "existingusererror": "pages.accounts.existingusererror",
            "errormessage": "pages.accounts.errormessage",
            "new": "pages.accounts.new",
            "newwarning": "pages.accounts.newwarning",
            "resetpopup": {
                "title": "pages.accounts.resetpopup.title",
                "resetquestion": "pages.accounts.resetpopup.resetquestion",
                "cancel": "pages.accounts.resetpopup.cancel",
            },
            "deletepopup": {
                "title": "pages.accounts.deletepopup.title",
                "deletequestion": "pages.accounts.deletepopup.deletequestion",
                "cancel": "pages.accounts.deletepopup.cancel",
            },
            "cantreset": {
                "title": "pages.accounts.cantreset.title",
                "text": "pages.accounts.cantreset.text",
                "rolestext": "pages.accounts.cantreset.rolestext",
                "close": "pages.accounts.cantreset.close"
            },
            "newpassword": {
                "title": "pages.accounts.newpassword.title",
                "close": "pages.accounts.newpassword.close"
            },
            "cantdelete": {
                "title": "pages.accounts.cantdelete.title",
                "text": "pages.accounts.cantdelete.text",
                "rolestext": "pages.accounts.cantdelete.rolestext",
                "close": "pages.accounts.cantdelete.close"
            }
        },
        "rights": {
            "usercreate": "pages.rights.usercreate",
            "useredit": "pages.rights.useredit",
            "groupcreate": "pages.rights.groupcreate",
            "groupedit": "pages.rights.groupedit",
            "domaincreate": "pages.rights.domaincreate",
            "domainedit": "pages.rights.domainedit",
            "save": "pages.rights.save",
            "createerror": "pages.rights.createerror",
            "createsuccess": "pages.rights.createsuccess",
            "updateerror": "pages.rights.updateerror",
            "updatesuccess": "pages.rights.updatesuccess",
            "roleassignmenterror": "pages.rights.roleassignmenterror",
            "roleassignmensuccess": "pages.rights.roleassignmensuccess",
            "deletesuccess": "pages.rights.deletesuccess",
            "deleteerror": "pages.rights.deleteerror",
            "deleteconfirm": "pages.rights.deleteconfirm",
            "invalidform": "pages.rights.invalidform",
            "invalidformpartners": "pages.rights.invalidformpartners",
            "customeraccess": "pages.rights.customeraccess",
            "roles": "pages.rights.roles",
            "partners": "pages.rights.partners",
            "multiplecustomerserror": "pages.rights.multiplecustomerserror",
            "customers": "pages.rights.customers",
            "columns": {
                "userobjectid": "pages.rights.columns.userobjectid",
                "username": "pages.rights.columns.username",
                "customername": "pages.rights.columns.customername",
                "lastmodified": "pages.rights.columns.lastmodified",
                "groupobjectid": "pages.rights.columns.groupobjectid",
                "domain": "pages.rights.columns.domain",
                "groupname": "pages.rights.columns.groupname",
                "roles": "pages.rights.columns.roles",
                "partner": "pages.rights.columns.partner"
            },
            "values": {
                "allcustomers": "pages.rights.values.allcustomers",
                "unknowncustomer": "pages.rights.values.unknowncustomer"
            },
            "roledescriptions": {
                "facilitymanager": "pages.rights.roledescriptions.facilitymanager",
                "cleaningadmin": "pages.rights.roledescriptions.cleaningadmin",
                "workspacesadmin": "pages.rights.roledescriptions.workspacesadmin",
            },
            "rolenames": {
                "facilitymanager": "pages.rights.rolenames.facilitymanager",
                "teammember": "pages.rights.rolenames.teammember",
                "functionaladmin": "pages.rights.rolenames.functionaladmin",
                "analyst": "pages.rights.rolenames.analyst",
                "cleaningadmin": "pages.rights.rolenames.cleaningadmin",
                "cleaner": "pages.rights.rolenames.cleaner",
                "cleaningcustomeremployee": "pages.rights.rolenames.cleaningcustomeremployee",
                "administrator": "pages.rights.rolenames.administrator",
                "cleaningdistrictmanager": "pages.rights.rolenames.cleaningdistrictmanager",
                "tester": "pages.rights.rolenames.tester",
                "cleaningobjectleader": "pages.rights.rolenames.cleaningobjectleader",
                "developer": "pages.rights.rolenames.developer",
                "customermanager": "pages.rights.rolenames.customermanager",
                "cleaningrayonmanager": "pages.rights.rolenames.cleaningrayonmanager",
                "application": "pages.rights.rolenames.application",
                "workspacesadmin": "pages.rights.rolenames.workspacesadmin"
            }
        }
    },
    "userSettingsMenu": {
        "logout": "userSettingsMenu.logout",
        "language": "userSettingsMenu.language",
        "feedback": "userSettingsMenu.feedback",
        "help": "userSettingsMenu.help",
        "settings": "userSettingsMenu.settings"
    },
    "notifications": {
        "invalidfloorspaces": "notifications.invalidfloorspaces"
    },
    "mappopup": {
        "loadingtext": "mappopup.loadingtext",
        "sensor": {
            "occupancystatus": {
                "title": "mappopup.sensor.occupancystatus.title",
                "unoccupied": "mappopup.sensor.occupancystatus.unoccupied",
                "occupied": "mappopup.sensor.occupancystatus.occupied",
                "coolingdown": "mappopup.sensor.occupancystatus.coolingdown",
                "symbol": "mappopup.sensor.occupancystatus.symbol"
            },
            "motion": {
                "title": "mappopup.sensor.motion.title",
                "false": "mappopup.sensor.motion.false",
                "true": "mappopup.sensor.motion.true",
                "symbol": "mappopup.sensor.motion.symbol"
            },
            "humidity": {
                "title": "mappopup.sensor.humidity.title",
                "symbol": "mappopup.sensor.humidity.symbol"
            },
            "sound": {
                "title": "mappopup.sensor.sound.title",
                "symbol": "mappopup.sensor.sound.symbol"
            },
            "light": {
                "title": "mappopup.sensor.light.title",
                "symbol": "mappopup.sensor.light.symbol"
            },
            "temperature": {
                "title": "mappopup.sensor.temperature.title",
                "symbol": "mappopup.sensor.temperature.symbol"
            },
            "carbondioxide": {
                "title": "mappopup.sensor.carbondioxide.title",
                "symbol": "mappopup.sensor.carbondioxide.symbol"
            },
            "count": {
                "title": "mappopup.sensor.count.title",
                "symbol": "mappopup.sensor.count.symbol"
            }
        },
        "buttons": {
            "insights": "mappopup.buttons.insights"
        }
    }
};

export default translations;