import * as React from "react";
import { useState, useEffect } from "react";
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { IAsset } from "./interfaces/IAsset";
import LanguageProvider from "../../../../providers/languageProvider";
import translations from "../../../../translations/mapper";
import { PulseLoader } from "react-spinners";

interface ISelectableContext {
    floorId: string;
    selectedColor: string;
    setSelectedSpaces: (selectedAssetIds: string[], color: string) => void;
}

interface IAssetListProps {
    assets: IAsset[];
    loading: boolean;
    title: string;
    selectableContext?: ISelectableContext;
}

interface AssetState {
    selectedIds: string[],
    selectedAll: boolean
}


export const AssetList: React.FC<IAssetListProps> = (props: IAssetListProps) => {
    const [assetState, setAssetState] = useState<AssetState>({ selectedAll: false, selectedIds: [] });
    const { selectableContext } = props;

    const assetToogle = (value: string) => (): void => {
        if (!selectableContext) {
            return;
        }

        const updatedAssetState = { ...assetState };
        const currentIndex = updatedAssetState.selectedIds.indexOf(value);

        if (currentIndex === -1) {
            updatedAssetState.selectedIds.push(value);
        } else {
            updatedAssetState.selectedIds.splice(currentIndex, 1);
        }

        updatedAssetState.selectedAll = updatedAssetState.selectedIds.length === props.assets.length;
        selectableContext.setSelectedSpaces(updatedAssetState.selectedIds, selectableContext.selectedColor);
        setAssetState(updatedAssetState);
    };

    const selectAllToggle = (selected: boolean) => (): void => {
        if (!selectableContext) {
            return;
        }

        const updatedAssetState = { ...assetState };
        updatedAssetState.selectedIds = selected ? props.assets.map(asset => asset.id) : [];
        updatedAssetState.selectedAll = selected;
        selectableContext.setSelectedSpaces(updatedAssetState.selectedIds, selectableContext.selectedColor);
        setAssetState(updatedAssetState);
    };

    useEffect(() => {
        if (!selectableContext?.floorId) {
            return;
        }

        setAssetState({ selectedAll: false, selectedIds: [] });
    }, [selectableContext?.floorId]);

    return (
        <div style={{ marginBottom: "20px" }}>
            {props.loading && (
                <div className="loader">
                    <PulseLoader
                        color="#37a0e6"
                        size={8}
                        margin="5px"
                        loading={props.loading}
                    />
                </div>
            )}
            <List sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: '#f6f6f6',
                overflow: 'auto',
                maxHeight: 250,
                minHeight: 200
            }} subheader={
                <ListSubheader>
                    <h6><b>{props.assets.length}</b> {props.title}</h6>
                </ListSubheader>}>
                {
                    selectableContext && props.assets.length > 0 && <ListItem
                        key={-1}
                        disablePadding
                    >
                        <ListItemButton onClick={selectAllToggle(!assetState.selectedAll)} dense>
                            <ListItemIcon>
                                <Checkbox checked={assetState.selectedAll} />
                            </ListItemIcon>
                            <ListItemText primary={LanguageProvider.getTranslation(translations.pages.autodesk.selectall)} />
                        </ListItemButton>
                    </ListItem>
                }
                {props.assets.map((asset, index) => {
                    return (
                        <ListItem
                            key={index}
                            disablePadding
                        >
                            <ListItemButton onClick={assetToogle(asset.id)} dense>
                                {
                                    selectableContext && <ListItemIcon>
                                        <Checkbox checked={assetState.selectedIds.indexOf(asset.id) !== -1} />
                                    </ListItemIcon>
                                }
                                <ListItemText primary={`${asset.name} - ${asset.type}`} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};