import ClientSideCacheItem from "./clientSideCacheItem";

export default class ClientSideCache {
    public static TryGetResponseFromCache(url: string): any {
        const item = new ClientSideCacheItem();
        item.parse(sessionStorage.getItem(url));
        if (!item.isValid) {
            sessionStorage.removeItem(url);
        }

        return item.contents;
    }

    public static AddReponseToCache(url: string, contents: string): void {
        try{
        sessionStorage.setItem(url, JSON.stringify(new ClientSideCacheItem(url, contents)));
        }
        catch(e)
        {
            console.warn(`Error while writing to local storage, likely local storage is full. \n Error is ${e}.`);
        }
    }
}