import { Tooltip } from '@mui/material';
import * as React from 'react';
import './infoTooltip.scss';


export interface IBasicTooltip {
    icon: string;
    content: any;
}

export const BasicTooltip: React.FC<IBasicTooltip> = (props) => {
    return (
        <Tooltip title={<div className="tooltipTitle">{props.content}</div>}
            sx={{
                maxWidth: "none"
            }}>
            <img className="iconInfo" src={props.icon} />
        </Tooltip>
    );
};