export default class TranslationMapperGenerator {
    public static GenerateMapper(translationFile: any): string[] {

        const getKeys = (translationFileObject: any): string[] => {
            const disectObject = (objectToDisect: any, targetObject: any, path?: string): any => {
                Object.keys(objectToDisect).forEach(key => {
                    const prop = `${path ? path : ""}${key}`;

                    if (typeof objectToDisect[key] === 'object') {
                        targetObject[key] = {};
                        disectObject(objectToDisect[key], targetObject[key], `${prop}.`);
                    }
                    else {
                        targetObject[key] = prop;
                    }
                });
                return targetObject;
            };

            const target = {};
            const resultingMap = disectObject(translationFileObject, target);
            return resultingMap;
        };

        const keys = getKeys(translationFile);

        return keys;
    }
}