import MaintenanceService from "./maintenanceService";
import { DocumentBlobType } from "../types/documentBlobType";
import CustomerProvider from "../providers/customerProvider";
import Dictionary from '../utils/dictionary';
import ISubscription from "../interfaces/ISubscription";
import { ServiceType } from "../types/serviceType";

export default class SubscriptionService {
    private maintenanceService: MaintenanceService;
    private venuesWithSubscriptionForCustomerByService: Dictionary<string[]>;
    private static EmptyGuid: string = "00000000-0000-0000-0000-000000000000";

    public constructor() {
        this.maintenanceService = new MaintenanceService();
        this.venuesWithSubscriptionForCustomerByService = new Dictionary<string[]>();
    }

    public async GetAllowedDocumentTypesForCustomerSubscriptions(): Promise<DocumentBlobType[]> {
        this.venuesWithSubscriptionForCustomerByService = new Dictionary<string[]>();
        const allowedTypes: DocumentBlobType[] = [];

        const customer = CustomerProvider.getActiveCustomer();
        if (!customer) {
            return [];
        }

        await this.InitializeSubscriptionsForCustomerByVenue(customer.id);

        const hasBeEquipped = this.EnsureActiveService("BEEquipped");
        const hasBEEnergized = this.EnsureActiveService("BEEnergized");
        const hasBEVital = this.EnsureActiveService("BEVital");
        const hasBEClean = this.EnsureActiveService("BEClean");

        allowedTypes.push('All');

        if (hasBeEquipped) {
            allowedTypes.push('BE Equipped');
        }
        if (hasBEEnergized) {
            allowedTypes.push('BE Energized');
        }
        if (hasBEVital) {
            allowedTypes.push('BE Vital');
        }
        if (hasBEClean) {
            allowedTypes.push('BE Clean');
        }

        return allowedTypes;
    }

    public async GetAllowedDocumentTypesForVenueSubscriptions(venueId: string): Promise<DocumentBlobType[]> {
        const allowedTypes: DocumentBlobType[] = [];

        const customer = CustomerProvider.getActiveCustomer();
        if (!customer) {
            return [];
        }

        await this.InitializeSubscriptionsForCustomerByVenue(customer.id);

        const hasBeEquipped = this.EnsureActiveServiceOnVenue(venueId, "BEEquipped");
        const hasBEEnergized = this.EnsureActiveServiceOnVenue(venueId, "BEEnergized");
        const hasBEVital = this.EnsureActiveServiceOnVenue(venueId, "BEVital");
        const hasBEClean = this.EnsureActiveServiceOnVenue(venueId, "BEClean");

        allowedTypes.push('All');

        if (hasBeEquipped) {
            allowedTypes.push('BE Equipped');
        }
        if (hasBEEnergized) {
            allowedTypes.push('BE Energized');
        }
        if (hasBEVital) {
            allowedTypes.push('BE Vital');
        }
        if (hasBEClean) {
            allowedTypes.push('BE Clean');
        }

        return allowedTypes;
    }

    private EnsureActiveServiceOnVenue(venueId: string, service: ServiceType): boolean {
        if (this.venuesWithSubscriptionForCustomerByService.containsKey(service)) {
            return !!this.venuesWithSubscriptionForCustomerByService.item(service)?.find(venueIds => venueIds?.includes(venueId)
                || venueIds?.includes(SubscriptionService.EmptyGuid));
        }

        return false;
    }

    private EnsureActiveService(service: ServiceType): boolean {
        return !!this.venuesWithSubscriptionForCustomerByService?.item(service);
    }

    private async InitializeSubscriptionsForCustomerByVenue(customerId: string): Promise<void> {
        const requestUrl = `customers/${customerId}/subscriptions`;
        var result = await this.maintenanceService.get<ISubscription[]>(requestUrl);

        result.forEach(s => {
            const service = s.service;
            if (this.venuesWithSubscriptionForCustomerByService.containsKey(service)) {
                this.venuesWithSubscriptionForCustomerByService.item(service).push(s.venueId ?? SubscriptionService.EmptyGuid);
            }
            else {
                this.venuesWithSubscriptionForCustomerByService.add(service, [s.venueId ?? SubscriptionService.EmptyGuid]);
            }
        });
    }
}
