import * as React from "react";

export default class ClickHandler<T> extends React.Component<IClickHandlerProps<T>> {
    public constructor(props: IClickHandlerProps<T>) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    private onClick(): void {
        this.props.onClick(this.props.params);
    }

    public render(): JSX.Element {
        return (
            <a onClick={this.onClick} href="#">{this.props.name}</a>
        );
    }
}

interface IClickHandlerProps<T> {
    name: string;
    params?: T;
    onClick: (params?: T) => void;
}