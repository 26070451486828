enum CoreSpaceValues {
    OccupancyStatus,
    ActualFree,
    PeopleCount,
    Sound,
    Temperature,
    Humidity,
    CarbonDioxide,
    Light,
    NrOfPeopleEntering,
    NrOfPeopleLeaving,
    NrOfPeopleEnteredTotal,
    NrOfPeopleLeftTotal,
    LastBeCountV2Update
}

export default CoreSpaceValues;