import * as React from "react";
import ISettingsDescriptionOverviewProps from "./interfaces/ISettingsDescriptionOverviewProps";
import ISettingsDescriptionOverviewState from "./interfaces/ISettingsDescriptionOverviewState";
import Select from 'react-select';
import SelectBoxUtils from "../../../utils/selectBoxUtils";
import LanguageProvider from "../../../providers/languageProvider";
import Dictionary from "../../../utils/dictionary";
import SettingsDescriptionProvider from "../../../components/utils/settingsDescriptionProvider";

export default class SettingsDescriptionOverview extends React.Component<ISettingsDescriptionOverviewProps, ISettingsDescriptionOverviewState> {

    public constructor(props: ISettingsDescriptionOverviewProps) {
        super(props);
        const state: ISettingsDescriptionOverviewState = {
            deviceTypeSettings: new Dictionary<Dictionary<string>>(),
            selectedDeviceType: ""
        };
        this.state = state;
        this.selectDescription = this.selectDescription.bind(this);
    }

    public componentDidMount(): void {
        const descriptions = SettingsDescriptionProvider.getSettings();
        this.setState({
            deviceTypeSettings: descriptions
        });
    }

    private selectDescription(event: any): void {
        if (event == null || event.value === null) {
            this.setState({
                selectedDeviceType: ""
            });
        }
        else{
            this.setState({
                selectedDeviceType: event.value
            });
        }
    }

    public render(): JSX.Element {
        const options = this.state.deviceTypeSettings.getKeys();
        const optionItems = options.map(o => {
            return {
                label: o,
                value: o
            };
        });

        const selectedSettings = this.state.deviceTypeSettings.item(this.state.selectedDeviceType);
        return (
            <div id="settings-description-overview">
                <div className="header">
                    <h3>Toelichting Instellingen</h3>
                </div>
                <Select
                    id="occupance-filter-input"
                    onChange={this.selectDescription}
                    options={optionItems}
                    placeholder={LanguageProvider.getTranslation("pages.home.selectsetting")}
                    styles={SelectBoxUtils.getDefaultSelectStyles(40)}
                    isMulti={false}
                    isClearable={true}
                />
                <div id={'settings-content'}>
                    {selectedSettings && selectedSettings.getKeys().map(sk => {
                        return (
                            <div key={sk}>
                                <span className="setting-title">{sk}<br /></span>
                                {
                                    selectedSettings.item(sk).indexOf('<table') === -1 &&
                                    selectedSettings.item(sk).split('\n').map((l, i) => {
                                        return <span key={i}>{l}<br /></span>;
                                    })}
                                {
                                    selectedSettings.item(sk).indexOf('<table') !== -1 &&
                                    <span dangerouslySetInnerHTML={{ __html: selectedSettings.item(sk) }} />
                                }
                                <br /></div>
                        );
                    })}
                </div>
            </div>
        );
    }
}