import React from "react";
import { withLocalize } from "react-localize-redux";
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';

import IUserAccessCreateModalProps from "./interfaces/IUserAccessCreateModalProps";
import IUserAccessCreateModalState from "./interfaces/IUserAccessCreateModalState";

import Yellow_Cross_Cancel from "../../../images/Yellow_Cross_Cancel.svg";
import LanguageProvider from "../../../providers/languageProvider";
import mapper from "../../../translations/mapper";
import CoreSpaceService from "../../../services/coreSpaceService";
import CenteredPageLoader from "../../../components/loaders/centeredPageLoader";
import Sorter from "../../../utils/sorter";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import RightsService from "../../../services/rightsService";

class UserAccessCreateModal extends React.Component<IUserAccessCreateModalProps, IUserAccessCreateModalState>{
    private readonly spaceService: CoreSpaceService;
    private readonly rightsService: RightsService;

    private closeButtonRef: React.RefObject<HTMLButtonElement>;

    public constructor(props: IUserAccessCreateModalProps) {
        super(props);

        this.state = {
            customers: [],
            loading: true,
            selectedCustomers: [],
            customerSelectionOptions: [],
            allCustomers: false,
            userName: "",
            userObjectId: "",
            validObjectId: false,
            submitting: false
        };

        this.spaceService = new CoreSpaceService();
        this.rightsService = new RightsService();
        this.resetForm = this.resetForm.bind(this);
        this.onCustomerSelectChange = this.onCustomerSelectChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onUserNameChange = this.onUserNameChange.bind(this);
        this.onUserObjectIdChange = this.onUserObjectIdChange.bind(this);
        this.onAllCustomersChange = this.onAllCustomersChange.bind(this);

        this.closeButtonRef = React.createRef();
    }

    public async componentDidMount(): Promise<void> {
        let customers = await this.spaceService.getCustomers();
        customers = Sorter.sortByProperty(customers, "name");
        const customerSelectionOptions = customers.map(v => ({
            value: v.id,
            label: v.name
        }));

        this.setState({
            customers: customers,
            loading: false,
            customerSelectionOptions: customerSelectionOptions
        });
    }

    private resetForm(): void {
        this.setState({
            allCustomers: false,
            selectedCustomers: [],
            userName: "",
            userObjectId: "",
            submitting: false,
            validObjectId: false
        });
    }

    private onCustomerSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): void {
        if (optionSelected === undefined) {
            return;
        }

        const selectedOptions = optionSelected as IReactSelectValue[] ?? [];
        this.setState({
            selectedCustomers: selectedOptions
        });
    }

    private onAllCustomersChange(event: any): void {
        this.setState({
            allCustomers: event.target.checked
        });
    }

    private onUserNameChange(event: any): void {
        const invalidUpnCharactersRegex = /[^A-Za-z0-9,.\-_!#^~'@]/g;
        const upnInput: string = event.target.value;
        this.setState({
            userName: upnInput.replace(invalidUpnCharactersRegex, '')
        });
    }

    private onUserObjectIdChange(event: any): void {
        const invalidCharsRegex = /[^0-9a-zA-Z-]/g;
        let userId: string = event.target.value;
        userId = userId.replace(invalidCharsRegex, '');
        userId = userId.toLowerCase();

        this.setState({
            userObjectId: userId,
            validObjectId: this.validateGuid(userId)
        });
    }

    private async onSave(): Promise<void> {
        if (!this.validateInputForm()) {
            NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.invalidform));
            return;
        }

        this.setState({
            submitting: true
        });

        if (this.state.allCustomers) {
            // Only need to create a single record, for the all customer access:
            const requestBody = {
                allCustomers: true,
                userObjectId: this.state.userObjectId,
                userUpn: this.state.userName,
                customers: []
            };

            const response = await this.rightsService.UpsertUserCustomerAccessAssignment(requestBody);

            if (response.status !== 204) {
                NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.createerror));

                this.setState({
                    submitting: false
                });
                return;
            }
        }
        else {
            // Need to create a seperate record for each customer:
            const customers = this.state.selectedCustomers.map(option => { return { customerId: option.value, customerName: option.label }; });

            const requestBody = {
                allCustomers: false,
                userObjectId: this.state.userObjectId,
                userUpn: this.state.userName,
                customers: customers
            };

            const response = await this.rightsService.UpsertUserCustomerAccessAssignment(requestBody);

            if (response.status !== 204) {
                if (this.state.selectedCustomers.length > 1) {
                    NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.multiplecustomerserror));
                }
                else {
                    NotificationManager.error(LanguageProvider.getTranslation(mapper.pages.rights.createerror));
                }

                this.setState({
                    submitting: false
                });
                return;
            }
        }

        NotificationManager.success(LanguageProvider.getTranslation(mapper.pages.rights.createsuccess));

        if (this.closeButtonRef.current) {
            this.closeButtonRef.current.click();
        }

        await this.props.callbackAfterSuccessfullCreate();
        this.resetForm();
    }

    private validateGuid(potentialGuid: string): boolean {
        const validGuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return validGuidRegex.test(potentialGuid);
    }

    private validateInputForm(): boolean {
        const customerSelectionValid = this.state.allCustomers || this.state.selectedCustomers.length > 0;
        const userObjectIdValid = this.state.validObjectId;
        const userNameValid = this.state.userName.length > 0;

        return customerSelectionValid && userObjectIdValid && userNameValid;
    }

    public render(): JSX.Element {
        return (
            <div className="modal fade"
                id="user-customeraccess-create-modal"
                role="dialog"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="rights-create-modal user-create-modal modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {LanguageProvider.getTranslation(mapper.pages.rights.usercreate)}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetForm}>
                                <img src={Yellow_Cross_Cancel} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                            {!this.state.loading &&
                                <form>
                                    <div className="form-group">
                                        <span className="access-input-title">{LanguageProvider.getTranslation(mapper.pages.rights.values.allcustomers)}</span>
                                        <input
                                            className="all-customers-checkbox"
                                            type="checkbox"
                                            checked={this.state.allCustomers}
                                            onChange={this.onAllCustomersChange}
                                            name="allcustomers"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title">{LanguageProvider.getTranslation(mapper.pages.rights.customers)}</span>
                                        <div className="customers-select">
                                            <Select
                                                classNamePrefix={this.state.allCustomers || this.state.selectedCustomers.length > 0 ? "access-customers-valid" : "access-customers-invalid"}
                                                defaultValue={[]}
                                                options={this.state.customerSelectionOptions}
                                                onChange={this.onCustomerSelectChange}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.allCustomers ? [] : this.state.selectedCustomers}
                                                isDisabled={this.state.allCustomers}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.userobjectid)}</span>
                                        <input
                                            className={`form-control ${this.state.validObjectId ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.userObjectId}
                                            onChange={this.onUserObjectIdChange}
                                            name="userobjectid"
                                            autoComplete="off"
                                            maxLength={36}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span className="access-input-title access-input-side-title">{LanguageProvider.getTranslation(mapper.pages.rights.columns.username)}</span>
                                        <input
                                            className={`form-control ${this.state.userName.length > 0 ? "is-valid" : "is-invalid"}`}
                                            type="text"
                                            value={this.state.userName}
                                            onChange={this.onUserNameChange}
                                            name="username"
                                            autoComplete="off"
                                            maxLength={113}
                                        />
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            {!this.state.submitting && <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.onSave}
                            >
                                {LanguageProvider.getTranslation(mapper.pages.rights.save)}
                            </button>}
                            {this.state.submitting && <CenteredPageLoader loading={this.state.submitting} />}
                        </div>
                        <button type="button"
                            className="d-none"
                            data-dismiss="modal"
                            data-target="#user-customeraccess-create-modal"
                            ref={this.closeButtonRef}></button>
                    </div>
                </div>

            </div >
        );
    }
}

export default withLocalize(UserAccessCreateModal);