import moment from 'moment';

import BaseService from "./baseService";
import { ApplicationConfig } from "../config";
import MaintenanceUserAuthenticator from "../authenticators/maintenanceUserAuthenticator";
import VenueProvider from "../providers/venueProvider";
import ChangedDeviceConfig from "../models/changedDeviceConfig";
import IDeviceData from '../interfaces/IDeviceData';
import DeviceAssetChange from '../models/deviceAssetChange';
import DocumentCreate from '../models/documentCreate';
import DocumentMetaData from '../models/documentMetaData';

export default class MaintenanceService extends BaseService {
    public constructor() {
        super(ApplicationConfig.endpoints.winvisionMaintenanceApi, new MaintenanceUserAuthenticator());
    }

    public async updateDeviceConfig(config: ChangedDeviceConfig[]): Promise<Response> {
        return this.post("deviceconfiguration", config);
    }

    public async getCustomerPermissions(): Promise<string[]> {
        return this.get<string[]>("customerpermissions").catch(e => {
            console.warn('No customerpermissions have been found for current user');
            return [];
        });
    }

    public async getMapwizeKey(): Promise<string> {
        const venueId = this.getVenueId();
        const request = `credentials/venues/${venueId}/mapwize`;

        return await this.get<string>(request).catch(e => {
            console.log(e);
            return "";
        });
    }

    public async getDeviceData(spaceId: string, startDate: string, endDate: string): Promise<IDeviceData[]> {
        const formattedStartDate = this.formatDate(startDate);
        const formattedEndDate = this.formatDate(endDate);
        const customerId = this.getCustomerId();

        const request = `customer/${customerId}/space/${spaceId}/devicedata/${formattedStartDate}/${formattedEndDate}`;

        return await this.get<IDeviceData[]>(request);
    }

    public async sendDeviceAssetChange(deviceAssetChange: DeviceAssetChange): Promise<Response> {
        return this.post("devices", deviceAssetChange);
    }

    public async getDocumentContent(blobId: string): Promise<string> {
        const request = `documents/${blobId}?saveDownloadedOn=false`;
        return this.get<string>(request);
    }

    public async getAllDocuments(skipCache: boolean = false): Promise<DocumentMetaData[]> {
        return this.get<DocumentMetaData[]>("documents/all", skipCache);
    }

    public async postFileForVenue(venueId: string, document: DocumentCreate): Promise<Response> {
        return this.post(`documents/venues/${venueId}`, document);
    }

    public async postFileForCustomer(customerId: string, document: DocumentCreate): Promise<Response> {
        return this.post(`documents/customers/${customerId}`, document);
    }

    public async deleteDocument(id: string): Promise<Response> {
        return this.delete(`documents/${id}`);
    }

    private formatDate(date: Date | string): string {
        return moment(date).format("YYYY-MM-DD");
    }

    private getVenueId(): string {
        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        return venue.id;
    }

    private getCustomerId(): string {
        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        return venue.customerId;
    }
}