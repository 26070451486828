import CoreSpace from "../models/coreSpace";
import Customer from "../models/customer";
import MaintenanceService from "./maintenanceService";
import CoreSpaceIncludes from "./../enums/coreSpaceIncludes";
import CoreSpaceTypes from "./../enums/coreSpaceTypes";
import CoreSpaceDatatypes from "./../enums/coreSpaceDatatypes";
import ICoreSpace from "../interfaces/ICoreSpace";
import { SubscriptionType } from "../types/subscriptionType";

export default class CoreSpaceService {
    private readonly maintenanceService: MaintenanceService;

    public constructor() {
        this.maintenanceService = new MaintenanceService();
    }

    public async getCustomers(skipCacheCheck: boolean = false): Promise<Customer[]>{
        const requestUrl = `spaces/customers`;
        return this.maintenanceService.get<Customer[]>(requestUrl, skipCacheCheck);
    }

    public async getVenuesWithAllRelatedData(skipCacheCheck: boolean = false): Promise<CoreSpace[]>{
        let requestUrl = `spaces/venues`;
        const includes = [CoreSpaceIncludes.Properties, CoreSpaceIncludes.DataTypes];
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, undefined);

        const spaces = this.maintenanceService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return (await spaces).map(s => new CoreSpace(s));
    }

    public async getVenuesWithProperties(skipCacheCheck: boolean = false): Promise<CoreSpace[]>{
        let requestUrl = `spaces/venues`;
        const includes = [CoreSpaceIncludes.Properties];
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, undefined);

        const spaces = this.maintenanceService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return (await spaces).map(s => new CoreSpace(s));
    }

    public async getVenuesBySubscriptionType(subscriptionTypes: SubscriptionType[]): Promise<CoreSpace[]> {
        const spaces = await this.getVenuesWithAllRelatedData(false);
        return spaces;
    }

    public async getVenueById(venueId: string, includes?: CoreSpaceIncludes[], skipCacheCheck: boolean = false): Promise<CoreSpace>{
        let requestUrl = `spaces/venues/${venueId}`;
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, undefined);

        return this.maintenanceService.get<ICoreSpace>(requestUrl, skipCacheCheck).then(s => new CoreSpace(s));
    }

    public async getFloorsForVenue(venueId: string, includes?: CoreSpaceIncludes[], skipCacheCheck: boolean = false): Promise<CoreSpace[]> {
        let requestUrl = `spaces/venues/${venueId}/floors`;
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, undefined);

        const spaces = await this.maintenanceService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return spaces.map(s => new CoreSpace(s));
    }

    public async getSpacesForFloor(venueId: string, floorId: string, types?: CoreSpaceTypes[], includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], skipCacheCheck: boolean = false): Promise<CoreSpace[]> {
        let requestUrl = `spaces/venues/${venueId}/floors/${floorId}/spaces`;
        requestUrl += this.constructSpacesQueryParameters(types, includes, dataTypes);

        const spaces = await this.maintenanceService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return spaces.map(s => new CoreSpace(s));
    }

    public async getSpacesForVenue(venueId: string, types?: CoreSpaceTypes[], includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], skipCacheCheck: boolean = false): Promise<CoreSpace[]> {
        let requestUrl = `spaces/venues/${venueId}/spaces`;
        requestUrl += this.constructSpacesQueryParameters(types, includes, dataTypes);

        const spaces = await this.maintenanceService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return spaces.map(s => new CoreSpace(s));
    }

    public async getSpaceById(venueId: string, spaceId: string, includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], skipCacheCheck: boolean = false): Promise<CoreSpace> {
        let requestUrl = `spaces/venues/${venueId}/spaces/${spaceId}`;
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, dataTypes);

        return this.maintenanceService.get<ICoreSpace>(requestUrl, skipCacheCheck).then(s => new CoreSpace(s));
    }

    public async UpdateBEEquippedExternalApplicationUrlAsync(venueId: string, url: string): Promise<[Response, string]> {
        const requestUrl = `spaces/venues/${venueId}/applicationurl`;
        return this.maintenanceService.postWithResponse<string>(requestUrl, url);
    }

    public async RemoveBEEquippedExternalApplicationUrlAsync(venueId: string): Promise<[Response, string]> {
        const requestUrl = `spaces/venues/applicationurl/removeurl/${venueId}`;
        return this.maintenanceService.putWithResponse<string>(requestUrl, undefined);
    }

    private constructSpacesQueryParameters(types?: CoreSpaceTypes[], includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[]): string {
        const queryParameters: string[] = [];

        if (types) {
            types.forEach(type => {
                queryParameters.push(`types=${type}`);
            });
        }

        if (includes) {
            includes.forEach(include => {
                queryParameters.push(`includes=${include}`);
            });
        }

        if (dataTypes) {
            dataTypes.forEach(dataType => {
                queryParameters.push(`dataTypes=${dataType}`);
            });
        }

        if (queryParameters.length > 0) {
            const combinedParameters = queryParameters.join('&');
            return `?${combinedParameters}`;
        }
        else {
            return "";
        }
    }
}