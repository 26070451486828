import * as React from "react";
import { Component } from "react";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { nl } from 'date-fns/esm/locale';

import ApplicationFormProps from "./interfaces/ApplicationFormProps";
import ApplicationFormState from "./interfaces/ApplicationFormState";

import LanguageProvider from "../../../providers/languageProvider";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";

import CoreSpaceService from "../../../services/coreSpaceService";
import ICoreSpace from "../../../interfaces/ICoreSpace";
import { PulseLoader } from "react-spinners";

export default class ApplicationForm extends Component<ApplicationFormProps, ApplicationFormState> {
    private readonly customerSeperator: string = ';';
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: ApplicationFormProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        var cs = this.props.buildings[props.customerIds] as ICoreSpace;

        const state: ApplicationFormState = {
            buildings: this.props.buildings,
            coreSpace: cs,
            loading: false,
            prioritySelectOptions: [],
            isFormValid: false,
            url: this.props.url,
            customerIds: [],
        };
        if(cs != null) state.customerIds.push(cs?.venueId);

        this.state = state;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCustomerSelectChange = this.onCustomerSelectChange.bind(this);
        this.renderCustomerSelect = this.renderCustomerSelect.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    public componentDidMount(): void {
        this.validateForm();

        registerLocale('nl', nl);
        setDefaultLocale('nl');
    }

    public async onCustomerSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): Promise<void> {
        let customerIds: string;

        if (optionSelected === null || optionSelected === undefined) {
            customerIds = '';
            this.setState({ isFormValid: false });
            return;
        }

        const optionValues: string[] = [];

        (optionSelected as IReactSelectValue[]).map(option => optionValues.push(option.value));
        // eslint-disable-next-line
        customerIds = optionValues.join(this.customerSeperator);
        this.setState({customerIds: optionValues, isFormValid: true});
    }

   public renderCustomerSelect(): JSX.Element {
        const preSelectedCustomers: IReactSelectValue[] = [];

        if (this.props.customerIds) {
            this.props.customerSelectOptions.forEach(option => {

                if (this.state.coreSpace?.venueId === option.value) {
                    preSelectedCustomers.push(option);
                }
            });
         }

        return <Select
            defaultValue={preSelectedCustomers}
            options={this.props.customerSelectOptions}
            onChange={this.onCustomerSelectChange}
            isClearable={true}
            isMulti={true}
        />;
    }

    public async handleInputChange(event: any): Promise<void> {
        const target = event.target;
        const name = target.name;

        if(name === "title") {
            this.checkUrl(target.value);
            this.setState({ url: target.value});
        }
    }

    private checkUrl(url: string): void {
        const urlRegEx = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var result = urlRegEx.test(url);

        if(result)this.setState({ isFormValid: true});
        else this.setState({ isFormValid: false});
    }

    public async handleSubmit(): Promise<void> {

        this.setState({ loading: true });

        try {
            this.state.customerIds.forEach((id: string) => {
                this.coreSpaceService.UpdateBEEquippedExternalApplicationUrlAsync(id, this.state.url);
            });
        } catch (error) {
            this.setState({ loading: false });
            return NotificationManager.error(LanguageProvider.getTranslation('pages.applications.errormessagecreate'));
        }

        setTimeout(() => {
            this.finishForm();
        }, 3000);

    }

    public finishForm(): void {
        this.setState({
            loading: false
        });

        NotificationManager.info(LanguageProvider.getTranslation(`pages.applications.${this.props.isNewMessage ? 'messagecreated' : 'succesfullyupdated'}`));
        this.props.refreshMessages();
    }

    private validateForm(): void {
        const isFormValid = true;

        const currentlyValid = this.state?.isFormValid;
        if (currentlyValid === isFormValid) {
            return;
        }

        this.setState({
            isFormValid: isFormValid
        });
    }

    public render(): JSX.Element {
        return (
            <div className="new-message-container ">
                {!this.state?.loading && <h3>{LanguageProvider.getTranslation(`pages.applications.${this.props.isNewMessage ? 'newmessageheader' : 'editmessageheader'}`)}</h3>}

                <div className="row">
                    {!this.state?.loading && <div className="applications-input-form col-md-6">
                        <label>
                            {LanguageProvider.getTranslation('pages.applications.customers')}
                            {this.renderCustomerSelect()}
                        </label>

                        <label>
                            {LanguageProvider.getTranslation('pages.applications.url')}
                            <input
                                type="text"
                                value={this.state.url}
                                onChange={this.handleInputChange}
                                name="title"
                                autoComplete="off"
                                maxLength={200}
                            />
                        </label>

                        <button
                            className={`besense-button yellow-button ${!this.state?.isFormValid ? 'disabled' : 'clickable'} `}
                            onClick={!this.state.isFormValid ? (): void => window.alert(LanguageProvider.getTranslation("pages.applications.validationerror")) : this.handleSubmit}>
                            {LanguageProvider.getTranslation('buttons.save')}
                        </button>
                        <button className="besense-button blue-button clickable" onClick={this.props.handleCancel}>{LanguageProvider.getTranslation('buttons.cancel')}</button>
                    </div>
                    }
                    {
                        this.state.loading && <PulseLoader color={'#37a0e6'} size={10}  margin={"15px"} loading={this.state.loading} />
                    }
                </div>
            </div>
        );
    }
}