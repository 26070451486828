import * as React from 'react';

import { withLocalize } from 'react-localize-redux';
import Select from "react-select";
import { NotificationManager } from 'react-notifications';
import IReactSelectValue from '../../interfaces/IReactSelectValue';
import LanguageProvider from '../../providers/languageProvider';
import CoreSpaceService from '../../services/coreSpaceService';
import UserAccountService from '../../services/userAccountService';
import PageHeader from "../../components/header/pageHeader";
import Mapper from "../../translations/mapper";

import IAddAccountPageProps from "./interfaces/IAddAccountPageProps";
import IAddAccountPageState from "./interfaces/IAddAccountPageState";

import UserRoles from "../../enums/userRoles";

import "./addAccountPage.scss";
import UserCreate from '../../models/userCreate';
import AzureAdDetails from '../../models/azureAdDetails';
import Customer from '../../models/customer';

class AddAccountPage extends React.Component<IAddAccountPageProps, IAddAccountPageState>{

    private readonly coreSpaceService: CoreSpaceService;
    private readonly accountService: UserAccountService;

    public constructor(props: IAddAccountPageProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        this.accountService = new UserAccountService();

        this.state = {
            customers: [],
            accountName: "",
            companyName: "",
            firstName: "",
            lastName: "",
            selectedCustomers: [],
            allCustomersSelected: false,
            selectedRoles: [],
            isFormValid: false,
            accountnameContainsInvalidCharacters: false,
            isAccountNameValid: false,
            isCompanyNameValid: false,
            isLastNameValid: false,
            isFirstNameValid: false,
            isCustomersValid: false,
            showResult: false,
            password: "",
            upn: ""
        };

        this.getCustomers = this.getCustomers.bind(this);
        this.onAccountNameChange = this.onAccountNameChange.bind(this);
        this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.getCustomersSelectValues = this.getCustomersSelectValues.bind(this);
        this.onCustomerSelectChange = this.onCustomerSelectChange.bind(this);
        this.handleSelectAllCustomers = this.handleSelectAllCustomers.bind(this);
        this.getRolesSelectValues = this.getRolesSelectValues.bind(this);
        this.onRolesSelectChange = this.onRolesSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getPasswordWarning = this.getPasswordWarning.bind(this);
        this.getUserCreateModel = this.getUserCreateModel.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        await this.getCustomers();
    }

    private async getCustomers(): Promise<void> {
        const customers = await this.coreSpaceService.getCustomers();

        this.setState({ customers: customers });
    }

    private onAccountNameChange(event: any): void {
        this.setState({ accountName: event.target.value }, () => this.validateForm());
    }

    private onCompanyNameChange(event: any): void {
        this.setState({ companyName: event.target.value }, () => this.validateForm());
    }

    private onFirstNameChange(event: any): void {
        this.setState({ firstName: event.target.value }, () => this.validateForm());
    }

    private onLastNameChange(event: any): void {
        this.setState({ lastName: event.target.value }, () => this.validateForm());
    }

    private getCustomersSelectValues(): IReactSelectValue[] {
        const customers = this.state.customers;

        const customerSelectOptions: IReactSelectValue[] = customers.map(c => ({
            label: c.name,
            value: c.id
        }));

        return customerSelectOptions;
    }

    public onCustomerSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): void {
        if (optionSelected === null || (optionSelected as IReactSelectValue[]).length === 0) {
            this.setState({
                selectedCustomers: []
            }, () => this.validateForm());
            return;
        }

        this.setState({
            selectedCustomers: (optionSelected as IReactSelectValue[])
        }, () => this.validateForm());

    }

    public handleSelectAllCustomers(event: any): void {
        this.setState({ allCustomersSelected: event.target.checked, selectedCustomers: [] }, () => this.validateForm());
    }

    public getRolesSelectValues(): IReactSelectValue[] {
        const rolesSelectValues =
            [{
                label: LanguageProvider.getTranslation(Mapper.pages.accounts.roles.facilitymanager.title) + " : "
                    + LanguageProvider.getTranslation(Mapper.pages.accounts.roles.facilitymanager.description),
                value: UserRoles.FacilityManager.valueOf()
            },
            {
                label: LanguageProvider.getTranslation(Mapper.pages.accounts.roles.besensemember.title) + " : "
                    + LanguageProvider.getTranslation(Mapper.pages.accounts.roles.besensemember.description),
                value: UserRoles.TeamMember.valueOf()
            },
            {
                label: LanguageProvider.getTranslation(Mapper.pages.accounts.roles.functionaladmin.title) + " : "
                    + LanguageProvider.getTranslation(Mapper.pages.accounts.roles.functionaladmin.description),
                value: UserRoles.FunctionalAdmin.valueOf()
            },
            {
                label: LanguageProvider.getTranslation(Mapper.pages.accounts.roles.besenseanalist.title) + " : "
                    + LanguageProvider.getTranslation(Mapper.pages.accounts.roles.besenseanalist.description),
                value: UserRoles.Analyst.valueOf()
            },
            {
                label: LanguageProvider.getTranslation(Mapper.pages.accounts.roles.customermanager.title) + " : "
                    + LanguageProvider.getTranslation(Mapper.pages.accounts.roles.customermanager.description),
                value: UserRoles.CustomerManager.valueOf()
            },
            {
                label: LanguageProvider.getTranslation(Mapper.pages.accounts.roles.workspacesadmin.title) + " : "
                    + LanguageProvider.getTranslation(Mapper.pages.accounts.roles.workspacesadmin.description),
                value: UserRoles.WorkspacesAdmin.valueOf()
            }];

        return rolesSelectValues;
    }

    public async onRolesSelectChange(optionSelected: IReactSelectValue | readonly IReactSelectValue[] | undefined | null): Promise<void> {

        if (optionSelected === null || (optionSelected as IReactSelectValue[]).length === 0) {
            this.setState({
                selectedRoles: []
            });
            return;
        }

        const selected = (optionSelected as IReactSelectValue[]).map(o => ({ label: o.value, value: o.value }));
        this.setState({
            selectedRoles: selected
        });
    }

    private validateForm(): void {
        const invalidAccountNameCharacters = "[\\s\\\\%&*+/=?{}|<>();:,@\\[\\]\"]";

        const accountNameContainsInvalidCharacters = this.state.accountName.match(invalidAccountNameCharacters) !== null;
        const isAccountNameValid = this.state.accountName !== ""
            && !accountNameContainsInvalidCharacters;
        const isFirstNameValid = this.state.firstName !== "";
        const isLastNameValid = this.state.lastName !== "";
        const isCompanyNameValid = this.state.companyName !== "";
        const isCustomersValid = this.state.allCustomersSelected || this.state.selectedCustomers.length > 0;

        const isFormValid = isAccountNameValid && isFirstNameValid && isLastNameValid && isCompanyNameValid && isCustomersValid;

        this.setState({
            isFormValid: isFormValid,
            accountnameContainsInvalidCharacters: accountNameContainsInvalidCharacters,
            isAccountNameValid: isAccountNameValid,
            isFirstNameValid: isFirstNameValid,
            isLastNameValid: isLastNameValid,
            isCompanyNameValid: isCompanyNameValid,
            isCustomersValid: isCustomersValid
        });
    }

    public async handleSubmit(): Promise<void> {
        if (this.state.selectedRoles.length === 0) {
            if (!window.confirm(LanguageProvider.getTranslation(Mapper.pages.accounts.norolesselected))) {
                return;
            }
        }

        const userCreate = this.getUserCreateModel();
        try {
            const [response, createdUser] = await this.accountService.createUserAccount(userCreate);

            if (response.ok) {
                this.setState({ password: createdUser.initialPassword, upn: createdUser.upn, showResult: true });
                return;
            }

            if (response.status === 400) {
                const reason = await response.text();

                if (reason.indexOf("User with same username already exists in AAD.") >= 0) {
                    NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.accounts.existingusererror));
                    return;
                }
            }

            NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.accounts.errormessage));
        } catch (error) {
            NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.accounts.errormessage));
        }
    }

    public getPasswordWarning(): string {
        const accountName = this.state.accountName;
        const warning = LanguageProvider.getTranslation(Mapper.pages.accounts.passwordwarningpart1) + accountName
            + LanguageProvider.getTranslation(Mapper.pages.accounts.passwordwarningpart2);

        return warning;
    }

    private getUserCreateModel(): UserCreate {

        const azureAdDetails: AzureAdDetails = {
            userName: this.state.accountName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            companyName: this.state.companyName
        };

        const roles = this.state.selectedRoles.map<UserRoles>(r => UserRoles[r.value]);
        const customers: Customer[] = [];
        this.state.selectedCustomers.forEach(sc => {
            const customer = this.state.customers.find(c => c.id === sc.value);
            if (customer) {
                customers.push(customer);
            }
        });

        const userCreate: UserCreate = {
            azureAdDetails: azureAdDetails,
            userCustomerAccess: customers,
            giveUserAccessToAllCustomers: this.state.allCustomersSelected,
            userRoles: roles
        };

        return userCreate;
    }

    public handleClear(): void {

        if (!window.confirm(LanguageProvider.getTranslation(Mapper.pages.accounts.newwarning))) {
            return;
        }

        this.setState({
            accountName: "", companyName: "", firstName: "",
            lastName: "", selectedCustomers: [], allCustomersSelected: false,
            selectedRoles: [], showResult: false, password: "", upn: "", isFormValid: false
        });
    }

    public render(): JSX.Element {
        return (
            <div className="add-accounts">
                <PageHeader pageName="accounts"/>
                <div className="row page">
                    <div className="col-sm col-md mr-5 mt-5 mb-5">
                        <h3 className="col-md-6 mb-3">{LanguageProvider.getTranslation(Mapper.pages.accounts.title)} </h3>
                        <div className="account-input-form col-md-6">
                            {!this.state.showResult && <React.Fragment>
                                <label>
                                    <span className="account-input-title">{LanguageProvider.getTranslation(Mapper.pages.accounts.accountname)}</span>
                                    <div className="input-group-sm">
                                        <input type="text"
                                            value={this.state.accountName || ''}
                                            onChange={this.onAccountNameChange}
                                            maxLength={64}
                                            className={`form-control ${this.state.isAccountNameValid ? "is-valid" : "is-invalid"}`}
                                        />
                                    </div>
                                    {this.state.accountnameContainsInvalidCharacters && <div className="invalid-account-characters">{LanguageProvider.getTranslation(Mapper.pages.accounts.accountnameexplanation)}</div>}
                                </label>
                                <label>
                                    <span className="account-input-title">{LanguageProvider.getTranslation(Mapper.pages.accounts.companyname)}</span>
                                    <div className="input-group-sm">
                                        <input
                                            value={this.state.companyName}
                                            onChange={this.onCompanyNameChange}
                                            maxLength={64}
                                            className={`form-control ${this.state.isCompanyNameValid ? "is-valid" : "is-invalid"}`}
                                        />
                                    </div>
                                </label>
                                <label>
                                    <span className="account-input-title">{LanguageProvider.getTranslation(Mapper.pages.accounts.firstname)}</span>
                                    <div className="input-group-sm">
                                        <input type="text"
                                            value={this.state.firstName || ''}
                                            onChange={this.onFirstNameChange}
                                            maxLength={64}
                                            className={`form-control ${this.state.isFirstNameValid ? "is-valid" : "is-invalid"}`}
                                        />
                                    </div>
                                </label>
                                <label>
                                    <span className="account-input-title">{LanguageProvider.getTranslation(Mapper.pages.accounts.lastname)}</span>
                                    <div className="input-group-sm">
                                        <input type="text"
                                            value={this.state.lastName || ''}
                                            onChange={this.onLastNameChange}
                                            maxLength={64}
                                            className={`form-control ${this.state.isLastNameValid ? "is-valid" : "is-invalid"}`}
                                        />
                                    </div>
                                </label>

                                <label>
                                    <span className="account-input-title">{LanguageProvider.getTranslation(Mapper.pages.accounts.customers)}</span>
                                    <Select
                                        options={this.getCustomersSelectValues()}
                                        onChange={this.onCustomerSelectChange}
                                        placeholder={LanguageProvider.getTranslation(Mapper.pages.accounts.selectcustomers)}
                                        value={this.state.selectedCustomers}
                                        isClearable={true}
                                        isMulti={true}
                                        isDisabled={this.state.allCustomersSelected}
                                        classNamePrefix={this.state.isCustomersValid ? "account-customers-valid" : "account-customers-invalid"}
                                    />
                                    <label className="account-input-allcustomers-label clickable">
                                        <span className="account-input-allcustomers">{LanguageProvider.getTranslation(Mapper.pages.accounts.allcustomers)}</span>
                                        <input
                                            name="allCustomers"
                                            type="checkbox"
                                            className="account-input-allcustomers-checkbox form-check-input"
                                            onChange={this.handleSelectAllCustomers}
                                        />
                                    </label>
                                </label>
                                <label>
                                    <span className="account-input-title">{LanguageProvider.getTranslation(Mapper.pages.accounts.rolessection)}</span>
                                    <Select
                                        options={this.getRolesSelectValues()}
                                        onChange={this.onRolesSelectChange}
                                        placeholder={LanguageProvider.getTranslation(Mapper.pages.accounts.selectroles)}
                                        value={this.state.selectedRoles}
                                        isClearable={true}
                                        isMulti={true}
                                    />
                                </label>
                                <button className="btn btn-primary"
                                    disabled={!this.state.isFormValid}
                                    onClick={this.handleSubmit}>
                                    {LanguageProvider.getTranslation(Mapper.buttons.submit)}
                                </button>
                            </React.Fragment>}

                            {this.state.showResult && <div>
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.upn || ""}
                                        contentEditable={false}
                                        readOnly={true}
                                        placeholder={LanguageProvider.getTranslation(Mapper.pages.accounts.upn)} />
                                    <div className="input-group-append">
                                        <span
                                            onClick={(): void => { navigator.clipboard.writeText(this.state.upn); }}
                                            className="input-group-text clickable" id="basic-addon2">Copy</span>
                                    </div>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                                <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                                <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.password || ""}
                                        contentEditable={false}
                                        readOnly={true}
                                        placeholder={LanguageProvider.getTranslation(Mapper.pages.accounts.password)} />
                                    <div className="input-group-append">
                                        <span
                                            onClick={(): void => { navigator.clipboard.writeText(this.state.password); }}
                                            className="input-group-text clickable" id="basic-addon2">Copy</span>
                                    </div>
                                </div>
                                <div className="alert alert-warning">
                                    {this.getPasswordWarning()}
                                </div>
                                <button className="btn btn-primary"
                                    onClick={this.handleClear}>
                                    {LanguageProvider.getTranslation(Mapper.pages.accounts.new)}
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(AddAccountPage);