import * as React from 'react';

import { withLocalize } from 'react-localize-redux';
import ReactTable, { Column } from "react-table";

import IDevicesMetadataPageProps from "./interfaces/IDevicesMetadataPageProps";
import IDevicesMetadataPageState from "./interfaces/IDevicesMetadataPageState";
import translations from '../../translations/mapper';

import "./devicesMetadataPage.scss";
import DeviceService from '../../services/deviceService';
import LanguageProvider from '../../providers/languageProvider';
import { ReactTableUtils } from '../../utils/reactTableUtils';
import IMetadata from '../../interfaces/IMetadata';
import CenteredPageLoader from '../../components/loaders/centeredPageLoader';
import moment from 'moment';
import TimeZoneUtils from '../../utils/timeZoneUtils';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import CustomPagination from '../../components/pagination/customPagination';
import DocumentUtils from '../../utils/documentUtils';

class DevicesMetadataPage extends React.Component<IDevicesMetadataPageProps, IDevicesMetadataPageState>{
    private deviceService: DeviceService;
    private reactTable: ReactTable<IMetadata> | null;

    public constructor(props: IDevicesMetadataPageProps) {
        super(props);

        this.deviceService = new DeviceService();

        this.updateColumns = this.updateColumns.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
        this.updateNumberOfRows = this.updateNumberOfRows.bind(this);
        this.getStatusTranslation = this.getStatusTranslation.bind(this);

        this.state = {
            metadata: [],
            loading: true,
            columns: this.getColumns(),
            numberOfRows: 20
        };

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
    }

    public async componentDidMount(): Promise<void> {
        window.addEventListener('resize', this.updateNumberOfRows);
        this.updateNumberOfRows();
        const metadata = await this.deviceService.getMetadataForAllDevices(true);

        this.setState({
            metadata: metadata,
            loading: false
        });
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateNumberOfRows);
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
    }

    private updateColumns(): void {
        const columns = this.getColumns();
        this.setState({
            columns: columns
        });
    }

    private downloadCsv(): void {
        const csvContent = this.generateCsvContent();
        DocumentUtils.downloadCsv(csvContent, `metadata_${moment().format('DD-MM-YYYY')}.csv`);
    }

    private generateCsvContent(): string {
        let csvContent = this.generateCsvHeader();

        // Get the data valid for current filters by looking at the state of the React Table component using ref set on component.
        const filteredMetadata = this.reactTable !== null ?
            (this.reactTable.state as any).sortedData.map(r => r._original) as IMetadata[] :
            this.state.metadata;

        for (const metadata of filteredMetadata) {
            const row = this.transformMetadataIntoCsvRow(metadata);
            csvContent += "\r\n" + row;
        }

        return csvContent;
    }

    private generateCsvHeader(): string {
        return "Device ID, Customer, Venue, Floor Name, Floor Level, Space, Battery Level, Firmware Version, Device Type, Last Data Message, Last Keepalive, Keep-alive > 25h, Device Status, Last Status Update";
    }

    private transformMetadataIntoCsvRow(metadata: IMetadata): string {
        const fields: string[] = [];
        fields.push(this.makeSafeForCsv(metadata.deviceId));
        fields.push(this.makeSafeForCsv(metadata.customerName));
        fields.push(this.makeSafeForCsv(metadata.venueName !== undefined ? metadata.venueName : "-"));
        fields.push(this.makeSafeForCsv(metadata.floorName !== undefined && metadata.floorName !== "" ? metadata.floorName : "-"));
        fields.push(this.makeSafeForCsv(metadata.floorLevel !== undefined && metadata.floorLevel !== "" ? metadata.floorLevel : "-"));
        fields.push(this.makeSafeForCsv(metadata.spaceName !== undefined ? metadata.spaceName : "-"));
        fields.push(this.makeSafeForCsv(metadata.batteryLevel !== undefined ? this.getRoundedBatteryLevel(metadata.batteryLevel) : "-"));
        fields.push(this.makeSafeForCsv(metadata.deviceFirmwareVersion !== undefined ? metadata.deviceFirmwareVersion : "-"));
        fields.push(this.makeSafeForCsv(metadata.adtDeviceType));
        fields.push(this.makeSafeForCsv(metadata.lastDataTimestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(metadata.lastDataTimestamp).format('YYYY-MM-DD HH:mm') : "-"));
        fields.push(this.makeSafeForCsv(metadata.lastKeepAliveTimestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(metadata.lastKeepAliveTimestamp).format('YYYY-MM-DD HH:mm') : "-"));
        fields.push(this.makeSafeForCsv(moment(metadata.lastKeepAliveTimestamp) > moment().add(-25, 'hours') ? "No" : "Yes"));
        fields.push(this.makeSafeForCsv(metadata.deviceStatus));
        fields.push(this.makeSafeForCsv(metadata.lastStatusUpdate ? TimeZoneUtils.ConvertUtcDateToWestEurope(metadata.lastStatusUpdate).format('YYYY-MM-DD HH:mm') : "-"));

        return fields.join(',');
    }

    private makeSafeForCsv(input: string): string {
        return input.replace(',', '.').replace('\n', '');
    }

    private getColumns(): Column[] {
        const columns = [
            {
                id: 'device',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.deviceid),
                accessor: "deviceId",
                filterable: true
            },
            {
                id: 'customer',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.customername),
                accessor: "customerName",
                filterable: true
            },
            {
                id: 'venue',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.venuename),
                accessor: (metadata: IMetadata): string => metadata.venueName !== undefined ? metadata.venueName : "-",
                filterable: true
            },
            {
                id: 'floorname',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.floorname),
                accessor: (metadata: IMetadata): string => metadata.floorName !== undefined && metadata.floorName !== "" ? metadata.floorName : "-",
                filterable: true
            },
            {
                id: 'floorlevel',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.floorlevel),
                accessor: (metadata: IMetadata): string => metadata.floorLevel !== undefined && metadata.floorLevel !== "" ? metadata.floorLevel : "-",
                filterable: true
            },
            {
                id: 'space',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.spacename),
                accessor: (metadata: IMetadata): string => metadata.spaceName !== undefined ? metadata.spaceName : "-",
                filterable: true
            },
            {
                id: 'battery',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.batterylevel),
                accessor: (metadata: IMetadata): string => metadata.batteryLevel !== undefined ? this.getRoundedBatteryLevel(metadata.batteryLevel) : "-",
                filterable: true,
                sortMethod: (left: string, right: string): number => {
                    const leftNumber = Number.parseFloat(left);
                    const rightNumber = Number.parseFloat(right);
                    if(isNaN(leftNumber)){
                        return -1;
                    }
                    if(isNaN(rightNumber)){
                        return 1;
                    }

                    return leftNumber - rightNumber;
                }
            },
            {
                id: 'devicefirmwareversion',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.devicefirmwareversion),
                accessor: (metadata: IMetadata): string => metadata.deviceFirmwareVersion ?? "-",
                filterable: true,
                sortMethod: (left: string, right: string): number => {
                    const leftNumber = Number.parseFloat(left);
                    const rightNumber = Number.parseFloat(right);
                    if(isNaN(leftNumber)){
                        return -1;
                    }
                    if(isNaN(rightNumber)){
                        return 1;
                    }

                    return leftNumber - rightNumber;
                }
            },
            {
                id: 'adtdevicetype',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.adtdevicetype),
                accessor: "adtDeviceType",
                filterable: true
            },
            {
                id: 'lastdatamessage',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.lastdatamessage),
                accessor: (metadata: IMetadata): string =>
                    metadata.lastDataTimestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(metadata.lastDataTimestamp).format('YYYY-MM-DD, HH:mm') : "-",
                filterable: true
            },
            {
                id: 'lastkeepalive',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.lastkeepalive),
                accessor: (metadata: IMetadata): string =>
                    metadata.lastKeepAliveTimestamp ? TimeZoneUtils.ConvertUtcDateToWestEurope(metadata.lastKeepAliveTimestamp).format('YYYY-MM-DD, HH:mm') : "-",
                filterable: true
            },
            {
                id: 'expiredkeepalive',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.expiredkeepalive),
                accessor: (metadata: IMetadata): string => moment(metadata.lastKeepAliveTimestamp) > moment().add(-25, 'hours')
                    ? LanguageProvider.getTranslation(translations.pages.devices.metadata.hasnotexpired)
                    : LanguageProvider.getTranslation(translations.pages.devices.metadata.hasexpired),
                filterable: true
            },
            {
                id: 'devicestatus',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.devicestatus),
                accessor: (metadata: IMetadata): string => this.getStatusTranslation(metadata.deviceStatus),
                filterable: true
            },
            {
                id: 'laststatusupdate',
                Header: LanguageProvider.getTranslation(translations.pages.devices.metadata.columns.laststatusupdate),
                accessor: (metadata: IMetadata): string =>
                    metadata.lastStatusUpdate ? TimeZoneUtils.ConvertUtcDateToWestEurope(metadata.lastStatusUpdate).format('YYYY-MM-DD, HH:mm') : "-",
                filterable: true
            }
        ];

        return columns;
    }

    private getRoundedBatteryLevel(batteryLevel: number): string {
        return (Math.round(batteryLevel * 1000) / 1000).toString();
    }

    private getStatusTranslation(statusString: string): string {
        const translationAccessor = statusString.toLowerCase();
        const translationIndex = translations.pages.devices.metadata.devicestatus[translationAccessor];
        if(translationIndex !== undefined){
            return LanguageProvider.getTranslation(translationIndex);
        }
        else{
            return LanguageProvider.getTranslation(translations.pages.devices.metadata.devicestatus.unknown);
        }
    }

    private updateNumberOfRows(): void {
        const windowInnerHeight = window.innerHeight;
        const tableSize = windowInnerHeight - 260;
        const rowHeight = 39;
        const numberOfRowsFitting = Math.floor(tableSize / rowHeight);

        // Round to a number of rows that is divisible by 5:
        const numberOfRowsDesired = Math.max(5, 5 * Math.floor(numberOfRowsFitting / 5));

        if (numberOfRowsDesired !== this.state.numberOfRows) {
            this.setState({
                numberOfRows: numberOfRowsDesired
            });
        }
    }

    public render(): JSX.Element {
        return (
            <div className="metadata">
                <div className="metadata-header">
                    <h3 className="metadata-title">{LanguageProvider.getTranslation(translations.pages.devices.metadata.title)}</h3>
                    {!this.state.loading &&
                        <button className="metadata-export-button btn btn-primary" onClick={this.downloadCsv}>{LanguageProvider.getTranslation(translations.pages.devices.metadata.export)}</button>}
                </div>
                {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                {!this.state.loading &&
                    <div className="metadata-body">
                        <ReactTable
                            className="metadata-table"
                            columns={this.state.columns}
                            loading={this.state.loading}
                            showPaginationBottom={true}
                            showPaginationTop={false}
                            defaultPageSize={this.state.numberOfRows}
                            pageSize={this.state.numberOfRows}
                            data={this.state.metadata}
                            PaginationComponent={CustomPagination}
                            minRows={0}
                            defaultSorted={[{
                                id: 'device',
                                desc: false
                            }]}
                            defaultFilterMethod={ReactTableUtils.useContainsFilter}
                            ref={(r: ReactTable<IMetadata> | null): void => { this.reactTable = r; }}
                        />
                    </div>}
            </div>
        );
    }
}

export default withLocalize(DevicesMetadataPage);