export const translations = {
    "buttons": {
        "logout": "Uitloggen",
        "dropdowns": {
            "venue": "Selecteer een gebouw",
            "company": "Selecteer een bedrijf",
            "floor": "Verdieping",
            "asset": "Asset",
            "customer": "Selecteer een klant",
            "documenttype": "Selecteer een document type",
            "nooptions": "Geen opties beschikbaar"
        },
        "cancel": "Annuleer",
        "submit": "Indienen",
        "save": "Opslaan",
    },
    "pagination": {
        "showing": "Toont",
        "to": "tot",
        "of": "van",
        "items": "items"
    },
    "datepicker": {
        "startdate": "Van",
        "enddate": "Tot en met",
        "today": "Vandaag",
        "time": "Tijd",
    },
    "dates": {
        "day": "Dag",
        "week": "Week",
        "month": "Maand",
        "year": "Jaar",
        "january": "Januari",
        "february": "Februari",
        "march": "Maart",
        "april": "April",
        "may": "Mei",
        "june": "Juni",
        "july": "Juli",
        "august": "Augustus",
        "september": "September",
        "october": "Oktober",
        "november": "November",
        "december": "December",
        "shorthand": {
            "monday": "Ma",
            "tuesday": "Di",
            "wednesday": "Wo",
            "thursday": "Do",
            "friday": "Vr",
            "saturday": "Za",
            "sunday": "Zo",
            "january": "Jan",
            "february": "Feb",
            "march": "Mar",
            "april": "Apr",
            "may": "Mei",
            "june": "Jun",
            "july": "Jul",
            "august": "Aug",
            "september": "Sept",
            "october": "Okt",
            "november": "Nov",
            "december": "Dec"
        }
    },
    "navigation": {
        "home": "Home",
        "map": "Plattegrond",
        "insightasset": "Inzicht asset",
        "upload": "Document management",
        "adminmessagecenter": "Admin berichten",
        "devices": "Devices",
        "accounts": "Accounts",
        "addaccount": "Account toevoegen",
        "rights": "Rechten",
        "userrights": "Gebruikers",
        "grouprights": "Groepen",
        "domainrights": "Domeinen",
        "autodesk": "Autodesk upload",
        "applications": "Applicaties",
    },
    "comfort": {
        "temperature": "Temperatuur",
        "carbondioxide": "CO2",
        "humidity": "Luchtvochtigheid",
        "sound": "Geluid",
        "light": "Licht",
        "empty": "Selecteer type"
    },
    "pages": {
        "home": {
            "title": "Home",
            "all": "Alles",
            "total": {
                "title": "Totale bezetting"
            },
            "actual": {
                "building": "Gebouw",
                "status": "Status bezetting",
                "title": "Actuele bezetting"
            },
            "latest": {
                "title": "Laatste meldingen",
                "datetime": "Datum/Tijd",
                "category": "Categorie",
                "building": "Gebouw",
                "asset": "Asset",
                "description": "Omschrijving",
                "status": "Status"
            },
            "graph": {
                "total": "Totaal",
                "currentweek": "Huidige week"
            },
            "selectcustomer": "Selecteer een klant"
        },
        "map": {
            "title": "Plattegrond",
            "noapikey": "Uw account is niet goed geconfigureerd. De map API key ontbreekt. Neem contact op met Beyond Eyes om dit op te lossen",
            "norawdatafound": "Geen data gevonden",
            "noassetsselected": "Geen asset geselecteerd",
            "adddevice": {
                "title": "Voeg een device toe",
                "close": "Sluiten",
                "name": "Naam",
                "add": "Toevoegen",
                "connected": "Gekoppelde devices",
                "newid": "Te koppelen ID",
                "devicenotfound": "Device niet gevonden",
                "invalidid": "Incorrect Hardware ID ingevoerd",
                "notinsupply": "Device hangt niet onder voorraad",
                "devicefoundinspace": "Device gevonden onder asset",
                "andcustomer": "en klant",
                "deviceconnected": "Device verbonden aan asset",
                "assetoccupiedconfirmation": "Weet u zeker dat u het device naar deze asset wilt verplaatsen?",
                "missingrights": "Geen rechten om devices te verplaatsen",
                "genericerror": "Fout bij het verplaatsen van het device",
                "stock": "Voorraad",
                "malfunctioning": "Defect",
                "lost": "Vermist",
                "movetostock": "Weet u zeker dat u het device naar de voorraad wil verplaatsen?",
                "movetomalfunction": "Weet u zeker dat u het device als defect wil opgeven?",
                "movetolost": "Weet u zeker dat u het device als vermist wil opgeven?",
                "edit": "Wijzig naam",
                "namechangedsuccess": "Naam successvol gewijzigd",
                "namechangederror": "Er is iets fout gegaan bij het toewijzen van de naam"
            },
            "assetinsights": {
                "datetime": "Datum/Tijd",
                "occupancy": "Vrij/Bezet",
                "peoplecount": "Aantal personen",
                "temperature": "Temperatuur",
                "humidity": "Luchtvochtigheid",
                "carbondioxide": "CO2",
                "values": {
                    "occupied": "Bezet",
                    "unoccupied": "Vrij"
                }
            },
            "search": {
                "text": "Zoek op assetnaam, asset ID of device ID",
                "notfound": "Er zijn geen resultaten gevonden."
            }
        },
        "devices": {
            "metadata": {
                "title": "Metadata",
                "export": "Exporteren",
                "columns": {
                    "customername": "Klant",
                    "venuename": "Gebouw",
                    "floorname": "Verdiepingsnaam",
                    "spacename": "Asset",
                    "floorlevel": "Verdiepingsnummer",
                    "deviceid": "Device ID",
                    "batterylevel": "Batterij niveau",
                    "devicefirmwareversion": "Firmware versie",
                    "adtdevicetype": "Device type (ADT)",
                    "lastkeepalive": "Laatste keep-alive",
                    "lastdatamessage": "Laatste data bericht",
                    "expiredkeepalive": "Keep-alive >25u",
                    "devicestatus": "Device status",
                    "laststatusupdate": "Laatste status update"
                },
                "devicestatus": {
                    "unknown": "Onbekend",
                    "notyetregistered": "Nog niet gemeld",
                    "registered": "Geregistreerd",
                    "downlinkfailed": "Downlink mislukt",
                    "downlinksend": "Downlink verstuurd",
                    "wrongconfiguration": "Foutieve configuratie",
                    "deleted": "Verwijderd",
                    "downlinkexpired": "Downlink verlopen"
                },
                "hasexpired": "Ja",
                "hasnotexpired": "Nee"
            }
        },
        "upload": {
            "title": "Document Management",
            "forbiddendocument": "U heeft geen toegang tot de klant van dit document voor het downloaden",
            "upload": "Upload",
            "customerreport": "Upload een energie rapport bestand naar een klant",
            "venuereport": "Upload een energie rapport bestand naar een venue",
            "nofileerror": "Geen bestand geselecteerd",
            "fileuploaded": "Bestand succesvol geupload",
            "erroronupload": "Fout bij het uploaden: ",
            "nodocumenttype": "Document type is verplicht",
            "error400onupload": "Het verzoek dat je probeert te doen is niet gelukt. Weet je zeker dat er niet al een bestand met dezelfde naam bestaat?",
            "columns": {
                "documenttype": "Document type",
                "customer": "Klant",
                "venue": "Gebouw",
                "filename": "Bestandsnaam",
                "uploadedon": "Geupload op",
                "downloadedon": "Gedownload op"
            },
            "requiredfield": "Verplicht veld",
            "deleteareyousure": "Weet u zeker dat u het bestand wil verwijderen?"
        },
        "deviceconfiguration": {
            "title": "Device configuratie"
        },
        "applications": {
            "applicationsheader": "Applicaties",
            "newmessageheader": "Nieuwe link",
            "editmessageheader": "Pas link aan",
            "messagecreated": "Link is succesvol aangemaakt. \n Het kan even duren voordat deze zichtbaar wordt door het opslaan en verwerken in Azure",
            "url": "URL",
            "customers": "Klanten",
            "errormessage": "Er is iets mis gegaan bij het ophalen van de gebouwen/links",
            "errormessagecreate": "Er is iets mis gegaan bij het aanmaken van de link",
            "errormessageupdate": "Er is iets mis gegaan bij het updaten van de link",
            "deleteconsentmessage": "Weet u zeker dat u deze link wilt verwijderen?",
            "deletemessageerror": "Link kon niet worden verwijderd, rowKey ontbreekt",
            "deletemessagesucces": "Link is verwijderd",
            "selectabuilding": "Selecteer een gebouw/link",
            "validationerror": "U heeft niet alle benodigde gegevens ingevoerd. \n Selecteer minimaal 1 klant",
            "succesfullyupdated": "Link is succesvol aangepast. \n Het kan even duren voordat deze zichtbaar wordt door het opslaan en verwerken in Azure.",
            "true": "Ja",
            "false": "Nee",
            "building": "Gebouw",
            "link": "Link",
        },
        "adminmessagecenter": {
            "adminmessagecenterheader": "Admin berichten",
            "newmessageheader": "Nieuw admin bericht",
            "editmessageheader": "Pas bericht aan",
            "startdate": "Ingangsdatum",
            "enddate": "Einddatum",
            "message": "Bericht",
            "title": "Onderwerp",
            "visibleforallcustomers": "Tonen bij alle klanten",
            "customers": "Klanten",
            "priority": "Prioriteit",
            "messagecreated": "Bericht is succesvol aangemaakt",
            "errormessage": "Er is iets mis gegaan bij het ophalen van de berichten",
            "errormessagecreate": "Er is iets mis gegaan bij het aanmaken van het bericht",
            "errormessageupdate": "Er is iets mis gegaan bij het updaten van het bericht",
            "succesfullyupdated": "Bericht is succesvol aangepast",
            "deleteconsentmessage": "Weet u zeker dat u dit bericht wilt verwijderen?",
            "deletemessageerror": "Bericht kon niet worden verwijderd, rowKey ontbreekt",
            "deletemessagesucces": "Bericht is verwijderd",
            "selectamessage": "Selecteer een bericht",
            "validationerror": "U heeft niet alle benodigde gegevens ingevoerd. \n- Selecteer minimaal 1 klant, of vink de optie \"Tonen bij alle klanten\" aan. \n- Voer een titel of beschrijving in",
            "messagepriority": {
                "high": "Hoog",
                "medium": "Gemiddeld",
                "low": "Laag",
            },
            "true": "Ja",
            "false": "Nee",
        },
        "autodesk": {
            "title": "Autodesk",
            "mapupdatesucceeded": "Nieuwe kaart is succesvol gepubliceerd",
            "mapupdatefailed": "De nieuwe kaart is niet gepubliceerd",
            "assetupdatesucceeded": "Assets worden met succes bijgewerkt",
            "assetupdatefailed": "Mislukt om assets bij te werken",
            "translationinprogress": "Fout opgetreden {0} bij het ophalen van kaartgegevens, er wordt een nieuwe poging gedaan {1} van {2}. Even geduld a.u.b.",
            "maploadfailed": "Het laden van de kaart is mislukt. Probeer het later nog eens.",
            "publish": "Publiceren",
            "publishandupsertassets": "Publiceren en bijwerken Dynamics ",
            "preview": "Voorbeeld (even wachten na uploaden,ca 60sec.)",
            "upload": "Uploaden",
            "uploadinfo": "Selecteer een gebouw om te zien welke voorbeelden er het laatste zijn geupload.<br/><br/>Selecteer ook een verdieping en kies een bestand om een nieuw voorbeeld klaar te zetten om te bekijken.<br/><br/>Het bestand moet in zip-formaat zijn.",
            "previewinfo": "Per verdieping kan er worden gepubliceerd.<br/><br/>Schakel naar de juiste verdieping om te zien of er een nieuw voorbeeld klaar staat en om deze te publiceren.<br/><br/>Publiceer-knop is alleen beschikbaar als er een ander voorbeeld staat dan de huidige kaart in de applicaties wordt gebruikt.<br/><br/>Kan er niet gepubliceerd worden, ga dan terug en upload een nieuw bestand voor de gewenste verdieping.",
            "invalidfile": "Alleen .zip of .dwg bestanden worden aanvaard",
            "nodwgpresent": "Provided zip file does not contain a .dwg file",
            "back": "Terug",
            "publishconfirm": "Weet je zeker dat je deze kaart wilt publiceren?",
            "publishandupsertassetsconfirm": "Weet u zeker dat u Dynamics wilt publiceren en bijwerken? \nDe update van Dynamics duurt even (tussen 5 en 60 seconden). \nKlik op de verversingsknop om de status van de nieuwe assets te zien.",
            "upsertassetsconfirm": "Weet u zeker dat u Dynamics bijwerken? \nDe update van Dynamics duurt even (tussen 5 en 60 seconden). \nKlik op de verversingsknop om de status van de nieuwe assets te zien.",
            "addedassets": "Assets die worden toegevoegd of geheractiveerd",
            "deactivatedassets": "Assets die worden gedeactiveerd",
            "existingdassets": "Bestaande assets",
            "invalidguidassets": "Assets met ongeldige GUID",
            "invalidpositionassets": "Assets met ongeldige positie",
            "refreshassets": "Verversen",
            "upsertassets": "Bijwerken Dynamics",
            "nopreviewavailable": "Er is geen preview beschikbaar voor dit gebouw.",
            "loadingfailed": "Het laden is mislukt.",
            "selectall": "Alles selecteren"
        },
        "accounts": {
            "title": "Account toevoegen",
            "accountname": "Accountnaam",
            "companyname": "Bedrijfsnaam",
            "firstname": "Voornaam",
            "lastname": "Achternaam",
            "fullname": "Naam",
            "reset": "Wachtwoord Reset",
            "delete": "Verwijderen",
            "customers": "Klanten",
            "rolessection": "Rollen (optioneel)",
            "selectcustomers": "Selecteer klanten",
            "allcustomers": "Alle klanten",
            "selectroles": "Selecteer rollen",
            "accountnameexplanation": "De volgende karakters zijn niet toegestaan in de accountnaam: \\%&*+/=?{}|<>();:,@[]\" \nWitruimte is ook niet toegestaan.",
            "roles": {
                "facilitymanager": {
                    "title": "Facility manager",
                    "description": "toegang tot de Dashboard, Workspaces en Reservations applicaties"
                },
                "besensemember": {
                    "title": "BeyondEyes teamlid",
                    "description": "voor interne BeyondEyes teamleden"
                },
                "functionaladmin": {
                    "title": "Functioneel beheerder",
                    "description": "voor interne BeyondEyes functioneel beheerders"
                },
                "besenseanalist": {
                    "title": "BeyondEyes analist",
                    "description": "voor interne BeyondEyes data analisten"
                },
                "customermanager": {
                    "title": "Klantbeheerder",
                    "description": "voor interne BeyondEyes klantbeheerders"
                },
                "cleaningadmin": {
                    "title": "Schoonmaakbeheerder",
                    "description": "toegang tot de BeClean management applicatie"
                },
                "cleaner": {
                    "title": "Schoonmaker",
                    "description": "toegang tot de BeClean applicatie"
                },
                "cleaningcustomeremployee": {
                    "title": "BE Clean klantmedewerker",
                    "description": "rol om klant toegang te geven tot bepaalde delen van BeClean Management"
                },
                "workspacesadmin": {
                    "title": "Workspaces admin",
                    "description": "rol om klant toegang te geven om Personal Access Tokens (PAT) aan te vragen voor Workspaces"
                }
            },
            "norolesselected": "Weet u zeker dat u geen rol wilt toekennen aan dit account?\nDit betekent dat dit account geen toegang heeft tot Dashboard, wel tot Workspaces en Reservations (wanneer de klant deze dienst heeft).",
            "upn": "Gebruikersnaam",
            "password": "Wachtwoord",
            "passwordwarningpart1": "Let op. Dit is het wachtwoord dat hoort bij account ",
            "passwordwarningpart2": ". Bij verlaten van de pagina kunt u dit niet terughalen. Dus sla dit zelf op.",
            "existingusererror": "Er bestaat al een gebruiker met die gebruikersnaam in AAD!",
            "errormessage": "Er is iets mis gegaan bij het aanmaken van het account",
            "new": "Nieuw account aanmaken",
            "newwarning": "Pas op, als u doorgaat verdwijnt het wachtwoord. Zorg dat u deze heeft opgeslagen.",
            "resetpopup": {
                "title": "Wachtwoord resetten",
                "resetquestion": "Weet u zeker dat u het wachtwoord voor account '{0}' wil resetten?",
                "cancel": "Annuleren",
            },
            "deletepopup": {
                "title": "Account verwijderen",
                "deletequestion": "Weet u zeker dat u account '{0}' wil verwijderen?",
                "cancel": "Annuleren",
            },
            "cantreset": {
                "title": "Password reset niet gelukt",
                "rolestext": "U kunt het wachtwoord van admins, developers, functioneel beheerders of testers niet resetten. Neem contact op met het BE platform team.",
                "text": "Resetten van wachtwoord is mislukt. Neem contact op met het BE platform team",
                "close": "Sluiten"
            },
            "newpassword": {
                "title": "Nieuw wachtwoord",
                "close": "Sluiten"
            },
            "cantdelete": {
                "title": "Verwijderen niet gelukt",
                "rolestext": "U kunt admins, developers, beheerders of testers niet verwijderen. Neem contact op met het BE platform team",
                "text": "Verwijderen van de gebruiker is mislukt.  Neem contact op met het BE platform team",
                "close": "Sluiten"
            }
        },
        "rights": {
            "usercreate": "Nieuwe gebruiker toegang",
            "useredit": "Gebruiker aanpassen",
            "groupcreate": "Nieuwe groep toegang",
            "groupedit": "Group aanpassen",
            "domaincreate": "Nieuwe domain toegang",
            "domainedit": "Domein aanpassen",
            "save": "Opslaan",
            "createsuccess": "De toegang is toegekend",
            "createerror": "Er is iets misgegaan bij het aanmaken van de toegang!",
            "updatesuccess": "Het aanpassen is gelukt",
            "updateerror": "Er is iets misgegaan bij het aanpassen!",
            "roleassignmenterror": "Er is iets misgegaan met het aanpassen van de rollen",
            "roleassignmensuccess": "Toevoegen van rollen is gelukt",
            "deletesuccess": "Het verwijderen is gelukt",
            "deleteerror": "Er is iets misgegaan bij het verwijderen!",
            "deleteconfirm": "Weet u zeker dat u de geselecteerde toegang regel(s) wil verwijderen?",
            "invalidform": "Niet alle velden zijn geldig ingevuld (let op dat er minstens 1 klant (of alle klanten) geselecteerd moet zijn).",
            "invalidformpartners": "Niet alle velden zijn geldig ingevuld (let op dat er minstens 1 partner geselecteerd moet zijn).",
            "customeraccess": "Klant toegang",
            "roles": "Rollen",
            "partners": "Partners",
            "multiplecustomerserror": "Er is iets misgegaan bij het aanmaken van de toegang, let op dat de toegang op sommige klanten wel al aangemaakt kan zijn!",
            "customers": "Klanten",
            "columns": {
                "userobjectid": "Gebruiker object id",
                "username": "Gebruikersnaam",
                "customername": "Klant naam",
                "lastmodified": "Laatst aangepast",
                "groupobjectid": "Groep object id",
                "domain": "Domein",
                "groupname": "Groep naam",
                "roles": "Rollen",
                "partner": "Partner"
            },
            "values": {
                "allcustomers": "Alle klanten",
                "unknowncustomer": "Onbekende klant"
            },
            "roledescriptions": {
                "facilitymanager": "hiermee krijgt het AD level oa toegang tot Dashboard",
                "cleaningadmin": "hiermee krijgt het AD level toegang tot Cleaning Management",
                "workspacesadmin": "hiermee krijgt het AD level toegang tot PAT binnen Workspaces"
            },
            "rolenames": {
                "facilitymanager": "Facilitair manager",
                "teammember": "BE teamlid",
                "functionaladmin": "Functioneel beheerder",
                "analyst": "Analyst",
                "cleaningadmin": "Schoonmaak beheerder",
                "cleaner": "Schoonmaker",
                "cleaningcustomeremployee": "Schoonmaak klant medewerker",
                "administrator": "Administrator",
                "cleaningdistrictmanager": "Schoonmaak district beheerder",
                "tester": "Tester",
                "cleaningobjectleader": "Schoonmaak object leider",
                "developer": "Developer",
                "customermanager": "Klantbeheerder",
                "cleaningrayonmanager": "Schoonmaak rayon beheerder",
                "application": "Applicatie",
                "workspacesadmin": "Workspaces admin"
            }
        }
    },
    "userSettingsMenu": {
        "logout": "Uitloggen",
        "language": "Taal",
        "feedback": "Feedback",
        "help": "Help",
        "settings": "Instellingen"
    },
    "notifications": {
        "invalidfloorspaces": "Er zijn geen ruimtes gevonden voor deze verdieping."
    },
    "mappopup": {
        "loadingtext": "Bezig met het ophalen van de gegevens...",
        "sensor": {
            "occupancystatus": {
                "title": "Ruimte vrij",
                "unoccupied": "Ja",
                "occupied": "Nee",
                "coolingdown": "Nee",
                "symbol": ""
            },
            "motion": {
                "title": "Beweging",
                "false": "Nee",
                "true": "Ja",
                "symbol": ""
            },
            "humidity": {
                "title": "Luchtvochtigheid",
                "symbol": "%"
            },
            "sound": {
                "title": "Geluid",
                "symbol": "niveau"
            },
            "light": {
                "title": "Licht",
                "symbol": "lux"
            },
            "temperature": {
                "title": "Temperatuur",
                "symbol": "&deg;"
            },
            "carbondioxide": {
                "title": "Carbon Dioxide",
                "symbol": "ppm"
            },
            "count": {
                "title": "Aantal",
                "symbol": "people"
            }
        },
        "buttons": {
            "insights": "Inzicht asset"
        }
    }
};

export default translations;