import * as React from "react";
import { useEffect, useState } from "react";
import CoreSpaceService from "../../../../services/coreSpaceService";
import CoreSpaceIncludes from "../../../../enums/coreSpaceIncludes";
import CoreSpace from "../../../../models/coreSpace";
import { AssetList } from "./assetList";
import { IAsset } from "./interfaces/IAsset";
import { autodeskConstants } from "../../autodeskConstants";
import { IMapFloor } from "./interfaces/IMapFloor";
import "./previewAssets.scss";
import LanguageProvider from "../../../../providers/languageProvider";
import translations from "../../../../translations/mapper";
import { ISpaceOnMap } from "@beyondeyes/shared";

interface IPreviewAssetsProps {
    mapFloor: IMapFloor;
    setSelectedSpaces: (selectedAssetIds: string[], color: string) => void;
    setUpsertAssetsRequest: (addedAssets: IAsset[], removedAssets: IAsset[]) => void;
    refreshKey: number;
}

class PreviewAssetsState {
    public constructor(
        public addedAssets: IAsset[] = [],
        public deactivatedAssets: IAsset[] = [],
        public existingAssets: IAsset[] = [],
        public invalidGuidAssets: IAsset[] = [],
        public invalidPositionAssets: IAsset[] = [],
        public loading: boolean = true) {
    }
}

export const PreviewAssets: React.FC<IPreviewAssetsProps> = (props: IPreviewAssetsProps) => {
    const [previewAssetsState, setPreviewAssetsState] = useState<PreviewAssetsState>(new PreviewAssetsState());
    const { mapFloor, setUpsertAssetsRequest, refreshKey } = props;

    useEffect(() => {
        if (!mapFloor) {
            return;
        }

        setPreviewAssetsState(new PreviewAssetsState());

        const isValidGUID = (value: string): boolean => {
            if (value.length > 0) {
                return (/^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/).test(value);
            }
            return false;
        };

        const validSpaces = mapFloor.spaces.filter(space => isValidGUID(space.id.toString()) && space.position.x + space.position.y > 0);
        const spacesWithInvalidGuids = mapFloor.spaces.filter(space => !isValidGUID(space.id.toString()));
        const spacesWithInvalidPosition = mapFloor.spaces.filter(space => space.position.x + space.position.y === 0);

        const getAddedAssets = (coreSpaces: CoreSpace[]): IAsset[] => {
            return validSpaces
                .filter(mapSpace => !coreSpaces.some(coreSpace => coreSpace.id === mapSpace.id))
                .map(mapSpace => (
                    {
                        id: mapSpace.id.toString(),
                        name: mapSpace.properties ? mapSpace.properties![autodeskConstants.assetExternalId] : autodeskConstants.unknown,
                        type: mapSpace.properties ? mapSpace.properties![autodeskConstants.assetType] : autodeskConstants.unknown
                    }));
        };

        const getDeactivatedAssets = (coreSpaces: CoreSpace[]): IAsset[] => {
            return coreSpaces
                .filter(coreSpace => !validSpaces.some(mapSpace => mapSpace.id === coreSpace.id))
                .map(coreSpace => ({ id: coreSpace.id, name: coreSpace.name, type: coreSpace.type }));

        };

        const getExistingAssets = (coreSpaces: CoreSpace[]): IAsset[] => {
            return coreSpaces
                .filter(coreSpace => validSpaces.some(mapSpace => mapSpace.id === coreSpace.id))
                .map(coreSpace => ({ id: coreSpace.id, name: coreSpace.name, type: coreSpace.type }));

        };

        const getInvalidAssets = (invalidSpaces: ISpaceOnMap[]): IAsset[] => {
            return invalidSpaces
                .map(mapSpace => (
                    {
                        id: mapSpace.id.toString(),
                        name: mapSpace.properties ? mapSpace.properties![autodeskConstants.assetExternalId] : autodeskConstants.unknown,
                        type: mapSpace.properties ? mapSpace.properties![autodeskConstants.assetType] : autodeskConstants.unknown
                    }));
        };

        const getFloorSpaces = async (): Promise<void> => {
            const coreSpaceService = new CoreSpaceService();

            const spaces = await coreSpaceService.getSpacesForFloor(mapFloor.venueId,
                mapFloor.id,
                undefined,
                [CoreSpaceIncludes.DataTypes, CoreSpaceIncludes.Values, CoreSpaceIncludes.Properties],
                undefined,
                true
            );

            const addedAssets = getAddedAssets(spaces);
            const invalidGuidsAssets = getInvalidAssets(spacesWithInvalidGuids);
            const invalidPositionAssets = getInvalidAssets(spacesWithInvalidPosition);
            const deactivatedAssets = getDeactivatedAssets(spaces);
            const existingAssets = getExistingAssets(spaces);

            setPreviewAssetsState(new PreviewAssetsState(addedAssets, deactivatedAssets, existingAssets, invalidGuidsAssets, invalidPositionAssets, false));

            setUpsertAssetsRequest(addedAssets, deactivatedAssets);
        };

        getFloorSpaces();

    }, [mapFloor, refreshKey, setUpsertAssetsRequest]);

    return (
        <div className="upsert-assets">
            <div className="col-md-12">
                <AssetList
                    selectableContext={{ floorId: mapFloor.id, selectedColor: "#63d100", setSelectedSpaces: props.setSelectedSpaces }}
                    loading={previewAssetsState.loading}
                    assets={previewAssetsState.addedAssets}
                    title={LanguageProvider.getTranslation(translations.pages.autodesk.addedassets)}
                ></AssetList>
            </div>
            <div className="col-md-12">
                <AssetList
                    loading={previewAssetsState.loading}
                    assets={previewAssetsState.deactivatedAssets}
                    title={LanguageProvider.getTranslation(translations.pages.autodesk.deactivatedassets)}
                ></AssetList>
            </div>
            <div className="col-md-12">
                <AssetList
                    selectableContext={{ floorId: mapFloor.id, selectedColor: "#009FE3", setSelectedSpaces: props.setSelectedSpaces }}
                    loading={previewAssetsState.loading}
                    assets={previewAssetsState.existingAssets}
                    title={LanguageProvider.getTranslation(translations.pages.autodesk.existingdassets)}
                ></AssetList>
            </div>
            <div className="col-md-12">
                <AssetList
                    selectableContext={{ floorId: mapFloor.id, selectedColor: "#ffc92b", setSelectedSpaces: props.setSelectedSpaces }}
                    loading={previewAssetsState.loading}
                    assets={previewAssetsState.invalidGuidAssets}
                    title={LanguageProvider.getTranslation(translations.pages.autodesk.invalidguidassets)}
                ></AssetList>
            </div>
            <div className="col-md-12">
                <AssetList
                    selectableContext={{ floorId: mapFloor.id, selectedColor: "#eb3342", setSelectedSpaces: props.setSelectedSpaces }}
                    loading={previewAssetsState.loading}
                    assets={previewAssetsState.invalidPositionAssets}
                    title={LanguageProvider.getTranslation(translations.pages.autodesk.invalidpositionassets)}
                ></AssetList>
            </div>
        </div >
    );
};