import UserCreate from "../models/userCreate";
import CreatedUser from "../models/createdUser";
import UserAccount from "../models/userAccount";
import MaintenanceService from "./maintenanceService";

export default class UserAccountService {

    private readonly maintenanceService: MaintenanceService;

    public constructor() {
        this.maintenanceService = new MaintenanceService();
    }

    public async createUserAccount(userCreate: UserCreate): Promise<[Response, CreatedUser]> {
        const requestUrl = `users`;
        return this.maintenanceService.postWithResponse<CreatedUser>(requestUrl, userCreate);
    }

    public async getUserAccounts(): Promise<UserAccount[]> {
        const requestUrl = `users`;
        return this.maintenanceService.get<UserAccount[]>(requestUrl, true);
    }

    public async resetPasswordForUserAccount(user: UserAccount): Promise<[Response, string]> {
        const requestUrl = `users/${user.upn}/resetpassword`;
        return this.maintenanceService.putWithResponse<string>(requestUrl, undefined);
    }

    public async deleteUserAccount(user: UserAccount): Promise<Response> {
        const requestUrl = `users/${user.upn}`;
        return this.maintenanceService.delete(requestUrl);
    }
}