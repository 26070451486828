import * as React from 'react';
import { PulseLoader } from 'react-spinners';

import { withLocalize } from 'react-localize-redux';

import "./homePage.scss";

import IHomePageProps from './interfaces/IHomePageProps';
import IHomePageState from "./interfaces/IHomePageState";
import CurrentOccupanceOverview from './components/currentOccupanceOverview';
import CoreSpaceService from '../../services/coreSpaceService';
import VenueProvider from '../../providers/venueProvider';
import AppEventHub, { AppEvents } from "../../utils/appEventHub";

class HomePage extends React.Component<IHomePageProps, IHomePageState> {

    public constructor(props: IHomePageProps) {
        super(props);

        const state: IHomePageState = {
            loading: true
        };

        this.state = state;
        this.setPageData = this.setPageData.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.setPageData);
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.setPageData);
    }

    public async componentDidMount(): Promise<void> {
        let activeVenue = await VenueProvider.getActiveVenue();
        // Ensure a venue is selected (since this component mounts before app.tsx does).
        if (!activeVenue) {
            const coreSpaceService = new CoreSpaceService();
            const venues = await coreSpaceService.getVenuesWithAllRelatedData();
            activeVenue = venues[0];

            if (activeVenue) {
                VenueProvider.saveActiveVenue(activeVenue);
            }
        }

        this.setState({
            loading: false
        });
    }

    private async setPageData(): Promise<void> {
        this.setState({
            loading: false
        });
    }

    public render(): JSX.Element {
        return (
            <span className="homepage">
                {!this.state.loading &&
                    <div>
                        <div className="row page">
                            <div className="col-sm col-md ml-5 mr-5 mt-5 mb-5 pl-5">
                                <CurrentOccupanceOverview />
                            </div>
                        </div>
                    </div>}
                {this.state.loading && (
                    <div id="pulse-loader" className="loader">
                        <PulseLoader
                            color="#37a0e6"
                            size={8}
                            margin="15px"
                            loading={this.state.loading}
                        />
                    </div>
                )}
            </span>
        );
    }
}

export default withLocalize(HomePage);