import Dictionary from "../utils/dictionary";

export default class ChangedDeviceConfig {
    public EUI: string;
    public deviceType: string | null;
    public downlinkProperties: { [id: string]: number };

    public constructor(deviceId: string, downlinkProperties: Dictionary<number>, type: string | null) {
        this.EUI = deviceId;
        this.downlinkProperties = downlinkProperties.toJsonDictionary();
        this.deviceType = type;
    }
}