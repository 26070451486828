import Device from "../../../models/device";
import DownLinkProperty from "../../../models/downLinkProperty";

export default class DeviceConfigurationExtractor {

    private static basicProperties: string[] = ["X", "Y", "Z", "U"];

    public static extractConfiguration(devices: Device[]): DownLinkProperty[] {

        const propsLookup: { [index: string]: DownLinkProperty } = {};

        devices
            .map(d => d.getDownLinkProperties())
            .forEach(ps => {
                ps.forEach(p => propsLookup[p.name] = p);
            });

        const downlinkPropertiesArray: DownLinkProperty[] = [];
        for (const prop in propsLookup) {
            downlinkPropertiesArray.push(propsLookup[prop]);
        }

        const basics = downlinkPropertiesArray.filter(p => DeviceConfigurationExtractor.isBasic(p))
            .sort(DeviceConfigurationExtractor.downlinkSort);
        const singles = downlinkPropertiesArray.filter(p => !DeviceConfigurationExtractor.isBasic(p) && DeviceConfigurationExtractor.isSingleLetter(p))
            .sort(DeviceConfigurationExtractor.downlinkSort);
        const others = downlinkPropertiesArray.filter(p => !DeviceConfigurationExtractor.isBasic(p) && !DeviceConfigurationExtractor.isSingleLetter(p))
            .sort(DeviceConfigurationExtractor.downlinkSort);

        const sortedDownlinkProperties = basics.concat(singles).concat(others);
        return sortedDownlinkProperties;
    }

    private static isBasic(p: DownLinkProperty): boolean {
        return DeviceConfigurationExtractor.basicProperties.includes(p.name);
    }

    private static isSingleLetter(p: DownLinkProperty): boolean {
        return p.name.length === 1;
    }

    private static downlinkSort(a: DownLinkProperty, b: DownLinkProperty): number {
        return a.name.localeCompare(b.name);
    }
}