import React from "react";

class AuthorizedPage extends React.Component<IPageProps, IPageState> {
    public constructor(props: IPageProps) {
        super(props);

        this.state = {
            loading: true,
            authorized: false
        };
    }

    public async componentDidMount(): Promise<void> {
        const authorized = await this.props.authorizeRequest();

        this.setState({
            loading: false,
            authorized: authorized
        });
    }

    public async componentDidUpdate(prevProps: IPageProps): Promise<void> {
        if (this.props.authorizeRequest !== prevProps.authorizeRequest) {
            const authorized = await this.props.authorizeRequest();

            this.setState({
                authorized: authorized
            });
        }
    }

    public render(): JSX.Element {
        const PageComponent = this.props.component;

        return (
            <>
                {!this.state.loading &&
                    <>
                        {!this.state.authorized && <h1>No access.</h1>}
                        {this.state.authorized && <PageComponent {...this.props} />}
                    </>
                }
            </>
        );
    }
}

export default AuthorizedPage;

interface IPageProps {
    component: any;
    authorizeRequest: () => Promise<boolean>
}

interface IPageState {
    authorized: boolean;
    loading: boolean;
}