import * as React from "react";
import Select from "react-select";
import { NotificationManager } from 'react-notifications';

import Yellow_Cross_Cancel from "../../images/Yellow_Cross_Cancel.svg";
import Mapper from "../../translations/mapper";
import LanguageProvider from "../../providers/languageProvider";
import CustomerSelectionDropDown from "./components/customerSelectionDropDown";
import VenueSelectionDropDown from "../../components/header/venueSelectionDropDown";
import SubscriptionService from "../../services/subscriptionService";
import SelectBoxUtils from "../../utils/selectBoxUtils";
import IUploadModalProps from "./interfaces/IUploadModalProps";
import IUploadModalState from "./interfaces/IUploadModalState";
import IReactSelectValue from "../../interfaces/IReactSelectValue";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import CustomerProvider from "../../providers/customerProvider";
import DocumentCreate from "../../models/documentCreate";
import MaintenanceService from "../../services/maintenanceService";
import { DocumentBlobType } from "../../types/documentBlobType";

export default class UploadModal extends React.Component<IUploadModalProps, IUploadModalState>{

    private readonly subscriptionService: SubscriptionService;
    private readonly maintenanceService: MaintenanceService;

    private fileInputRef: React.RefObject<HTMLInputElement>;
    private closeButtonRef: React.RefObject<HTMLButtonElement>;

    public constructor(props: any) {
        super(props);
        const state: IUploadModalState = {
            selectedDocumentType: null,
            selectedDocumentTypeOptions: [],
            selectedVenueId: null,
            fileName: "",
            fileData: "",
            isFileSelected: false
        };

        this.state = state;
        this.cancelForm = this.cancelForm.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.initializeAppTypesAsync = this.initializeAppTypesAsync.bind(this);
        this.setActiveDocumentType = this.setActiveDocumentType.bind(this);
        this.setActiveVenue = this.setActiveVenue.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadFileInternalAsync = this.uploadFileInternalAsync.bind(this);
        this.clearFileData = this.clearFileData.bind(this);

        this.subscriptionService = new SubscriptionService();
        this.maintenanceService = new MaintenanceService();

        this.fileInputRef = React.createRef();
        this.closeButtonRef = React.createRef();

        AppEventHub.on(AppEvents.CustomerSpaceSelected, this.initializeAppTypesAsync);
    }

    public async componentDidMount(): Promise<void> {
        await this.initializeAppTypesAsync();
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.CustomerSpaceSelected, this.initializeAppTypesAsync);
    }

    private async initializeAppTypesAsync(): Promise<void> {
        const documentTypes = await this.subscriptionService.GetAllowedDocumentTypesForCustomerSubscriptions();
        this.setCurrentDocumentState(documentTypes);
    }

    private setActiveDocumentType(appType: IReactSelectValue | readonly IReactSelectValue[] | null | undefined): void {
        const item = appType as IReactSelectValue | null;
        this.setState({
            selectedDocumentType: item
        });
    }

    private async setActiveVenue(selectValue: IReactSelectValue | readonly IReactSelectValue[] | null | undefined): Promise<void> {
        let venueId: string | null = null;
        if (selectValue) {
            venueId = (selectValue as IReactSelectValue).value;
        }

        var isRemoved = this.state.selectedVenueId !== venueId && venueId === null;

        this.setState({
            selectedVenueId: venueId
        });

        var documentTypes: DocumentBlobType[];

        if (venueId !== null) {
            documentTypes = await this.subscriptionService.GetAllowedDocumentTypesForVenueSubscriptions(venueId);
            this.setCurrentDocumentState(documentTypes);
        }

        if (isRemoved) {
            documentTypes = await this.subscriptionService.GetAllowedDocumentTypesForCustomerSubscriptions();
            this.setCurrentDocumentState(documentTypes);
        }
    }

    private setCurrentDocumentState(documentTypes: DocumentBlobType[]): void {
        const documentTypeSelectionOptions: IReactSelectValue[] = documentTypes.map(a => ({
            value: a,
            label: a
        }));

        this.setState({
            selectedDocumentTypeOptions: documentTypeSelectionOptions,
            selectedDocumentType: null
        });
    }

    private cancelForm(): void {
        this.clearForm();
    }

    private clearForm(): void {
        this.clearFileData();
    }

    private handleFileChange(event: any): void {
        const file: File = event.target.files[0];
        if (file === undefined) {
            NotificationManager.error("No file found");
            this.clearFileData();
            return;
        }

        const myReader: FileReader = new FileReader();

        myReader.onloadend = (e): void => {
            const fileData = myReader.result as string;
            this.setState({
                isFileSelected: true,
                fileData: fileData,
                fileName: file.name
            });
        };

        myReader.readAsDataURL(file);
    }

    private clearFileData(): void {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.value = '';
        }

        this.setState({
            isFileSelected: false,
            fileData: "",
            fileName: ""
        });
    }

    private uploadFile(): void {
        this.uploadFileInternalAsync(this.state.isFileSelected, this.state.fileData, this.state.fileName);
    }

    private async uploadFileInternalAsync(fileSelected: boolean, fileData: string, fileName: string): Promise<void> {
        if (!fileSelected) {
            NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.upload.nofileerror));
            return;
        }

        const documentType = this.state.selectedDocumentType;

        if (!documentType) {
            NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.upload.nodocumenttype));
            return;
        }

        const documentCreate: DocumentCreate = {
            content: fileData,
            fileName: fileName,
            documentType: documentType.value,
            documentSubType: "none",
            description: "An uploaded file from the maintenance app"
        };

        let response: Response;
        if (this.state.selectedVenueId) {
            response = await this.maintenanceService.postFileForVenue(this.state.selectedVenueId, documentCreate);
        } else {
            const customerId = CustomerProvider.getActiveCustomer()?.id;
            if (customerId){
                response = await this.maintenanceService.postFileForCustomer(customerId, documentCreate);
            } else {
                return;
            }
        }

        if (response.ok) {
            NotificationManager.success(LanguageProvider.getTranslation(Mapper.pages.upload.fileuploaded));

            if (this.closeButtonRef.current) {
                this.closeButtonRef.current.click();
            }

            this.clearFileData();
            this.props.onSuccessfulUpload();
        }
        else {
            let responseText = await response.text();

            if (response.status === 400 && responseText.indexOf("Bad request") >= 0) {
                responseText = LanguageProvider.getTranslation(Mapper.pages.upload.error400onupload);
            }

            NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.upload.erroronupload) + response.status + " -- " + responseText);
        }
    }

    public render(): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation(Mapper.buttons.dropdowns.nooptions);

        return (
            <div
                className="modal fade"
                id="upload-modal"
                role="dialog"
                aria-labelledby="createModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {LanguageProvider.getTranslation(Mapper.pages.upload.upload)}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.cancelForm}>
                                <img src={Yellow_Cross_Cancel} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <b>{LanguageProvider.getTranslation(Mapper.pages.upload.columns.customer)}</b>
                                    <CustomerSelectionDropDown />
                                </div>
                                <div className="form-group">
                                    <b>{LanguageProvider.getTranslation(Mapper.pages.upload.columns.venue)}</b>
                                    <VenueSelectionDropDown
                                        filterByActiveCustomer={true}
                                        disableSessionStorage={true}
                                        isClearable={true}
                                        onChange={this.setActiveVenue} />
                                </div>
                                <div className="form-group">
                                    <b>{LanguageProvider.getTranslation(Mapper.pages.upload.columns.documenttype)}</b>
                                    <Select
                                        value={this.state.selectedDocumentType}
                                        onChange={this.setActiveDocumentType}
                                        isClearable={true}
                                        options={this.state.selectedDocumentTypeOptions}
                                        placeholder={`${LanguageProvider.getTranslation(Mapper.buttons.dropdowns.documenttype)}...`}
                                        noOptionsMessage={noOptionsMessage}
                                        styles={SelectBoxUtils.getVenueSelectStyles(40)}
                                    />
                                </div>
                                <input
                                    className="form-control-file mt-3 mb-3"
                                    type="file"
                                    ref={this.fileInputRef}
                                    name="customerFileUpload"
                                    multiple={false}
                                    onChange={this.handleFileChange}
                                />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.uploadFile}
                            >{LanguageProvider.getTranslation(Mapper.pages.upload.upload)}</button>
                        </div>
                        <button type="button"
                            className="d-none"
                            data-dismiss="modal"
                            data-target="#create-modal"
                            ref={this.closeButtonRef}></button>
                    </div>
                </div>
            </div>
        );
    }
}
