import Dictionary from "./dictionary";

export class ReactTableUtils {
    private static InactiveRows: Dictionary<any> = new Dictionary();

    public static getInactiveRows(): string[] {
        return ReactTableUtils.InactiveRows.getKeys();
    }

    public static useContainsFilter : (filter : any, row : any) => boolean = (filter, row) => {
        const key = filter.pivotId || filter.id;

        let isActive = true;
        if (row[key] !== null) {
            isActive = (row[key] !== undefined ? row[key].toLowerCase().indexOf(filter.value.toLowerCase()) > -1 : true);
        }

        if (!isActive) {
            ReactTableUtils.InactiveRows.add(row._index, 1);
        }
        else {
            ReactTableUtils.InactiveRows.remove(row._index);
        }

        return isActive;
    };
}