import { ApplicationConfig } from "../config";
import { PublicClientApplication } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";
import IAuthenticator from "./interfaces/IAuthenticator";

export default abstract class MsalAuthenticator implements IAuthenticator {

    protected readonly publicClientApplication: PublicClientApplication;

    public constructor() {
        this.publicClientApplication = new PublicClientApplication({
            auth: {
                clientId: ApplicationConfig.ad.clientId,
                authority: `https://login.microsoftonline.com/bf2a505e-cf12-424c-a0d5-4577608ea121`,
                redirectUri: ApplicationConfig.ad.redirectUri,
            },
            cache: {
                cacheLocation: ApplicationConfig.ad.cacheLocation
            }
        });
    }

    public async login(): Promise<void> {
        let account = await this.getUser();

        let isExpired = true;

		if (account?.idTokenClaims?.exp) {
			const exp = account?.idTokenClaims?.exp * 1000;
			const now = Date.now();
			isExpired = exp < now;
		}

		if (isExpired) {
            this.publicClientApplication.loginRedirect({ scopes: [ApplicationConfig.ad.scopes.MaintenanceFrontend] });
            account = await this.getUser();
        }

        this.publicClientApplication.setActiveAccount(account);
    }

    private async getUser(): Promise<any> {
        return await this.publicClientApplication.handleRedirectPromise().then((response) => {
            if (response != null) {
                return response.account;
            } else {
                const loggedInAccount = this.getLoggedInAccount();
                return loggedInAccount;
            }
        });
    }

    public async authenticateRequest(request: RequestInit): Promise<RequestInit> {
        return request;
    }

    public async getTokenAsync(scopes: string[]): Promise<string> {

        const account = this.publicClientApplication.getActiveAccount();

        if (account == null) {
            return "";
        }

        const silentRequest = {
            scopes: scopes,
            forceRefresh: false,
            account: account
        };

        const result = await this.publicClientApplication.acquireTokenSilent(silentRequest);
        if (!result.accessToken) {
            throw Error();
        }

        this.publicClientApplication.setActiveAccount(account);

        return result.accessToken;

    }

    private getLoggedInAccount(): AccountInfo | null {
        const allAccounts = this.publicClientApplication.getAllAccounts();
        if (allAccounts.length === 1) {
            return allAccounts[0];
        }
        else if (allAccounts.length > 1) {
            allAccounts.forEach(e => {
                const logoutRequest = {
                    account: e,
                };

                this.publicClientApplication.logoutRedirect(logoutRequest);
            });
        }

        return null;
    }
}