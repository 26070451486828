import Dictionary from "../../utils/dictionary";

export default class SettingsDescriptionProvider {
    public static getSettings(): Dictionary<Dictionary<string>> {
        const descriptions = new Dictionary<Dictionary<string>>();
        const beTherePirDescription = new Dictionary<string>();
        beTherePirDescription.add("U (Standaard: 900)", "Slaaptijd in seconden (300-3600)");
        beTherePirDescription.add("X (Standaard: 10)", "Bloktijd in seconden (10-3600)");
        beTherePirDescription.add("Y (Standaard: 1)", "Aantal blokken (1-1) Niet in gebruik voor PIR");
        beTherePirDescription.add("Z (Standaard: 1)", "Aantal positieve blokken (1-1) Niet in gebruik voor PIR");
        beTherePirDescription.add("ApplicationSetting (standaard 2)", "Application uplinks instelling (2: data/config). Dit is alleen van toepassing op devices met versie 3.0 \nApplication uplinks instelling (0: full, 1: data, 2: data/config). Alleen van toepassing op devices met versie >=3.1");
        beTherePirDescription.add("ConfirmedSetting (standaard 3)", "Confirmed uplinks instelling (0: disabled, 1: all, 2: data, 3: alive). Alleen van toepassing op devices met versie >=3.0");
        descriptions.add("BeTherePir", beTherePirDescription);

        const beThereDeskDescription = new Dictionary<string>();
        beThereDeskDescription.add("U (Standaard: 900)", "Slaaptijd in seconden (300-3600)");
        beThereDeskDescription.add("X (Standaard: 15)", "Bloktijd in seconden (10-60)");
        beThereDeskDescription.add("Y (Standaard: 8)", "Aantal blokken (5-100)");
        beThereDeskDescription.add("Z (Standaard: 6)", "Aantal positieve blokken (1-100 en max.Y waarde)");
        beThereDeskDescription.add("ApplicationSetting (standaard 2)", "Application uplinks instelling (2: data/config). Dit is alleen van toepassing op devices met versie 3.0 \nApplication uplinks instelling (0: full, 1: data, 2: data/config). Alleen van toepassing op devices met versie >=3.1");
        beThereDeskDescription.add("ConfirmedSetting (standaard 3)", "Confirmed uplinks instelling (0: disabled, 1: all, 2: data, 3: alive). Alleen van toepassing op devices met versie >=3.0");
        descriptions.add("BeThereDesk", beThereDeskDescription);

        const beThereToiletV1Description = new Dictionary<string>();
        beThereToiletV1Description.add("U (Standaard: 1)", "Slaaptijd in seconden (1-1) Niet in gebruik voor Toilet");
        beThereToiletV1Description.add("X (Standaard: 10)", "Bloktijd in seconden (10-300)");
        beThereToiletV1Description.add("Y (Standaard: 10)", "Aantal blokken (10-250)");
        beThereToiletV1Description.add("Z (Standaard: 1)", "Aantal positieve blokken (1-1) Niet in gebruik voor Toilet");
        beThereToiletV1Description.add("ApplicationSetting (standaard 2)", "Application uplinks instelling (2: data/config). Dit is alleen van toepassing op devices met versie 3.0 \nApplication uplinks instelling (0: full, 1: data, 2: data/config). Alleen van toepassing op devices met versie >=3.1");
        beThereToiletV1Description.add("ConfirmedSetting (standaard 3)", "Confirmed uplinks instelling (0: disabled, 1: all, 2: data, 3: alive). Alleen van toepassing op devices met versie >=3.0");
        descriptions.add("beThereToiletV1", beThereToiletV1Description);

        const beThereToiletV2Description = new Dictionary<string>();
        beThereToiletV2Description.add("U (Standaard: 1)", "Slaaptijd in seconden (1-1) Niet in gebruik voor Toilet");
        beThereToiletV2Description.add("X (Standaard: 480)", "Bloktijd in seconden (10-1000)");
        beThereToiletV2Description.add("Y (Standaard: 15)", "Aantal blokken (10-180)");
        beThereToiletV2Description.add("Z (Standaard: 1)", "Aantal positieve blokken (1-1) Niet in gebruik voor Toilet");
        beThereToiletV2Description.add("ApplicationSetting (standaard 2)", "Application uplinks instelling (2: data/config). Dit is alleen van toepassing op devices met versie 3.0 \nApplication uplinks instelling (0: full, 1: data, 2: data/config). Alleen van toepassing op devices met versie >=3.1");
        beThereToiletV2Description.add("ConfirmedSetting (standaard 3)", "Confirmed uplinks instelling (0: disabled, 1: all, 2: data, 3: alive). Alleen van toepassing op devices met versie >=3.0");
        descriptions.add("beThereToiletV2", beThereToiletV2Description);

        const beComfortDescription = new Dictionary<string>();
        beComfortDescription.add("A", "PIR sensor inactive (00) or active (01)");
        beComfortDescription.add("B", "Measurement interval (0-1440 minutes)");
        beComfortDescription.add("C", "Number of 15 seconds PIR detection blocks for movement detection (1-8)");
        beComfortDescription.add("D", "Number of PIR detection blocks to trigger PIR message uplink (1-8) > never exceed the detection blocks number");
        beComfortDescription.add("E", "PIR sleeptime after a message uplink (1-240 minutes)");
        beComfortDescription.add("F", "Temperature- & Relative Humidity sensor inactive (00) or active (01)");
        beComfortDescription.add("G", "Light sensor inactive (00) or active (01)");
        beComfortDescription.add("H", "CO2 sensor inactive (00), active (01), periodic (02) or PIR triggered (03)");
        beComfortDescription.add("I", "CO2 sensor periodic time between 2 consequential CO2 measurements (3-240 minutes)");
        beComfortDescription.add("J", "CO2 alarmlevel (0-2000 ppm)");
        beComfortDescription.add("K", "Sound sensor inactive (00) or active (01)");
        beComfortDescription.add("L", "Sound sensor's sensitivity level (1-10) > 10 = most sensitive");
        beComfortDescription.add("M", "Push Button inactive (00) or active (01)");
        beComfortDescription.add("N", "Push Button message in next uplink (00) or direct message uplink when pressed (01)");
        beComfortDescription.add("O", "Ignore time for Push Button after use (3-240 minutes)");
        beComfortDescription.add("P", `LED indication mask: 
                                        (A) = Uplink (2 short, orange flashes indicating a transmission) 
                                        (B) = Low battery voltage(2 short red flashes every 10 seconds) 
                                        (C) = Critical battery voltage(5 short red flashes every 5 seconds) 
                                        (D) = High CO2 level(5 short red / green alternating flashes every 5 seconds) 
                                        (E) = Push Button actuation(1 medium green flash) 
                                        (F) = PIR sensor detection(1 short red flash)`);
        beComfortDescription.add("Q", `LED indication mask incomming message: 
        (00) = NO indication        
        (01) = GREEN, 1, 500, 1        
        (02) = GREEN, 2, 500, 500        
        (03) = GREEN, 3, 500, 500        
        (04) = GREEN, 3, 200, 200        
        (05) = RED, 1, 500, 1        
        (06) = RED, 2, 500, 500        
        (07) = RED, 3, 500, 500        
        (08) = RED, 3, 200, 200        
        (09) = ORANGE, 1, 500, 1        
        (10) = ORANGE, 2, 500, 500        
        (11) = ORANGE, 3, 500, 500        
        (12) = ORANGE, 3, 200, 200        
        (13) = GREEN_RED, 1, 500, 1        
        (14) = GREEN_RED, 2, 500, 500        
        (15) = GREEN_RED, 3, 500, 500        
        (16) = GREEN_RED, 3, 200, 200        
        (17) = RED_GREEN, 1, 500, 1        
        (18) = RED_GREEN, 2, 500, 500        
        (19) = RED_GREEN, 3, 500, 500        
        (20) = RED_GREEN, 3, 200, 200`);
        beComfortDescription.add("R", "LED indication time after incomming message (1-240 minutes)");
        descriptions.add("beComfort", beComfortDescription);

        const beComfortPlusDescription = new Dictionary<string>();
        beComfortPlusDescription.add("B", "Device mode (0 - 9)");
        beComfortPlusDescription.add("C", "Push button activation (0 or 1)");
        beComfortPlusDescription.add("D", "Buzzer activation (0 or 1)");
        beComfortPlusDescription.add("E", "Selected functionality for the Temperature- & Relative Humidity sensor (0 (Measurement only when CO2 is measured) or 1 (Measurement on every uplink))");
        beComfortPlusDescription.add("G", "Fixed interval in minutes (3 - 1440)");
        beComfortPlusDescription.add("H", "Short PIR armed period in seconds (5 - 30)");
        beComfortPlusDescription.add("I", "Long PIR armed period in minutes (3 - 60)");
        beComfortPlusDescription.add("J", "CO2 alarm threshold in ppm (0 - 5000)");
        beComfortPlusDescription.add("K", "Push button ignore period in minutes (3 - 60)");
        beComfortPlusDescription.add("L", "Alive interval in hours (6 - 168)");
        beComfortPlusDescription.add("M", `Alive uplinks setting:
                                            0 = Alive uplinks are of packages type FULL (SYSTEM + DATA + CONFIG)
                                            1 = Alive uplinks are of package type CONFIG (SYSTEM + CONFIG)
                                            2 = Alive uplinks are of package type NONE (SYSTEM, no additional packages)
                                            3 = RFU`);
        beComfortPlusDescription.add("N", `Confirmed uplinks setting: 
                                            0 = Confirmed uplinks are DISABLED
                                            1 = Confirmed uplinks enabled on ALL messages
                                            2 = Confirmed uplinks enabled on DATA messages and in response to new
                                                settings on ACK+NACK messages
                                            3 = Confirmed uplinks enabled on ALIVE messages and in response to new 
                                                settings on ACK+NACK messages`);
        beComfortPlusDescription.add("O", `Application uplinks setting: 
                                            0 = Application uplinks are of package type FULL (SYSTEM + DATA + CONFIG)
                                            1 = Application uplinks are of package type DATA (SYSTEM + DATA)
                                            2 = Application uplinks have package type DATA and ACK/NACK has CONFIG (SYSTEM + DATA on
                                                uplinks and SYSTEM + CONFIG on ACK)
                                            3 = RFU`);
        descriptions.add("BeComfortPlus", beComfortPlusDescription);

        const beScaleDescription = new Dictionary<string>();
        beScaleDescription.add("U (Standaard: 0)", "Uplink modus (0-1): in modus 0 (gewicht) worden berichten verzonden na een wijziging van Y kilo sinds het laatste verzonden bericht. In modus 1 (tijd) worden berichten elke X seconden verzonden.");
        beScaleDescription.add("X (Standaard: 900)", "Tijdsperiode tussen berichten in seconden (300-21600).");
        beScaleDescription.add("Y (Standaard: 8)", "Benodige gewichts wijziging voor het versturen van een bericht (2-10) , alleen van toepassing bij uplink modus 0.");
        descriptions.add("BeScale", beScaleDescription);

        const countSensorDescription = new Dictionary<string>();
        countSensorDescription.add("CountInterval (standaard 300)", "Interval tussen metingen in seconden (300-86400)");
        countSensorDescription.add("ConfidenceLevel (standaard 40)", "Confidence level waarop een persoon wordt herkend in procent (30-90)");
        countSensorDescription.add("AliveSetting (standaard 1)", "Bericht type alive uplinks (0: full, 1: config, 2: none)");
        countSensorDescription.add("ConfirmedSetting (standaard 1)", "Confirmed uplinks instelling (0: disabled, 1: all, 2: data, 3: alive)");
        countSensorDescription.add("ApplicationSetting (standaard 1)", "Application uplinks instelling (0: full, 1: data, 2: data/config");
        countSensorDescription.add("--De hierna volgende instellingen zijn per apparaat uniek--", "Deze zullen normaal alleen bij de eerste setup worden ingesteld");
        countSensorDescription.add("AlphaValue", "Alpha waarde in tiendes (dus 1 = 0.1, 2 = 0.2, 10 = 1.0, etc.)");
        countSensorDescription.add("BetaValue", "Beta waarde");
        countSensorDescription.add("DecisionA", "A parameter voor de beslissings lijn");
        countSensorDescription.add("DecisionB", "B parameter voor de beslissings lijn");
        countSensorDescription.add("DecisionC", "C parameter voor de beslissings lijn");
        countSensorDescription.add("DecisionType", "Type beslissings lijn");
        countSensorDescription.add("NumberOfFrames", "Aantal frames dat een persoon buiten frame moet zijn om te worden gederegistreerd");
        countSensorDescription.add("MinimalPixels", "Minimum aantal pixels nodig tussen twee personen");
        countSensorDescription.add("RFU", "Reserved for future use");
        descriptions.add("CountSensor", countSensorDescription);
        return descriptions;
    }
}