import * as React from 'react';
import { withLocalize } from 'react-localize-redux';
import Select from 'react-select';

import ICustomerSelectionDropDownProps from "./interfaces/ICustomerSelectionDropDownProps";
import ICustomerSelectionDropDownState from "./interfaces/ICustomerSelectionDropDownState";

import './customerSelectionDropDown.scss';

import CoreSpaceService from '../../../services/coreSpaceService';
import CustomerProvider from '../../../providers/customerProvider';
import Sorter from '../../../utils/sorter';
import SelectBoxUtils from '../../../utils/selectBoxUtils';
import IReactSelectValue from '../../../interfaces/IReactSelectValue';
import Dictionary from '../../../utils/dictionary';
import LanguageProvider from '../../../providers/languageProvider';
import Customer from '../../../models/customer';

class CustomerSelectionDropDown extends React.Component<ICustomerSelectionDropDownProps, ICustomerSelectionDropDownState> {
    private coreSpaceService: CoreSpaceService;

    private customers: Dictionary<Customer>;

    public constructor(props: ICustomerSelectionDropDownProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        this.setActiveCustomer = this.setActiveCustomer.bind(this);

        this.customers = new Dictionary<Customer>();

        const customer = CustomerProvider.getActiveCustomer();
        const state: ICustomerSelectionDropDownState = {
            selectedCustomer: customer ? { label: customer.name, value: customer.id } : null,
            customerSelectionOptions: []
        };

        this.state = state;
    }

    public async componentDidMount(): Promise<void> {
        let customers = await this.coreSpaceService.getCustomers();
        customers = Sorter.sortByProperty(customers, "name");
        customers.forEach(v => this.customers.add(v.id, v));

        const customerSelectionOptions = customers.map(v => ({
            value: v.id,
            label: v.name
        }));

        this.setState({
            customerSelectionOptions: customerSelectionOptions
        });
    }

    private setActiveCustomer(selectedItem: IReactSelectValue | readonly IReactSelectValue[] | null | undefined): void {
        if (selectedItem === null || selectedItem === undefined) {
            return;
        }

        this.setState({
            selectedCustomer: selectedItem as IReactSelectValue
        });

        const customer = this.customers.item((selectedItem as IReactSelectValue).value);
        CustomerProvider.saveActiveCustomer(customer);
    }

    public render(): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation("buttons.dropdowns.nooptions");

        return (
            <Select
                value={this.state.selectedCustomer}
                onChange={this.setActiveCustomer}
                options={this.state.customerSelectionOptions}
                placeholder={`${LanguageProvider.getTranslation("buttons.dropdowns.customer")}...`}
                noOptionsMessage={noOptionsMessage}
                styles={SelectBoxUtils.getVenueSelectStyles(40)}
            />
        );
    }
}

export default withLocalize(CustomerSelectionDropDown);