import * as React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import CoreSpaceIncludes from "../../../enums/coreSpaceIncludes";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import CoreSpaceService from "../../../services/coreSpaceService";
import { NotificationManager } from 'react-notifications';
import LanguageProvider from "../../../providers/languageProvider";
import translations from "../../../translations/mapper";
import AutodeskService from "../../../services/autodeskService";
import Select from 'react-select';

interface IUploadMapProps {
    setLoading: (value: boolean) => void;
    setVenueId: (venueId: string) => void;
    showPreview: (value: boolean) => void;
}

export const UploadMap: React.FC<IUploadMapProps> = (props: IUploadMapProps) => {

    const autodeskService: AutodeskService = new AutodeskService();
    const [venueOptions, setVenueOptions] = useState<IReactSelectValue[]>([]);
    const [floorOptions, setFloorOptions] = useState<IReactSelectValue[]>([]);
    const [selectedVenueOption, setSelectedVenueOption] = useState<IReactSelectValue | undefined | null>(null);
    const [selectedFloorOption, setSelectedFloorOption] = useState<IReactSelectValue | undefined | null>(null);
    const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const uploadedDate = useRef<Date | undefined>(undefined);

    const { setLoading, setVenueId } = props;

    useEffect(() => {
        if (!selectedVenueOption) {
            return;
        }

        const loadFloors = async (): Promise<void> => {
            setLoading(true);
            const coreSpaceService = new CoreSpaceService();
            let floors = await coreSpaceService.getFloorsForVenue(selectedVenueOption!.value, [CoreSpaceIncludes.Properties]);

            floors = floors.filter(x=> !!x.floorLevel);

            floors = floors.sort((a, b) => parseInt(a.floorLevel) - parseInt(b.floorLevel));
            setFloorOptions( floors
                .map(floor => ({ label: floor.name, value: floor.id })));
            setLoading(false);
        };

        loadFloors();

    }, [selectedVenueOption, setLoading]);

    useEffect(() => {
        const loadVenues = async (): Promise<void> => {
            setLoading(true);
            const coreSpaceService = new CoreSpaceService();
            const venues = await coreSpaceService.getVenuesWithProperties();
            setVenueOptions(venues.map(venue => ({ label: venue.name, value: venue.venueId })));
            setLoading(false);
        };

        loadVenues();

    }, [setLoading]);

    useEffect(() => {
        if (!selectedVenueOption) {
            return;
        }
        setVenueId(selectedVenueOption.value);
        setSelectedFloorOption(null);
    }, [selectedVenueOption, setVenueId]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const file: File = event.target.files![0];
        if (file === undefined) {
            NotificationManager.error("No file found");
            return;
        }
        setUploadedFile(file);
    };

    const uploadFileAsync = async (): Promise<void> => {
        if (!uploadedFile) {
            return;
        }

        props.setLoading(true);
        const formData = new FormData();
        formData.append("file", uploadedFile);

        const response = await autodeskService.uploadAsync(selectedVenueOption!.value, selectedFloorOption!.value, formData);

        if (response.ok) {
            await onUploadFileSuccessAsync(response);
        }
        else {
            await onUploadFileFailureAsync(response);
        }
        props.setLoading(false);

        setUploadedFile(undefined);
        fileInputRef.current!.value = "";
    };

    const onUploadFileSuccessAsync = async (response: Response): Promise<void> => {
        NotificationManager.success(LanguageProvider.getTranslation(translations.pages.upload.fileuploaded));
        const data = await response.json();
        await autodeskService.setPreviewAsync(selectedVenueOption!.value, selectedFloorOption!.value, data.urn);
        uploadedDate.current = new Date();
    };

    const showPreview = async (): Promise<void> => {
        const delay = (ms: number): Promise<void> => {
            return new Promise(resolve => setTimeout(resolve, ms));
        };

        const isMapUploadedLessThan3SecondsAgo = (): boolean => {
            if (!uploadedDate.current) {
                return false;
            }
            const uploadedSecondsAgo = (new Date().getTime() - uploadedDate.current.getTime()) / 1000;
            return uploadedSecondsAgo < 3;
        };

        // Due to ADT internal cache the map preview is delayd with 3 seconds before the map is displayed
        if (isMapUploadedLessThan3SecondsAgo()) {
            props.setLoading(true);
            await delay(3000);
            props.setLoading(false);
        }

        props.showPreview(true);
    };

    const onUploadFileFailureAsync = async (response: Response): Promise<void> => {
        let responseText = await response.text();

        if (response.status === 400 && responseText) {
            responseText = LanguageProvider.getTranslation(responseText);
        }

        NotificationManager.error(LanguageProvider.getTranslation(translations.pages.upload.erroronupload) + response.status + " -- " + responseText);
    };

    return (
        <div className="row page">
            <div className="autodesk-page-form col-md-6">
                <label>
                    <Select
                        value={selectedVenueOption}
                        onChange={setSelectedVenueOption}
                        options={venueOptions}
                        placeholder={`${LanguageProvider.getTranslation("buttons.dropdowns.venue")}...`}
                    />
                </label>
                <label>
                    <Select
                        value={selectedFloorOption}
                        onChange={setSelectedFloorOption}
                        options={floorOptions}
                        placeholder={`${LanguageProvider.getTranslation("buttons.dropdowns.floor")}...`}
                    />
                </label>

                <input
                    className="form-control-file mt-3 mb-3"
                    type="file"
                    name="customerFileUpload"
                    multiple={false}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />

                <button
                    disabled={!uploadedFile || !selectedFloorOption}
                    className="btn btn-primary"
                    type="button"
                    onClick={uploadFileAsync}
                >{LanguageProvider.getTranslation(translations.pages.autodesk.upload)}</button>
                <button disabled={!selectedVenueOption}
                    className="btn btn-primary"
                    type="button"
                    onClick={showPreview}
                >{LanguageProvider.getTranslation(translations.pages.autodesk.preview)}</button>
            </div>
        </div>
    );
};