import IAuthenticator from "./interfaces/IAuthenticator";
import MsalAuthenticator from "./MsalAuthenticator";
import { ApplicationConfig } from "../config";

export default class MaintenanceUserAuthenticator extends MsalAuthenticator implements IAuthenticator {
    public constructor() {
        super();
    }

    public async authenticateRequest(request: RequestInit): Promise<RequestInit> {
        const accessToken = await super.getTokenAsync([ApplicationConfig.ad.scopes.winvisionMaintenanceApi]);
        request.headers = { ...request.headers, "Authorization": `Bearer ${accessToken}` };
        return request;
    }

    public getTokenAsync(): Promise<string> {
        return super.getTokenAsync([ApplicationConfig.ad.scopes.winvisionMaintenanceApi]);
    }
}