import ISerializedDeviceType from "../interfaces/ISerializedDeviceType";
import SpatialIntelligenceEntity from "./spatialIntelligenceEntity";

export default class DeviceType extends SpatialIntelligenceEntity {
    private name: string;

    public get displayValue(): string {
        return this.name;
    }

    public constructor(json?: ISerializedDeviceType) {
        super(json);
        this.fromJSON(json);
    }
}