import * as React from 'react';

import IBaseCustomerAccessAssignment from "../../../interfaces/IBaseCustomerAccessAssignment";
import LanguageProvider from "../../../providers/languageProvider";
import translations from '../../../translations/mapper';
import Customer from '../../../models/customer';

export class CustomerNameElementStyler {
    public static getCustomerNameStyling(row: any, customers: Customer[]): JSX.Element {
        const customerName = CustomerNameElementStyler.getCustomerNameForAccessAssignment(row.original, customers);

        let className = "";

        if (row.original.allCustomers) {
            className += "highlighted-customer";
        }
        else if (!customers.find(c => c.id === row.original.customerId)) {
            className += "unknown-customer";
        }

        return (
            <div className={className} >
                { customerName}
            </div>);
    }

    public static getCustomerNameForAccessAssignment(assignment: IBaseCustomerAccessAssignment, customers: Customer[]): string {
        if (assignment.allCustomers) {
            return LanguageProvider.getTranslation(translations.pages.rights.values.allcustomers);
        }

        const customer = customers.find(c => c.id === assignment.customerId);

        return customer?.name ?? LanguageProvider.getTranslation(translations.pages.rights.values.unknowncustomer);
    }
}