import * as React from 'react';
import DropDownYellow from "../../../images/Dropdown_Yellow.svg";
import IDropDownMenuProps from "./interfaces/IDropDownMenuProps";
import IDropDownMenuState from './interfaces/IDropDownMenuState';

export default class DropDownMenu extends React.Component<IDropDownMenuProps, IDropDownMenuState> {
    public constructor(props: IDropDownMenuProps) {
        super(props);
        this.onClick = this.onClick.bind(this);

        const state: IDropDownMenuState = {
            loading: false,
            displayName: ""
        };

        this.state = state;
    }

    public sortItems(): void {
        if (this.props.sort) {
            this.props.items.sort((l, r) => l[this.props.displayPropertyName].localeCompare(r[this.props.displayPropertyName]));
        }
    }

    public render(): JSX.Element {
        this.sortItems();
        const items = this.props.items;

        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle float-right dropdown-config" type="button" id="dropdown-menu-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.state.displayName ? this.state.displayName : this.props.initialDisplayName}
                    <img src={DropDownYellow} alt="Dropdown menu" id="dropdown-icon"/>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {this.props.isRequired ? '' : <MenuItem key={undefined} displayName="Geen waarde geselecteerd" displayValue={null} />}
                    {items.map(item => <MenuItem key={item[this.props.valuePropertyName]} displayName={item[this.props.displayPropertyName]} displayValue={item[this.props.valuePropertyName]} onItemClick={this.onClick} />)}
                </div>
            </div>
        );
    }

    private onClick(itemId: string, itemName: string): void {
        this.setState({ displayName: itemName });
        this.props.onClickHandler(itemId);
    }
}

// because bind or lambdas in jsx have a performance degredation, we need to create an extra class to prevent an extra function creation per item.
// see: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md#lists-of-items
// tslint:disable-next-line:max-classes-per-file
class MenuItem extends React.Component<any, {}>{
    public constructor(props: any) {
        super(props);
        // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md#es6-classes
        this.onClick = this.onClick.bind(this);
    }

    public render(): JSX.Element {
        if (this.props.displayValue == null) {
            return (<span>
                <a key={this.props.id} href="#" className="dropdown-item" onClick={this.onClick}>{this.props.displayName}</a>
                <div className="dropdown-divider" />
            </span>);
        }
        else {
            return (<a key={this.props.id} href="#" className="dropdown-item" onClick={this.onClick}>{this.props.displayName}</a>);
        }
    }

    private onClick(): void {
        this.props.onItemClick(this.props.displayValue, this.props.displayName);
    }
}