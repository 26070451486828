import ColorProvider from "../providers/colorProvider";

export default class SelectBoxUtils {

    public static getDefaultSelectStyles(height?: number): any {
        const styles = SelectBoxUtils.defaultSelectStyles;
        const control = SelectBoxUtils.getControlProps(height);

        styles.control = control;

        return { ...styles, control: control };
    }

    public static getDefaultSelectColoredStyles(height?: number): any {
        const styles = { ...SelectBoxUtils.defaultSelectStyles };
        const control = SelectBoxUtils.getControlProps(height);
        const multiValueLabel = SelectBoxUtils.getMultiValueLabelProps();
        styles.control = control;
        styles.multiValueLabel = multiValueLabel;
        return { ...styles, control: control };
    }

    public static getVenueSelectStyles(height?: number): any {
        const styles = SelectBoxUtils.getDefaultSelectStyles(height);
        return {
            ...styles,
            control: SelectBoxUtils.getVenueControlProps(height),
            clearIndicator: (provided: any, state: any): any => ({
                ...provided,
                color: '#DEDC02',
                '&:hover': {
                    color: 'red'
                },
            }),
            dropdownIndicator: (provided: any, state: any): any => ({
                ...provided,
                color: '#DEDC02',
                'backgroundColor': '#009fe3',
                cursor: 'pointer',
                '&:hover': {
                    color: '#DEDC02',
                }
            }),
            indicatorSeparator: (provided: any, state: any): any => ({
                ...provided,
                'backgroundColor': '#009fe3',
            }),
            input: (provided: any, state: any): any => ({
                ...provided,
                color: 'white',
            }),
            menu: (provided: any, state: any): any => ({
                ...provided,
                'zIndex': '100',
                'borderRadius': '0px',
                'marginTop': '11px',
            }),
            multiValueLabel: (provided: any, state: any): any => ({
                ...provided,
                color: 'white',
                'backgroundColor': '#dedc00',
                'fontSize': 12,
                'borderRadius': 0,
                'paddingLeft': 15,
                'paddingRight': 15,
                'paddingTop': 5,
                'paddingBottom': 5
            }),
            multiValueRemove: (provided: any, state: any): any => ({
                ...provided,
                'backgroundColor': '#009fe3',
                'borderRadius': 0,
                '&:hover': {
                    color: 'red',
                    'backgroundColor': '#009fe3'
                }
            }),
            placeholder: (provided: any, state: any): any => ({
                ...provided,
                color: 'white',
            }),
            singleValue: (provided: any, state: any): any => ({
                ...provided,
                color: 'white'
            })
        };
    }

    private static appendHeightToControl(defaultPropsMethod: (provided: any) => any, height?: number): any {
        if (height) {
            return (provided: any, state: any): any => {
                const result = defaultPropsMethod(provided);
                return {
                    ...result,
                    'minHeight': height
                };
            };
        }
        else {
            return (provided: any, state: any): any => defaultPropsMethod(provided);
        }
    }

    private static getVenueControlProps(height?: number): any {
        const defaultControlProps = (provided: any): any => ({
            ...provided,
            color: '#DEDC02',
            'backgroundColor': '#009fe3',
            boxShadow: 'none',
            border: 'none',
            cursor: 'pointer',
            'borderRadius': 0,
        });

        return SelectBoxUtils.appendHeightToControl(defaultControlProps, height);
    }

    private static getControlProps(height?: number): any {
        const defaultControlProps = (provided: any): any => ({
            ...provided,
            color: '#212529',
            boxShadow: 'none',
            border: '1px solid #D3D3D3',
            'line-height': 16,
            cursor: 'pointer',
            'borderRadius': 0,
            '&:hover': {
                border: '1px solid #D3D3D3'
            },
        });

        return SelectBoxUtils.appendHeightToControl(defaultControlProps, height);
    }

    private static getMultiValueLabelProps(): any {
        const multiValueLabel = (provided: any, state: any): any => ({
            ...provided,
            color: ColorProvider.getNextSemiRandomTextColorForIndex(state.selectProps.value.indexOf(state.data)),
            'backgroundColor': ColorProvider.getNextSemiRandomBackgroundColorForIndex(state.selectProps.value.indexOf(state.data)),
            'fontSize': 12,
            'borderRadius': 0,
            'paddingLeft': 15,
            'paddingRight': 15,
            'paddingTop': 0,
            'paddingBottom': 0
        });

        return multiValueLabel;
    }

    private static defaultSelectStyles: any = {
        clearIndicator: (provided: any, state: any): any => ({
            ...provided,
            color: 'red',
            '&:hover': {
                color: 'red',
            },
            'padding': '0px'
        }),
        container: (provided: any, state: any): any => ({
            ...provided,
        }),
        dropdownIndicator: (provided: any, state: any): any => ({
            ...provided,
            color: '#DEDC02',
            cursor: 'pointer',
            '&:hover': {
                color: '#DEDC02',
            },
            padding: "0px 8px 0px 0px"
        }),
        group: (provided: any, state: any): any => ({
            ...provided,
        }),
        groupHeading: (provided: any, state: any): any => ({
            ...provided,
        }),
        indicatorsContainer: (provided: any, state: any): any => ({
            ...provided
        }),
        indicatorSeparator: (provided: any, state: any): any => ({
            ...provided,
            'backgroundColor': '#FFFFFF',
        }),
        input: (provided: any, state: any): any => ({
            ...provided,
            color: '#212529'
        }),
        loadingIndicator: (provided: any, state: any): any => ({
            ...provided,
        }),
        loadingMessage: (provided: any, state: any): any => ({
            ...provided,
        }),
        menu: (provided: any, state: any): any => ({
            ...provided,
            'zIndex': '100',
            'borderRadius': '0px',
        }),
        menuList: (provided: any, state: any): any => ({
            ...provided,
            'outlineStyle': 'solid',
            'outlineColor': '#F0F0F0',
        }),
        menuPortal: (provided: any, state: any): any => ({
            ...provided,
        }),
        multiValue: (provided: any, state: any): any => ({
            ...provided,
        }),
        multiValueLabel: (provided: any, state: any): any => ({
            ...provided,
            color: '#212529',
            'backgroundColor': '#FFFFFF',
            'fontSize': 12,
            'borderRadius': 0,
            'paddingLeft': 15,
            'paddingRight': 15,
            'paddingTop': 0,
            'paddingBottom': 0
        }),
        multiValueRemove: (provided: any, state: any): any => ({
            ...provided,
            color: 'red',
            'borderRadius': 0,
            'backgroundColor': '#FFFFFF',
            '&:hover': {
                color: 'red',
                'backgroundColor': '#FFFFFF'
            },
        }),
        noOptionsMessage: (provided: any, state: any): any => ({
            ...provided,
            color: '#212529',
        }),
        option: (provided: any, state: any): any => ({
            ...provided,
        }),
        placeholder: (provided: any, state: any): any => ({
            ...provided,
            color: '#212529',
        }),
        singleValue: (provided: any, state: any): any => ({
            ...provided,
            color: '#212529',
        }),
        valueContainer: (provided: any, state: any): any => ({
            ...provided,
        }),
    };
}