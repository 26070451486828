import * as React from "react";
import { Component } from "react";
import { NotificationManager } from 'react-notifications';
import ReactTable from "react-table";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import moment from 'moment';

import './adminMessageCenter.scss';

import IAdminMessageCenterState from "./interfaces/IAdminMessageCenterState";
import IAdminMessageCenterProps from "./interfaces/IAdminMessageCenterProps";
import CustomPagination from "../../components/pagination/customPagination";
import EditGrey from "../../images/Edit_Grey.svg";
import EditBlue from "../../images/Edit_Blue.svg";
import AddGreen from "../../images/Add_Green.svg";
import DeleteBlue from "../../images/Delete_Blue.svg";
import DeleteGrey from "../../images/Delete_Grey.svg";

import IAdministratorMessage from "../../interfaces/IAdministratorMessage";
import AdminMessagePriority from "../../enums/adminMessagePriority";
import AdminMessageService from "../../services/adminMessageService";
import LanguageProvider from "../../providers/languageProvider";
import CoreSpaceService from "../../services/coreSpaceService";
import IReactSelectValue from "../../interfaces/IReactSelectValue";
import AdminMessageForm from "./components/adminMessageForm";
import AdministratorMessage from "../../models/administratorMessage";

export default class AdminMessageCenter extends Component<IAdminMessageCenterProps, IAdminMessageCenterState> {
    private readonly adminMessageService: AdminMessageService;
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: IAdminMessageCenterProps) {
        super(props);

        this.adminMessageService = new AdminMessageService();
        this.coreSpaceService = new CoreSpaceService();

        const emptyFormInput: IAdministratorMessage = {
            customerIds: '',
            isVisibleForAllCustomers: false,
            body: '',
            priority: AdminMessagePriority.Low,
            title: '',
            startDate: moment().format(),
            endDate: moment().add(1, 'days').format(),
            rowKey: '',
        };

        const state: IAdminMessageCenterState = {
            adminMessages: [],
            columns: this.getColumns(),
            loading: true,
            newAdminMessage: emptyFormInput,
            customers: [],
            customerSelectOptions: [],
            selectedRow: -1,
            showEditMessageForm: false,
            showNewMessageForm: false,
        };
        this.state = state;

        this.getAdminMessages = this.getAdminMessages.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.setActiveRow = this.setActiveRow.bind(this);
        this.renderEditMessageModal = this.renderEditMessageModal.bind(this);
        this.renderNewMessageForm = this.renderNewMessageForm.bind(this);
        this.handleFormCancel = this.handleFormCancel.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
    }

    public async componentDidMount(): Promise<void> {
        await this.getAdminMessages();
    }

    public getColumns(): any {
        return (
            [
                {
                    Header: '',
                    width: 25,
                    Cell: (row: any) : JSX.Element => (
                        <div>
                            <label className="checkbox-container" onClick={this.setActiveRow}>
                                <input type="checkbox" checked={row.index === this.state.selectedRow} readOnly />
                                <span className="checkmark" data-row-index={`${row.index}`} />
                            </label>
                            <span>{row.value}</span>
                        </div>
                    )
                }, {
                    Header: LanguageProvider.getTranslation('pages.adminmessagecenter.startdate'),
                    id: 'startDate',
                    width: 150,
                    accessor: (message: IAdministratorMessage) : string => message.startDate ? moment(message.startDate).format('X') : "",
                    Cell: (message: any) : string => message.original.startDate ? moment(message.original.startDate).format('DD-MM-YYYY, HH:mm') : "",
                }, {
                    Header: LanguageProvider.getTranslation('pages.adminmessagecenter.enddate'),
                    id: 'endDate',
                    width: 150,
                    accessor: (message: IAdministratorMessage) : string => message.endDate ? moment(message.endDate).format('X') : "",
                    Cell: (message: any) : string => message.original.endDate ? moment(message.original.endDate).format('DD-MM-YYYY, HH:mm') : ""
                }, {
                    Header: LanguageProvider.getTranslation('pages.adminmessagecenter.title'),
                    id: 'title',
                    accessor: 'title',
                }, {
                    Header: LanguageProvider.getTranslation('pages.adminmessagecenter.message'),
                    id: 'body',
                    accessor: 'body',
                }, {
                    Header: LanguageProvider.getTranslation('pages.adminmessagecenter.customers'),
                    id: 'customerNames',
                    accessor: 'customerNames',
                }, {
                    Header: LanguageProvider.getTranslation('pages.adminmessagecenter.visibleforallcustomers'),
                    id: 'isVisibleForAllCustomers',
                    accessor: (message: IAdministratorMessage) : string => LanguageProvider.getTranslation(`pages.adminmessagecenter.${message.isVisibleForAllCustomers}`),
                }
                , {
                    Header: LanguageProvider.getTranslation('pages.adminmessagecenter.priority'),
                    id: 'priority',
                    accessor: (message: IAdministratorMessage) : string => LanguageProvider.getTranslation(`pages.adminmessagecenter.messagepriority.${AdminMessagePriority[message.priority].toLowerCase()}`)
                }
            ]
        );
    }

    private updateColumns(): void {
        const columns = this.getColumns();

        this.setState({ columns: columns });
    }

    public onRowClick(event: any): JSX.Element {
        const message = this.state.adminMessages[event.index] as AdministratorMessage;
        if (!message) {
            return <div />;
        }
        return (
            <div className="row admin-message-subcomponent">
                <div className="col-sm-6">
                    <div className="message-details-header">
                        {LanguageProvider.getTranslation('pages.adminmessagecenter.startdate')}
                    </div>
                    <div className="message-content">
                        {moment(message.startDate).format("DD-MM-YYYY, HH:mm")}
                    </div>

                    <div className="message-details-header">
                        {LanguageProvider.getTranslation('pages.adminmessagecenter.enddate')}
                    </div>
                    <div className="message-content">
                        {moment(message.endDate).format("DD-MM-YYYY, HH:mm")}
                    </div>

                    <div className="message-details-header">
                        {LanguageProvider.getTranslation('pages.adminmessagecenter.priority')}
                    </div>
                    <div className="message-content">
                        {LanguageProvider.getTranslation(`pages.adminmessagecenter.messagepriority.${AdminMessagePriority[message.priority].toLowerCase()}`)}
                    </div>

                    <div className="message-details-header">
                        {LanguageProvider.getTranslation('pages.adminmessagecenter.visibleforallcustomers')}
                    </div>
                    <div className="message-content">
                        {LanguageProvider.getTranslation(`pages.adminmessagecenter.${message.isVisibleForAllCustomers}`)}
                    </div>

                    <div className="message-details-header">
                        {LanguageProvider.getTranslation('pages.adminmessagecenter.customers')}
                    </div>
                    <div className="message-content">
                        {message.customerNames}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="message-details-header">
                        {LanguageProvider.getTranslation('pages.adminmessagecenter.title')}
                    </div>
                    <div className="message-content">
                        {message.title}
                    </div>

                    <div className="message-details-header">
                        {LanguageProvider.getTranslation('pages.adminmessagecenter.message')}
                    </div>
                    <div className="message-content">
                        {message.body}
                    </div>
                </div>
            </div>
        );
    }

    private async setActiveRow(e: any): Promise<void> {
        e.stopPropagation();
        e.preventDefault();
        const rowIndexNumber = parseInt(e.target.dataset.rowIndex, 0);
        if (this.state.selectedRow === rowIndexNumber) {
            await this.setState({
                selectedRow: -1,
            });
        }
        else {
            await this.setState({
                selectedRow: rowIndexNumber,
            });
        }
    }

    public async getAdminMessages(): Promise<void> {
        await this.setState({
            loading: true,
        });

        const customers = await this.coreSpaceService.getCustomers();

        const customerSelectOptions: IReactSelectValue[] = customers.map(c => ({
            label: c.name,
            value: c.id
        }));
        await this.setState({ customers, customerSelectOptions, });
        let adminMessages;
        const results: IAdministratorMessage[] = [];
        try {
            adminMessages = await this.adminMessageService.getAdminMessages();
            adminMessages.forEach((message: IAdministratorMessage) => {
                results.push(new AdministratorMessage(message, customers));
            });
        } catch (error) {
            NotificationManager.error(LanguageProvider.getTranslation('pages.adminmessagecenter.errormessage'));
            await this.setState({
                customers,
                customerSelectOptions,
                loading: false,
            });
        }

        this.setState({
            adminMessages: results,
            loading: false,
            showEditMessageForm: false,
            showNewMessageForm: false
        });
    }

    public renderEditMessageModal(): void {
        this.setState({
            showEditMessageForm: true,
            showNewMessageForm: false
        });
    }

    public renderNewMessageForm(): void {
        this.setState({
            showNewMessageForm: true,
            showEditMessageForm: false
        });
    }

    public async handleFormCancel(): Promise<void> {
        this.setState({
            showEditMessageForm: false,
            showNewMessageForm: false,
        });
    }

    public async deleteMessage(): Promise<void> {
        const consent = window.confirm(LanguageProvider.getTranslation("pages.adminmessagecenter.deleteconsentmessage"));
        if (!consent) {
            return;
        }

        const message = this.state.adminMessages[this.state.selectedRow];
        if (message.rowKey !== undefined) {
            await this.adminMessageService.deleteAdminMessage(message.rowKey);
        } else {
            NotificationManager.error(LanguageProvider.getTranslation("pages.adminmessagecenter.deletemessageerror"));
            return;
        }

        NotificationManager.success(LanguageProvider.getTranslation("pages.adminmessagecenter.deletemessagesucces"));
        this.getAdminMessages();
    }

    public render(): JSX.Element {
        return (
            <div className="admin-message-center">
                {this.state.showNewMessageForm &&
                    <AdminMessageForm
                        adminMessage={this.state.newAdminMessage}
                        customerSelectOptions={this.state.customerSelectOptions}
                        refreshMessages={this.getAdminMessages}
                        isNewMessage={true}
                        handleCancel={this.handleFormCancel}
                    />}
                {this.state.showEditMessageForm &&
                    <AdminMessageForm
                        adminMessage={this.state.adminMessages[this.state.selectedRow]}
                        customerSelectOptions={this.state.customerSelectOptions}
                        refreshMessages={this.getAdminMessages}
                        isNewMessage={false}
                        handleCancel={this.handleFormCancel}
                    />}

                <div hidden={this.state.showEditMessageForm || this.state.showNewMessageForm ? true : false}>
                    <div className="row mt-5 mb-4" id="message-table-header-section">
                        <div className="col-md-11">
                            {this.state.adminMessages && <h3>{LanguageProvider.getTranslation('pages.adminmessagecenter.adminmessagecenterheader')}</h3>}
                        </div>
                        <div id="message-table-buttons col-md-1">
                            {this.state.adminMessages.length > 0 && <img src={this.state.selectedRow === -1 ? EditGrey : EditBlue} alt="Edit" id="edit-icon" onClick={this.state.selectedRow === -1 ? () : string => NotificationManager.info(LanguageProvider.getTranslation('pages.adminmessagecenter.selectamessage')) : this.renderEditMessageModal} />}
                            {this.state.adminMessages.length > 0 && <img src={this.state.selectedRow === -1 ? DeleteGrey : DeleteBlue} alt="Delete" id="edit-icon" onClick={this.state.selectedRow === -1 ? () : string => NotificationManager.info(LanguageProvider.getTranslation('pages.adminmessagecenter.selectamessage')) : this.deleteMessage} />}
                            <img src={AddGreen} alt="Add" id="add-icon" onClick={this.renderNewMessageForm} />
                        </div>
                    </div>

                    {this.state.adminMessages && this.state.adminMessages.length > 0 &&
                        <ReactTable
                            className="admin-message-table-overview"
                            columns={this.state.columns}
                            loading={this.state.loading}
                            showPaginationBottom={true}
                            showPaginationTop={false}
                            defaultPageSize={50}
                            data={this.state.adminMessages}
                            SubComponent={this.onRowClick}
                            PaginationComponent={CustomPagination}
                            minRows={0}
                            defaultSorted={[{
                                id: 'startDate',
                                desc: false
                            }]}
                        />
                    }
                </div>
            </div>
        );
    }
}