import React from "react";
import ReactTable, { Column } from "react-table";
import { PulseLoader } from "react-spinners";
import { NotificationManager } from 'react-notifications';

import CustomPagination from "../../components/pagination/customPagination";
import LanguageProvider from "../../providers/languageProvider";
import IDocumentManagementProps from "./interfaces/IDocumentManagementProps";
import IDocumentManagementState from "./interfaces/IDocumentManagementState";

import "./documentManagement.scss";
import Mapper from "../../translations/mapper";
import { ReactTableUtils } from "../../utils/reactTableUtils";
import AddGreen from "../../images/Add_Green.svg";
import DeleteBlue from "../../images/Delete_Blue.svg";
import DeleteGrey from "../../images/Delete_Grey.svg";
import UploadModal from "./uploadModal";
import DocumentUtils from "../../utils/documentUtils";
import IDocument from "./interfaces/IDocument";
import CoreSpaceService from "../../services/coreSpaceService";
import Dictionary from "../../utils/dictionary";
import HttpResponseCodeError from "../../errors/httpResponseCodeError";
import MaintenanceService from "../../services/maintenanceService";

export default class DocumentManagement extends React.Component<IDocumentManagementProps, IDocumentManagementState> {

    private readonly maintenanceService: MaintenanceService;
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: IDocumentManagementProps) {
        super(props);

        this.maintenanceService = new MaintenanceService();
        this.coreSpaceService = new CoreSpaceService();

        const state: IDocumentManagementState = {
            columns: this.getColumns(),
            loading: true,
            documents: [],
            selectedRow: -1
        };

        this.state = state;

        this.setActiveRow = this.setActiveRow.bind(this);
        this.deleteFileAsync = this.deleteFileAsync.bind(this);
        this.initializeDocumentsAsync = this.initializeDocumentsAsync.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        await this.initializeDocumentsAsync();
    }

    private async initializeDocumentsAsync(): Promise<void> {
        const documentMetaDataTask = this.maintenanceService.getAllDocuments(true);
        const venuesTask = this.coreSpaceService.getVenuesWithAllRelatedData();
        const customersTask = this.coreSpaceService.getCustomers();

        const [documentMetaData, venues, customers] = await Promise.all([documentMetaDataTask, venuesTask, customersTask]);

        const venueLookup = new Dictionary<string>();
        const customerLookup = new Dictionary<string>();
        venues.forEach(venue => venueLookup.add(venue.id, venue.name));
        customers.forEach(customer => customerLookup.add(customer.id, customer.name));

        const transformedDocuments: IDocument[] = documentMetaData.map(d => {
            return {
                venue: venueLookup.item(d.venueId),
                customer: customerLookup.item(d.customerId),
                documentType: d.type,
                fileName: d.name,
                uploadedOn: new Date(d.createdDate),
                downloadedOn: new Date(d.downloadedOn),
                id: d.blobId
            };
        }
        );
        this.setState({ documents: transformedDocuments, loading: false });
    }

    private getColumns(): Column[] {
        const columns = [
            {
                id: 'DocumentType',
                Header: LanguageProvider.getTranslation(Mapper.pages.upload.columns.documenttype),
                accessor: 'documentType',
                filterable: true,
                Cell: (row: any): JSX.Element => (
                    <div>
                        <label className="checkbox-container" onClick={this.setActiveRow}>
                            <input type="checkbox" checked={row.index === this.state.selectedRow} onChange={(): void => { }} />
                            <span className="checkmark" data-row-index={`${row.index}`} />
                        </label>
                        <span>{row.value}</span>
                    </div>
                )
            },
            {
                id: 'Customer',
                Header: LanguageProvider.getTranslation(Mapper.pages.upload.columns.customer),
                accessor: 'customer',
                filterable: true
            },
            {
                id: 'Venue',
                Header: LanguageProvider.getTranslation(Mapper.pages.upload.columns.venue),
                accessor: 'venue',
                filterable: true
            },
            {
                id: 'Filename',
                Header: LanguageProvider.getTranslation(Mapper.pages.upload.columns.filename),
                accessor: 'fileName',
                filterable: true,
                Cell: (row: any): JSX.Element => <div><span title={row.value}>{row.value}</span></div>
            },
            {
                id: 'UploadedOn',
                Header: LanguageProvider.getTranslation(Mapper.pages.upload.columns.uploadedon),
                accessor: 'uploadedOn',
                Cell: (row: any): JSX.Element => (<span>{row.value.toLocaleString()}</span>)
            },
            {
                id: 'DownloadedOn',
                Header: LanguageProvider.getTranslation(Mapper.pages.upload.columns.downloadedon),
                accessor: 'downloadedOn',
                Cell: (row: any): JSX.Element => (<span>{row.value.getFullYear() !== 1 ? row.value.toLocaleString() : "-"}</span>)
            },
            {
                id: 'Download',
                Header: 'Download',
                Cell: (row): any => (
                    <button className={"btn btn-primary btn-block w-50 ml-auto mr-auto"}
                        onClick={this.getDocument(row.original.id, row.original.fileName)}>Download</button>
                )
            },
        ];

        return columns;
    }

    private getDocument(blobid: string, filename: string) {
        return (event: any): void => {
            this.downloadDocumentAsync(blobid, filename);
        };
    }

    private async downloadDocumentAsync(blobId: string, filename: string): Promise<void> {
        try{
            const content = await this.maintenanceService.getDocumentContent(blobId);
            DocumentUtils.openDocument(content, filename);
        }
        catch (error){
            if(error instanceof HttpResponseCodeError && (error as HttpResponseCodeError).StatusCode === 403){
                NotificationManager.error(LanguageProvider.getTranslation(Mapper.pages.upload.forbiddendocument));
            }
        }
    }

    private setActiveRow(e: any): void {
        e.stopPropagation();
        e.preventDefault();
        const rowIndexNumber = parseInt(e.target.dataset.rowIndex, 10);

        // Reset when a click occurs on a currently selected item
        if (this.state.selectedRow === rowIndexNumber) {
            this.setState({
                selectedRow: -1,
                selectedDocument: undefined
            });
        }
        else {
            this.setState({
                selectedRow: rowIndexNumber,
                selectedDocument: this.state.documents[rowIndexNumber]
            });
        }
    }

    private async deleteFileAsync(): Promise<void> {
        if (!this.state.selectedDocument) {
            return;
        }

        if (!window.confirm(LanguageProvider.getTranslation(Mapper.pages.upload.deleteareyousure))) {
            return;
        }

        const id = this.state.selectedDocument.id;
        await this.maintenanceService.deleteDocument(id);

        this.setState({
            selectedDocument: undefined,
            selectedRow: -1
        }, async () => await this.initializeDocumentsAsync());
    }

    public render(): JSX.Element {
        return (
            <div className="document-management">
                <div className="row mb-4 mt-5">
                    <div className="col-md-11">
                        <h3>{LanguageProvider.getTranslation(Mapper.pages.upload.title)}</h3>
                    </div>
                    <div id="col-md-1">
                        {this.state.documents.length > 0 &&
                            <img src={this.state.selectedRow === -1 ? DeleteGrey : DeleteBlue}
                                alt="Delete" id="delete-icon"
                                className={this.state.selectedRow === -1 ? "" : "clickable"}
                                onClick={this.deleteFileAsync} />}
                        <img src={AddGreen} id="add-icon" alt="Add" className="clickable ml-2" data-toggle="modal" data-target="#upload-modal" />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <PulseLoader
                        color={'#009FE3'}
                        size={10}
                        margin={"15px"}
                        loading={this.state.loading} />
                </div>

                {!this.state.loading && <>
                    <ReactTable
                        defaultPageSize={10}
                        pageSize={this.state.documents.length > 10 ? 10 : this.state.documents.length}
                        className="documents-table"
                        columns={this.state.columns}
                        resizable={false}
                        loading={this.state.loading}
                        minRows={0}
                        showPaginationTop={false}
                        showPagination={this.state.documents.length >= 10}
                        PaginationComponent={CustomPagination}
                        data={this.state.documents}
                        defaultFilterMethod={ReactTableUtils.useContainsFilter} />

                    <UploadModal onSuccessfulUpload={this.initializeDocumentsAsync} /></>}
            </div>
        );
    }
}