import IApplicationConfig from "./interfaces/IApplicationConfig";

export const ApplicationConfig: IApplicationConfig = {
    endpoints: {
        dynamics: process.env.REACT_APP_endpoints_dynamics || "",
        winvisionMaintenanceApi: process.env.REACT_APP_endpoints_winvisionMaintenanceApi || "",
        winvisionAutodeskProxy: process.env.REACT_APP_endpoints_winvisionAutodeskProxy || "",
    },
    environment: process.env.REACT_APP_environment || "",
    // "true".toLowerCase since this makes it a non-constant string. When we use variable injection on the .env values
    // we need this, since otherwise constant string === constant string is optimized to a boolean during build (before we inject the .env values).
    disableCosmeticPermissions: process.env.REACT_APP_disableCosmeticPermissions === "true".toLowerCase() || false,

    ad: {
        tenant: "common",
        clientId: process.env.REACT_APP_clientId || "",
        endpoints: {
            winvisionMaintenanceApi: process.env.REACT_APP_appIds_maintenanceApi || "",
        },
        scopes: {
            MaintenanceFrontend: process.env.REACT_APP_scopes_MaintenanceFrontend || "",
            winvisionMaintenanceApi: process.env.REACT_APP_scopes_maintenanceApi || "",
        },
        cacheLocation: 'sessionStorage',
        redirectUri: window.location.origin
    },
    autodesk: {
        header: {
            venueId: "beyondeyes-autodesk-venueid",
        },
    },
};