enum roles {
    FacilityManager = "FacilityManager",
    TeamMember = "TeamMember",
    FunctionalAdmin = "FunctionalAdmin",
    Analyst = "Analyst",
    CleaningAdmin = "CleaningAdmin",
    Cleaner = "Cleaner",
    CleaningCustomerEmployee = "CleaningCustomerEmployee",
    Administrator = "Administrator",
    CleaningDistrictManager = "CleaningDistrictManager",
    Tester = "Tester",
    CleaningObjectLeader = "CleaningObjectLeader",
    Developer = "Developer",
    CustomerManager = "CustomerManager",
    CleaningRayonManager = "CleaningRayonManager",
    Application = "Application",
    WorkspacesAdmin = "WorkspacesAdmin"
}

export default roles;