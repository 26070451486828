import React, { useCallback, useEffect, useRef, useState } from "react";
import { AutodeskMap, IAutodeskFloorChangeEvent, IFloor, IFloorChangeEvent, ISpaceOnMap, ModelUtils } from "@beyondeyes/shared";
import MaintenanceUserAuthenticator from "../../../authenticators/maintenanceUserAuthenticator";
import { ApplicationConfig } from "../../../config";
import { NotificationManager } from 'react-notifications';
import LanguageProvider from "../../../providers/languageProvider";
import Mapper, { translations } from "../../../translations/mapper";
import CoreSpaceService from "../../../services/coreSpaceService";
import CoreSpaceIncludes from "../../../enums/coreSpaceIncludes";
import "./maintenanceMap.scss";
import { autodeskConstants } from "../autodeskConstants";
import { replaceString } from "../../../utils/stringUtils";

export interface IMapProps {
    venueId?: string;
    onFloorChange?: (event: IFloorChangeEvent) => void;
    spaces?: ISpaceOnMap[];
    onNoPreviewAvailable: () => void;
}

interface IDocumentFailedDetails {
    errorMessage: string;
    errorCode: any;
    floor: IFloor;
}

export const MaintenanceMap: React.FC<IMapProps> = (props) => {

    const [accessToken, setAccessToken] = useState<string>();
    const [showMap, setShowMap] = useState<boolean>();
    const [mapFloors, setMapFloors] = useState<IFloor[]>();
    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
    const [documentFailedDetails, setDocumentFailedDetails] = useState<IDocumentFailedDetails>();
    const [mapName, setMapName] = useState<string>();

    const proxyEndpoint = ApplicationConfig.endpoints.winvisionAutodeskProxy;
    const { onFloorChange, venueId, onNoPreviewAvailable } = props;

    const maxRetries = 5;
    const count = useRef<number>(0);

    const reloadFloors = useCallback(
        (async (): Promise<void> => {
            const coreSpaceService = new CoreSpaceService();
            const floors = await coreSpaceService.getFloorsForVenue(venueId!, [CoreSpaceIncludes.Properties], true);

            const mapFloors = floors.filter(floor => floor.previewUrn)
                .map(floor => ({ id: floor.id, venueId: floor.venueId, urn: floor.previewUrn, level: parseInt(floor.floorLevel) }));
            setMapFloors(mapFloors);
            const previewsAvailable = mapFloors.length > 0;
            setShowMap(previewsAvailable);
            if (!previewsAvailable) {
                onNoPreviewAvailable();
            }
        }), [venueId, onNoPreviewAvailable]
    );

    const onFloorChangedAsync = useCallback(
        async (context: IAutodeskFloorChangeEvent): Promise<void> => {
            if (!onFloorChange) {
                return;
            }
            const mapName = atob(context.floor.urn).split('/').pop();
            if (mapName) {
                const decodedName = decodeURI(mapName);
                const removeGuidRegex = /\b[a-f\d-]{37}\b/;
                setMapName(decodedName.replace(removeGuidRegex, '').replace(".zip", ''));
            }
            const mapSpaces = await ModelUtils.getAllSpacesFromViewModelAsync(context.model, {}, [autodeskConstants.assetType, autodeskConstants.assetExternalId]);
            onFloorChange({ floor: context.floor, renderedSpaces: mapSpaces });
            // eslint-disable-next-line
        }, []);

    useEffect(() => {
        if (!venueId) {
            return;
        }

        (async (): Promise<void> => {
            reloadFloors();
        })();

    }, [venueId, reloadFloors]);

    const onDocumentLoadFailure = async (errorCode: any, errorMsg: string, floor: IFloor): Promise<void> => {
        if (count.current < maxRetries) {
            let message = LanguageProvider.getTranslation(Mapper.pages.autodesk.translationinprogress);
            message = replaceString(message, errorMsg, (count.current + 1).toString(), maxRetries.toString());

            NotificationManager.info(message);
            setShowMap(false);
            await delay(5000);
            await reloadFloors();
            setShowMap(true);
            count.current++;
        }
        else {
            NotificationManager.warning(LanguageProvider.getTranslation(Mapper.pages.autodesk.maploadfailed));
            setShowErrorMsg(true);
            setDocumentFailedDetails({
                errorCode: errorCode,
                errorMessage: errorMsg,
                floor: floor
            }); 
        }
    };

    const delay = (ms: number): Promise<void> => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const getCustomHeaders = (): { [key: string]: string } => {
        return { "beyondeyes-autodesk-skipcache": "true" };
    };

    const getMinFloorLevel = (): number => {
        if (!mapFloors) {
            return 0;
        }

        return Math.min(...mapFloors.map(m => m.level));
    };

    useEffect(() => {
        let mounted = true;
        (async (): Promise<void> => {
            if (!mounted) {
                return;
            }
            const authenticator = new MaintenanceUserAuthenticator();
            const accessToken = await authenticator.getTokenAsync();
            setAccessToken(`Bearer ${accessToken}`);
        })();
        return (): void => { mounted = false; };
    }, []);

    return (
        <>
            {showErrorMsg && <div className="alert alert-danger text-break">
                {LanguageProvider.getTranslation(translations.pages.autodesk.loadingfailed)}<br />
                <b>Error Code</b> {documentFailedDetails?.errorCode} - {documentFailedDetails?.errorMessage}<br />
                <b>Floorlevel</b> {documentFailedDetails?.floor.level}<br />
                <b>Floor ID</b> {documentFailedDetails?.floor.id}<br />
                <b>Floor URN</b> {documentFailedDetails?.floor.urn}
            </div>}
            {showMap && (
                <>
                    <h3>{mapName}</h3>
                    <AutodeskMap
                        id="autodesk-map"
                        accessToken={accessToken}
                        venueId={props.venueId}
                        floors={mapFloors}
                        proxyEndpoint={proxyEndpoint}
                        onFloorChangedAsync={onFloorChangedAsync}
                        onDocumentLoadFailure={onDocumentLoadFailure}
                        customHeaders={getCustomHeaders()}
                        startFloorLevel={getMinFloorLevel()}
                        spaces={props.spaces}
                        maxNumberOfFloorButtons={7}
                    />
                </>)}
        </>
    );
};
