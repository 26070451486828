import IAuthenticator from "../authenticators/interfaces/IAuthenticator";
import ClientSideCache from "../models/clientSideCache";
import HttpResponseCodeError from "../errors/httpResponseCodeError";

type CRUDOperation = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export default abstract class BaseService {
  public constructor(
    private resource: string,
    private authenticator?: IAuthenticator
  ) { }

  public async get<T>(
    query: string,
    skipCacheCheck: boolean = false
  ): Promise<T> {
    const url = `${this.resource}/${query}`;

    if (!skipCacheCheck) {
      const cachedResponse = ClientSideCache.TryGetResponseFromCache(url);
      if (cachedResponse !== undefined) {
        return cachedResponse;
      }
    }

    return this.execute(query, "GET").then(r => {
      if (r.status !== 200 && r.status !== 202 && r.status !== 204) {
        throw new HttpResponseCodeError(`Requested url ${url} returned non succes statuscode (${r.status}).`, r.status);
      }

      return r.json().then(result => {
        ClientSideCache.AddReponseToCache(url, result);
        return result;
      });
    }
    );
  }

  public async postFormData(query: string, data: FormData): Promise<Response> {
    let request: RequestInit = {
      method: "POST",
      body: data
    };

    if (this.authenticator) {
      request = await this.authenticator.authenticateRequest(request);
    }
    const url = `${this.resource}/${query}`;

    return fetch(url, request).then(response => {
      if (!response.ok) {
        console.error(response.statusText);
      }
      return response;
    });
  }

  public async post(query: string, data: any): Promise<Response> {
    return this.execute(query, "POST", data);
  }

  public async postWithResponse<T>(query: string, data: any): Promise<[Response, T]> {
    var response = await this.execute(query, "POST", data);
    return ([response, response.ok ? await response.json() : undefined]);
  }

  public async put(query: string, data: any): Promise<Response> {
    return this.execute(query, "PUT", data);
  }

  public async putWithResponse<T>(query: string, data: any): Promise<[Response, T]> {
    var response = await this.execute(query, "PUT", data);
    return ([response, response.ok ? await response.json() : undefined]);
  }

  public async patch(query: string, data: any): Promise<Response> {
    return this.execute(query, "PATCH", data);
  }

  public async delete(query: string): Promise<Response> {
    return this.execute(query, "DELETE");
  }

  private async execute(
    query: string,
    method: CRUDOperation,
    data?: any
  ): Promise<Response> {
    const url = `${this.resource}/${query}`;
    let request: RequestInit = {
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      },
      method
    };

    if (data) {
      request.body = JSON.stringify(data);
    }
    if (this.authenticator) {
      request = await this.authenticator.authenticateRequest(request);
    }
    return fetch(url, request).then(response => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return response;
    });
  }
}
