import * as React from 'react';
import IPaginationButtonProps from './interfaces/IPaginationButtonProps';
import IPaginationButtonState from './interfaces/IPaginationButtonState';
import { withLocalize } from 'react-localize-redux';

class PaginationButton extends React.Component<IPaginationButtonProps, IPaginationButtonState> {
    public render(): JSX.Element {
        return (
            <button type="button" onClick={this.props.onClick} className="clickable">
                <img src={this.props.imageUrl} alt="Pagination"/>
            </button>
        );
    }
}

export default withLocalize(PaginationButton);