import * as React from 'react';
import classnames from 'classnames';
import Arrow_Left from "../../images/Arrow Left.svg";
import Arrow_Right from "../../images/Arrow Right.svg";
import Double_Arrow_Left from "../../images/Double Arrow Left.svg";
import Double_Arrow_Right from "../../images/Double Arrow Right.svg";
import "./customPagination.css";
import PaginationButton from './paginationButton';
import LanguageProvider from '../../providers/languageProvider';
//
// import _ from './utils'


const oneStepButton = (props) : JSX.Element => (
    <button type="button" {...props} className="clickable">
        {props.children}
    </button>
);

export default class CustomPagination extends React.Component<any, any> {
    public constructor(props: any) {
        super(props);

        this.getSafePage = this.getSafePage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.lastPage = this.lastPage.bind(this);

        this.state = {};
    }

    public getSafePage(page: number): number {
        if (Number.isNaN(page)) {
            page = this.props.page;
        }
        return Math.min(Math.max(page, 0), this.props.pages - 1);
    }

    public changePage(page: number): void {
        page = this.getSafePage(page);
        this.setState({ page });
        if (this.props.page !== page) {
            this.props.onPageChange(page);
        }
    }

    private firstPage(): void {
        if (!this.props.canPrevious) { return; }
        this.changePage(0);
    }

    private previousPage(): void {
        if (!this.props.canPrevious) { return; }
        this.changePage(this.props.page - 1);
    }

    private nextPage(): void {
        if (!this.props.canNext) { return; }
        this.changePage(this.props.page + 1);
    }

    private lastPage(): void {
        if (!this.props.canNext) { return; }
        this.changePage(this.props.pages - 1);
    }

    public render(): JSX.Element {
        const {
            // Computed
            pages,
            // Props
            page,
            pageSize,
            canPrevious,
            canNext,
            className,
            OnePreviousComponent = oneStepButton,
            OneNextComponent = oneStepButton,
        } = this.props;

        return (
            <div className={classnames(className, 'custom-pagination')} style={this.props.style}>
                <div className="result-counter">
                    {page * pageSize + 1} {LanguageProvider.getTranslation("pagination.to")} {pages - 1 > page ? (page + 1) * pageSize : this.props.resolvedData.length} {LanguageProvider.getTranslation("pagination.of")} {this.props.resolvedData.length} {LanguageProvider.getTranslation("pagination.items")}
                </div>
                <div className="-previous">
                    <PaginationButton
                        onClick={this.firstPage}
                        imageUrl={Double_Arrow_Left}

                    />
                    <PaginationButton
                        onClick={this.previousPage}
                        imageUrl={Arrow_Left}

                    />
                </div>

                <div className="-center">
                    {canPrevious && <OnePreviousComponent
                        onClick={this.previousPage}
                        disabled={!canPrevious}>
                        {page}
                    </OnePreviousComponent>
                    }
                    <span className="current-page">
                        {page + 1}
                    </span>
                    {canNext && <OneNextComponent
                        onClick={this.nextPage}
                        disabled={!canNext}>
                        {page + 2}
                    </OneNextComponent>
                    }
                </div>
                <div className="-next">
                    <PaginationButton
                        onClick={this.nextPage}
                        imageUrl={Arrow_Right}
                    />
                    <PaginationButton
                        onClick={this.lastPage}
                        imageUrl={Double_Arrow_Right}
                    />
                </div>
            </div >
        );
    }
}